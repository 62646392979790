import ToConfirmButton from "../entity/ToConfirmButton";
import NewValueDataStore from "../entity/NewValueDataStore";
import DateManager, { BASE_DATETIME_FORMAT } from "./DateManager";
import Swal from "sweetalert2";
import { INVALID_FORM_FIELD_CLASS } from "./utils";
import $ from "jquery";
import { DateTime } from "luxon";

const NEW_VALUE_ID_PREFIX = "new-value-form";
const NEW_VALUE_MESSAGE_ID_SUFFIX = "new-value-message";

const MODAL_ID_SUFFIX = "new-form-modal";

const NEW_VALUE_MESSAGE_CLASS_SUCCESS = "form-modal-message-success";
const NEW_VALUE_MESSAGE_CLASS_ERROR = "form-modal-message-error";

const DATEPICKER_FIELD_ID = "pack-datepicker-new-value";

const NEW_VALUE_MESSAGE_CLASSES = [NEW_VALUE_MESSAGE_CLASS_ERROR, NEW_VALUE_MESSAGE_CLASS_SUCCESS];

/**
 * Abstract pack form for measure indexes
 */
class AbstractPackNewMeasureForm {
  constructor(prefix, fields, datePicker, container = $("body")) {
    this.container = container;
    this.indics = [];

    this._message = $(`#${prefix}-${NEW_VALUE_MESSAGE_ID_SUFFIX}`);

    // The form itself
    this._form = $(`#${prefix}-${NEW_VALUE_ID_PREFIX}`);

    // Submit button
    this._submit = new ToConfirmButton(`#${prefix}-${NEW_VALUE_ID_PREFIX}-submit`);

    // Callbacks called when data are sent
    this._data_sent_callbacks = [];

    // Fields
    this._fields_keys = fields;
    this._fields = {};
    for (let id of fields) {
      this._fields[id] = $(`#${prefix}-${NEW_VALUE_ID_PREFIX}-${id}`);
    }

    this.getParameters();

    this._data_store = new NewValueDataStore(
      "one-day",
      prefix,
      this._pool_id,
      this._pond_id,
      this._circuit_id,
      this._client_id,
    );

    $(`#${prefix}-${MODAL_ID_SUFFIX}`).on("hide.bs.modal", () => {
      $(datePicker).hide();
    });

    this._date_field = $(`#${DATEPICKER_FIELD_ID}`);

    this.resetMessage = this.resetMessage.bind(this);

    this.ref_data = null;

    this.tooltips = [];
  }

  stopRequests() {
    this._data_store.abortRequests();
  }

  getParameters() {
    // Get current page parameters
    let body = $("body");
    this._pool_id = body.data("pool");
    this._client_id = body.data("client");
    this._onsen_user = !!body.data("onsen-user");
    this._attendance_manager = !!body.data("attendance-manager");

    this._pond_id = null;
    this._circuit_id = body.data("circuit") || null;

    this._pond_name = null;
  }

  /**
   * Method called after constructor, used to perform methods at the start but after child constructor
   */
  init() {
    // Manage date
    let ctx = this;
    this._date_field.on("change", function () {
      let value = $(this).val();
      if ($(this).prop("max")) {
        $(this).val(Date.now() < new Date(value) ? DateTime.local().toISO().substring(0, 16) : value);
      }
      ctx.checkExistingData();
    });
    this.checkExistingData();

    Object.values(this._fields).forEach((elt) => {
      elt.change(() => {
        this.fieldChangeCallback(elt);
      });
    });

    // Manage form handling
    this._form.submit((e) => {
      e.preventDefault();
      this._submit.click();
    });

    this._submit.click(
      (e) => {
        e.preventDefault();
        this.message("Chargement");
        this.errorMessage("");

        this.handleForm().then(
          // ? OK
          () => {
            this.postSendPopup();
          },
          // ? KO
          (reason) => {
            Swal.fire({
              title: "Une erreur s'est produite",
              icon: "error",
            });
            this.errorMessage("Une erreur s'est produite.");
            this._submit.reset();
          },
        );
      },
      (e) => {
        let missingFields = false;
        Object.values(this._fields).forEach((elt) => {
          if (elt.prop("required")) {
            let missingField = !this.checkField(elt);
            missingFields |= missingField;
          }
        });
        if (missingFields) {
          this.errorMessage("Veuillez remplir tous les champs obligatoires.");
        }
        return !missingFields;
      },
    );
  }

  /**
   * Information popup after data has been sent to server
   **/
  postSendPopup() {
    this.successMessage("Saisie enregistrée.");

    let tolReached = false;

    let formData = this.readFormData();
    let content = `
            <table class="table popupTable">
            <thead>
            <tr>
                <th scope="col"></th>
                <th scope="col">Consommation calculée depuis la dernière saisie</th>
                <th scope="col">Consommation télérelevée</th>
                <th scope="col">Erreur calculée</th>
                <th scope="col">Pourcentage d'erreur</th>
            </tr>
            </thead>
            <tbody>
        `;
    for (const key in formData.data) {
      if (Object.hasOwnProperty.call(formData.data, key) && !isNaN(formData.data[key])) {
        const element = formData.data[key];
        const consumption = Math.max(0, element - this.ref_data?.[key]?.value); // IF reset of indexes

        let refval = this.ref_data?.[key]?.remote;
        let tol = this.ref_data?.[key]?.reference?.tol;
        let indicator = this.ref_data?.indicators?.find((indic) => indic.reference == key);

        let error_percent = refval ? ((refval - consumption) / consumption) * 100 : null;

        tolReached |= Math.abs(error_percent) > tol;
        content += `
                <tr class= "${
                  Math.abs(error_percent) > tol ? "table-danger" : refval ? "table-success" : "table-secondary"
                }">
                    <th scope="row">${indicator?.label}</th>
                    <td>${consumption > 0 ? consumption.toFixed(2) : "inchangée"}</td>
                    <td>${refval ? parseFloat(refval).toFixed(2) : "-"}</td>
                    <td>${consumption && refval ? (refval - consumption).toFixed(2) : "-"}</td>
                    <td>${consumption && error_percent ? error_percent.toFixed(0) + "%" : "-"}</td>
                </tr>`;
      }
    }

    content += `</tbody></table>`;

    if (tolReached) {
      content +=
        `<p class="text-danger">Le pourcentage d'erreur sur l'un des index est jugé important,` +
        ` le support Onsen est alerté et vous recontactera dans les meilleurs delais</p>`;
    }

    Swal.fire({
      title: "Saisie enregistrée",
      html: content,
      width: "auto",
    });
    this._data_sent_callbacks.forEach((callback) => {
      callback();
    });
    this.checkExistingData();
  }

  /**
   * Method called when form should be sent
   *
   * @returns {Promise}
   */
  handleForm() {
    try {
      let formData = this.readFormData();
      return this._data_store.sendData(formData.date, formData.data);
    } catch (error) {
      this.errorMessage("Erreur dans le formulaire.");
      throw error;
    }
  }

  /**
   * Reads the form data and return it
   */
  readFormData() {}

  /**
   * This method will be called when a field value change
   *
   * @param field the field that has changed
   */
  fieldChangeCallback(field) {
    if (field) {
      this.checkField(field);
    }
  }

  /**
   * Remove all format from the message
   *
   * @param hide
   */
  resetMessage(hide = true) {
    this._message.removeClass(NEW_VALUE_MESSAGE_CLASSES);

    if (hide) {
      this._message.text("");
      this._message.hide();
    }
  }

  /**
   * Display a custom text in the message
   *
   * @param message
   */
  message(message) {
    this.resetMessage();
    this._message.text(message);
    this._message.show();
  }

  /**
   * Set the form message as success and display it with specific text
   *
   * @param message
   */
  successMessage(message) {
    this.message(message);
    this._message.addClass(NEW_VALUE_MESSAGE_CLASS_SUCCESS);
  }

  /**
   * Set the form message as error and display it with specific text
   *
   * @param message
   */
  errorMessage(message) {
    this.message(message);
    this._message.addClass(NEW_VALUE_MESSAGE_CLASS_ERROR);
  }

  /**
   * Return true if a field is valid
   *
   * @param field
   * @returns {boolean}
   */
  checkField(field) {
    if (field.attr("type") === "time") {
      field.removeClass(INVALID_FORM_FIELD_CLASS);
      return true;
    }

    const numericValue = parseFloat(field.val());
    const valid = !isNaN(numericValue);
    const max = field.attr("max") || Infinity;
    const min = field.attr("min") || -Infinity;

    if (valid) {
      if (field.val() != "") {
        field.val(Math.max(Math.min(numericValue, max), min));
      }
      field.removeClass(INVALID_FORM_FIELD_CLASS);
    } else {
      field.addClass(INVALID_FORM_FIELD_CLASS);
    }

    return valid;
  }

  /**
   * Resets the form
   */
  reset() {
    this._submit.reset();
    Object.values(this._fields).forEach((field) => {
      if ($(field).attr("type") == "time") {
        field.val(DateTime.local().toLocaleString(DateTime.TIME_24_WITH_SECONDS));
      } else {
        field.val("");
      }
      field.removeClass(INVALID_FORM_FIELD_CLASS);
    });
    this.resetMessage();
    this.fieldChangeCallback();
    this.container.find(".js-hide-on-disable").show();
    for (let field of this._fields_keys) {
      this._fields[field].prop("disabled", false);
    }
  }

  /**
   * This method is a callback called whenever current date change
   */
  checkExistingData() {
    this.show_loaders();

    this.reset();

    let raw_date = null;
    try {
      raw_date = DateManager.parseDateFromInput(this._date_field);
    } catch (error) {
      this.hide_loaders();
      return;
    }

    let date = raw_date.toFormat(BASE_DATETIME_FORMAT);
    this._data_store
      .loadData(date)
      .then(
        (r) => {
          let data = r.data;
          this.ref_data = data;
          this.updateForm(data);

          let now = DateTime.local();
          if (raw_date > now) {
            this.dataAlreadySentCallback(data);
          } else if (Object.entries(data).length > 1) {
            this.dataAlreadySentCallback(data);
            this.fieldChangeCallback();
          } else {
            this.noDataSentCallback(data);
          }
          this.hide_loaders();
        },
        (e) => {
          if (e.statusText !== "abort") this.errorMessage("Une erreur s'est produite");
          this.hide_loaders();
        },
      )
      .catch((e) => {
        console.log(e);
      });
  }

  /**
   * This method is called whenever data are queried and returned a not null result, i.e. data has already been sent
   * for the current day
   *
   * @param data the data that has been previously sent
   */
  dataAlreadySentCallback(data) {
    let raw_date = DateManager.parseDateFromInput(this._date_field);
    let now = DateTime.local();
    let today = DateTime.local().startOf("day");
    let is_today = raw_date.day === today.day;

    let forbid = false;

    // ? FUTURE
    if (raw_date > now && !this._onsen_user) {
      this.errorMessage("Saisie impossible pour des dates futures");
      this.container.find(".js-hide-on-disable").hide();
      forbid = true;
    }
    // ? HAS DATA
    else if (Object.keys(data).filter((k) => k.includes("regulation")).length > 0) {
      // ? IS TODAY
      if (!is_today && !this._onsen_user) {
        this.errorMessage("Donnée déjà saisie pour cette date");
        this.container.find(".js-hide-on-disable").hide();
        forbid = true;
      }
      // ? IS TODAY BUT ADMIN
      else if (this._onsen_user) {
        this.successMessage("Donnée déjà saisie pour cette date, accés admin");
      } else {
        this.successMessage("Donnée saisie aujourd'hui mais un ajout reste possible");
      }
    }

    let parent = this;
    this.indics.forEach(function (indic, index) {
      $(parent._fields[indic.reference])
        .find("input")
        .val(data[indic.reference]?.value ?? null);
      $(parent._fields[indic.reference]).find("input").prop("disabled", forbid);
    });
  }

  /**
   * This method is called whenever data are queried and returned an empty response, i.e. no data has been sent for
   * the current day
   */
  noDataSentCallback(data) {}

  /**
   * Update form fields with indicators
   */
  updateForm(data) {}

  hide_loaders() {
    $(this.container).find(".ajax-loader").remove();
  }

  show_loaders() {
    //See ajax-loader.css to understand this part
    //Loader is animated with css

    let loader = $("<div>", {
      class: "ajax-loader d-flex align-items-center justify-content-center",
    });

    //First circle, normal
    $("<p>").appendTo(loader);

    //Second circle: start at the half of the first animation's life
    $("<p>", { class: "delayed" }).appendTo(loader);

    if ($(this.container).find(".ajax-loader").length === 0) {
      $(this.container).append(loader);
    }
  }
}

export default AbstractPackNewMeasureForm;

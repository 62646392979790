import PeriodRenderer from "../PeriodRenderer";
import { computeBarThickness, customTooltip } from "../../helpers/ChartUtilities";
import CircleStat from "../../helpers/CircleStat";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { SankeyController, Flow } from "chartjs-chart-sankey";
import Swal from "sweetalert2";
import Chart from "chart.js/auto";
import { DBEC_ONLY_SELECTOR, formatNumber, GENERAL_ONLY_SELECTOR } from "../../helpers/utils";
import $ from "jquery";
import { DateTime } from "luxon";

class EnergyPackPeriodRenderer extends PeriodRenderer {
  constructor(container = $(".ajax-periods"), detailStart, detailEnd, isPopup = false, indexSwitchPeriodicity = null) {
    super(container, detailStart, detailEnd, isPopup, indexSwitchPeriodicity);
    var context = this;

    Chart.register(SankeyController, Flow);

    this.side_charts_main_selector = "energypack-side-charts";

    this._is_dbec = true;

    this.unit = "MWh";
    this.request_reference = true;
    //Charts
    this.wpDistribution = container.find("#js-energypack-histogram-distribution");
    this.wpDistributionContainer = $(`#${this.side_charts_main_selector}-distribution-container`);

    this.wpHistogram = container.find("canvas.js-energypack-histogram");

    this.hasWpDistribution = container.find("#js-energypack-histogram-distribution").length > 0;

    this.energyPackMainChart = null;
    this.wpCurvesChart = null;
    this.wpDistributionChart = null;

    this.side_charts = [];

    this.energyChart = null;
    this.dateLabels = [];

    this.datasets = {
      hist: [],
      prevHist: [],
      curves: {
        consumption: {
          values: [],
        },
        gain: {
          values: [],
        },
        preheated_water: {
          values: [],
        },
      },
    };

    this.tooltips = {
      hist: [],
      curves: [],
    };

    this.current_side_graph = null;
    //Only one decimal
    this.default_decimals_rounding = 1;

    this.initSideCharts();

    if (this.isPopup) this.date_manager.switchPeriodicity(indexSwitchPeriodicity);
  }

  //Overwriting

  buildCharts(response) {
    //Destroy charts
    if (this.energyPackMainChart) this.energyPackMainChart.destroy();

    if (this.wpCurvesChart) this.wpCurvesChart.destroy();

    if (this.wpDistributionChart) {
      this.wpDistributionChart.destroy();
    }

    let data = response.data;
    this.displayLabels(response);
    let parent = this;

    let customHistogramTooltipsOptions = {
      tooltip_id: "chartjs-tooltip-energy",
      container_id: "js-energypack-histogram",

      getTitle: (titleLines) => {
        let title = $("<p>", {
          class: "has-light-grey-border-bottom p-2",
        });
        let title_content = "";

        titleLines.forEach((title, i) => {
          let date_split = title.split(",");
          let date = this.date_manager.isWeekPeriodicity ? date_split[1] : date_split[0];
          title_content += date;
        });

        title.text(title_content);

        return title;
      },
      getBody: () => {
        let body = $("<div/>", {
          class: "d-flex justify-content-around flex-wrap align-items-stretch",
          css: {
            // height: 100,
            // width: 135,
          },
        });

        for (let i in this.datasets.hist) {
          let dataset = this.datasets.hist[i];
          if (dataset.isEnabled) {
            let span = $("<span>", {
              class: "chartjs-tooltip-element",
              css: {
                "background-color": dataset.color,
              },
            })
              .html(
                `
                                ${dataset.label}<br />
                                ${formatNumber(this.tooltips.hist[i], 2)}
                            `,
              )
              .appendTo(body);
          }
        }

        $("<div>", {
          class: "has-light-grey-border-top p-2 w-100 text-center",
        })
          .html(
            '<a class="text-nowrap"><strong>Total : </strong>' +
              formatNumber(this.tooltipSum, 2) +
              " " +
              this.unit +
              "</a>" +
              `<p><i style="opacity:0.5;font-size:0.8em">*cliquez pour voir le détail</i></p>`,
          )
          .appendTo(body);

        return body;
      },
    };

    let histogram_datasets = [];

    for (let index in this.datasets.hist) {
      let histogram = this.datasets.hist[index];
      let label = "Période";
      label += index == 0 ? "" : "blank";

      histogram_datasets.push({
        label: label,
        backgroundColor: histogram.color,
        data: histogram.values,
        type: "bar",
        yAxisID: "y",
        order: 1,
        isEnabled: true,
        stack: "stack 0",
        indic_id: histogram.id,
        borderWidth: 1,
        borderRadius: 10,
      });
    }

    let prev_histogram_datasets = [];
    for (let index in this.datasets.prevHist) {
      let histogram = this.datasets.prevHist[index];
      let label = "Référence";
      label += index == 0 ? "" : "blank";
      prev_histogram_datasets.push({
        label: label,
        backgroundColor: histogram.color,
        data: histogram.values,
        type: "bar",
        yAxisID: "y",
        order: 0,
        isEnabled: true,
        stack: "stack 1",
        rounded: true,
        indic_id: histogram.id,
        indic_id: histogram.id,
        borderRadius: 10,
      });
    }

    var newLegendClickHandler = function (e, legendItem) {
      var index = legendItem.datasetIndex;
      let ci = this.chart;
      let currentDataset = ci.data.datasets[index];

      // Toggle current dataset
      var meta = ci.getDatasetMeta(index);
      meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

      // Toggle others datasets
      for (let i = 0; i < ci.data.datasets.length; i++) {
        let dataset = ci.data.datasets[i];
        var meta = ci.getDatasetMeta(i);
        if (
          (currentDataset.label.includes("Période") && dataset.label.includes("Période")) ||
          (currentDataset.label.includes("Référence") && dataset.label.includes("Référence"))
        ) {
          if (currentDataset != dataset) {
            meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
          }
        }
      }

      // render
      ci.update();
    };
    var displayDetails = function (e) {
      //check if its hours so return
      const regex = /h00$/;
      if (parent.dateLabels.some((str) => regex.test(str))) return;

      let dateNextClicked = "";
      let dateClicked = this.tooltip.title; //['lun. 07/03']
      let index = 0;
      let pack = this.renderer.dataStore._pack;
      //return key of matching arr date_clicked and arr all dates (all periods in ISO)
      this.renderer.dateLabels.forEach((dateLabel, k) => {
        if (dateClicked[0] == dateLabel) {
          return (index = k);
        }
      });

      //return the matching date ISO with the index of periods array
      dateClicked = DateTime.fromISO(parent.helper.periods[index]["start"]);
      dateNextClicked = DateTime.fromISO(parent.helper.periods[index]["end"]);

      $.ajax({
        url: `/pack/energy/details`,
        method: "GET",
        data: {
          date: dateClicked.toISO(),
          dateNext: dateNextClicked.toISO(),
          titleDate: this.tooltip.title[0],
          pack: pack,
          indicators: this.renderer.indicators,
          indicator_colors: this.renderer.indicator_colors,
        },
      }).then((response) => {
        Swal.fire({
          html: response,
          width: "85%",
          confirmButtonColor: "rgb(40, 163, 179)",
          showCloseButton: true,
        });
      });
    };

    this.energyPackMainChart = new Chart(this.wpHistogram, {
      type: "bar",
      data: {
        labels: this.dateLabels,
        datasets: [...prev_histogram_datasets, ...histogram_datasets],
      },
      options: {
        onClick: displayDetails,
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 30,
            right: 30,
            top: 45,
            bottom: 30,
          },
        },
        elements: {
          point: {},
        },
        scales: {
          y: {
            stacked: true,
            beginAtZero: true,
            title: {
              display: true,
              text: "MWh",
            },
          },
          x: {
            display: true,
            stacked: true,
            grid: {
              color: "black",
              borderDash: [2, 2],
            },
          },
        },

        labels: {
          display: true,
        },

        plugins: {
          tooltip: {
            backgroundColor: "rgb(255, 255, 255)",
            bodyFontColor: "rgb(0, 255, 0)",
            mode: "index",
            intersect: false,
            enabled: false,
            callbacks: {
              beforeFooter: (tooltipItems) => {
                for (let index in this.datasets.hist) {
                  this.tooltips.hist[index] = parseFloat(this.datasets.hist[index].values[tooltipItems[0].dataIndex]);
                }
              },
            },
            external: (tooltip_model) => {
              customTooltip(tooltip_model, customHistogramTooltipsOptions);
            },
          },
          legend: {
            onClick: newLegendClickHandler,
            labels: {
              filter: function (legendItem, data) {
                return !legendItem.text.includes("blank");
              },
              generateLabels: function (chart) {
                var data = chart.data;
                var legends = Array.isArray(data.datasets)
                  ? data.datasets.map(function (dataset, i) {
                      return {
                        text: dataset.label.replace("rounded", ""),
                        fillStyle: !Array.isArray(dataset.backgroundColor)
                          ? dataset.backgroundColor
                          : dataset.backgroundColor[0],
                        hidden: !chart.isDatasetVisible(i),
                        lineCap: dataset.borderCapStyle,
                        lineDash: dataset.borderDash,
                        lineDashOffset: dataset.borderDashOffset,
                        lineJoin: dataset.borderJoinStyle,
                        lineWidth: dataset.borderWidth,
                        strokeStyle: dataset.borderColor,
                        pointStyle: dataset.pointStyle,

                        // Below is extra data used for toggling the datasets
                        datasetIndex: i,
                      };
                    }, this)
                  : [];

                return legends;
              },
            },
          },
        },
      },
    });

    for (let index in response.helper.indicators) {
      let indicator = response.helper.indicators[index];
      $(`#consumptionsummary-element--${index}`).on("click", (event) => {
        for (let index_2 in parent.energyPackMainChart.data.datasets) {
          let dataset = parent.energyPackMainChart.data.datasets[index_2];
          if (dataset.indic_id === indicator.id) {
            dataset.hidden = !dataset.hidden;
            if (dataset.hidden) $(event.target).parents(".consumptionsummary-element").removeClass("active");
            else $(event.target).parents(".consumptionsummary-element").addClass("active");
          }
        }
        parent.energyPackMainChart.update();
      });
    }

    this.energyPackMainChart.renderer = this;
    this.refreshDBECState(response);
    this.energyPackMainChart.update();
    this.renderCurentSideChart();
    this.animate($(`.consumptionsummary-element--${this.index_active}`));

    // var ctx = document.getElementById("chart-sankey").getContext("2d");
    // var colors = {
    //   Chaudière: "#baeef7",
    //   "Chaudière ": "#baeef7",
    //   Pompes: "#baeef7",
    //   "Pompes ": "#baeef7",
    //   ECS: "#80a5ac",
    //   Lumière: "#baeef7",
    //   EFS: "#80a5ac",
    //   Général: "#004b58",
    //   Autre: "#baeef7",
    //   "Autre ": "#baeef7",
    // };

    // let testimony = document.getElementById("sankey-testimony");
    // this.energyPackSankey = new Chart(ctx, {
    //   type: "sankey",
    //   maintainAspectRatio: false,

    //   data: {
    //     datasets: [
    //       {
    //         data: [
    //           { from: "ECS", to: "Chaudière", flow: 15 },
    //           { from: "ECS", to: "Pompes", flow: 20 },
    //           { from: "ECS", to: "Lumière", flow: 25 },
    //           { from: "ECS", to: "Autre", flow: 10 },
    //           { from: "Général", to: "ECS", flow: 70 },
    //           { from: "Général", to: "EFS", flow: 60 },
    //           { from: "EFS", to: "Pompes ", flow: 15 },
    //           { from: "EFS", to: "Chaudière ", flow: 35 },
    //           { from: "EFS", to: "Autre ", flow: 10 },
    //         ],
    //         colorFrom: (c) => getColor(c.dataset.data[c.dataIndex].from),
    //         colorTo: (c) => getColor(c.dataset.data[c.dataIndex].to),
    //         borderWidth: 2,
    //         borderColor: "black",
    //         size: "max",
    //       },
    //     ],
    //   },
    //   options: {
    //     events: ["mousemove", "mouseout", "click"],
    //     onClick: function (e, activeElements) {
    //       console.log("click", activeElements);
    //       if (activeElements.length === 0) return; // If we click on a node

    //       testimony.innerHTML =
    //         "From: " +
    //         activeElements[0].element.from.key +
    //         "<br>To: " +
    //         activeElements[0].element.to.key +
    //         "<br>Value: " +
    //         activeElements[0].element.to.in;
    //     },
    //   },
    //   plugins: {
    //     tooltip: {
    //       // Tooltip will only receive click events
    //       events: ["click"],
    //     },
    //   },
    // });

    function getColor(name) {
      return colors[name] || "green";
    }
  }

  /**
   * Initialise the charts on the right of the screen and display the tabs
   * the displayed tabs depends on DBEC and pond selected
   */
  initSideCharts() {
    //Side charts managing

    this.distribution_chart_container = $("#" + this.side_charts_main_selector + "-distribution");
    this.energy_reuse_chart_container = $("#" + this.side_charts_main_selector + "-reused-energy");
    this.energy_preheat_chart_container = $("#" + this.side_charts_main_selector + "-preheated-energy");

    this.side_charts_tabs_list = [
      {
        id: "distribution",
        label: "Répartition",
        click: () => {
          //Hide unnecessary graphs and display the good one
          this.distribution_chart_container.removeClass("d-none");
          this.energy_preheat_chart_container.addClass("d-none");
          this.energy_reuse_chart_container.addClass("d-none");
          this.displayDistributionGraph();
        },
        element: null,
      },
      {
        id: "preheating-rate",
        label: "Efficience énergétique",
        click: () => {
          //Hide unnecessary graphs and display the good one
          this.distribution_chart_container.addClass("d-none");
          this.energy_preheat_chart_container.removeClass("d-none");
          this.energy_reuse_chart_container.addClass("d-none");
          this.displayPreheatGraph();
        },
        dbec_only: true,
        element: null,
      },
    ];

    //In this will be stored the methods to draw each different side graph
    this.side_graph_display_methods = {};

    let side_charts_tabs_parent = $("#" + this.side_charts_main_selector + "-tab-list");
    this.side_charts_tabs_list.forEach((tab) => {
      let li = $("<li>", {
        class: this.side_charts_main_selector + "-tab-item",
        id: this.side_charts_main_selector + "-tab-" + tab.id,
      }).appendTo(side_charts_tabs_parent);
      $("<a>").text(tab.label).appendTo(li);

      li.on("click", () => {
        if (this.current_side_graph !== tab) {
          //Save the current side graph to know what to display in the display current side graph function
          this.current_side_graph = tab;

          tab.click();
          $("." + this.side_charts_main_selector + "-tab-item").removeClass("active");
          li.addClass("active");
        }
      });

      //If general only add the selector to get it when changing pond
      if (tab.general_only) {
        li.addClass(GENERAL_ONLY_SELECTOR);
      }

      //If dbec only add the selector to remove tabs if needed
      if (tab.dbec_only) {
        li.addClass(DBEC_ONLY_SELECTOR);
        li.addClass("d-none-" + DBEC_ONLY_SELECTOR);
      }

      tab.element = li;

      //Save the click method for the display current side graph function
      this.side_graph_display_methods[tab.id] = tab.click;
    });

    //Set the first element as active
    this.side_charts_tabs_list[0].element.click();
  }

  /**
   * Render the chart on the right side according to the current active tab
   */
  renderCurentSideChart() {
    if (this.side_graph_display_methods.hasOwnProperty(this.current_side_graph.id)) {
      this.side_graph_display_methods[this.current_side_graph.id]();
    }
  }

  /**
   * Display the energy distribution chart
   */
  displayDistributionGraph() {
    let datasets = [
      {
        backgroundColor: this.datasets.hist.filter((dataset) => dataset.isEnabled).map((dataset) => dataset.color),
        data: this.datasets.hist
          .filter((dataset) => dataset.isEnabled)
          .filter((dataset) => dataset.values.length > 0)
          .map((dataset) => dataset.values.reduce((a, b) => a + b), 1),
      },
    ];

    let data = {
      datasets: datasets,
      labels: this.datasets.hist.filter((dataset) => dataset.isEnabled).map((dataset) => dataset.label),
    };

    let options = {
      layout: {
        padding: 60,
      },
      responsive: true,
      maintainAspectRatio: false,

      legendCallback: () => {
        let container_selector = this.side_charts_main_selector + "-distribution-legend";
        this.distributionChartLegend = $("#" + container_selector);
        if (this.distributionChartLegend.length === 0) {
          this.distributionChartLegend = $("<ul>", {
            id: container_selector,
            class: "justify-content-center flex-column",
          });
        }

        this.distributionChartLegend.text("");

        let labels = data.labels;
        let values = data.datasets[0].data;
        let colors = data.datasets[0].backgroundColor;

        let percentage_factor = 1;
        if (values.length > 0)
          percentage_factor =
            100 /
            Math.max(
              values.reduce((a, b) => a + b),
              1,
            );

        for (let i = 0; i < labels.length; i++) {
          let li = $("<li>", {
            class: "distribution-legend-item text-center",
            css: {
              color: colors[i],
            },
          })
            .html(
              `
                            ${labels[i]}<br />
                            ${formatNumber(values[i], 2)} ${this.unit}<br />
                            ${Math.round(values[i] * percentage_factor)}%
                            `,
            )
            .appendTo(this.distributionChartLegend);

          if (values[i] === 0) li.css("display", "none");
        }
        return this.distributionChartLegend;
      },
      labels: {
        display: true,
      },

      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          align: "end",
          anchor: "end",
          display: "auto",
          clamp: true,
          formatter: (value, context) => {
            let total = context.dataset.data.reduce((a, b) => a + b);
            return value > 0
              ? context.chart.data.labels[context.dataIndex] +
                  "\n" +
                  formatNumber(value, 2) +
                  " " +
                  this.unit +
                  "\n" +
                  formatNumber((100 * value) / total, true) +
                  "%"
              : null;
          },
          color: (context) => {
            return context.dataset.backgroundColor;
          },
        },
      },

      events: [],
    };

    this.wpDistributionChart?.destroy();
    if (this.hasWpDistribution) {
      this.wpDistributionChart = new Chart(this.wpDistribution, {
        type: "pie",
        data: data,
        options: options,
        plugins: [ChartDataLabels],
      });

      this.wpDistributionChart.update();
      this.side_charts.push(this.wpDistributionChart);
    }
  }

  /**
   * Display the water preheat chart
   */
  displayPreheatGraph() {
    let total_gain = this.datasets.curves.gain.values.reduce((a, b) => a + b, 0); // MWh
    let total_preheated_water = this.datasets.curves.preheated_water.values.reduce((a, b) => a + b, 0); // m^3

    let circle = new CircleStat(
      "preheated-energy",
      formatNumber(parseFloat((total_gain * 1000) / total_preheated_water), 2),
      // this.datasets.curves.consumption.values.reduce((a, b) => a + b, 0),
      30,
      this.colors.valid,
      "--small",
      80,
      7,
      "kWh/m&sup3",
      false,
      formatNumber(total_gain, 2),
      "MWh",
    );
    circle.create();
    this.side_charts.push(circle);
  }

  /**
   * Return the sum of each number displayed in the tooltip
   * @returns {number}
   */
  get tooltipSum() {
    let tooltips = Object.values(this.tooltips.hist);
    let ret = 0;

    tooltips.forEach((tooltip) => {
      ret += isNaN(tooltip) ? null : tooltip;
    });

    return ret;
  }

  emptyChartsData() {
    this.dateLabels = [];
    this.datasets.hist = [];
    this.datasets.prevHist = [];
    for (let j in this.datasets.curves) {
      this.datasets.curves[j].values = [];
    }
    this.tooltips = {
      hist: [],
      curves: [],
    };
  }

  /**
   * This is a tool to refresh elements dependent of the dbec state,
   * for example tabs that are displayed only if dbec exists
   */
  refreshDBECState(response) {
    // TODO: Better check
    this._is_dbec = response.helper.has_dbec;

    //Show/hide general only elements
    let dbec_only_elements = $("." + DBEC_ONLY_SELECTOR);

    //Use a particular class to hide, to avoid it to be displayed if it should be hidden for other reasons
    if (this._is_dbec) {
      dbec_only_elements.removeClass("d-none-" + DBEC_ONLY_SELECTOR);
    } else {
      dbec_only_elements.addClass("d-none-" + DBEC_ONLY_SELECTOR);

      //If we are currently displaying a tab which needs dbec but it should not be displayed
      //Anymore, go back to the distribution one
      // if (this.current_side_graph.dbec_only)
      //     this.side_charts_tabs_list[0].element.click();
    }
  }

  destroyCharts() {
    super.destroyCharts();

    if (this.energyChart) this.energyChart.destroy();
    if (this.energyPackMainChart) this.energyPackMainChart.destroy();
  }

  updateChartsData(res) {
    const data = res.data;
    const helper = res.helper;

    let colors = [this.colors.normal, this.colors.dark, this.colors.light, this.colors.other];
    for (let index in helper.indicators) {
      let indicator = helper.indicators[index];
      this.datasets.hist[index] = {
        values: [],
        label: indicator.label,
        color: colors[index],
        isEnabled: true,
        id: indicator.id,
      };
    }

    let indicators = res.helper.indicators;
    this.updateDateLabels();

    for (let datum of Object.values(data)) {
      this.datasets.curves.gain.values.push(datum.dbec_pond_gain_mwatt + datum.ure_gain_kwh / 1000);
      this.datasets.curves.consumption.values.push(datum.water_heating);
      this.datasets.curves.preheated_water.values.push(datum.dbec_warmed_water);

      for (let ii in indicators) {
        ii = parseInt(ii);
        let indicator = indicators[ii];
        this.datasets.hist[ii].values.push(datum[indicator.reference]);
      }
    }

    for (let index in this.datasets.hist) {
      this.tooltips.hist[index] = "NC";
    }
    for (let index in this.datasets.hist) {
      this.tooltips.hist[index] = "NC";
    }
  }

  updatePreviousChartsData(res) {
    const data = res.data;
    const helper = res.helper;

    let colors = [
      this.colors.normal_transparent,
      this.colors.dark_transparent,
      this.colors.light_transparent,
      this.colors.other_transparent,
    ];
    for (let index in helper.indicators) {
      let indicator = helper.indicators[index];
      this.datasets.prevHist[index] = {
        values: [],
        label: indicator.label,
        color: colors[index],
        isEnabled: true,
        id: indicator.id,
      };
    }

    for (let i in data) {
      i = parseInt(i);
      if (data.hasOwnProperty(i)) {
        for (let index in data[i].indicators) {
          let indicator = helper.indicators[index];
          this.datasets.prevHist[index].values.push(data[i][indicator.reference]);
        }
      }
    }
  }

  static get pack_name() {
    return "energy";
  }

  static get element_switch_enabled() {
    return false;
  }

  get target() {
    return "pool";
  }
}

export default EnergyPackPeriodRenderer;

import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import $ from "jquery";

export default class extends Controller {
  source = "";
  connect() {
    let data = null;
    let element = $(this.element).find("pre").length == 0 ? $(this.element) : $(this.element).find("pre");
    element.hide();
    try {
      data = JSON.parse($(element).text());
      this.source = data;
    } catch (e) {
      return;
    }

    // element.text("").jsonViewer(data, {
    //   withQuotes: true,
    //   collapsed: element.data("collapse") ?? true,
    // });
    element.show();
  }

  async compare() {
    console.log("compare");
    const { value: file } = await Swal.fire({
      title: "Select image",
      input: "file",
      inputAttributes: {
        accept: ".json",
        "aria-label": "Choix du fichier JSON",
      },
    });

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.target.result);
      };
      reader.readAsText(file);
    }
  }
}

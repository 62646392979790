import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}
  static targets = ["field", "eye"];

  get field() {
    //refactoring
    return this.fieldTarget.value;
  }
  reveal() {
    if (this.fieldTarget.type === "password") {
      this.eyeTarget.classList.remove("fa-eye");
      this.eyeTarget.classList.add("fa-eye-slash");
      this.fieldTarget.type = "text";
    } else {
      this.eyeTarget.classList.remove("fa-eye-slash");
      this.eyeTarget.classList.add("fa-eye");
      this.fieldTarget.type = "password";
    }
  }
}

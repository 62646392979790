import PeriodRenderer from "../PeriodRenderer";
import Swal from "sweetalert2";
import { computeBarThickness, customTooltip } from "../../helpers/ChartUtilities";
import CircleStat from "../../helpers/CircleStat";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";
import { formatNumber, GENERAL_ONLY_SELECTOR } from "../../helpers/utils";
import $ from "jquery";
import { DateTime } from "luxon";

class ChemicalPackPeriodRenderer extends PeriodRenderer {
  constructor(
    container = $(".ajax-periods"),
    detailStart,
    detailEnd,
    isPopup = false,
    index_active,
    indexSwitchPeriodicity = null,
  ) {
    super(container, detailStart, detailEnd, isPopup, indexSwitchPeriodicity);
    // this.index_active = "0";
    if (isPopup) {
      //pass the param index_active indicator when zoomed in chart
      this.index_active = parseInt(index_active);
      //change periodicity
      this.date_manager.switchPeriodicity(indexSwitchPeriodicity);
    } else {
      //index_active by default
      this.index_active = 0;
    }
    var context = this;
    $(".consumptionsummary-element").on("click", function () {
      let i = $(this).attr("class").split("--").pop();
      let btn = $(".consumptionsummary-element");
      btn.css({
        "pointer-events": "none",
        "background-color": "#AAAAAA",
      });
      const promise = new Promise((resolve, reject) => {
        context.index_active = parseInt(i);
        context.render();
        resolve();
      })
        .then(
          () => {
            btn.css("pointer-events", "");
            btn.css({
              "pointer-events": "",
              "background-color": "white",
            });
          },
          (reason) => console.log(reason),
        )
        .catch((e) => {
          console.log(e);
        });
    });
    this.side_charts_main_selector = "chemicalpack-side-charts";

    this.unit = "L";
    this.request_reference = true;
    //Charts
    this.wpDistribution = container.find("#js-chemicalpack-histogram-distribution");
    this.wpDistributionContainer = $(`#${this.side_charts_main_selector}-distribution-container`);

    this.wpLevel = container.find("#js-chemicalpack-histogram-level");
    this.wpLevelContainer = $(`#${this.side_charts_main_selector}-level-container`);

    this.wpHistogram = container.find("canvas.js-chemicalpack-histogram");

    this.chemicalPackMainChart = null;
    this.wpCurvesChart = null;
    this.wpDistributionChart = null;
    this.wpLevelChart = null;

    this.side_charts = [];

    this.hasWpDistribution = container.find("#js-chemicalpack-histogram-distribution").length > 0;

    this.last_values = {};

    this.chemicalChart = null;
    this.dateLabels = [];

    this.datasets = {
      hist: [],
      prevHist: [],
      curves: [],
    };

    this.tooltips = {
      hist: [],
      prevhist: [],
      curves: [],
    };

    this.current_side_graph = null;
    //Only one decimal
    this.default_decimals_rounding = 1;

    if (!this.isPopup) {
      this.initSideCharts();
    }
  }

  //Overwriting

  buildCharts(response) {
    $(`.consumptionsummary-element`).removeClass("active");
    $(`.consumptionsummary-element--${this.index_active}`).addClass("active");

    // Set indicators background color for inituitive clicking
    $(`.consumptionsummary-element--${this.index_active}`).css("background-color", "white");
    let other_elements = $(`.consumptionsummary-element`).not(`.consumptionsummary-element--${this.index_active}`);
    other_elements.css("background-color", "whitesmoke");
    $(`.consumptionsummary-element--${this.index_active}`).unbind("mouseenter mouseleave");
    other_elements.hover(
      function () {
        $(this).css("background-color", "white");
      },
      function () {
        $(this).css("background-color", "whitesmoke");
      },
    );

    //Destroy charts
    if (this.chemicalPackMainChart) this.chemicalPackMainChart.destroy();

    if (this.wpCurvesChart) this.wpCurvesChart.destroy();

    if (this.wpDistributionChart) {
      this.wpDistributionChart.destroy();
    }

    if (this.wpLevelChart) {
      this.wpLevelChart.destroy();
    }

    let data = response.data;
    let helper = response.helper;

    this.displayLabels(response);
    // let parent = this;

    var newLegendClickHandler = function (e, legendItem) {
      var index = legendItem.datasetIndex;
      let ci = this.chart;
      let currentDataset = ci.data.datasets[index];

      // Toggle current dataset
      var meta = ci.getDatasetMeta(index);
      meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

      // Toggle others datasets
      for (let i = 0; i < ci.data.datasets.length; i++) {
        let dataset = ci.data.datasets[i];
        var meta = ci.getDatasetMeta(i);
        if (
          (currentDataset.label.includes("Consommations") && dataset.label.includes("Consommations")) ||
          (currentDataset.label.includes("Référence") && dataset.label.includes("Référence"))
        ) {
          if (currentDataset != dataset) {
            meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
          }
        }
      }

      // render
      ci.update();
    };

    let customHistogramTooltipsOptions = {
      tooltip_id: "chartjs-tooltip-chemical",
      container_id: "js-chemicalpack-histogram",

      getTitle: (titleLines) => {
        let title = $("<p>", {
          class: "has-light-grey-border-bottom p-2",
        });
        let title_content = "";

        titleLines.forEach((title, i) => {
          let date_split = title.split(",");
          let date = this.date_manager.isWeekPeriodicity ? date_split[1] : date_split[0];
          title_content += date;
        });

        title.text(title_content);

        return title;
      },
      getBody: () => {
        let body = $("<div/>", {
          class: "d-flex justify-content-around flex-wrap align-items-stretch h-100 flex-column",
          css: {
            // height: 100,
            // width: 135,
          },
        });

        let content = `
                <table class="table">
                    <thead style="text-align: center;"                    >
                        <tr>
                            <th scope="col"></th>
                            <th scope="col" style="color: rgba(0,0,0,0.7)">Réference</th>
                            <th scope="col"><i class="fa fa-exchange-alt"></i></th>
                            <th scope="col" style="color: rgba(0,0,0,1)">Mesure</th>
                        </tr>
                    </thead>
                <tbody>`;

        let indicators = response.helper.indicators;
        for (let i in indicators) {
          // .filter(tooltip => tooltip.hide != true)

          let dataset = this.datasets.hist[i];
          let tooltip = this.tooltips.hist[i];
          let prev_dataset = this.datasets.prevHist[parseInt(i)];
          let prev_tooltip = this.tooltips.prevhist[parseInt(i)];

          content += `
                    <tr">
                    <th scope="row" class="chartjs-tooltip-element-row-title">
                        ${dataset.abbreviation}
                    </th>
                    <td class="chartjs-tooltip-element-water">
                        <a style="color: ${prev_dataset.color};">
                        ${formatNumber(prev_tooltip, 2)}
                        </a>
                    </td>
                    <td class="chartjs-tooltip-element-water">
                        <a>
                        ${formatNumber(tooltip - prev_tooltip, 2)}
                        </a>
                    </td>
                    <td class="chartjs-tooltip-element-water">
                        <a style="color: ${dataset.color};">
                        <strong>${formatNumber(tooltip, 2)}</strong>
                        </a>
                    </td>
                    </tr>
                    `;
        }

        content += "</tbody></table>";
        body.html(content);

        for (let i in indicators) {
          let dataset = this.datasets.hist[i];
          $("<div>", {
            class: "has-light-grey-border-top p-2 text-center",
          })
            .html(
              `<p><strong>Niveau ${dataset.label}: </strong> ${formatNumber(this.tooltips.curves[i], 2)} ${
                this.unit
              }</p>`,
            )
            .appendTo(body);
        }
        $("<div>", {
          class: "has-light-grey-border-top p-2 text-center",
        })
          .html(`<p><i style="opacity:0.5;font-size:0.8em">*cliquez pour voir le détail</i></p>`)
          .appendTo(body);

        return body;
      },
    };

    let curve_datasets = [];
    for (let index in this.datasets.curves) {
      let curve = this.datasets.curves[index];
      let label = "Niveaux";
      //parent = this;
      curve_datasets.push({
        label: label,
        backgroundColor: curve.color,
        data: curve.values,
        type: "line",
        yAxisID: "right-y-axis",
        order: 3,
        isEnabled: curve.isEnabled && helper.periodicity == "day",
        borderRadius: 10,
        borderWidth: 3,
      });
    }

    let histogram_datasets = [];

    for (let index in this.datasets.hist) {
      let histogram = this.datasets.hist[index];
      let label = "Consommations";

      histogram_datasets.push({
        label: label,
        backgroundColor: histogram.color,
        data: histogram.values,
        type: "bar",
        yAxisID: "left-y-axis",
        order: 3,
        isEnabled: histogram.isEnabled,
        stack: "stack 0",
        borderRadius: 10,
        borderWidth: 1,
      });
    }

    let prev_histogram_datasets = [];
    for (let index in this.datasets.prevHist) {
      let histogram = this.datasets.prevHist[index];
      let label = "Référence";
      // label += index == 0 ? "" : "blank";
      // label += index == this.datasets.hist.length - 1 ? "rounded" : "";
      prev_histogram_datasets.push({
        label: label, // for rounded chart
        backgroundColor: histogram.color,
        data: histogram.values,
        type: "bar",
        yAxisID: "left-y-axis",
        order: 0,
        isEnabled: index == this.index_active,
        stack: "stack 1",
        borderRadius: 10,
        borderWidth: 1,
      });
    }

    let points = {
      consumption: new Image(),
      reuse: new Image(),
    };

    points.consumption.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAPFBMVEVHcEw4vNE4vNE3vtE3vNE3vNE4vNE4vNE4vNE4vdE4vdH///83vNFszt7J7fNIwtX3/P2K2OQ7vdLh9fg1J1CnAAAAC3RSTlMARN4Cqe0Um31Xe3FlzdEAAABvSURBVBjTZY9LEsMgDENtAphgmU9y/7uWkraL+u2sGckS0SJzESmc6eHgmLBIkY99nwHd5rSOcL4VDpe1oTqaXYGXP8Ju3dyGmIlTb/qh9cRUYOMrDEMlwdQfE+KF+m9xoe6tK+aru3F7fhWpz/wXn7wItxYIGRAAAAAASUVORK5CYII=";
    points.reuse.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAS1BMVEVHcEwAulcAuFYAulcAulYAulcAulcAulcAu1YAulcAuVgAulcAulf///8BuldMzYPB7dH2/Pje9eUYwGUXwGR42Jx32Zt32Jt22Jvkq1GWAAAADXRSTlMARALe7ZupfRRXFXuqOBo5TAAAAH5JREFUGNNlj9sSgzAIRMFc1QKJidr//9KSVDtjc55gZxZ2AZQFg3MBF/gy4cuyYj1OfZ8NFzkOKWzmpqBhSZkoJ2GD6vcsG3U2YR8BbUl0kapFCCz5FrLwCo53+rGzewqnCoNlOBr/3w7BevQq51vqFV3L+Uc5Jbb6K8Y2fwBIOAnuZrb66wAAAABJRU5ErkJggg==";

    let curve_color =
      curve_datasets.filter((dataset) => dataset.isEnabled).length > 0
        ? curve_datasets.filter((dataset) => dataset.isEnabled)[0].backgroundColor
        : "rgba(255, 255, 255, 0.5)";
    let hist_color =
      histogram_datasets.filter((dataset) => dataset.isEnabled)[0]?.backgroundColor ?? "rgba(255, 255, 255, 0.5)";

    let parent = this;
    var displayDetails = function (e) {
      //check if its hours so return
      const regex = /h00$/;
      if (parent.dateLabels.some((str) => regex.test(str))) return;

      let dateNextClicked = "";
      let dateClicked = this.tooltip.title; //['lun. 07/03']
      let index = 0;
      let pack = this.renderer.dataStore._pack;

      //return key of matching arr date_clicked and arr all dates (all periods in ISO)
      this.renderer.dateLabels.forEach((dateLabel, k) => {
        if (dateClicked[0] == dateLabel) {
          return (index = k);
        }
      });

      //return the matching date ISO with the index of periods array
      dateClicked = DateTime.fromISO(parent.helper.periods[index]["start"]);
      dateNextClicked = DateTime.fromISO(parent.helper.periods[index]["end"]);

      $.ajax({
        url: `/pack/chemical/details`,
        method: "GET",
        data: {
          date: dateClicked.toISO(),
          dateNext: dateNextClicked.toISO(),
          titleDate: this.tooltip.title[0],
          pack: pack,
          indicators: this.renderer.indicators,
          indicator_colors: this.renderer.indicator_colors,
        },
      }).then((response) => {
        Swal.fire({
          html: response,
          width: "85%",
          confirmButtonColor: "rgb(40, 163, 179)",
          showCloseButton: true,
        });
      });
    };

    this.chemicalPackMainChart = new Chart(this.wpHistogram, {
      type: "bar",
      data: {
        labels: this.dateLabels,
        datasets: [
          ...curve_datasets.filter((dataset) => dataset.isEnabled),
          ...prev_histogram_datasets.filter((dataset) => dataset.isEnabled),
          ...histogram_datasets.filter((dataset) => dataset.isEnabled),
        ],
      },
      options: {
        layout: {
          padding: {
            top: 45,
            bottom: 30,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        elements: {
          line: {
            pointRadius: 5,
            pointHoverRadius: 5,
            pointHitRadius: 5,
            fill: false,
            cubicInterpolationMode: "monotone",
            tension: 0.4,
            borderColor: "#8dc8d2",
          },
          point: {
            pointStyle: points.consumption,
          },
        },
        scales: {
          "right-y-axis": {
            // stacked: true,
            position: "right",
            beginAtZero: false,

            title: {
              display: true,
              fontColor: curve_color,
              text: "niveau (L)",
            },
            ticks: {
              fontColor: curve_color,
            },
          },
          "left-y-axis": {
            type: "linear",
            position: "left",
            title: {
              fontColor: hist_color,
              display: true,
              text: "consommation (L)",
            },
            grid: { display: false },
            ticks: {
              fontColor: hist_color,
            },
          },
          x: {
            display: true,
            stacked: true,
            grid: {
              color: "lightgrey",
              borderDash: [2, 2],
            },
          },
        },

        labels: {
          display: true,
        },
        onClick: displayDetails,

        plugins: {
          legend: {
            onClick: newLegendClickHandler,
            labels: {
              filter: function (legendItem, data) {
                return !legendItem.text.includes("blank");
              },
              generateLabels: function (chart) {
                var data = chart.data;
                var legends = Array.isArray(data.datasets)
                  ? data.datasets.map(function (dataset, i) {
                      return {
                        text: dataset.label.replace("rounded", ""),
                        fillStyle: !Array.isArray(dataset.backgroundColor)
                          ? dataset.backgroundColor
                          : dataset.backgroundColor[0],
                        hidden: !chart.isDatasetVisible(i),
                        lineCap: dataset.borderCapStyle,
                        lineDash: dataset.borderDash,
                        lineDashOffset: dataset.borderDashOffset,
                        lineJoin: dataset.borderJoinStyle,
                        lineWidth: dataset.borderWidth,
                        strokeStyle: dataset.borderColor,
                        pointStyle: dataset.pointStyle,

                        // Below is extra data used for toggling the datasets
                        datasetIndex: i,
                      };
                    }, this)
                  : [];

                return legends;
              },
            },
          },
          tooltip: {
            backgroundColor: "rgb(255, 255, 255)",
            bodyFontColor: "rgb(0, 255, 0)",
            mode: "index",
            intersect: false,
            enabled: false,
            callbacks: {
              beforeFooter: (tooltipItems) => {
                for (let index in this.datasets.hist) {
                  this.tooltips.hist[index] = parseFloat(this.datasets.hist[index].values[tooltipItems[0].dataIndex]);
                  this.tooltips.prevhist[index] = parseFloat(
                    this.datasets.prevHist[index].values[tooltipItems[0].dataIndex],
                  );
                  this.tooltips.curves[index] = parseFloat(
                    this.datasets.curves[index].values[tooltipItems[0].dataIndex],
                  );
                }
              },
            },
            external: (tooltip_model) => {
              customTooltip(tooltip_model, customHistogramTooltipsOptions);
            },
          },
        },
      },
    });

    // We hide the reference data by default
    for (let i in this.chemicalPackMainChart.data.datasets) {
      if (this.chemicalPackMainChart.data.datasets[i].label.includes("Référence")) {
        this.chemicalPackMainChart.getDatasetMeta(i).hidden = true;
      }
    }

    this.chemicalPackMainChart.renderer = this;
    this.chemicalPackMainChart.update();

    if (!this.isPopup) {
      this.renderCurentSideChart();
      this.animate($(`.consumptionsummary-element--${this.index_active}`));
    }
  }

  /**
   * Initialise the charts on the right of the screen and display the tabs
   * the displayed tabs depends on DBEC and pond selected
   */
  initSideCharts() {
    //Side charts managing

    this.distribution_chart_container = $("#" + this.side_charts_main_selector + "-distribution");
    this.level_chart_container = $("#" + this.side_charts_main_selector + "-level");

    this.side_charts_tabs_list = [
      {
        id: "distribution",
        label: "Répartition",
        click: () => {
          //Hide unnecessary graphs and display the good one
          this.level_chart_container.hide();
          this.distribution_chart_container.show();
          this.displayDistributionGraph();
        },
        element: null,
      },
      {
        id: "level",
        label: "Niveaux",
        click: () => {
          //Hide unnecessary graphs and display the good one
          this.level_chart_container.show();
          this.distribution_chart_container.hide();
          this.displayLevelGraph();
        },
        element: null,
      },
    ];

    //In this will be stored the methods to draw each different side graph
    this.side_graph_display_methods = {};

    let side_charts_tabs_parent = $("#" + this.side_charts_main_selector + "-tab-list");
    this.side_charts_tabs_list.forEach((tab) => {
      let li = $("<li>", {
        class: this.side_charts_main_selector + "-tab-item",
        id: this.side_charts_main_selector + "-tab-" + tab.id,
      }).appendTo(side_charts_tabs_parent);
      $("<a>").text(tab.label).appendTo(li);

      li.on("click", () => {
        if (this.current_side_graph !== tab) {
          //Save the current side graph to know what to display in the display current side graph function
          this.current_side_graph = tab;

          tab.click();
          $("." + this.side_charts_main_selector + "-tab-item").removeClass("active");

          li.addClass("active");
        }
      });

      //If general only add the selector to get it when changing pond
      if (tab.general_only) {
        li.addClass(GENERAL_ONLY_SELECTOR);
      }

      tab.element = li;

      //Save the click method for the display current side graph function
      this.side_graph_display_methods[tab.id] = tab.click;
    });

    //Set the first element as active
    this.side_charts_tabs_list[1].element.click();
  }

  /**
   * Render the chart on the right side according to the current active tab
   */
  renderCurentSideChart() {
    if (this.side_graph_display_methods.hasOwnProperty(this.current_side_graph.id)) {
      this.side_graph_display_methods[this.current_side_graph.id]();
    }
  }

  /**
   * Display the chemical distribution chart
   */
  displayDistributionGraph() {
    let datasets = [
      {
        backgroundColor: this.datasets.hist
          // .filter(dataset => dataset.isEnabled)
          .map((dataset) => dataset.color),
        data: this.datasets.hist
          // .filter(dataset => dataset.isEnabled)
          .filter((dataset) => dataset.values.length > 0)
          .map((dataset) => dataset.values.reduce((a, b) => a + b), 1),
      },
    ];

    let data = {
      datasets: datasets,
      labels: this.datasets.hist
        // .filter(dataset => dataset.isEnabled)
        .map((dataset) => dataset.label),
    };

    let options = {
      layout: {
        padding: 50,
      },
      responsive: true,
      maintainAspectRatio: false,

      labels: {
        display: true,
      },

      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          align: "end",
          anchor: "end",
          display: "auto",
          clamp: true,
          formatter: (value, context) => {
            let total = context.dataset.data.reduce((a, b) => a + b);
            return value > 0
              ? context.chart.data.labels[context.dataIndex] +
                  "\n" +
                  formatNumber(value, 2) +
                  " " +
                  this.unit +
                  "\n" +
                  formatNumber((100 * value) / total, true) +
                  "%"
              : null;
          },
          color: (context) => {
            return context.dataset.backgroundColor;
          },
        },
      },
      events: [],
    };

    this.wpDistributionChart?.destroy();
    if (this.hasWpDistribution) {
      this.wpDistributionChart = new Chart(this.wpDistribution, {
        type: "pie",
        data: data,
        options: options,
        plugins: [ChartDataLabels],
      });
      this.wpDistributionChart.update();
      this.side_charts.push(this.wpDistributionChart);
    }
  }

  /**
   * Display the chemical distribution chart
   */
  displayLevelGraph() {
    let datasets = [
      {
        backgroundColor: this.datasets.hist.filter((dataset) => dataset.isEnabled).map((dataset) => dataset.color),
        data: this.datasets.hist
          .filter((dataset) => dataset.isEnabled)
          .map((dataset) => this.last_values[dataset.data_label]),
        tank: this.datasets.curves.filter((dataset) => dataset.isEnabled).map((dataset) => dataset.tank),
        label: this.datasets.hist.filter((dataset) => dataset.isEnabled).map((dataset) => dataset.label),
        date: this.datasets.hist
          .filter((dataset) => dataset.isEnabled)
          .map((dataset) => this.last_values["date_" + dataset.data_label]),
      },
    ];
    console.log(datasets);
    $("#data-ref").text(datasets[0].label);
    $("#data-ref-date").html("Dernières données :<br />" + datasets[0].date);
    let circle = new CircleStat(
      "chemicalpack-side-charts-level-canvas-container",
      formatNumber(parseInt(datasets[0].data[0])),
      2000,
      datasets[0].backgroundColor,
      "mt-2",
      70,
      5,
      "L",
      false,
      formatNumber(parseInt(datasets[0].data[0] / datasets[0].tank / 0.001)),
      "cm",
    );
    circle.create();
  }

  /**
   * Return the sum of each number displayed in the tooltip
   * @returns {number}
   */
  get tooltipSum() {
    let tooltips = Object.values(this.tooltips.hist);
    let ret = 0;

    tooltips.forEach((tooltip) => {
      ret += tooltip;
    });

    return ret;
  }

  emptyChartsData() {
    this.dateLabels = [];
    this.datasets.hist = [];
    this.datasets.prevHist = [];
    for (let j in this.datasets.curves) {
      this.datasets.curves[j].values = [];
    }
    this.tooltips = {
      hist: [],
      curves: [],
      prevhist: [],
    };
  }

  destroyCharts() {
    super.destroyCharts();

    if (this.chemicalChart) this.chemicalChart.destroy();
    if (this.chemicalPackMainChart) this.chemicalPackMainChart.destroy();
  }

  updateChartsData(res) {
    const data = res.data;
    const helper = res.helper;

    this.last_values = helper.last_values;

    let colors = [this.colors.normal, this.colors.dark, this.colors.light, this.colors.other];
    let colors_t = [
      this.colors.normal_transparent,
      this.colors.dark_transparent,
      this.colors.light_transparent,
      this.colors.other_transparent,
    ];

    for (let index in helper.indicators) {
      let indicator = helper.indicators[index];

      // cm to L
      if (this.last_values[indicator.reference.replace("niveau ", "")] !== null)
        this.last_values[indicator.reference.replace("niveau ", "")] *=
          100 * helper.tank[indicator.reference.replace("niveau ", "")] * 0.001;

      this.datasets.hist[index] = {
        values: [],
        data_label: indicator.reference.replace("niveau ", ""),
        label: indicator.label,
        abbreviation: indicator.abbreviation,
        color: colors[index],
        isEnabled: parseInt(index) === parseInt(this.index_active),
      };
      this.datasets.curves[index] = {
        values: [],
        label: "Niveau " + indicator.label,
        abbreviation: indicator.abbreviation,
        color: colors_t[index],
        isEnabled: parseInt(index) === parseInt(this.index_active),
        tank: helper.tank[indicator.reference.replace("niveau ", "")],
      };
    }

    let indicators = res.helper.indicators;
    this.updateDateLabels();

    for (let datum of Object.values(data)) {
      for (let ii in indicators) {
        ii = parseInt(ii);
        let indicator = indicators[ii];
        // cm to L
        this.datasets.curves[ii].values.push(
          datum[indicator.reference] === null
            ? null
            : datum[indicator.reference] * 100 * helper.tank[indicator.reference.replace("niveau ", "")] * 0.001,
        );

        this.datasets.hist[ii].values.push(
          datum[indicator.reference.replace("niveau ", "")] !== null
            ? Math.max(0, datum[indicator.reference + "_delta"]) *
                100 *
                helper.tank[indicator.reference.replace("niveau ", "")] *
                0.001
            : null,
        );
      }
    }

    for (let index in this.datasets.hist) {
      this.tooltips.hist[index] = "NC";
      this.tooltips.curves[index] = "NC";
    }
  }

  updatePreviousChartsData(res) {
    const data = res.data;
    const helper = res.helper;

    let colors = [
      this.colors.normal_transparent,
      this.colors.dark_transparent,
      this.colors.light_transparent,
      this.colors.other_transparent,
    ];
    for (let index in helper.indicators) {
      let indicator = helper.indicators[index];
      this.datasets.prevHist[index] = {
        values: [],
        label: indicator.label,
        color: colors[index],
        isEnabled: true,
      };
    }

    for (let i in data) {
      i = parseInt(i);
      if (data.hasOwnProperty(i)) {
        for (let index in data[i].indicators) {
          let indicator = helper.indicators[index];
          this.datasets.prevHist[index].values.push(
            Math.max(0, data[i][indicator.reference]) *
              100 *
              data["tank"][indicator.reference.replace("niveau ", "")] *
              0.001,
          );
        }
      }
    }

    for (let index in this.datasets.prevHist) {
      this.tooltips.prevhist[index] = "NC";
    }
  }

  static get pack_name() {
    return "chemical";
  }

  static get element_switch_enabled() {
    return false;
  }

  get target() {
    return "pool";
  }
}

export default ChemicalPackPeriodRenderer;

import { Controller } from "@hotwired/stimulus";
import * as monaco from "monaco-editor";
import $ from "jquery";

export default class extends Controller {
  connect() {
    var element = $("#editor");
    console.log(JSON.stringify(element.data("source")));

    var diffEditor = monaco.editor.createDiffEditor(document.getElementById("editor"), {
      renderSideBySide: true,
      readOnly: true,
      overviewRulerLanes: 0,
      hideCursorInOverviewRuler: true,
      overviewRulerBorder: false,
      minimap: {
        enabled: false,
      },
      // experimental: {
      //   useVersion2: true,
      // }
    });

    diffEditor.setModel({
      original: monaco.editor.createModel(JSON.stringify(element.data("source"), null, 2), "json"),
      modified: monaco.editor.createModel(JSON.stringify(element.data("target"), null, 2), "json"),
    });
    diffEditor.updateOptions({});
    // monaco.editor.create(document.getElementById('editor'), {
    //   value: 'console.log("Hello, world")',
    //   language: 'javascript'
    // });
  }
}

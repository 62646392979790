import "../../../scss/_admin.scss";
import { doIntersect } from "@js/pages/hippo-local/utils";

import _, { debounce, find, includes, isNull, map, max, memoize, throttle } from "underscore";
import cytoscape from "cytoscape";
import edgehandles from "cytoscape-edgehandles";
import cxtmenu from "cytoscape-cxtmenu";
import Swal from "sweetalert2";
import popper from "cytoscape-popper";
import domNode from "cytoscape-dom-node";
import Layers from "cytoscape-layers";
import { mod } from "@js/pages/hippo-local/utils";
import $ from "jquery";

import "cytoscape-context-menus/cytoscape-context-menus.css";
import Edge from "@js/pages/hippo-local/Edge";

import { compareJSON } from "../../helpers/JSONTools";
import { dump_performance, formatNumber } from "../../helpers/utils";

cytoscape.use(domNode);
cytoscape.use(popper);
cytoscape.use(cxtmenu);
cytoscape.use(edgehandles);
cytoscape.use(Layers);

const NO_OPERATION = 0;
const PATH_SELECT_OPERATION = 1;
const MANUAL_SELECT_OPERATION = 2;

export default class Synoptic {
  static mode = "w";
  static pool_id;
  static menu;
  static cy = null;
  static isRunning = true;
  static eh = null;
  static ready = false;
  static runningLoader = 0;
  static save = null;
  static locked = false;
  static prototypes;
  operationPathSelect = null;

  constructor(pool_id, mode = "w", container = $("#cy"), svgs = null) {
    this.svgs = svgs;

    Synoptic.save = null;
    this.requests = [];
    this.container = container;

    this.currentOperation = NO_OPERATION;

    this.runningLayout = null;
    Synoptic.pool_id = pool_id;
    Synoptic.mode = mode;
    this.draggedOverUnit = null;
    this.patterns = null;
    this.data = null;
    this.currentOpGroup = null;
    this.initialized = false;

    this.queue = 0;

    this.unitLinksVisible = 2;

    this.layouts = ["hydraulic"];

    container.parent().on("contextmenu", (_e) => {
      return false;
    });
  }

  async init() {
    console.log("INIT");

    await $.ajax({ url: "/prototypes" }).then((response) => {
      Synoptic.prototypes = response;
    });

    Synoptic.cy = cytoscape({
      container: this.container,
      // renderer: { name: "offcanvas" },
      headless: false, // container to render in
      layout: null,
      elements: [],
      // textureOnViewport: Synoptic.mode == "r",
      // userZoomingEnabled: Synoptic.mode != "r",
      // userPanningEnabled: Synoptic.mode != "r",
      pixelRatio: 1,
      motionBlur: false,
      renderer: {
        showFps: true,
        skipFrame: true,
      },
    });
    window.core = Synoptic.cy;
  }

  async defaultPositioning() {
    Synoptic.cy.elements().removeClass("positioned");

    let defaultPositions = await $.ajax({
      url: `/pool/${Synoptic.pool_id}/circuit/${Synoptic.getCircuit()}/structure/default`,
      contentType: "application/json; charset=utf-8",
    });

    Synoptic.cy.startBatch();
    for (let key in defaultPositions) {
      let [_class, gid] = key.split("-");
      let node = Synoptic.cy.nodes(`[entity.class="${_class}"][entity.global_id=${gid}]`);
      let value = defaultPositions[key];

      let pos = value["position"] ?? null;
      if (pos) node.position(pos).addClass("positioned");

      let angle = value["angle"] ?? null;
      if (null !== angle) Synoptic.setRotation(node.children(".io"), angle, false);

      let ports = value["ports"] ?? null;
      if (ports) {
        ports.forEach((port, index) => {
          let portNode = node.children(`.io[index=${index}]`);
          let anchorNode = portNode.outgoers(".anchor");

          let anchors = port["anchors"] ?? null;
          if (anchors) {
            anchors.forEach((anchorData, anchorIndex) => {
              anchorNode[anchorIndex]?.position(anchorData["position"])?.addClass("positioned");

              // let pipe = Synoptic.cy.edges(`[entity.piping_component_id=${pipeId}]`);
              // let anchorData = pipes[pipeId]["anchor"] ?? null;
              // if (anchorData) {
              //   let anchor = Synoptic.cy.$id(pipe.data("anchor"));
              // }
            });
          }
        });
      }
    }
    Synoptic.cy.endBatch();

    Synoptic.cy
      .nodes(".he.new")
      .not(".positioned")
      .each((a) => {
        let bb = Synoptic.cy.nodes(".positioned").bb();
        a.position({ x: bb.x2 + a.width(), y: bb.y1 + a.height() }).addClass("positioned");
      });

    // correct last anchors
    Synoptic.cy.startBatch();
    Synoptic.cy
      .nodes(".anchor")
      .not(".positioned")
      .each((a) => {
        let x = [];
        let y = [];

        a.outgoers("node").each((n) => {
          x.push(n.position().x);
          y.push(n.position().y);
        });

        a.incomers("node").each((n) => {
          x.push(n.position().x);
          y.push(n.position().y);
        });

        a.not(".positioned")
          .position({ x: (Math.max(...x) + Math.min(...x)) / 2, y: (Math.max(...y) + Math.min(...y)) / 2 })
          .addClass("positioned");
      });
    Synoptic.cy.endBatch();
  }

  async onAppReady() {
    this.setupCtxMenu();

    setTimeout(() => {
      this.animateEdges({
        mode: "duration",
        modeValue: 2000, //ms
        // mode: "speed",
        // modeValue: 0.15, // pixel/ms
        direction: "forward",
        // randomOffset: false,
      });
      this.animateNodes();
    });
  }

  async reload() {
    await this.init();
    await this.update();
  }

  async update() {
    console.log("UPDATE");

    // abort previous request
    this.requests
      .filter((element) => element.readyState == 1)
      .forEach((request) => {
        try {
          request.abort();
        } catch (e) {
          console.log(e);
        }
      });
    this.requests = this.requests.filter((element) => element.readyState == 1);

    // Update graph
    Synoptic.cy.scratch("obj", this);

    Synoptic.cy.startBatch();

    // Synoptic.cy.mount($("#cy"));
    // Synoptic.cy.maxZoom(5);
    // Synoptic.cy.minZoom(0.5);

    Synoptic.ready = false;
    this.requests.push(
      this.requestData().done(async (response) => {
        dump_performance("requests done");

        this.data = response;
        Synoptic.data = response;

        dump_performance("build");
        await this.build(response);
        dump_performance("grid");
        this.setupGrid();
        dump_performance("post build");
        Synoptic.cy.batch(async () => await this.postBuild());
        dump_performance("post build done");

        // *UI
        Synoptic.cy.on("pan resize scrollzoom zoom", () => {
          Synoptic.cy.startBatch();
          if (this.popper) this.popper.update();
          Synoptic.cy.nodes().forEach((node) => {
            node.scratch("popper")?.update();
            node.scratch("operationPopper")?.update();
          });
          $(".data-dynamic-row").css("font-size", 14 * Synoptic.cy.zoom());
          Synoptic.cy.nodes().trigger("updatePopper");
          Synoptic.cy.endBatch();
          Synoptic.cy.trigger("endPan");
        });

        Synoptic.cy.ready(() => {
          dump_performance("last actions");
          switch (Synoptic.mode) {
            case "w":
              // EDGE CREATION TOOL
              this.setupEdgeHandles();
              break;
            default:
              break;
          }
          this.bindActions();
          dump_performance("ready");
          Synoptic.ready = true;
        });
      }),
    );
  }

  fakeUnitSize() {}

  requestData() {
    return $.ajax({
      url: `/pool/${Synoptic.pool_id}/structure/download?cids`,
    });
  }

  async build(response) {}

  async postBuild() {
    Synoptic.cy.nodes(":child").each((child) => {
      child.data("_parent", child.parent().id());
    });
    this.setCtxClasses();
  }

  buildMenu(data) {}

  async BuildElements(data) {}

  layout() {
    let options;
    switch (Synoptic.getLayer()) {
      case "hydraulic":
        let alignments = { vertical: [], horizontal: [] };
        let placements = [];
        let cy = Synoptic.cy;
        let selector = (ele) => {
          return (ele.hasClass("io") && ele.parent().hasClass("unit")) || ele.hasClass("logo");
        };

        let pumps = cy.$('[entity.class = "pump"]').descendants(selector);
        let filters = cy.$('[entity.class = "filter"]').descendants(selector);
        let dechloraminators = cy.$('[entity.class = "dechloraminator"]').descendants(selector);
        let waterHeaters = cy.$('[entity.class = "waterHeating"]').descendants(selector);
        let ponds = cy.$('[entity.class = "pond"]').descendants(selector);
        let ure = cy.$('[entity.class = "ureUnit"]').descendants(selector);
        let lef = cy.$('[p_claentity.classss = "cooledWashingUnit"]').descendants(selector);

        let buffertanks = cy.$('[entity.class = "bufferTank"]').descendants(selector);

        // TODO: align multipls waterHeaters, dechlo, ...

        // *PUMPS CONSTRAINTS
        let v_align = [];
        pumps.each((p, i) => {
          // PUMPS
          v_align.push(p.id());
          if (0 < i && i < pumps.length - 1) {
            placements.push({
              top: p.id(),
              bottom: pumps[i + 1].id(),
              gap: 100,
            });
          }

          // PUMP < FILTER
          filters.each((f) => {
            placements.push({ left: p.id(), right: f.id(), gap: 100 });
          });

          buffertanks.each((b) => {
            placements.push({ left: b.id(), right: p.id(), gap: 100 });
            // placements.push({ top: b.id(), bottom: p.id(), gap: 100 });
          });
          buffertanks
            .filter('[entity.class = "bufferTank"]')
            .children()
            .each((b) => {
              // placements.push({ left: b.id(), right: p.id(), gap: 100 });
              placements.push({ top: b.id(), bottom: p.id(), gap: 100 });
            });
          lef.each((l) => {
            placements.push({ bottom: l.id(), top: p.id(), gap: 100 });
          });

          if (filters.hasOwnProperty(i)) {
            alignments.horizontal.push([p.id(), filters[i].id()]);
          }
        });
        alignments.vertical.push(v_align);

        // *FILTERS CONSTRAINTS
        v_align = [];
        filters.each((f) => {
          v_align.push(f.id());
          cy.collection()
            .union([dechloraminators, waterHeaters])
            .each((o) => {
              placements.push({ left: f.id(), right: o.id(), gap: 100 });
              placements.push({ bottom: f.id(), top: o.id(), gap: 100 });
            });
        });
        alignments.vertical.push(v_align);

        // *PONDS CONSTRAINTS
        ponds.each((po) => {
          cy.collection()
            .union([pumps, filters, dechloraminators, waterHeaters])
            .each((o) => {
              placements.push({ bottom: o.id(), top: po.id(), gap: 100 });
            });
        });

        // *ures CONSTRAINTS
        ure.each((s) => {
          lef.each((l) => {
            placements.push({ bottom: s.id(), top: l.id(), gap: 100 });
          });
        });

        // *Heater CONSTRAINTS
        v_align = [];
        waterHeaters.union(dechloraminators).each((s) => {
          v_align.push(s.id());
        });
        alignments.vertical.push(v_align);

        break;

      case "software":
        break;
    }
  }

  bindActions() {
    console.log("BIND ACTIONS");

    this.popperNeeded = false;
    this.popper = null;
    this.lastTapped = null;
    // update popper position while needed

    Synoptic.cy.ready(async (event) => {
      dump_performance("post rotate");
      Synoptic.cy.endBatch();
      dump_performance("end batch");
      this.drawUREPath();
      dump_performance("draw path");
      this.graphUpdate();
      dump_performance("graph update");
      Synoptic.cy.batch(() => this.correct());

      dump_performance("correct edges");
      await this.onAppReady();
      dump_performance("on app ready actions");
    });

    Synoptic.cy.on("mouseover", ".toSewageEdge", (event) => {
      event.target.style("opacity", 1);
    });

    Synoptic.cy.on("mouseout", ".toSewageEdge", (event) => {
      event.target.style("opacity", 0);
    });

    var update = (event) => {
      let popper = event.target.scratch("tooltip");
      popper?.update();
    };
    Synoptic.cy.on("mouseover", ".he, .unit, .anchor", (event) => {
      let node = event.target;
      if (undefined === node.data("tooltip")) return;

      let popper = node.popper({
        content: () => {
          let div = document.createElement("div");
          div.innerHTML = node.data("tooltip");
          div.classList.add("nodeTooltip");
          div.setAttribute("style", "zIndex: 999;");
          document.body.appendChild(div);
          return div;
        },
      });

      node.scratch("tooltip", popper);

      node.on("position", update);
      // Synoptic.cy.on("pan zoom resize", update);

      node.once("mouseout", (event) => {
        $(".nodeTooltip").remove();
        update = null;
        popper.destroy();
        node.scratch("tooltip", null);
        node.off("position", update);
        // Synoptic.cy.off("pan zoom resize", update);
      });
    });

    Synoptic.cy.on("redraw", ".he,.unit", (event) => {
      $(event.target.data("dom"))?.css("opacity", event.target.style("opacity"));
    });

    Synoptic.cy.on("drawCross", "edge", (event) => {
      if (event.target.visible()) setTimeout(() => Synoptic.validateGraph(event.target), 200);
    });
  }

  animateEdges(options) {
    const layers = Synoptic.cy.layers();
    const layer = layers.nodeLayer.insertBefore("canvas");

    function getOrSet(elem, key, value) {
      const v = elem.scratch(key);
      if (v != null) {
        return v;
      }
      const vSet = value();
      elem.scratch(key, vSet);
      return vSet;
    }

    let dist = memoize(
      (start, end) => {
        return Math.sqrt((start.x - end.x) ** 2 + (start.y - end.y) ** 2);
      },
      function (input) {
        return JSON.stringify(input);
      },
    );

    function computeFactor(elapsed, offset, start, end) {
      const duration = options.mode == "duration" ? options.modeValue : dist(start, end) / options.modeValue;
      if (!Number.isFinite(duration) || Number.isNaN(duration)) {
        return 0;
      }
      let f = elapsed / duration;
      f += offset;
      const v = f - Math.floor(f);
      return options.direction == "forward" ? v : 1 - v;
    }

    let start = null;
    let elapsed = 0;
    const update = (time) => {
      if (start == null) {
        start = time;
      }
      elapsed = time - start;
      if (Synoptic.cy) {
        try {
          layer.update();
        } catch (e) {
          console.log(e);
          return;
        }
        // requestAnimationFrame(update);
      }
    };

    layers.renderPerEdge(
      layer,
      (ctx, edge, path, start, end) => {
        if (edge.hasClass("switchControl") || edge.hidden() || edge.transparent()) return;

        const offset = options.randomOffset ? getOrSet(edge, "_animOffset", () => Math.random()) : 0;
        const v = computeFactor(elapsed, offset, start, end);

        let undraw = edge.hidden() || edge.removed();

        let base = edge.hasClass("offGroup") || undraw ? "transparent" : "#cfcfcf";
        let color = edge.hasClass("offGroup") || undraw ? "transparent" : (edge.data("gcolor") ?? "#cfcfcf");

        ctx.lineJoin = "round";
        switch (edge.data("ganim")) {
          case "move":
            ctx.globalAlpha = 1;

            const g = ctx.createLinearGradient(start.x, start.y, end.x, end.y);
            g.addColorStop(0, color);
            g.addColorStop(v, color);
            // g.addColorStop(v, color);
            g.addColorStop(v, base);
            g.addColorStop(1, base);
            ctx.strokeStyle = g;
            break;
          case "blink":
            ctx.globalAlpha = v;
            ctx.strokeStyle = base;
            // if (v > 0.5) ctx.strokeStyle = color;
            // else ctx.strokeStyle = base;
            break;

          case "static":
            ctx.strokeStyle = color;
            break;
        }

        ctx.lineWidth = 3;
        ctx.lineCap = "square";
        ctx.globalCompositeOperation = "darken";
        ctx.stroke(path);
      },
      {
        checkBounds: true,
        checkBoundsPointCount: 5,
      },
    );
    requestAnimationFrame(update);
  }

  animateNodes() {}

  drawUREPath() {
    try {
      let ure = Synoptic.cy.nodes('[entity.class = "energy_recovering_units"]');
      let primary = Synoptic.cy.nodes('[entity.class = "heat_exchanger_primaries"]');
      let secundary = Synoptic.cy.nodes('[entity.class = "heat_exchanger_secundaries"]');
      let EUFOut = Synoptic.cy.nodes('[entity.class = "cooled_washing_tanks"]');
      if (EUFOut.length == 0) EUFOut = Synoptic.cy.nodes('[entity.class = "sewages"]');

      let dijkstraPrimary = Synoptic.cy.elements().dijkstra(
        primary,
        function (edge) {
          return 1;
        },
        true,
      );
      let dijkstraSecundary = Synoptic.cy.elements().dijkstra(
        secundary,
        function (edge) {
          return 1;
        },
        true,
      );

      let dijkstraSupply = Synoptic.cy.elements().dijkstra(
        '[entity.class = "water_supply_systems"]',
        function (edge) {
          return 1;
        },
        true,
      );

      let domain = ure.descendants().union(ure.descendants().connectedEdges());
      domain = domain.union(Synoptic.cy.edges());
      dijkstraPrimary.pathTo(EUFOut).removeClass(".hid").intersection(domain).addClass("EUF");

      Synoptic.cy.elements('[entity.class = "pumping_units"] > [entity.class = "pumps"]').each((p) => {
        let dijkstraPumps = Synoptic.cy.elements().dijkstra(function (edge) {
          return 1;
        }, true);
        dijkstraPumps.pathTo(primary).intersection(domain).addClass("EUC");
      });

      dijkstraSecundary.pathTo('[entity.class = "buffer_tanks"]').intersection(domain).addClass("ERC");
      dijkstraSupply.pathTo(secundary).intersection(domain).addClass("ERF");
    } catch (e) {
      console.log(e);
    }
  }

  graphUpdate() {
    // SIMPLIFY SEWAGES
    Synoptic.cy.$("[entity.class = 'sewages']").each((sewage) => {
      Synoptic.cy
        .nodes(`.io[_parent="${sewage.id()}"]`)
        .addClass("toSewage")
        .union(Synoptic.cy.$("[entity.class = 'sewages']"))
        .connectedEdges()
        .addClass("toSewageEdge")
        .sources()
        .addClass("toSewage");
    });
  }

  showLoader() {
    $("#cy").css("opacity", 0);
    $("#cy-loader-container").show();
  }

  hideLoader() {
    $("#cy").css("opacity", 1);
    $("#cy-loader-container").hide();
  }

  toggleSelectionMode(state = true) {
    if (state) {
      // SET SELECTION MODE
      Synoptic.isRunning = false;
      Synoptic.cy.elements().addClass("offGroup");
      Synoptic.cy.elements().not(".unit").data("events", "no");
      $(".nodeImg svg").css("opacity", 0.2);

      // TOGGLE ON TAP
      // Synoptic.cy.nodes(".unit").on("tap", (event) => {
      //   // Higlight node + children
      //   event.target.toggleClass(["onGroup", "offGroup"]);
      //   event.target.descendants().toggleClass(["onGroup", "offGroup"]);
      //   event.target.descendants(".logo").each((l) => {
      //     $(l.data("dom"))
      //       .find("svg")
      //       .css("opacity", event.target.hasClass("onGroup") ? 1 : 0.2);
      //   });

      //   // Higlight path
      //   let group = Synoptic.cy.$(".onGroup");
      //   Synoptic.cy.edges().addClass("offGroup").removeClass("onGroup");
      //   group.edgesWith(group).addClass("onGroup").removeClass("offGroup");
      // });
    } else {
      // RESET
      Synoptic.isRunning = true;
      Synoptic.cy.nodes(".unit").off("tap");
      Synoptic.cy.elements().removeClass(["onGroup", "offGroup"]);
      Synoptic.cy.elements().not(".unit").data("events", "yes");
      $(".nodeImg svg").css("opacity", 1);
    }
  }

  add_node(label = "", id, param = {}, allow_add = false) {
    Synoptic.cy.remove(Synoptic.cy.$id(id));

    let config = {
      id: id,
      name: label,
      width: 50,
      height: 50,
      shape: "rectangle",
      valign: "top",
      color: "white",
      fontSize: 10,
      opacity: 0,
      borderWidth: 0,
      borderColor: "black",
    };

    for (let p in param) {
      if (param.hasOwnProperty(p)) {
        if (p.includes("label")) {
          config[p.replace("label", "width")] = param[p].length * 10;
        }
        config[p] = param[p];
      }
    }

    let new_node = {
      group: "nodes",
      data: config,
    };

    if (allow_add) {
    }

    let node = Synoptic.add(new_node);
    return node;
  }

  removeLayout(layout) {
    if (this.layouts.includes(layout)) {
      this.layouts = this.layouts.filter((item) => {
        return item !== layout;
      });
    }
  }

  addLayout(layout) {
    if (!this.layouts.includes(layout)) {
      this.layouts.push(layout);
    }
  }

  setupGrid() {}

  // Connect to a new edge to a pipe anchor
  addPipeInput(pipe, port) {
    let anchorId = `anchor_${pipe?.piping_component_id}`;
    let branch = new Edge({
      id: `E_${port.id()}_${pipe.global_id}`,
      source: port.id(),
      target: anchorId,
      pipe: pipe,
      entity: pipe,
      anchor: anchorId,
    });
    Synoptic.addTypeToEntity(branch.obj);
  }

  // Connect to a new edge from a pipe anchor
  addPipeOutput(pipe, port) {
    let anchorId = `anchor_${pipe?.piping_component_id}`;
    let branch = new Edge({
      id: `E_${pipe.global_id}${port.id()}`,
      source: anchorId,
      target: port.id(),
      pipe: pipe,
      entity: pipe,
      anchor: anchorId,
    });
    Synoptic.addTypeToEntity(branch.obj);
  }

  toggleOperationPathSelect(operation) {
    $(".nodeOperationTooltip").remove();

    if (!Synoptic.cy) return;

    $("#cy-nav-operations button").attr("disabled", true);
    let currentPath = this.currentPath;

    if (this.currentOperation == NO_OPERATION) {
      this.currentOperation = PATH_SELECT_OPERATION;
      this.currentPath = operation;
      this.startOperationPathSelect();
    } else if (this.currentOperation == PATH_SELECT_OPERATION) {
      this.finishOperationPathSelect();
      this.currentPath = null;
      if (currentPath !== operation) {
        this.toggleOperationPathSelect(operation); // fast switch
      }
    } else
      Swal.fire({
        title: "une autre opération est en cours !",
        icon: "warning",
      });

    $("#cy-nav-operations button").attr("disabled", false);
  }

  startOperationPathSelect() {
    let operation = this.currentPath;
    Synoptic.cy.nodes("[entity.position]").each((node) => {
      node.scratch("operationPopper")?.destroy();

      let popper = node.popper({
        content: () => {
          let div = document.createElement("div");
          div.innerHTML = `<p>position: ${node.data(`entity.position.${this.currentPath}`)}</p>`;
          div.classList.add("nodeOperationTooltip");
          document.body.appendChild(div);
          $(div).on("click", () => {
            let nPositions = node.data("type.positions");
            let currentPosition = node.data(`entity.position.${this.currentPath}`);
            currentPosition = (currentPosition ?? -1) + 1;
            if (currentPosition >= nPositions) currentPosition = null;
            node.data(`entity.position.${this.currentPath}`, currentPosition);
            $(div)
              .find("p")
              .text(`position: ${currentPosition ?? "null"}`);
          });
          return div;
        },
      });
      node.scratch("operationPopper", popper);
    });

    Synoptic.cy.nodes().not(Synoptic.cy.$(".menu-item, .menu")).lock();
    Synoptic.cy.$(`[!entity.operation.${operation}]`).addClass("hideOperationPathSelect").trigger("redraw");
    Synoptic.cy.$(`[?entity.operation.${operation}]`).addClass("showOperationPathSelect").trigger("redraw");

    // enable select on sewage edge
    Synoptic.cy.$(".toSewageEdge").removeClass("noevents");

    $(`#cy-nav-operations button[data-operation='${operation}']`).css("background-color", "red");
  }

  async finishOperationPathSelect() {
    if (this.currentOperation == PATH_SELECT_OPERATION) this.currentOperation = NO_OPERATION;
    else
      return Swal.fire({
        title: "une autre opération est en cours !",
        icon: "warning",
      });

    let data = [];

    Synoptic.cy.$(`[entity.operation]`).each((node, i, nodes) => {
      data.push(node.data("entity"));
    });

    $.ajax({
      type: "POST",
      url: `/pool/${Synoptic.pool_id}/synoptic/save`,
      data: JSON.stringify(data),
      contentType: "application/json",
    }).then(async (res) => {
      let diff = {};
      data.forEach((d) => {
        let entity = res.find((element) => element.global_id == d.global_id);
        let entityDiff = compareJSON(entity, d);
        if (Object.keys(entityDiff).length > 0) {
          diff[d.global_id] = entityDiff;
        }
      });

      if (Object.keys(diff).length > 0) {
        Swal.fire({
          title: "Enregistrement echoué",
          icon: "error",
          html: `<pre data-controller="json-viewer">${JSON.stringify(diff)}</pre>`,
        });
      } else {
        Swal.fire({
          title: "Chemins sauvegardés !",
          position: "top-end",
          showConfirmButton: false,
          icon: "success",
          timer: 2000,
          toast: true,
        });
      }
    });

    Synoptic.cy.nodes().not(Synoptic.cy.$(".menu-item, .menu")).unlock();
    Synoptic.cy
      .elements()
      .removeClass("hideOperationPathSelect")
      .removeClass("showOperationPathSelect")
      .trigger("redraw");

    // disable select on sewage edge
    Synoptic.cy.$(".toSewageEdge").addClass("noevents");
  }
  static async toggleFocusUnit(targetNode) {
    // Synoptic.cy.startBatch();
    targetNode = targetNode.hasClass("unit") ? targetNode : targetNode.ancestors(".unit");

    let state = targetNode.data("isFocused") ?? false;

    let neighbors = targetNode
      .union(targetNode.descendants())
      .union(targetNode.descendants().neighborhood())
      .union(targetNode.descendants().neighborhood().ancestors())
      .union(targetNode.descendants().neighborhood().siblings());

    neighbors
      .absoluteComplement()
      .data("isFocused", state)
      .trigger(state ? "collapse" : "restore");

    targetNode.data("isFocused", !state);

    // Synoptic.cy.endBatch();
  }

  static async focusUnit(targetNode) {
    targetNode = targetNode.hasClass("unit") ? targetNode : targetNode.ancestors(".unit");

    let neighbors = targetNode
      .union(targetNode.descendants())
      .union(targetNode.descendants().neighborhood())
      .union(targetNode.descendants().neighborhood().ancestors())
      .union(targetNode.descendants().neighborhood().siblings());

    let edges = Synoptic.cy.collection();
    neighbors.connectedEdges().forEach((edge) => {
      if (edge.data("pipe"))
        edges = edges.union(Synoptic.cy.edges(`[pipe.global_id = ${edge.data("pipe.global_id")}]`));
    });

    let targets = edges.sources();
    neighbors = neighbors
      .union(targets.neighborhood())
      .union(targets.neighborhood().ancestors())
      .union(targets.neighborhood().siblings());

    neighbors.trigger("restore").absoluteComplement().trigger("collapse");
  }

  static async unFocusUnit(targetNode) {
    Synoptic.cy.elements().trigger("restore");
  }

  async setupCtxMenu() {}

  async save() {
    console.log("save file");
    parent = this;
    // await Synoptic.validateGraph();

    return $.ajax({
      type: "POST",
      url: `/pool/${Synoptic.pool_id}/circuit/${Synoptic.getCircuit()}/structure/save`,
      data: JSON.stringify({ synoptic: Synoptic.cy.json() }),
      contentType: "application/json; charset=utf-8",
    });
  }

  async load() {
    console.log("load save file");
    parent = this;
    const save = await $.ajax({
      url: `/pool/${Synoptic.pool_id}/circuit/${Synoptic.getCircuit()}/structure/load`,
    });

    Synoptic.save = save;
    this.pins = [];
  }

  static edgeLines(edge) {
    let lines = [],
      cursor = edge.sourceEndpoint();
    if (edge.segmentPoints() != null) {
      edge.segmentPoints().forEach((point) => {
        lines.push([cursor, point]);
        cursor = point;
      });
      lines.push([cursor, edge.targetEndpoint()]);
    }
    return lines;
  }

  static async validateGraph() {
    let n_crossed = 0;
    Synoptic.crossCache ??= {};
    Synoptic.cy.nodes(".cross").remove();

    dump_performance("validation start");

    let edges = Synoptic.cy.edges(":visible");
    let detectAndDrawCross = function (edge) {
      let lines = Synoptic.edgeLines(edge);
      let crossed = false;
      let verticalEdge = null;
      let cross_point = null;

      // for all other edge
      let others = Synoptic.cy.edges(":visible").not(edge);
      others.forEach((check_edge) => {
        if (
          edge.visible() &&
          check_edge.visible() &&
          edge.source() != check_edge.source() &&
          edge.target() != check_edge.target()
          // &&
          // edge.data("pipe")?.global_id !== check_edge.data("pipe")?.global_id
        ) {
          // for all other edges segments
          let check_lines = Synoptic.edgeLines(check_edge);
          check_lines.forEach((check_line) => {
            lines.forEach((line) => {
              if (doIntersect(check_line[0], check_line[1], line[0], line[1])) {
                crossed = true;
                if (Math.abs(check_line[0].y - check_line[1].y) > 5) {
                  verticalEdge = check_edge;
                } else {
                  verticalEdge = edge;
                }

                let x1 = check_line[0].x,
                  y1 = check_line[0].y,
                  x2 = check_line[1].x,
                  y2 = check_line[1].y,
                  x3 = line[0].x,
                  y3 = line[0].y,
                  x4 = line[1].x,
                  y4 = line[1].y;
                let D = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
                cross_point = {
                  x: ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) / D,
                  y: ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) / D,
                  color: verticalEdge.style("line-color"),
                };
              }
            });
          });
        }
        // DRAW CROSS POINT
        let ids = [edge.id(), check_edge.id()];

        if (cross_point) {
          let cPoint = Synoptic.add({
            group: "nodes",
            data: { id: `${ids[0]}${ids[1]}` },
          });

          let color = cross_point.color;
          cPoint.position(cross_point).addClass("cross");
          cPoint.style("events", "no");
          cPoint.lock().ungrabify();
          cPoint.data("backgroundGradientStopColors", `#fafafa ${color} ${color} #fafafa`);
        }
      });
    };

    // let memoized = _.memoize(detectAndDrawCross, (edge) => {
    //   return JSON.stringify([edge.id(), edge.source().position(), edge.target().position()]);
    // });

    edges.forEach((edge, index, edges) => {
      // if (!edges.contains(edge)) return;
      detectAndDrawCross(edge);
    });

    dump_performance("validation end");
  }

  switchMode(mode = null) {
    let f = () => {
      switch (mode) {
        case "lef":
          this.toggleSelectionMode(true);
          break;
        default:
          this.toggleSelectionMode(false);
          break;
      }
    };

    if (this.initialized) f();
    else {
      $("#cy").one("initialized", () => f());
    }
  }

  addUnitToOperationGroup(unit, id = null) {}

  removeUnitFromOperationGroup(unit, id) {}

  delete() {
    // Synoptic.cy.destroy();
    // Synoptic.cy = null;
    Synoptic.cy.elements().data("dom", null);
    $(".node-logo").remove();
    $(".nodeTooltip").remove();
    $(".data-dynamic").remove();
    $("#axone-fullpopup").remove();
    Synoptic.cy.elements().remove();
    console.log("destroy");
  }

  static getCircuit() {
    return (
      parseInt($("#circuit-select").val() ?? $(".js-element-nav .item a").filter(".activeElement").data("elementid")) ||
      0
    );
  }

  static getLayer() {
    return $("#layer-select").val();
  }

  fit(collection = null) {
    Synoptic.fit(collection);
  }

  static fit(collection = null) {
    let fitAnim = Synoptic.cy.animation({
      fit: {
        eles: collection ?? Synoptic.cy.nodes(collection).not(Synoptic.cy.$(".menu-item, .menu")),
        padding: 50,
        duration: 1,
      },
    });

    return fitAnim.play().promise();
  }

  // FRONT TO BACK REQUESTS

  // Delete edge(outlet) in DB
  static disconnectEntity(payload) {
    return $.ajax({
      type: "POST",
      url: `/pool/${Synoptic.pool_id}/structure/remove/outlet`,
      data: payload,
    });
  }

  async addSVG(node) {
    let unitShown = (node) => ["analysers", "water_supply_systems"].includes(node.data("entity.class"));
    let ancestor = node.ancestors().last().id();

    let visible = unitShown(node) || node.hasClass("he");
    let imgPath = node.data("imgPath") ?? `/build/app/images/${node.data("entity.class")}`;

    if (
      (node.data("type.positions") === null || node.data("type.valve_type_name")?.toLowerCase()?.includes("electro")) &&
      !imgPath.includes("_controls")
    ) {
      imgPath += "_controls";
    }

    if (
      node.data("type.generic_actuator_type_name")?.toLowerCase()?.includes("surpresseur") &&
      !imgPath.includes("_surpressor")
    ) {
      imgPath += "_surpressor";
    }

    if (imgPath in this.svgs && visible) {
      if (undefined == this.svgs[imgPath].content) {
        this.svgs[imgPath].content = await $.get({ url: this.svgs[imgPath].url, dataType: "html" });
        console.log(imgPath);
      }

      let div = $(
        `<div class="nodeImg nodeImgComponent ${
          visible ? "" : "hidden"
        }" data-ancestor="${ancestor}" style="pointer-events: none" />`,
      );
      div.prepend($(this.svgs[imgPath].content));
      let pos = node.position();
      node.data({ dom: div[0], imgPath: imgPath });
      node.addClass("logo").position(pos);
      // Synoptic.setRotation(node, node.data("angle"));
      Synoptic.rotateDiv(node, node.data("angle"));
      node.trigger("add");
      node.on("data", (event) => div.css("opacity", node.effectiveOpacity()));
    }
  }

  // Delete node(entity) in DB
  static deleteEntity(payload) {
    return $.ajax({
      type: "DELETE",
      url: `/pool/${Synoptic.pool_id}/structure/remove/element`,
      data: payload,
    })
      .done((response) => {
        // this.update();
      })
      .always((response) => {});
  }

  static connectSwitch(payload) {
    return $.ajax({
      type: "POST",
      url: `/pool/${Synoptic.pool_id}/synoptic/connect/switch`,
      data: payload,
    });
  }

  // Create edge(outlet) in DB
  static connectEntity(payload) {
    return $.ajax({
      type: "POST",
      url: `/pool/${Synoptic.pool_id}/structure/add/outlet`,
      data: payload,
    });
  }

  // Add child in DB
  static addEntity(payload, pos = null) {
    return $.ajax({
      type: "POST",
      url: `/pool/${Synoptic.pool_id}/structure/add/child`,
      data: payload,
    })
      .done((response) => {})
      .always((response) => {});
  }

  getNameField(node) {
    let entityClass = node.data("entity.class");
    if (!entityClass) return;

    let proto = Synoptic.prototypes?.find((element) => element.field == entityClass);
    let nameField = proto?.properties?.find((property) => property?.hipponet?.[0] == "name")?.hipponet[1];
    return nameField;
  }

  static getIdField(node) {
    let entityClass = node.data("entity.class");
    if (!entityClass) return;

    let proto = Synoptic.prototypes?.find((element) => element.field == entityClass);
    let idField = proto?.properties?.find((property) => property?.hipponet?.[0] == "id")?.hipponet[1];
    return idField;
  }

  getType(type_id_field, id) {
    let loadField = Synoptic.prototypes?.find((element) =>
      element.properties.find((property) => property?.hipponet?.[1] == type_id_field),
    );
    let type = this.data.site_description?.[loadField?.field]?.find((_type) => _type?.[type_id_field] == id);
  }

  toggleManualSelect() {
    // Synoptic.cy.startBatch();
    switch (this.currentOperation) {
      case MANUAL_SELECT_OPERATION:
        Synoptic.cy.elements().removeClass(["showManualSelect", "hideManualSelect"]);
        this.currentOperation = NO_OPERATION;
        break;
      case NO_OPERATION:
        Synoptic.cy.elements().addClass("hideManualSelect");
        Synoptic.cy.elements("[?type.manual]").removeClass("hideManualSelect").addClass("showManualSelect");
        this.currentOperation = MANUAL_SELECT_OPERATION;
        break;
      default:
        Swal.fire({
          title: "une autre opération est en cours !",
          icon: "warning",
        });
    }
    // Synoptic.cy.endBatch();
  }

  static addTypeToEntity(node) {
    let idField = Synoptic.getIdField(node);

    let types = Synoptic.prototypes?.filter(
      (element) => element.root == "App\\Entity\\Pool\\Component\\Type\\AbstractType",
    );

    // Obtain type Prototype from entity fields
    let typePrototype, typeIdField;
    for (let field in node.data("entity")) {
      typePrototype = types.find((_type) =>
        _type.properties.find((prop) => {
          let condition = prop?.hipponet?.[0] == "id" && prop?.hipponet?.[1] == field;
          if (condition) {
            typeIdField = prop?.hipponet?.[1];
            return true;
          }
        }),
      );
      if (typePrototype) break;
    }
    if (typePrototype) {
      // Get type from prototype and id
      let type = Synoptic.data?.site_description?.[typePrototype.field].find(
        (type) => type?.[typeIdField] == node.data(`entity.${typeIdField}`),
      );
      node.data("type", type);
      node.data("typePrototype", typePrototype);
      node.data("typeIDField", typeIdField);
    }
  }

  correct() {
    console.log("CORRECT EDGES");
    Synoptic.cy.elements().trigger("position correct");
  }

  static hops(element, { outgoing = false, filter = "*", stop = "", selector = "*" }) {
    let eles = element;
    let next = null;
    if (element.isEdge()) next = outgoing ? eles.targets(filter) : eles.sources(filter);

    let sEles = [];
    let sElesIds = {};

    for (;;) {
      next ??= outgoing ? eles.outgoers(filter) : eles.incomers(filter);

      if (next.length == 0) {
        break;
      } // done if none left

      let newNext = false;

      for (var i = 0; i < next.length; i++) {
        var n = next[i];
        var nid = n.id();

        if (!sElesIds[nid]) sElesIds[nid] = 1;

        if (sElesIds[nid] < 20) {
          sElesIds[nid] += 1;
          if (!sEles.includes(n)) sEles.push(n);
          newNext = true;
        }
      }

      if (!newNext) {
        break;
      } // done if touched all outgoers already

      // stop criteria is not reached
      if (next.filter(stop).length == 0) eles = next;
      else {
        break;
      }
      next = null;
    }

    return element.spawn(sEles, true).filter(selector);
  }

  filter(pattern) {
    if (pattern == "") {
      Synoptic.cy?.elements().removeClass("filtered").toggle("redraw");
      return;
    }

    let match = Synoptic.cy?.elements().filter((node) => {
      let matched = false;
      let type = node.data("type") ?? [];
      for (const [key, value] of Object.entries(type)) if (key?.includes(pattern) && value) matched = true;
      let entity = node.data("entity") ?? [];
      for (const [key, value] of Object.entries(entity)) if (key?.includes(pattern) && value) matched = true;
      let operations = node.data("entity.operation") ?? [];
      for (const [key, value] of Object.entries(operations)) if (key?.includes(pattern) && value) matched = true;

      for (const c of node.classes()) if (c?.includes(pattern)) matched = true;

      return matched;
    });

    Synoptic.cy?.elements().removeClass("filtered").not(match).addClass("filtered");
    Synoptic.cy?.elements(match).children(".io, .logo").removeClass("filtered");
    Synoptic.cy?.elements().trigger("redraw");
  }

  toggleUnitsDisplay() {
    Synoptic.cy
      .elements(".unit")
      .not('$node > node.logo, [entity.class="water_supply_systems"]')
      .toggleClass("hideUnit");
    $("#cy-nav-toggle").children(".far").toggleClass("fa-eye");
    $("#cy-nav-toggle").children(".far").toggleClass("fa-eye-slash");
  }

  getUser() {
    return $.ajax({
      url: `/isloggedin`,
    });
  }
  clearNodeImgs() {
    $(".nodeImgComponent").css("display", "none");
  }

  array_mode(a) {
    return Object.values(
      a.reduce((count, e) => {
        if (!(e in count)) {
          count[e] = [0, e];
        }

        count[e][0]++;
        return count;
      }, {}),
    ).reduce((a, v) => (v[0] < a[0] ? a : v), [0, null])[1];
  }

  toggleLock(elem) {
    if (Synoptic.locked) {
      $(elem).children(".fa-unlock").hide();
      $(elem).children(".fa-lock").show();
      Synoptic.cy.nodes(".he, .unit").unlock();
      Synoptic.locked = false;
    } else {
      $(elem).children(".fa-unlock").show();
      $(elem).children(".fa-lock").hide();
      Synoptic.cy.nodes(".he, .unit").lock();
      Synoptic.locked = true;
    }
  }

  static add(config) {
    let id = config.data.id;
    let angle = config.data.angle;
    let existing = Synoptic.cy.$id(id);

    let element = null;

    if (existing.length) {
      let wasHidden = existing.hasClass("userHidden");
      existing.classes(wasHidden ? "userHidden" : "").removeData();
      existing.data({ ...config.data, angle: angle });
      element = existing;
    } else {
      let pins = Synoptic.cy.elements(`[baseEdge = "${id}"]`);
      let newElement = Synoptic.cy.add(config);
      newElement.addClass("new");
      if (pins.length) {
        Synoptic.cy.remove(newElement);
        pins.scratch("baseEdge", newElement);
      }
      element = newElement;
    }
    element.removeClass("invalidate");
    return element;
  }

  static setRotation(io, angle, round = false) {
    let base = io.scratch("parent");
    if (base === undefined) {
      return;
    }
    if (base === null) {
      console.log(io);
    }

    let ios = Synoptic.cy.nodes(`.io[_parent = "${base.id()}"]`);

    let logo = base;

    angle = angle ?? base.data("angle");
    if (angle == undefined) {
      round = true;
      let pos = ios.filter(".io[index = 0]").position();
      if (!pos) return;
      angle = Math.atan2(pos.y - base.position().y, pos.x - base.position().x);
    }

    if (round) {
      let divider = Synoptic.mode == "w" ? Math.max(ios.length, 2) : Math.max(ios.length, 2);

      if (ios.length >= 3) divider = Math.ceil(ios.length / 2);
      angle = Math.round(angle / (Math.PI / divider)) * (Math.PI / divider);
    }
    angle = mod(angle, 2 * Math.PI);

    let x = logo.position("x");
    let y = logo.position("y");

    let rotationW = $(logo.data("dom")).width();
    let rotationH = $(logo.data("dom")).height();

    if (base.data("ports")?.length < 3 || base.data("ports") === undefined) {
      rotationH = rotationW; // make rotation round if the logo follows the rotation
      Synoptic.rotateDiv(logo, angle);
      logo.data("angle", angle);
    } else {
      Synoptic.rotateDiv(logo, 0);
      logo.data("angle", 0);
    }

    // PLACE NODES
    Synoptic.cy.startBatch();

    if (base.data("entity.class") == "sewages") {
      rotationW = 0;
      rotationH = 0;
    }
    base.data("angle", angle);

    ios.each((io, i) => {
      let divider = Synoptic.mode == "w" ? Math.max(ios.length, 2) : Math.max(ios.length, 2);
      if (ios.length >= 3) divider = 2 * Math.ceil(ios.length / 2); // even number
      let step = io.data("index") * ((2 * Math.PI) / divider);

      let pos = {
        x: x + 0.5 * rotationW * Math.cos(angle + step),
        y: y + 0.5 * rotationH * Math.sin(angle + step),
      };
      if (isNaN(pos.x)) {
        pos = base.position();
      }
      io.position(pos);
    });
    Synoptic.cy.endBatch();
    // if (round) ios.trigger("snap");
  }

  static rotateDiv(logo, angle) {
    let div = logo.data("dom");

    // force rotation earlier
    let transformStyle =
      $(div)
        ?.attr("style")
        ?.split(";")
        ?.find((s) => s.includes("transform"))
        ?.replace("transform:", "")
        ?.trim() ?? "";
    let regex = /rotate\((.*)\)/;

    let svg = $(logo.data("dom")).find("svg");
    if (svg.hasClass("limitAngle1")) angle = 0;
    else if (svg.hasClass("limitAngle2")) angle = mod(angle, Math.PI);

    if (regex.test(transformStyle)) {
      transformStyle = transformStyle.replace(regex, `rotate(${angle}rad)`);
    } else {
      transformStyle += ` rotate(${angle}rad)`;
    }
    $(div).css("transform", transformStyle);
    return transformStyle;
  }

  /**
   * Update Position of all ports based on the currentIO dragged IO and the mouse position
   * @param {*} pos
   * @returns
   */
  updateIOPos(pos) {
    if (!pos) return;
    let io = this.currentIO;
    if (!io) return;

    let base = io.scratch("parent");
    let ios = Synoptic.cy.nodes(`.io[_parent = "${base.id()}"]`);

    if (base.length == 0) return;
    if (io.hasClass("unbind")) return;
    else io.addClass("unbind");

    let angle = 0.0;
    if (base.hasClass("rlock")) {
      angle = base.data("angle") ?? io.parent().data("angle");
    } else {
      angle = Math.atan2(pos.y - base.renderedPosition("y"), pos.x - base.renderedPosition("x"));
    }

    let divider = Synoptic.mode == "w" ? Math.max(ios.length, 2) : Math.max(ios.length, 2);
    if (ios.length >= 3) divider = 2 * Math.ceil(ios.length / 2); // even number
    let step = io.data("index") * ((2 * Math.PI) / divider);
    angle -= step;

    io.trigger("setrotation", [angle]);
    io.removeClass("unbind");
  }

  setCtxClasses() {
    //Classes for context menu
    Synoptic.cy.elements().removeClass("ctrlValue ctrl opMode opModeValue wash ctrlMode manualValve");
    let elements = (_class) => Synoptic.cy.elements(`[entity.class='${_class}']`);

    elements("pumps").add(elements("water_heaters")).addClass("ctrlValue");
    elements("filters")
      .add(elements("dechloraminators"))
      .add(elements("generic_actuators"))
      .add(elements("chemical_feeders"))
      .addClass("ctrl");

    elements("pond_units")
      .add(elements("buffer_tank_units"))
      .add(elements("pediluviums"))
      .add(elements("dechloraminating_units"))
      .add(elements("water_heating_units"))
      .add(elements("intermediary_units"))
      .add(elements("auxiliary_units"))
      .add(elements("cooled_washing_units"))
      .addClass("opMode");

    elements("pumping_units").addClass("opModeValue");
    elements("filtering_units").addClass("wash");
    elements("energy_recovering_units").addClass("ctrlMode");
    elements("valves")
      .filter(
        "[?entity.position.dump],[?entity.position.fill],[?entity.position.filtration],[?entity.position.rinse],[?entity.position.stop],[?entity.position.wash]",
      )
      .addClass("manualValve");
  }
}

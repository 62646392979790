import { Controller } from "@hotwired/stimulus";
import { formatNumber } from "../../js/helpers/utils";
import $ from "jquery";

export default class extends Controller {
  static targets = ["water", "energy", "regulation"];

  static data = null;

  connect() {
    let report_id = $("#report-container").data("report-id");
    let isWaterDeltaPositive;

    // Get water data and trigger graphs
    if (this.hasWaterTarget) {
      $.ajax({
        url: `/admin/app/report/${report_id}/validate/data/water`,
        context: document.body,
      }).done((data) => {
        $("#report-container").data("water", data).trigger("waterReady");
        isWaterDeltaPositive = data["pool"]["water_apport"].isWaterDeltaPositive;

        this.updateFront(document.body, isWaterDeltaPositive);
        this.updateValues(data);
        window.dispatchEvent(new CustomEvent("refresh"));
      });
    }

    // Get energy data and trigger graphs
    if (this.hasEnergyTarget) {
      $.ajax({
        url: `/admin/app/report/${report_id}/validate/data/energy`,
        context: document.body,
      }).done((data) => {
        this.data = data;
        $("#report-container").data("energy", data).trigger("energyReady");
        this.updateValues(data);
        window.dispatchEvent(new CustomEvent("refresh-colors"));
      });
    }
  }

  updateValues(data) {
    // find elements that needs values
    let history = [];
    for (let target in data) {
      for (let key in data[target]) {
        $(`.${target}-${key}`).each((i, e) => {
          // General
          //$(e).find("#value").text((data[target][key]?.value).toLocaleString());
          history.push(
            $(e)
              .find("#value")
              .text(formatNumber(data[target][key]?.value, 0, 1000)),
          );
          history.push(
            $(e)
              .find("#average")
              .text(formatNumber(data[target][key]?.average, 0, 1000)),
          );
          history.push($(e).find("#ndays").text(formatNumber(data[target]?.n_days, 0)));
          history.push($(e).find("#mwh_gain").text(formatNumber(data[target][key]?.mwh_gain, 0)));
          history.push($(e).find("#kwh_gain").text(formatNumber(data[target][key]?.kwh_gain, 0)));
          history.push($(e).find("#total_gain").text(formatNumber(data[target][key]?.total_gain, 0)));

          history.push($(e).find("#evolution").text(formatNumber(data[target][key]?.evolution, 0)));
          history.push(
            $(e)
              .find("#delta")
              .text(formatNumber(data[target][key]?.delta, "", ""))
              .trigger("color"),
          );
          history.push($(e).find("#previous").text(formatNumber(data[target][key]?.previousValue, 0)));
          // Situationnal
          history.push($(e).find("#per_swimmer-value").text(formatNumber(data[target][key]?.per_swimmer?.value, 0)));

          history.push(
            $(e)
              .find("#per_swimmer-delta")
              .text(formatNumber(data[target][key]?.per_swimmer?.delta, 0))
              .trigger("color"),
          );
          history.push(
            $(e).find("#per_swimmer-evolution").text(formatNumber(data[target][key]?.per_swimmer?.evolution, 2)),
          );

          let value;
          let previousValue;
          if (key == "dbec_pond_gain_mwatt") {
            value = data[target][key]?.value;
            previousValue = data[target][key]?.previousValue;
          } else if (key == "active_energy") {
            value = data[target][key]?.value;
            previousValue = data[target][key]?.previousValue;
          } else {
            value = data[target][key]?.value;
            previousValue = data[target][key]?.previousValue;
          }
          let evolution =
            previousValue && previousValue != 0 && value != 0
              ? Math.round(((value - previousValue) / value) * 100.0)
              : null;
          history.push($(e).find("#energy-special-evolution").text(formatNumber(evolution, 2)));
          history.push($(e).find("#per_surface-value").text(formatNumber(data[target][key]?.per_surface?.value, 0)));
          history.push(
            $(e)
              .find("#per_day-value")
              .text(formatNumber(data[target][key]?.value / data[target].n_days, 0)),
          );
          history.push($(e).find("#unit").text(data[target]?.attendance_unit));
          history.push($(e).find("#sunny-days").text(data.pool?.weather?.sunny_days));

          let te =
            data?.pool?.water_processing_consumption?.value > 0
              ? data.pool?.water_processing_consumption?.value
              : data.pool?.water_apport?.value;
          if (te > 0) {
            history.push(
              $(e)
                .find("#value-over-te")
                .text(formatNumber((100 * data[target][key]?.value) / te, true)),
            );
          }

          let wa = data[target]?.water_apport?.value; // or data.pool, Global apport != circuit apport
          if (wa > 0) {
            history.push(
              $(e)
                .find("#value-over-water_apport")
                .each((i, e2) => {
                  history.push($(e2).text(formatNumber((100 * data[target][key]?.value) / wa, true)));
                }),
            );
          }

          history.push(
            $(e)
              .find("#value-over-french")
              .text(formatNumber(data[target][key]?.value / (55 / 12), 2)),
          );
        });
      }
    }
    history.forEach((query) => {
      query.each((i, element) => {
        this.updateColor(element);
      });
    });
  }

  /*  Les données de bilans ont des comportement très différent selon son resultat negative ou positive,
    nous avons besoin de faire des cas spécifique pour les couleur et symbol + / - afin que le bilan soit cohérent et lisible aux yeux
    du client
    les cas sont:
    - avoir du rouge sur resultat negatif avec le symbole - ( comportement par défaut )
    - avoir du vert su positif avec le symbole + ( comportement par défaut )
    - avoir du rouge sur resultat positif avec + (exemple: trop de conso...)
    - avoir du vert sur rsultat negatif avec - (exemple: economie lié au résultat)*/
  updateColor(element) {
    let $val = $(element).text();
    let $invert = $(element).hasClass("invert");
    let $greenPlus = $(element).hasClass("greenPlus");
    let $deltaBehavior = $(element).hasClass("deltaBehavior");

    if ($val < 0 && !$invert) {
      $(element).removeClass("text-success");
      $(element).addClass("text-danger");
    }
    if ($val > 0 && $greenPlus) {
      $(element).addClass("addPlus");
    }

    if ($val > 0 && $deltaBehavior) {
      $(element).addClass("addPlus");
      $(element).removeClass("text-success");
      $(element).addClass("text-danger");
    }
  }

  //fonction qui récupère le boolean si le bool est à true
  // ajout de classe sur le front pour cacher des elements

  updateFront(elem, bool) {
    if (bool) {
      $(elem).find(".toHide").addClass("hidden");
    }
  }
}

//DJU
/**
 *         {% set value = 0 %}
 {% if reference == 'dbec_pond_gain_mwatt' %}
 {% if data.pool.dbec_warmed_water.value * data.pool.dbec_warmed_water.previousValue != 0 %}
 {% set value = (100*((data[reference].value * 1000 / (data.pool.dbec_warmed_water.value)) - (data[reference].previousValue * 1000 / (data.pool.dbec_warmed_water.previousValue)))
                      / (data[reference].value * 1000 / (data.pool.dbec_warmed_water.value))) %}
 {% endif %}

 {% elseif reference == 'active_energy' %}
 {% if pool.surface != 0 %}
 {% set value = (100*((data[reference].value * 1000 / (pool.surface)) - (data[reference].previousValue * 1000 / (pool.surface)))
                      / (data[reference].value * 1000 / (pool.surface))) %}
 {% endif %}
 {% else %}
 {% if pool.surface * data.totalDJU.value * data.totalDJU.previousValue != 0 %}
 {% set value = (100*((data[reference].value * 1000 / (pool.surface * data.totalDJU.value)) - (data[reference].previousValue * 1000 / (pool.surface * data.totalDJU.previousValue)))
                    / (data[reference].value * 1000 / (pool.surface * data.totalDJU.value))) %}
 {% endif %}
 {% endif %}
 **/

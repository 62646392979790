import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import $ from "jquery";
import DataTable from "datatables.net-dt";

let table = new DataTable("#myTable");

export default class extends Controller {
  static targets = ["formCard", "nav", "tab"];

  connect() {
    let domains = [];
    let pools = [];

    document.querySelectorAll("#domains-filter .form-check.active").forEach((domain) => {
      domains.push(domain.querySelector("input").value);
    });

    document.querySelectorAll("#pools-filter .form-check.active").forEach((pool) => {
      pools.push(pool.querySelector("input").value);
    });

    const table = new DataTable($("#form_archives_table"), {
      ajax: {
        url: `account/account_get_archives?isDeletedArchivesVisible=false&domains=${domains}&pools=${pools}`,
        dataSrc: "",
      },
      dom: "<f>rtip",
      language: {
        processing: "Traitement en cours...",
        search: "Rechercher&nbsp;:",
        lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
        info: "",
        infoEmpty: "",
        infoFiltered: "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        zeroRecords: "Aucune archive de formulaire à afficher",
        emptyTable: "Aucune archive de formulaire n'est disponible",
        searchPlaceholder: 'Ex: "fréquentation", "compteur", "2024-10-31", ...',
        paginate: {
          first: "<<",
          previous: "<",
          next: ">",
          last: ">>",
        },
        aria: {
          sortAscending: ": activer pour trier la colonne par ordre croissant",
          sortDescending: ": activer pour trier la colonne par ordre décroissant",
        },
      },
      order: [[4, "desc"]],
      columns: [
        {
          title: "Id",
          data: "id",
        },
        {
          title: "fakeId",
          data: "fakeId",
        },
        {
          title: "",
          data: "isDeleted",
          sortable: false,
          render: function (data, type) {
            if (data == false) {
              return `<div class="form-status"><i class="fa fa-check status-icon"></i></div>`;
            } else {
              return `<div class="form-status"><i class="fa fa-ban status-icon is-deleted"></i></div>`;
            }
          },
        },
        {
          title:
            "Date/Auteur de saisie <i title='La date indiquée ici correspond à la date de saisie des données' class='far fa-question-circle date-info-helper'></i>",
          data: null,
          render: function (data, type) {
            return `<div class="form-date-author"><div class="form-date"><i class="fa fa-clock icon"></i><span class="date">${data.date}</span></div><div class="form-user"><i class="fa fa-user icon"></i><span class="user">${data.user}</span></div></div>`;
          },
        },
        {
          title:
            "Date des données <i title='La date indiquée ici correspond à la date indiquée dans le formulaire' class='far fa-question-circle date-info-helper'></i>",
          data: null,
          render: function (data, type) {
            return `<div class="form-date"><span class="date">${data.date_data}</span></div>`;
          },
        },
        {
          title: "Piscine",
          data: "pool",
          render: function (data, type) {
            return `<div class="form-pool"><span class="pool-name">${data.name}</span><span class="city-name">${data.city}</span></div>`;
          },
        },
        {
          title: "Formulaire",
          data: null,
          render: function (data, type) {
            let title = [];
            let targetsCount = [];
            data.targets.forEach((target) => {
              if (target.class != "Pool") {
                title.push(target.name);
                if (!targetsCount[target.class]) {
                  targetsCount[target.class] = 0;
                }
                targetsCount[target.class]++;
              }
            });

            let targetsCountText = "";
            for (const [key, value] of Object.entries(targetsCount)) {
              let tempTargetName = key;
              if (key == "Pond") {
                tempTargetName = "bassin(s)";
              } else if (key == "Watermeter") {
                tempTargetName = "compteur(s) d'eau";
              } else if (key == "Energymeter") {
                tempTargetName = "compteur(s) d'électricité";
              } else {
                tempTargetName = "composant(s)";
              }
              targetsCountText += `${value} ${tempTargetName}, `;
            }
            targetsCountText = targetsCountText.slice(0, -2);

            if (targetsCountText) {
              return `
              <div class="form-form">
                <i class="fa fa-${data.form.domain.icon} domain-icon"></i>
                <div>
                <span class="form-form-name">${data.form.name}</span>
                <span class="form-form-targets" title="${title}">${targetsCountText} saisis</span>
                </div>
              </div>
              `;
            } else {
              return `
              <div class="form-form">
                <i class="fa fa-${data.form.domain.icon} domain-icon"></i>
                <div>
                <span class="form-form-name">${data.form.name}</span>
                </div>
              </div>
              `;
            }
          },
        },

        {
          title: "Commentaire",
          data: "meta",
          sortable: false,
          width: "30%",
          render: function (data, type) {
            if (!data.comment) {
              return "";
            } else {
              const modifiedDate = data.modified.date.split(".")[0]; // Remove the milliseconds part
              return `<div class="form-comment"><span data-modified="${modifiedDate}" data-submitter="${data.submitter.replace(
                /"/g,
                "&quot;",
              )}" data-action="click->account--archives-form#showComment" title="${data.comment.replace(
                /"/g,
                "&quot;",
              )}">${data.comment.replace(/"/g, "&quot;")}</span></div>`;
            }
          },
        },
      ],
      paging: true,
      createdRow: function (row, data, dataIndex) {
        $(row).addClass("archive-row");
        if (data.isDeleted == true) {
          $(row).addClass("is-deleted");
        }
      },
      columnDefs: [
        {
          target: 0,
          visible: false,
          searchable: false,
        },
        {
          target: 1,
          visible: false,
          searchable: false,
        },
        {
          target: 2,
          visible: false,
          searchable: false,
        },
      ],
    });

    $("#form_archives_table").on("click", ".archive-row", function (e) {
      if (
        //We don't want to show the form details if the user clicks on the comment icon
        e.target.parentElement.classList.contains("form-comment") ||
        e.target.classList.contains("form-comment")
      ) {
        return;
      }

      var tr = $(this);
      var row = table.row(tr);
      const wasShown = row.child.isShown();

      //hideDetails();

      if (!wasShown) {
        row.child(format(row.data())).show();
        row.child().addClass("form-details-container");
        if (row.data().targets.length == 1) {
          row.child().addClass("one-target");
        }
        tr.addClass("shown");
      } else {
        tr.removeClass("shown");
        row.child.hide();
      }
    });

    function hideDetails() {
      $("#form_archives_table tbody .archive-row").each(function () {
        if (table.row(this).child.isShown()) {
          $(this).removeClass("shown");
          const that = this;
          setTimeout(() => {
            table.row(that).child.hide();
          }, 300);
        }
      });
    }

    function format(rowData) {
      var div = $("<div/>").addClass("loading").addClass("form-details").text("chargement...");

      fetch(`/account/account_get_one_form?id=${encodeURIComponent(rowData.id)}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.text())
        .then((html) => {
          div.html(html).removeClass("loading");
          $(".history-container").width($("#content_account").width() - 30);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Erreur",
            text: "Une erreur est survenue lors de la récupération de ce formulaire:" + error.message,
          });
        });
      return div;
    }
  }

  applyFilters() {
    const table = $("#form_archives_table").DataTable();

    let domains = [];
    let pools = [];
    const dateMin = document.querySelector("#min-date").value;
    const dateMax = document.querySelector("#max-date").value;

    document.querySelectorAll("#domains-filter .form-check.active").forEach((domain) => {
      domains.push(domain.querySelector("input").value);
    });

    document.querySelectorAll("#pools-filter .form-check.active").forEach((pool) => {
      pools.push(pool.querySelector("input").value);
    });

    table.ajax.url(
      `account/account_get_archives?domains=${domains}&pools=${pools}&dateMin=${dateMin}&dateMax=${dateMax}`,
    );

    table.ajax.reload();
  }

  filterToggleDomain(event) {
    const domainLabel = event.currentTarget;

    const domains = document.querySelectorAll("#domains-filter .form-check");
    const activeDomains = document.querySelectorAll("#domains-filter .form-check.active");

    if (domainLabel.parentElement.classList.contains("active")) {
      if (domains.length == activeDomains.length) {
        activeDomains.forEach((domain) => domain.classList.remove("active"));
        domainLabel.parentElement.classList.add("active");
      } else {
        domainLabel.parentElement.classList.remove("active");
      }
    } else {
      domainLabel.parentElement.classList.add("active");
    }

    this.applyFilters();
  }

  filterTogglePool(event) {
    const poolLabel = event.currentTarget;

    const pools = document.querySelectorAll("#pools-filter .form-check");
    const activePools = document.querySelectorAll("#pools-filter .form-check.active");

    if (poolLabel.parentElement.classList.contains("active")) {
      if (pools.length == activePools.length) {
        activePools.forEach((pool) => pool.classList.remove("active"));
        poolLabel.parentElement.classList.add("active");
      } else {
        poolLabel.parentElement.classList.remove("active");
      }
    } else {
      poolLabel.parentElement.classList.add("active");
    }

    this.applyFilters();
  }

  showComment(event) {
    const button = event.target;

    let comment = button.getAttribute("title");
    const modified = button.dataset.modified;
    const submitter = button.dataset.submitter;

    const SwalCustom = Swal.mixin({
      customClass: {
        container: "form-comment-popup-container",
      },
    });
    SwalCustom.fire({
      width: "45rem",
      html:
        '<div class="form-comment-content"><div class="popup-row"><label>Créé/Modifié le :</label><span>' +
        modified +
        "</span></div><div class='popup-row'><label>Par :</label><span>" +
        submitter +
        "</span></div><div class='popup-row'><label>Commentaire :</label><span>" +
        comment +
        "</span></div></div>",
      showConfirmButton: false,
    });
  }

  switchDeletedArchives(event) {
    this.applyFilters();

    // const table = $("#form_archives_table").DataTable();

    // table.ajax.url(
    //   `account/account_get_archives?isDeletedArchivesVisible=` +
    //     event.target.checked
    // );

    // table.ajax.reload();

    // if (event.target.checked) {
    //   table.column(2).visible(true);
    // } else {
    //   table.column(2).visible(false);
    // }
  }
}

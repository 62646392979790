import { SESSION_STORAGE_DATE_START_KEY, SESSION_STORAGE_DATE_END_KEY } from "../helpers/utils";
import { DateTime } from "luxon";

import $ from "jquery";
import "select2";

import { easepick } from "@easepick/core";
import { RangePlugin } from "@easepick/range-plugin";
import { AmpPlugin } from "@easepick/amp-plugin";

import coreCssUrl from "@scss/components/easepick/core.css?url";
import rangeCssUrl from "@scss/components/easepick/range.css?url";
import ampCssUrl from "@scss/components/easepick/amp.css?url";

const picker_opts = (from_field, to_field) => {
  return {
    element: from_field,
    zIndex: 1000,
    lang: navigator.language,
    css: [coreCssUrl, rangeCssUrl, ampCssUrl],
    plugins: [LuxonRangePlugin, AmpPlugin],
    RangePlugin: {
      tooltip: true,
      elementEnd: to_field,
      locale: { one: "jour", other: "jours" },
      repick: false,
    },
    AmpPlugin: {
      dropdown: {
        months: true,
        years: true,
      },
      darkMode: false,
    },
  };
};

export const DP_FORMAT = "ccc dd/LL/yyyy";

class LuxonRangePlugin extends RangePlugin {
  updateValues() {
    const start = this.picker.getStartDate();
    const end = this.picker.getEndDate();

    let startDateTime = DateTime.fromJSDate(start).setLocale(navigator.language);
    let endDateTime = DateTime.fromJSDate(end).setLocale(navigator.language);
    let startElem = this.picker.options.element;
    let endElem = this.options.elementEnd;

    startElem.value = startDateTime.toFormat(DP_FORMAT);
    endElem.value = endDateTime.toFormat(DP_FORMAT);
    var event = new Event("change");
    startElem.dispatchEvent(event);
    endElem.dispatchEvent(event);
  }
}

export function datePickers(new_value_default_date = DateTime.local().minus({ days: 1 }), filter = ["period"]) {
  let defaultDateStart = DateTime.local().minus({ days: 7 });
  let defaultDateEnd = DateTime.local();

  // SessionStorage
  let sessionDateStart = sessionStorage.getItem(SESSION_STORAGE_DATE_START_KEY);
  let sessionDateEnd = sessionStorage.getItem(SESSION_STORAGE_DATE_END_KEY);

  let dateEnd = defaultDateEnd;
  if (sessionDateEnd) {
    try {
      dateEnd = DateTime.fromISO(sessionDateEnd);
      if (!dateEnd.isValid) dateEnd = defaultDateEnd;
    } catch (error) {
      dateEnd = defaultDateEnd;
    }
  }

  let dateStart = defaultDateStart;
  if (sessionDateStart) {
    try {
      dateStart = DateTime.fromISO(sessionDateStart);
      if (!dateStart.isValid) dateStart = defaultDateStart;
    } catch (error) {
      dateStart = defaultDateStart;
    }
  }

  if (dateStart.diff(dateEnd).as("days") > 0) {
    dateStart = dateEnd.subtract(7, "days");
  }

  if (filter.includes("period")) {
    // Init main datepickers, which are for the data range
    const picker = new easepick.create({
      ...picker_opts(document.getElementById("pack-datepicker-from"), document.getElementById("pack-datepicker-to")),
    });
    picker.setDateRange(dateStart.toMillis(), dateEnd.toMillis());
  }

  if (filter.includes("issue")) {
    // ISSUE
    const issue_picker = new easepick.create(
      picker_opts(
        document.getElementById("pack-datepicker-issue-start"),
        document.getElementById("pack-datepicker-issue-end"),
      ),
    );
    issue_picker.setDateRange(dateStart.toMillis(), dateEnd.toMillis());
  }

  return {};
}

$.fn.textWidth = function (text, font) {
  if (!$.fn.textWidth.fakeEl) $.fn.textWidth.fakeEl = $("<span>").hide().appendTo(document.body);

  $.fn.textWidth.fakeEl
    .text(text || this.val() || this.text() || this.attr("placeholder"))
    .css("font", font || this.css("font"));

  return $.fn.textWidth.fakeEl.width();
};

$(".width-dynamic").change(function () {
  $(this).attr("size", $(this).val().length + 1);
});

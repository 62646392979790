import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {}

  attendance(event) {
    this.toggle(event.target);
  }

  water(event) {
    this.toggle(event.target);
  }

  air(event) {}

  regulation(event) {}

  energy(event) {
    this.toggle(event.target);
  }

  chemical(event) {}

  toggle(target) {
    // $(target).parents(".consumptionsummary-element").toggleClass("active");
  }
}

import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    let siteSelector = $(".js-site-selector");
    let siteSelectorToggle = $(".js-site-selector-toggle");
    let siteSelectorNav = $(".js-site-selector-nav");

    siteSelectorToggle.on("click", function () {
      siteSelectorNav.parent().toggleClass("col-3 col-12");
      siteSelector.toggleClass("siteselector--opened");
      $(".siteselector-search-input").focus();
    });
    $(".js-pool-nav-item").on("click", () => siteSelector.toggleClass("siteselector--opened"));

    $(".siteselector-search-input").on("input", function () {
      $(".siteselector-element")
        .not(`:icontains('${$(this).val()}')`)
        .hide();
      $(".siteselector-element")
        .filter(`:icontains('${$(this).val()}')`)
        .show();
    });
  }
}

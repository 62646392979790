import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import $ from "jquery";

export default class extends Controller {
  connect() {
    // console.log('DASH CONTROLLER');
    $(document).on("click", ".modal-trigger", this.openModal.bind(this));

    $(document).ready(function () {
      const menuToggleButton = $("#menu-toggle-button");
      const menuContainer = $(".dashboard-list-container");

      // Gestionnaire d'événement pour le clic sur le bouton ou le lien
      menuToggleButton.on("click", function (event) {
        event.preventDefault();
        // Inversez la visibilité du menu en utilisant fadeIn() et fadeOut()
        if (menuContainer.is(":hidden")) {
          menuContainer.fadeIn(100);
        } else {
          menuContainer.fadeOut(100);
        }
      });
    });
    this.switchElement();
  }

  switchElement(event = null) {
    let element = 0;
    if (event) {
      element = $(event.target).data("elementid");
    }
    let elements = $(".dashboard-element");

    // HIDE ELEMENTS IF ELEMENT ID IS WRONG
    elements.each((i, e) => {
      if ($(e).hasClass(`element-${element}`)) {
        $(e).removeClass("d-none");
      } else {
        $(e).addClass("d-none");
      }
    });

    // HIDE COLUMN IS ELEMENTS ARE EMPTY
    elements = elements.filter((i, e) => $(e).hasClass(`element-${element}`));
    if (elements.length == 0) {
      $("#dashboard-column").addClass("d-none").removeClass("d-flex");
    } else {
      $("#dashboard-column").removeClass("d-none").addClass("d-flex");
    }
  }

  openModal(event) {
    const url = $(event.currentTarget).data("url");
    console.log(url);
    $("#dashboard-popup").addClass("show");
    $("#dashboard-popup-content").html(
      `<iframe src="${url}" width="100%" height="100%" referrerpolicy="origin"></iframe>`,
    );
    $("#dashboard-popup-close").on(
      "click",
      (event) => ($("#dashboard-popup").removeClass("show"), $("#dashboard-popup-content").html("")),
    );
  }
}

import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  colorChange(event) {
    // Two generic classes in report_block_general.scss "addPlus" and "addMinus"

    let $val = $(this.element).text();
    let $elem = $(this.element);
    console.log($val);

    if ($val < 0) {
      $elem.addClass("text-danger");
    }
    if ($elem.hasClass("invert")) {
      console.log($elem);
      $elem.removeClass("text-danger");
      $elem.addClass("text-success");
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import { datePickers } from "@js/helpers/datepickers";
import AttendancePackMeasuresRenderer from "@js/renderers/Measures/AttendancePackMeasuresRenderer";
import AttendancePackPeriodRenderer from "@js/renderers/Periods/AttendancePackPeriodRenderer";
import AttendancePackNewMeasureForm from "@js/pages/attendancepack/AttendancePackNewMeasureForm";
import IssueNewMeasureForm from "@js/helpers/IssueNewMeasureForm";
import $ from "jquery";

export default class extends Controller {
  connect() {
    const pikadayElements = datePickers();
    let measuresRenderer = new AttendancePackMeasuresRenderer();
    measuresRenderer.init();
    let periodRenderer = new AttendancePackPeriodRenderer();
    periodRenderer.init();
    let newMeasureForm = new AttendancePackNewMeasureForm(pikadayElements.new, $(".attendance-side"));
    newMeasureForm.init();
    newMeasureForm.addDataSentCallback(measuresRenderer.render);
    newMeasureForm.addDataSentCallback(periodRenderer.render);
  }
}

import $ from "jquery";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    setInterval(this.update.bind(this), 1000);
  }

  async update() {
    let request = await fetch("datenow");
    const { date: date } = await request.json();
    $(this.element).val(date);
  }
}

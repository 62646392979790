import AbstractPackNewMeasureForm from "../../helpers/AbstractPackNewMeasureForm";
import DateManager, { BASE_DATETIME_FORMAT } from "../../helpers/DateManager";
import Swal from "sweetalert2";
import tippy, { createSingleton } from "tippy.js";
import "tippy.js/themes/material.css";
import "tippy.js/dist/tippy.css"; // optional for styling
import $ from "jquery";
import { DateTime } from "luxon";
import active_chlore_27 from "../../../csv/active_chlore_27.csv?raw";
import active_chlore_30 from "../../../csv/active_chlore_30.csv?raw";
import active_chlore_35 from "../../../csv/active_chlore_35.csv?raw";

const REGULATION_FORM_MODAL_MESSAGE_SUCCESS = "form-modal-message-success";
const REGULATION_FORM_MODAL_MESSAGE_ERROR = "form-modal-message-error";

const REGULATION_CHLORAMINE_FIELD_SUFFIX_ID = "chloramine";
const REGULATION_FREE_CHLORE_FIELD_SUFFIX_ID = "free-chlore";
const REGULATION_TOTAL_CHLORE_FIELD_SUFFIX_ID = "total-chlore";
const REGULATION_ACTIVE_CHLORE_FIELD_SUFFIX_ID = "active-chlore";
const REGULATION_PH_FIELD_SUFFIX_ID = "ph";
const REGULATION_TEMPERATURE_FIELD_SUFFIX_ID = "temperature-pond";
const REGULATION_STABILIZER_FIELD_SUFFIX_ID = "stabilisant";

const REGULATION_NEW_VALUE_FORM_FIELDS = [
  "ph",
  "active-chlore",
  "stabilisant",
  "temperature-pond",
  "temp-air-pond",
  "water-index",
  "filter-flow",
  "datetime",
  REGULATION_TOTAL_CHLORE_FIELD_SUFFIX_ID,
  REGULATION_FREE_CHLORE_FIELD_SUFFIX_ID,
];

const REGULATION_INPUT_ID_PREFIX = "#regulation-new-value-form";

const POND_FIELD_ID = REGULATION_INPUT_ID_PREFIX + "-element";

const REGULATION_REFERENCE_PREFIXES = "regulation_";

class RegulationPackNewMeasureForm extends AbstractPackNewMeasureForm {
  constructor(datePicker, container = $("body")) {
    super("regulation", REGULATION_NEW_VALUE_FORM_FIELDS, datePicker, container);

    this.init = this.init.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.readFormData = this.readFormData.bind(this);
    this.setPond = this.setPond.bind(this);
    this.addDataSentCallback = this.addDataSentCallback.bind(this);
    this.updateChloramine = this.updateChloramine.bind(this);
    this.updateActiveChlore = this.updateActiveChlore.bind(this);
    this.updateForm = this.updateForm.bind(this);

    this.reset = this.reset.bind(this);

    // Fields
    this._chloramine_field = $(`${REGULATION_INPUT_ID_PREFIX}-${REGULATION_CHLORAMINE_FIELD_SUFFIX_ID}`);
    this._free_chlore = $(`${REGULATION_INPUT_ID_PREFIX}-${REGULATION_FREE_CHLORE_FIELD_SUFFIX_ID}`);
    this._total_chlore = $(`${REGULATION_INPUT_ID_PREFIX}-${REGULATION_TOTAL_CHLORE_FIELD_SUFFIX_ID}`);
    this._ph = $(`${REGULATION_INPUT_ID_PREFIX}-${REGULATION_PH_FIELD_SUFFIX_ID}`);
    this._temperature = $(`${REGULATION_INPUT_ID_PREFIX}-${REGULATION_TEMPERATURE_FIELD_SUFFIX_ID}`);
    this._stabilizer = $(`${REGULATION_INPUT_ID_PREFIX}-${REGULATION_STABILIZER_FIELD_SUFFIX_ID}`);

    this._active_chlore_field = $(`${REGULATION_INPUT_ID_PREFIX}-${REGULATION_ACTIVE_CHLORE_FIELD_SUFFIX_ID}`);

    this.hasStabilizer = false;
    this.isOpenAir = false;

    // abacus
    this.abacus = {};
    this.abacus[27] = active_chlore_27.split(/\r?\n|\r/);
    this.abacus[30] = active_chlore_30.split(/\r?\n|\r/);
    this.abacus[35] = active_chlore_35.split(/\r?\n|\r/);
  }

  init() {
    this._pond_id = parseInt($(".js-pond-nav-item.activeElement").data("elementid"));
    this._pond_name = $(".js-pond-nav-item.activeElement").text();
    $("#regulation-new-value-form-pond").text(this._pond_name);
    this._data_store.pond = this._pond_id;

    super.init();
    this.addDataSentCallback(() => {
      let raw_date = DateManager.parseDateFromInput(this._date_field);
      if (!raw_date.hasSame(DateTime.local(), "day")) {
        this.container.find(".js-hide-on-disable").hide();
        for (let field of REGULATION_NEW_VALUE_FORM_FIELDS) {
          // this._fields[field].prop("disabled", true);
        }
      } else {
        // this.resetMessage();
        this._submit.reset();
      }
    });
  }

  fieldChangeCallback(field) {
    super.fieldChangeCallback(field);
    this.updateChloramine();
    if (!this.hasStabilizer && !this.isOpenAir) this.updateActiveChlore();
  }

  dataAlreadySentCallback(data) {
    let raw_date = DateManager.parseDateFromInput(this._date_field);
    let now = DateTime.local();
    let today = DateTime.local().startOf("day");
    let is_today = raw_date.hasSame(today, "day");

    let forbid = false;

    // ? FUTURE
    if (raw_date > now && !this._onsen_user) {
      this.errorMessage("Saisie impossible pour des dates futures");
      this.container.find(".js-hide-on-disable").hide();
      forbid = true;
    }
    // ? HAS DATA
    else if (Object.keys(data).filter((k) => k.includes("regulation")).length > 0) {
      // ? IS TODAY
      if (!is_today && !this._onsen_user) {
        this.errorMessage("Carnet sanitaire déjà saisi pour cette date");
        this.container.find(".js-hide-on-disable").hide();
        forbid = true;
      }
      // ? IS TODAY BUT ADMIN
      else if (this._onsen_user) {
        this.successMessage("Carnet sanitaire déjà saisi pour cette date, accés admin");
      } else {
        this.successMessage("Carnet sanitaire déjà saisi aujourd'hui mais un ajout reste possible");
      }
    }

    for (let field of REGULATION_NEW_VALUE_FORM_FIELDS) {
      if (field === "datetime") {
        // this._fields[field].val((raw_date.format("HH:mm")));
      } else {
        if (data[this.getReferenceFromFieldIdentifier(field)]) {
          this._fields[field].val((data[this.getReferenceFromFieldIdentifier(field)].value || 0.0).toFixed(2));
        }
      }
      this._fields[field].prop("disabled", forbid);
    }
  }

  readFormData() {
    let ret = { date: null, data: {} };

    for (let field of REGULATION_NEW_VALUE_FORM_FIELDS) {
      const elt = $(`${REGULATION_INPUT_ID_PREFIX}-${field}`);
      if (elt.length === 0) continue;
      if (elt.val() != "") {
        ret.data[this.getReferenceFromFieldIdentifier(field)] = parseFloat(elt.val());
      }
    }

    let raw_date = DateManager.parseDateFromInput(this._date_field);
    ret.date = raw_date.toFormat(BASE_DATETIME_FORMAT);
    this._date = ret.date;
    return ret;
  }

  addDataSentCallback(callback) {
    this._data_sent_callbacks.push(callback);
  }

  setPond(id, name) {
    this._pond_id = parseInt(id);
    this._pond_name = name;
    $("#regulation-new-value-form-pond").text(this._pond_name);
    this._data_store.pond = this._pond_id;
    this.checkExistingData();
  }

  updateForm(data) {
    this.hasStabilizer = data.hasStabilizer;
    this.hasStabilizer = data.isOpenAir;

    if (data.hasStabilizer) {
      this._stabilizer.closest(".row").show();
      this._stabilizer.prop("required", true);
    } else {
      this._stabilizer.closest(".row").hide();
      this._stabilizer.prop("required", false);
    }

    if (!data.isOpenAir && !data.hasStabilizer) {
      this._active_chlore_field.closest(".row").show();
      this._active_chlore_field.prop("required", true);
    } else {
      this._active_chlore_field.closest(".row").hide();
      this._active_chlore_field.prop("required", false);
    }

    this.tooltips.forEach((e) => e.destroy());
    this.tooltips = [];
    for (let field of REGULATION_NEW_VALUE_FORM_FIELDS) {
      let estimatedIndex = null;
      let datum = data[this.getReferenceFromFieldIdentifier(field)];
      if (datum?.remote) {
        estimatedIndex = datum?.remote;
      }
      if (estimatedIndex) {
        this.tooltips.push(
          ...tippy(`${REGULATION_INPUT_ID_PREFIX}-${field}`, {
            content: estimatedIndex ? `mesuré: ${estimatedIndex.toFixed(2)}` : "Pas de mesure disponible",
            trigger: "mouseenter focus focusin click",
            animation: "fade",
            placement: "right",
            theme: "material",
            arrow: true,
          }),
        );
      }
    }
  }

  updateChloramine() {
    let val =
      Math.round(100 * Math.max(0, parseFloat(this._total_chlore.val()) - parseFloat(this._free_chlore.val()))) / 100;
    this._chloramine_field.val(val.toFixed(2));
  }

  updateActiveChlore() {
    if (!this._active_chlore_field.is(":focus") && this.abacus && Object.keys(this.abacus).length > 0) {
      if (this._ph.val() == "" || this._free_chlore.val() == "" || this._temperature.val() == "") return;

      let temp = parseFloat(this._temperature.val());

      // GET CLOSEST TEMP FOR ABACUS
      let closest = Object.keys(this.abacus).reduce((prev, curr) =>
        Math.abs(curr - temp) < Math.abs(prev - temp) ? curr : prev,
      );

      let abacus = this.abacus[closest];

      let ph = Math.max(6.5, Math.min(8, parseFloat(this._ph.val())));
      let free_chlore = parseFloat(this._free_chlore.val());

      // GET CLOSEST ROW
      closest = abacus.slice(2).reduce((prev, curr) => {
        if (prev == undefined) prev = 0;
        else if (typeof prev === "string") prev = parseFloat(prev.split(",")[0]);
        curr = parseFloat(curr.split(",")[0]);
        return Math.abs(curr - ph) < Math.abs(prev - ph) ? curr : prev;
      });
      let row = abacus.slice(2).findIndex((row) => parseFloat(row.split(",")[0]) == closest) + 2;

      // GET CLOSEST COLUMN
      closest = abacus[1]
        .split(",")
        .slice(2)
        .reduce((prev, curr) => {
          if (prev == undefined) prev = 0;
          else if (typeof prev === "string") prev = parseFloat(prev);
          curr = parseFloat(curr);
          return Math.abs(curr - free_chlore) < Math.abs(prev - free_chlore) ? curr : prev;
        });
      let col =
        abacus[1]
          .split(",")
          .slice(2)
          .findIndex((col) => parseFloat(col) == closest) + 2;

      // UPDATE FIELD
      this._active_chlore_field.val(parseFloat(abacus[row].split(",")[col]).toFixed(2));
    }
  }

  postSendPopup() {
    this.successMessage("Saisie enregistrée.");

    let tolReached = false;

    let formData = this.readFormData();
    let content = `
        <table class="table popupTable">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Donnée saisie</th>
            <th scope="col">Mesure télérelevée</th>
            <th scope="col">Erreur calculée</th>
            <th scope="col">Pourcentage d'erreur</th>
          </tr>
        </thead>
        <tbody>
        `;
    for (const key in formData.data) {
      if (Object.hasOwnProperty.call(formData.data, key) && !isNaN(formData.data[key])) {
        const element = formData.data[key];
        let refval = null;
        let tol = null;

        if (this.ref_data[key] && this.ref_data[key].reference && this.ref_data[key].reference.linked_reference) {
          const refKey = this.ref_data[key].reference.linked_reference.label;

          if (Object.hasOwnProperty.call(this.ref_data, refKey)) {
            refval = this.ref_data[refKey].value;
            tol = this.ref_data[key].reference.tol;
          }
        }

        let error_percent = refval ? ((refval - element) / element) * 100 : null;

        tolReached |= Math.abs(error_percent) > tol;
        content += `
                <tr class= "${
                  Math.abs(error_percent) > tol ? "table-danger" : refval ? "table-success" : "table-secondary"
                }">
                    <th scope="row">${this.ref_data[key]?.reference?.entitled?.replace("Ecarnet", "")}</th>
                    <td>${element.toFixed(2)}</td>
                    <td>${refval ? parseFloat(refval).toFixed(2) : "NC"}</td>
                    <td>${refval ? (refval - element).toFixed(2) : ""}</td>
                    <td>${error_percent ? error_percent.toFixed(0) + "%" : ""}</td>

                </tr>`;
      }
    }

    content += `</tbody></table>`;

    if (tolReached) {
      content +=
        `<p class="text-danger">Le pourcentage d'erreur sur l'un des index est jugé important,` +
        ` le support Onsen est alerté et vous recontactera dans les meilleurs delais</p>`;
    }

    Swal.fire({
      title: "Saisie enregistrée",
      html: content,
      width: "auto",
    });
    this._data_sent_callbacks.forEach((callback) => {
      callback();
    });
    this.checkExistingData();
  }

  /**
   * Return the reference corresponding to a field identifier
   *
   * Fields id are in the form :     field-prefix + field-identifier
   * References are in the form of : reference-prefix + reference-identifier
   *
   * We keep a reference to the list of field identifier, which are the same as reference identifier but
   * with words separated by - instead of _
   */
  getReferenceFromFieldIdentifier(field_identifier) {
    return REGULATION_REFERENCE_PREFIXES + field_identifier.replaceAll("-", "_");
  }
}

export default RegulationPackNewMeasureForm;

import $ from "jquery";

/**
 * This class is used to represent a periodicity, its possibilities
 * and even its view in the dom
 */
class Periodicity {
  /**
   * constructor
   *
   * @param name the name of the periodicity (day, week...)
   * @param minimal_duration the minimal duration under which one the
   *                         periodicity is not displayed
   * @param maximal_duration the maximal duration after which one the
   *                         periodicity will not be displayed
   * @param root_selector the css selector for this periodicity
   * @param click_callback the callback when the periodicity is clicked
   */
  constructor(name, minimal_duration, maximal_duration, root_selector, click_callback, render = true) {
    this._name = name;
    this._minimal_duration = minimal_duration;
    this._maximal_duration = maximal_duration;
    this._active = false;
    this.root_selector = root_selector;
    this.render = render;

    this.view.on("click", (e) => {
      e.preventDefault();
      click_callback(this);
    });
  }

  /**
   * Check if the period is valid with the current duration
   */
  check(current_duration) {
    //if maximal_duration is false then there is no limit
    let valid = this.maximal_duration === false || current_duration <= this.maximal_duration;
    valid = valid && (this.minimal_duration === false || current_duration >= this.minimal_duration);

    if (!this.render) return valid;

    if (valid) {
      this.view.parent().removeClass("d-none");
      this.view.parent().attr("title", "");
    } else {
      this.view.parent().addClass("d-none");
      this.view.parent().attr("title", "Périodicité non disponible pour cette durée");
    }

    return valid;
  }

  //Accessors

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get maximal_duration() {
    return this._maximal_duration;
  }

  set maximal_duration(value) {
    this._maximal_duration = value;
  }

  get minimal_duration() {
    return this._minimal_duration;
  }

  set minimal_duration(value) {
    this._minimal_duration = value;
  }

  get view() {
    return this._view;
  }

  set root_selector(value) {
    this._view = $("#" + value + "-" + this.name);
  }

  get active() {
    return this._active;
  }

  set active(value) {
    this._active = value;

    if (!this.render) return;

    if (value) this.view.addClass("active");
    else this.view.removeClass("active");
  }
}

export default Periodicity;

import { DateTime } from "luxon";
import { formatNumber } from "../../helpers/utils";
import MeasuresRenderer from "../MeasuresRenderer";
import $ from "jquery";

class AttendancePackMeasuresRenderer extends MeasuresRenderer {
  constructor(primary = true, container = $(".ajax-measures")) {
    super(container);
    this.primary = primary;

    this.default_decimals_rounding = 0;
    this.unit = "Baigneurs";
  }

  drawCircles(response) {
    if (this.primary) super.drawCircles(response, "per_day", `${this.unit}/j`);
  }

  updateSummary(val) {
    let swimmersSum = null;
    let avgDailySwimmers = null;
    let messageYesterday = "Par rapport à hier";
    let messagePeriod = "Par rapport à la période précédente";

    let weather = val.data.weather.last_weather;
    let avgStart = this.dateStart;
    let avgEnd = this.dateEnd;
    let avgDiff = avgEnd.diff(avgStart).as("days") + 1;

    let dailyWeather = {
      sunny: {
        class: "sunny",
        label: "Soleil",
      },
      snow: {
        class: "snow",
        label: "Neige",
      },
      rain: {
        class: "rain",
        label: "Pluie",
      },
      cloudy: {
        class: "cloudy",
        label: "Nuages",
      },
      mitigated: {
        class: "mitigated",
        label: "Mitigé",
      },
    };

    swimmersSum = val.data.attendance_pool.value;

    // This condition avoids divisions by 0 which causes an "Infinity" result
    if (avgDiff <= 1) {
      avgDailySwimmers = swimmersSum;
    } else {
      avgDailySwimmers = parseFloat(swimmersSum / avgDiff).toFixed(1);
    }

    // compute per day values for indicators
    for (let i in val.helper.indicators) {
      let indicator = val.helper.indicators[i];
      if (avgDiff <= 1) {
        val.data[indicator.reference]["per_day"] = {
          value: val.data[indicator.reference].value,
        };
      } else {
        val.data[indicator.reference]["per_day"] = {
          value: parseInt(val.data[indicator.reference].value / avgDiff).toFixed(0),
        };
      }
    }

    let weather_data = val.data.weather;
    $("#js-weather-icon")
      .css("background-image", `url("http://openweathermap.org/img/wn/${weather_data.last_icon}@2x.png")`)
      .find("span")
      .text(`${formatNumber(weather_data.last_temperature, 0)} °C`);

    let sum = weather_data.sunny_days + weather_data.cloudy_days + weather_data.rainy_days;

    $("#number-sunny").text(`${weather_data.sunny_days} jour${weather_data.sunny_days > 1 ? "s" : ""}`);
    $("#number-cloudy").text(`${weather_data.cloudy_days} jour${weather_data.cloudy_days > 1 ? "s" : ""}`);
    $("#number-rainy").text(`${weather_data.rainy_days} jour${weather_data.rainy_days > 1 ? "s" : ""}`);
    $("#number-sunny-ratio").text(`${parseInt((weather_data.sunny_days * 100.0) / sum)}`);
    $("#number-cloudy-ratio").text(`${parseInt((weather_data.cloudy_days * 100.0) / sum)}`);
    $("#number-rainy-ratio").text(`${parseInt((weather_data.rainy_days * 100.0) / sum)} `);

    $("#js-attendancepack-totalsummary-stat").find(".statnumber-stat").text(formatNumber(swimmersSum, true));

    $("#js-attendancepack-totalsummary-stat").find("span").text(val.helper.indicators[0].unit);

    $("#js-attendancepack-avgsummary-stat")
      .find(".statnumber-stat")
      .text(formatNumber(Math.round(avgDailySwimmers, true)));

    let evolution_summary = $("#js-attendancepack-evolutionsummary-stat");
    this.displayIndicatorStat(evolution_summary, val.data.attendance_pool, this.unit, true, true);
  }

  updateValues(response) {
    let data = response.data;

    let helper = response.helper;
    let dateStart = DateTime.fromISO(helper.start);
    let dateEnd = DateTime.fromISO(helper.end);
    let previousDateStart = dateStart
      .set({ year: helper.reference_year })
      .setLocale(navigator.language)
      .toLocaleString(DateTime.DATE_MED);
    let previousDateEnd = dateEnd
      .set({ year: helper.reference_year })
      .setLocale(navigator.language)
      .toLocaleString(DateTime.DATE_MED);
    let tooltip = ` Par rapport à la période du ${previousDateStart} au ${previousDateEnd}`;
    $(`.period-tooltip`).find(".tooltip-text").find("b").text(tooltip);

    for (let i in data.indicators) {
      let indicator = data.indicators[i];
      let element = $(`#js-attendancepack-${i}-stat`);
      let reference = indicator.reference;
      let value = data[reference];
      this.unit = indicator.unit;

      this.displayIndicatorStat(element, value, indicator.unit, true);
    }
  }

  static get pack_name() {
    return "attendance";
  }

  static get element_switch_enabled() {
    return false;
  }

  get target() {
    return "pool";
  }
  displayIndicator(data) {
    if (this.primary) super.displayIndicator(data);
  }

  displayIndicatorStat(element, datum, unit, round = false, down_is_red = false) {
    if (this.primary) super.displayIndicatorStat(element, datum, unit, round, down_is_red);
  }
}

export default AttendancePackMeasuresRenderer;

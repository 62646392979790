import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["average", "pool", "client", "path"];
  connect() {}
  get average() {
    return this.averageTarget.value;
  }
  get client() {
    return this.clientTarget.value;
  }
  get pool() {
    return this.poolTarget.value;
  }
  get path() {
    return this.pathTarget.value;
  }
  goToPool() {
    window.location = this.path;
  }
}

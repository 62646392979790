import NBase from "./NBase";
import Synoptic from "./graph";

export default class NIO extends NBase {
  static get defaults() {
    return {
      opacity: 1,
      zIndex: 50,
    };
  }

  constructor(params, node) {
    super(params);
    this.circular = false;
    if (!node) {
      console.log("error in node init");
    }

    this.obj.scratch("parent", node).data("_parent", node.id());
  }

  init() {
    super.init();
    // IO specific
    let io = this.config.io;
    if (this.obj) {
      this.obj.data({ width: 10, height: 10, tooltip: this.obj.data("index") });
      this.obj.addClass("io").addClass((io?.type ?? io?.name)?.includes("in") ? "in" : "out");
    }

    this.obj.on("tap", () => console.log(this.obj));
  }

  bindActions() {}
}

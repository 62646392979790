import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["nponds", "ncircuits"];
  static values = { poolid: Number };

  async connect() {
    let poolDetails = await $.ajax(`/account/pool_list_detail`);

    this.npondsTargets.forEach((element, index) => {
      let poolid = $(element).data("poolid");
      $(element).text(poolDetails[poolid]?.nponds ?? "-");
    });

    this.ncircuitsTargets.forEach((element, index) => {
      let poolid = $(element).data("poolid");
      $(element).text(poolDetails[poolid]?.ncircuits ?? "-");
    });
  }
}

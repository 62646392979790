import { Controller } from "@hotwired/stimulus";
import { BASE_DATE_FORMAT } from "@js/helpers/DateManager";
import DataTable from "datatables.net-dt";
import $ from "jquery";
import { DateTime } from "luxon";

export default class extends Controller {
  connect() {
    $("#table_report").DataTable({
      ajax: {
        url: "/api/v1/reports?exists[path]=true&isValidated=true&createdAt[after]=2023-01-01T00:00:00Z&sort=-createdAt",
        dataSrc: "",
      },
      order: [4, "desc"],
      language: {
        processing: "Traitement en cours...",
        search: "Rechercher&nbsp;:",
        lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
        info: "",
        infoEmpty: "",
        infoFiltered: "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
        emptyTable: "Aucune donnée disponible dans le tableau",
        paginate: {
          first: "<<",
          previous: "<",
          next: ">",
          last: ">>",
        },
        aria: {
          sortAscending: ": activer pour trier la colonne par ordre croissant",
          sortDescending: ": activer pour trier la colonne par ordre décroissant",
        },
      },
      columns: [
        { data: "poolEntitled" },
        { data: "dateStart" },
        { data: "dateEnd" },
        { data: "type" },
        {
          data: "createdAt",
          type: "date",
          render: function (data, type, full, meta) {
            if (type === "display") {
              return DateTime.fromJSDate(new Date(data)).setLocale("fr").toLocaleString(DateTime.DATE_MED);
            }
            return data;
          },
        },
        {
          data: "path",
          render: function (data, type, row, meta) {
            return data
              ? ' <div class="td-center"><a href="/' + data + '"><i class="fas fa-download"></i></a></div>'
              : "";
          },
        },
      ],
      columnDefs: [
        {
          targets: [1, 2, 4],
          render: DataTable.render.datetime("Do MMM YYYY", "fr"),
        },
      ],
      createdRow:
        //Ajouter 1 minutes pour afficher la bonne date sur la table
        function (row, data, dataIndex) {
          let dateStart = DateTime.fromISO(data.dateStart, { setZone: true }); // parse the dateStart string as a moment object
          let newDateStart = dateStart.plus({ minute: 1 }); // add 1 day to the dateStart moment object
          $("td:eq(1)", row).html(newDateStart.setLocale("fr").toFormat(BASE_DATE_FORMAT)); // update the dateStart column with the new date string

          let dateEnd = DateTime.fromISO(data.dateEnd, { setZone: true }); // parse the dateStart string as a moment object
          let newDateEnd = dateEnd.plus({ minute: 1 }); // add 1 day to the dateStart moment object
          $("td:eq(2)", row).html(newDateEnd.setLocale("fr").toFormat(BASE_DATE_FORMAT)); // update the dateStart column with the new date string
        },
      rowCallback: function (row, data) {
        let lastIndex = this.api().rows().count() - 1;
        if (this.api().row(row).index() === lastIndex) {
          $(row).find(".report_badge").remove(".report_badge");
          $(row).find("td:first").append('   <span class="report_badge">New</span>');
        }
      },
      paging: true,
    });
    //Afficher les derniers elements du tableau au top de la liste en indiquant la column a trié
    let $rows = $("#table_report tbody tr");
    let reversedRows = $rows.toArray().reverse();
    $("#table_report tbody").empty().append($(reversedRows));
  }
}

import ChartDataLabels from "chartjs-plugin-datalabels";
import { customTooltip } from "../../helpers/ChartUtilities";
import CircleStat from "../../helpers/CircleStat";
import PeriodRenderer from "../PeriodRenderer";
import Swal from "sweetalert2";
import Chart from "chart.js/auto";
import { DBEC_ONLY_SELECTOR, formatNumber, GENERAL_ONLY_SELECTOR, REUSE_ONLY_SELECTOR } from "../../helpers/utils";
import $ from "jquery";

const SIDE_CHARTS_MAIN_SELECTOR = "waterpack-side-charts";

class WaterPackPeriodRenderer extends PeriodRenderer {
  constructor(
    container = $(".ajax-periods"),
    detailStart = null,
    detailEnd = null,
    isPopup = false,
    indexSwitchPeriodicity = null,
  ) {
    super(container, detailStart, detailEnd, isPopup, indexSwitchPeriodicity);

    this.dataStore.forceFormValues = $("#valueOrigin").prop("checked") || false;
    this.dataStore.target = "circuit";
    this._is_dbec = false;
    this._water_reused = false;
    this.html_unit = "m&sup3;";
    this.unit = "m\xb3";
    this.request_reference = true;
    //Charts
    this.wpHistogram = container.find(".js-waterpack-histogram");
    this.wpDistribution = container.find("#js-waterpack-histogram-distribution");
    this.wpDistributionContainer = container.find(`#${SIDE_CHARTS_MAIN_SELECTOR}-distribution-container`);

    this.waterPackMainChart = null;
    this.wpCurvesChart = null;
    this.wpDistributionChart = null;

    this.side_charts = [];

    this.hasWpCurves = container.find("#js-waterpack-curves").length > 0;
    this.hasWpDistribution = container.find("#js-waterpack-histogram-distribution").length > 0;
    this.hasSideChartsTabList = container.find(".pack-side-charts-tab").length > 0;

    this.waterChart = null;
    this.dateLabels = [];
    this.previous_dateLabels = [];

    //Setup data origin switcher
    $("#valueOrigin").on("change", (event) => {
      this.dataStore.forceFormValues = event.target.checked;
      this.dataStore.loadData(null, this.date_manager.periodicity).then((response) => {
        this.clearDataset();
        this.render();
      });
    });

    this.clearDataset();

    this.tooltips = {
      hist: [],
      prevhist: [],
      curves: [],
      lb: [],
    };

    this.current_side_graph = null;
    //Only one decimal
    this.default_decimals_rounding = 1;

    if (this.hasSideChartsTabList) this.initSideCharts();
    if (this.isPopup) this.date_manager.switchPeriodicity(indexSwitchPeriodicity);
  }

  clearDataset() {
    const container = $(".ajax-periods");

    let color_used = null;
    let color_reused = null;

    //For the consumption curves
    if (this.hasWpCurves) {
      this.wpCurves = container.find("#js-waterpack-curves").get(0).getContext("2d");

      color_used = this.wpCurves.createLinearGradient(0, 100, 0, 500);
      color_used.addColorStop(0, this.colors.gradient_blue);
      color_used.addColorStop(1, this.colors.transparent_blue);

      color_reused = this.wpCurves.createLinearGradient(0, 100, 0, 500);
      color_reused.addColorStop(0, this.colors.gradient_green);
      color_reused.addColorStop(1, this.colors.transparent_green);
    }

    this.datasets = {
      hist: [],
      prevHist: [],
      curves: {
        consumption_per_swimmer: {
          values: [],
          color: this.colors.normal,
        },
        consumption: {
          values: [],
          color: color_used,
        },
        preheated: {
          values: [],
          color: color_reused,
        },
        reused: {
          values: [],
          color: this.colors.normal,
        },
      },
    };
  }

  //Overwriting
  buildCharts(response) {
    this._water_reused = response.helper.water_reuse;

    //Destroy charts
    if (this.waterPackMainChart) this.waterPackMainChart.destroy();

    if (this.wpCurvesChart) this.wpCurvesChart.destroy();

    if (this.wpDistributionChart) {
      this.wpDistributionChart.destroy();
    }
    let data = response.data; //all periods with their values
    this.displayLabels(response);
    let parent = this;

    let customHistogramTooltipsOptions = {
      tooltip_id: "chartjs-tooltip-water",
      container_id: "js-waterpack-histogram",

      getTitle: (titleLines) => {
        let title = $("<p>", {
          class: "has-light-grey-border-bottom p-2",
        });
        let title_content = "";

        titleLines.forEach((title, i) => {
          let date_split = title.split(",");
          let date = this.date_manager.isWeekPeriodicity ? date_split[1] : date_split[0];
          title_content += date;
        });

        title.text(title_content);

        return title;
      },
      getBody: () => {
        let body = $("<div/>", {
          class: "d-flex justify-content-around flex-wrap align-items-stretch h-100 w-100",
          css: {
            height: 100,
            width: 135,
          },
        });

        let content = `
                <table class="table">
                    <thead style="text-align: center;"                    >
                        <tr>
                            <th scope="col"></th>
                            <th scope="col" style="color: rgba(0,0,0,0.7)">Réference</th>
                            <th scope="col"><i class="fa fa-exchange-alt"></i></th>
                            <th scope="col" style="color: rgba(0,0,0,1)">Mesure</th>
                        </tr>
                    </thead>
                <tbody>`;

        let indicators = response.helper.indicators;
        this.indicators = indicators;
        for (let i in indicators) {
          i = parseInt(i);
          // .filter(tooltip => tooltip.hide != true)

          let dataset = this.datasets.hist[i];
          let pb_dataset = this.datasets.hist[indicators.length + i];
          let tooltip = this.tooltips.hist[i];
          let pb_tooltip = this.tooltips.hist[indicators.length + i];
          let prev_dataset = this.datasets.prevHist[parseInt(i)];
          let prev_tooltip = this.tooltips.prevhist[parseInt(i)];

          content += `
                    <tr">
                    <th scope="row" class="chartjs-tooltip-element-row-title">
                        ${dataset.abbreviation}
                    </th>
                    <td class="chartjs-tooltip-element-water">
                        <a style="color: ${prev_dataset.color};">
                        ${formatNumber(prev_tooltip, 2)}
                        </a>
                    </td>
                    <td class="chartjs-tooltip-element-water">
                        <a>
                        ${formatNumber(tooltip - prev_tooltip, 2)}
                        </a>
                    </td>
                    <td class="chartjs-tooltip-element-water">
                        <a style="color: ${dataset.color};">
                        <strong>${formatNumber(tooltip, 2)}</strong>
                        </a>
                        <a style="color: ${pb_dataset.color};">
                        <strong> ${pb_tooltip > 0 ? "+ " + formatNumber(pb_tooltip, 2) : ""}</strong>
                        </a>
                    </td>
                    </tr>
                    `;
        }

        content += "</tbody></table>";
        body.html(content);

        let tooltip_content = `<p><strong>Total: </strong> ${formatNumber(this.tooltipSum, 2)} ${this.html_unit}</p>`;
        if (response.helper.periodicity !== "hour") {
          tooltip_content += `<p>Soit ${formatNumber(this.tooltips.lb, false)} L/B</p>`;
        }
        tooltip_content += `<p><i style="opacity:0.5;font-size:0.8em">*cliquez pour voir le détail</i></p>`;

        $("<div>", {
          class: "p-2 w-100 text-center",
        })
          .html(tooltip_content)
          .appendTo(body);

        return body;
      },
    };

    let LBDataset = {
      data: this.datasets.curves.consumption_per_swimmer.values,
      label: "L/B",
      // This binds the dataset to the right y axis
      yAxisID: "right-y-axis",
      type: "line",
      order: 2,
      borderColor: "rgba(0, 100, 255, 0.4)",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      spanGaps: true,
      fill: false,
      cubicInterpolationMode: "monotone",
      tension: 0.4,
      isEnabled: response.helper.periodicity !== "hour",
    };

    let points = {
      consumption: new Image(),
      reuse: new Image(),
    };

    points.consumption.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAPFBMVEVHcEw4vNE4vNE3vtE3vNE3vNE4vNE4vNE4vNE4vdE4vdH///83vNFszt7J7fNIwtX3/P2K2OQ7vdLh9fg1J1CnAAAAC3RSTlMARN4Cqe0Um31Xe3FlzdEAAABvSURBVBjTZY9LEsMgDENtAphgmU9y/7uWkraL+u2sGckS0SJzESmc6eHgmLBIkY99nwHd5rSOcL4VDpe1oTqaXYGXP8Ju3dyGmIlTb/qh9cRUYOMrDEMlwdQfE+KF+m9xoe6tK+aru3F7fhWpz/wXn7wItxYIGRAAAAAASUVORK5CYII=";
    points.reuse.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAS1BMVEVHcEwAulcAuFYAulcAulYAulcAulcAulcAu1YAulcAuVgAulcAulf///8BuldMzYPB7dH2/Pje9eUYwGUXwGR42Jx32Zt32Jt22Jvkq1GWAAAADXRSTlMARALe7ZupfRRXFXuqOBo5TAAAAH5JREFUGNNlj9sSgzAIRMFc1QKJidr//9KSVDtjc55gZxZ2AZQFg3MBF/gy4cuyYj1OfZ8NFzkOKWzmpqBhSZkoJ2GD6vcsG3U2YR8BbUl0kapFCCz5FrLwCo53+rGzewqnCoNlOBr/3w7BevQq51vqFV3L+Uc5Jbb6K8Y2fwBIOAnuZrb66wAAAABJRU5ErkJggg==";

    let histogram_datasets = [];

    for (let index in this.datasets.hist) {
      let histogram = this.datasets.hist[index];
      let label = "Période";
      label += index == 0 ? "" : "blank";

      histogram_datasets.push({
        label: label,
        abbreviation: histogram.abbreviation,
        backgroundColor: histogram.color,
        data: histogram.values,
        yAxisID: "left-y-axis",
        order: 3,
        isEnabled: true,
        stack: "stack 0",
        indic_id: histogram.id,
        borderWidth: 1,
        borderRadius: 10,
      });
    }

    let prev_histogram_datasets = [];
    for (let index in this.datasets.prevHist) {
      let histogram = this.datasets.prevHist[index];
      let label = "Référence";
      label += index == 0 ? "" : "blank";
      prev_histogram_datasets.push({
        label: label, // for rounded chart
        backgroundColor: histogram.color,
        data: histogram.values.filter((value) => value >= 0),
        yAxisID: "left-y-axis",
        order: 0,
        isEnabled: true,
        stack: "stack 1",
        indic_id: histogram.id,
        borderWidth: 1,
        borderRadius: 10,
      });
    }

    var newLegendClickHandler = function (e, legendItem) {
      var index = legendItem.datasetIndex;
      let ci = this.chart;
      let currentDataset = ci.data.datasets[index];

      // Toggle current dataset
      var meta = ci.getDatasetMeta(index);
      meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

      // Toggle others datasets
      for (let i = 0; i < ci.data.datasets.length; i++) {
        let dataset = ci.data.datasets[i];
        var meta = ci.getDatasetMeta(i);
        if (
          (currentDataset.label.includes("Période") && dataset.label.includes("Période")) ||
          (currentDataset.label.includes("Référence") && dataset.label.includes("Référence"))
        ) {
          if (currentDataset != dataset) {
            meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
          }
        }
      }

      // render
      ci.update();
    };

    var displayDetails = function (e) {
      //check if its hours so return
      const regex = /h00$/;
      if (parent.dateLabels.some((str) => regex.test(str))) return;

      let dateNextClicked = "";
      let dateClicked = this.tooltip.title; //['lun. 07/03']
      let index = 0;
      let pack = parent.dataStore._pack;
      //return key of matching arr date_clicked and arr all dates (all periods in ISO)
      parent.dateLabels.forEach((dateLabel, k) => {
        if (dateClicked[0] == dateLabel) {
          return (index = k);
        }
      });

      //return the matching date ISO with the index of periods array
      dateClicked = parent.helper.periods[index]["start"];
      dateNextClicked = parent.helper.periods[index]["end"];

      $.ajax({
        url: `/pack/water/details`,
        method: "GET",
        data: {
          date: dateClicked,
          dateNext: dateNextClicked,
          titleDate: this.tooltip.title[0],
          pack: pack,
          indicators: this.renderer.indicators,
          indicator_colors: this.renderer.indicator_colors,
        },
      }).then((response) => {
        Swal.fire({
          html: response,
          width: "85%",
          showCloseButton: true,
          showConfirmButton: false,
        });
      });
    };

    this.waterPackMainChart = new Chart(this.wpHistogram, {
      type: "bar",
      data: {
        labels: this.dateLabels,
        datasets: [
          ...prev_histogram_datasets, //three dots are spread operator
          ...histogram_datasets,
          LBDataset,
        ].filter((dataset) => dataset.isEnabled),
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 45,
            bottom: 30,
          },
        },
        elements: {
          point: {
            pointStyle: points.consumption,
          },
        },
        scales: {
          "left-y-axis": {
            type: "linear",
            position: "left",
            stacked: true,
            title: {
              display: true,
              text: "m\xb3",
            },
            beginAtZero: true,
          },
          "right-y-axis": {
            type: "linear",
            position: "right",
            title: {
              display: true,
              text: "L/B",
              color: "rgba(0, 100, 255, 0.4)",
            },
            grid: { display: false, color: "lightgrey" },
            beginAtZero: true,
            ticks: {
              color: "rgba(0, 100, 255, 0.4)",
            },
          },

          x: {
            display: true,
            stacked: true,
            grid: {
              color: "lightgrey",
              borderDash: [2, 2],
            },
          },
        },

        labels: {
          display: true,
        },
        onClick: displayDetails,
        plugins: {
          legend: {
            onClick: newLegendClickHandler,
            labels: {
              filter: function (legendItem, data) {
                return !legendItem.text.includes("blank");
              },
            },
          },
          tooltip: {
            backgroundColor: "rgb(255, 255, 255)",
            bodyFontColor: "rgb(0, 255, 0)",
            mode: "nearest",
            intersect: false,
            enabled: false,
            callbacks: {
              beforeFooter: (tooltipItems) => {
                for (let index in this.datasets.hist) {
                  index = parseInt(index);
                  this.tooltips.hist[index] = parseFloat(this.datasets.hist[index].values[tooltipItems[0].dataIndex]);
                  this.tooltips.prevhist[index] = parseFloat(
                    this.datasets.prevHist[index].values[tooltipItems[0].dataIndex],
                  );
                }
                this.tooltips.lb = parseFloat(
                  this.datasets.curves.consumption_per_swimmer.values[tooltipItems[0].dataIndex],
                );
              },
            },
            external: (context) => {
              customTooltip(context, customHistogramTooltipsOptions);
            },
          },
        },
      },
    });
    this.waterPackMainChart.renderer = this;
    this.waterPackMainChart.update();

    //pass the tooltip ahead if clicked on main chart
    if (this.isPopup) $(".chartjs-tooltip").css({ "z-index": "1100" });

    for (let index in response.helper.indicators) {
      let indicator = response.helper.indicators[index];
      $(`#consumptionsummary-element--${index}`).on("click", (event) => {
        for (let index_2 in parent.waterPackMainChart.data.datasets) {
          let dataset = parent.waterPackMainChart.data.datasets[index_2];
          if (dataset.indic_id === indicator.id) {
            dataset.hidden = !dataset.hidden;
            if (dataset.hidden) $(event.target).parents(".consumptionsummary-element").removeClass("active");
            else $(event.target).parents(".consumptionsummary-element").addClass("active");
          }
        }
        parent.waterPackMainChart.update();
      });
    }

    let customCurvesTooltipsOptions = {
      tooltip_id: "chartjs-tooltip-curves",
      tooltip_class: "chartjs-tooltip",
      container_id: "js-waterpack-consumptioncurves",
      offsetY: 80,

      getTitle: (titleLines) => {
        let title = $("<p>", {
          class: "text-center has-light-grey-border-bottom p-v-xs",
        });
        let title_content = "";

        titleLines.forEach((title) => {
          let date_split = title.split(",");
          let date = this.date_manager.isWeekPeriodicity ? date_split[1] : date_split[0];
          title_content += date;
        });

        title.addClass("text-center").addClass("p-v-xs");
        title.text(title_content);

        return title;
      },
      getBody: (body_lines, tooltip_model) => {
        let body = $("<div>");
        const classes = "d-flex align-items-start justify-content-start curves-chart-element flex-nowrap";

        $("<div>", {
          classes: classes,
        })
          .html(
            `
              <div class="${classes}">
                  <div class="mr-3 consumption-used"></div>
                  <p>${formatNumber(this.tooltips.curves.consumption, true)} ${this.html_unit}
                  consommés
                  </p>
              </div>
            `,
          )
          .appendTo(body);

        if (this._is_dbec) {
          let label = this._is_dbec ? "préchauffés" : "reutilisés";

          $("<div>", {
            classes: classes,
          })
            .html(
              `
                <div class="${classes}">
                    <i class="fa fa-arrows-alt-v fa-lg mx-2" aria-hidden="true"></i>
                    <p>${formatNumber(
                      this.tooltips.curves.consumption - this.tooltips.curves[this._is_dbec ? "preheated" : "reuse"],
                      true,
                    )} ${this.html_unit}
                    </p><br>
                </div>
                <div class="${classes}">
                    <div class="mr-3 consumption-reused"></div>
                    <p>${formatNumber(this.tooltips.curves[this._is_dbec ? "preheated" : "reuse"], true)} ${
                      this.html_unit
                    }
                    ${label}
                    </p><br>
                </div>

                `,
            )
            .appendTo(body);
        }

        return body;
      },
    };

    let default_curves_options = {
      pointRadius: 5,
      pointHoverRadius: 5,
      pointHitRadius: 5,
      fill: true,
      cubicInterpolationMode: "monotone",
      tension: 0.4,
    };

    let curves_datasets = [
      Object.assign(
        {
          backgroundColor: this.datasets.curves.consumption.color,
          pointStyle: points.consumption,
          data: this.datasets.curves.consumption.values,
        },
        default_curves_options,
      ),
    ];

    if (this.datasets.curves.preheated.values.length > 0) {
      curves_datasets.push(
        Object.assign(
          {
            backgroundColor: this.datasets.curves.preheated.color,
            pointStyle: points.reuse,
            data: this.datasets.curves.preheated.values,
          },
          default_curves_options,
        ),
      );
    } else if (this._water_reused) {
      curves_datasets.push(
        Object.assign(
          {
            backgroundColor: this.datasets.curves.reuse.color,
            pointStyle: points.reuse,
            data: this.datasets.curves.reuse.values,
          },
          default_curves_options,
        ),
      );
    }

    if (this.hasWpCurves) {
      this.wpCurvesChart = new Chart(this.wpCurves, {
        type: "line",
        data: {
          labels: this.dateLabels,
          datasets: curves_datasets.reverse(),
        },
        options: {
          responsive: true,
          scales: {
            x: {
              display: true,
              position: "top",
              grid: {
                display: false,
              },
            },

            y: {
              title: {
                display: true,
                text: this.unit,
              },
            },
          },

          labels: {
            display: true,
            position: top,
          },

          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: "nearest",
              intersect: false,
              enabled: false,
              callbacks: {
                beforeFooter: (tooltipItems) => {
                  for (let index in this.datasets.curves) {
                    this.tooltips.curves[index] = parseFloat(
                      this.datasets.curves[index].values[tooltipItems[0].dataIndex],
                    );
                  }
                },
              },
              external: (tooltip_model) => {
                customTooltip(tooltip_model, customCurvesTooltipsOptions);
              },
            },
          },
        },
      });

      this.wpCurvesChart.update();
    }

    // We hide the reference data by default
    for (let i in this.waterPackMainChart.data.datasets) {
      if (this.waterPackMainChart.data.datasets[i].label.includes("Référence")) {
        this.waterPackMainChart.getDatasetMeta(i).hidden = true;
      }
    }

    this.refreshDBECState(response);
    this.waterPackMainChart.update();
    this.renderCurentSideChart();
  }

  /**
   * Initialise the charts on the right of the screen and display the tabs
   * the displayed tabs depends on DBEC and pond selected
   */
  initSideCharts() {
    //Side charts managing

    this.distribution_chart_container = $("#" + SIDE_CHARTS_MAIN_SELECTOR + "-distribution");
    this.water_reuse_chart_container = $("#" + SIDE_CHARTS_MAIN_SELECTOR + "-reused-water");
    this.water_preheat_chart_container = $("#" + SIDE_CHARTS_MAIN_SELECTOR + "-preheated-water");

    this.side_charts_tabs_list = [
      {
        id: "distribution",
        label: "Répartition",
        click: () => {
          //Hide unnecessary graphs and display the good one
          this.distribution_chart_container.removeClass("d-none");
          this.water_preheat_chart_container.addClass("d-none");
          this.water_reuse_chart_container.addClass("d-none");
          this.displayDistributionGraph();
        },
        element: null,
      },
      {
        id: "preheating-rate",
        label: "Taux de préchauffage",
        click: () => {
          //Hide unnecessary graphs and display the good one
          this.distribution_chart_container.addClass("d-none");
          this.water_preheat_chart_container.removeClass("d-none");
          this.water_reuse_chart_container.addClass("d-none");
          this.displayPreheatGraph();
        },
        dbec_only: true,
        element: null,
      },
      {
        id: "reuse",
        label: "Réutilisation",
        click: () => {
          //Hide unnecessary graphs and display the good one
          this.distribution_chart_container.addClass("d-none");
          this.water_preheat_chart_container.addClass("d-none");
          this.water_reuse_chart_container.removeClass("d-none");
          this.displayWaterReuseGraph();
        },
        general_only: true,
        reuse_only: true,
        element: null,
      },
    ];

    //In this will be stored the methods to draw each different side graph
    this.side_graph_display_methods = {};

    let side_charts_tabs_parent = $("#" + SIDE_CHARTS_MAIN_SELECTOR + "-tab-list");
    this.side_charts_tabs_list.forEach((tab) => {
      let li = $("<li>", {
        class: SIDE_CHARTS_MAIN_SELECTOR + "-tab-item",
        id: SIDE_CHARTS_MAIN_SELECTOR + "-tab-" + tab.id,
      }).appendTo(side_charts_tabs_parent);
      $("<a>").text(tab.label).appendTo(li);

      li.on("click", () => {
        if (this.current_side_graph !== tab) {
          //Save the current side graph to know what to display in the display current side graph function
          this.current_side_graph = tab;

          tab.click();
          $("." + SIDE_CHARTS_MAIN_SELECTOR + "-tab-item").removeClass("active");
          li.addClass("active");
        }
      });

      //If general only add the selector to get it when changing pond
      if (tab.general_only) {
        li.addClass(GENERAL_ONLY_SELECTOR);
      }

      //If dbec only add the selector to remove tabs if needed
      if (tab.dbec_only) {
        li.addClass(DBEC_ONLY_SELECTOR);
        li.addClass("d-none-" + DBEC_ONLY_SELECTOR);
      }

      //If reuse only add the selector
      if (tab.reuse_only) {
        li.addClass(REUSE_ONLY_SELECTOR);
        li.addClass("d-none-" + REUSE_ONLY_SELECTOR);
      }

      tab.element = li;

      //Save the click method for the display current side graph function
      this.side_graph_display_methods[tab.id] = tab.click;
    });

    //Set the first element as active
    this.side_charts_tabs_list[0].element.click();
  }

  /**
   * Render the chart on the right side according to the current active tab
   */
  renderCurentSideChart() {
    if (this.side_graph_display_methods?.hasOwnProperty(this.current_side_graph?.id)) {
      this.side_graph_display_methods?.[this.current_side_graph?.id]();
    }
  }

  /**
   * Display the water distribution chart
   */
  displayDistributionGraph() {
    let datasets = [
      {
        backgroundColor: this.datasets.hist.filter((dataset) => dataset.isEnabled).map((dataset) => dataset.color),
        data: this.datasets.hist
          .filter((dataset) => dataset.isEnabled)
          .filter((dataset) => dataset.values.length > 0)
          .map((dataset) => dataset.values.reduce((a, b) => Math.max(a + b, 0))),
      },
    ];

    let data = {
      datasets: datasets,
      labels: this.datasets.hist.filter((dataset) => dataset.isEnabled).map((dataset) => dataset.abbreviation),
    };

    let options = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: 60,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          align: "end",
          anchor: "end",
          display: "auto",
          clamp: true,
          formatter: (value, context) => {
            let total = context.dataset.data.reduce((a, b) => a + b);
            return value > 0
              ? context.chart.data.labels[context.dataIndex] +
                  "\n" +
                  formatNumber(value, 2) +
                  " " +
                  this.unit +
                  "\n" +
                  formatNumber((100 * value) / total, true) +
                  "%"
              : null;
          },
          color: (context) => {
            return context.dataset.backgroundColor;
          },
        },
      },
      labels: {
        display: true,
      },

      events: [],
    };

    this.wpDistributionChart?.destroy();

    this.wpDistributionChart = new Chart(this.wpDistribution, {
      type: "pie",
      data: data,
      options: options,
      plugins: [ChartDataLabels],
    });

    this.side_charts.push(this.wpDistributionChart);
  }

  /**
   * Display the water reuse chart
   */
  displayWaterReuseGraph() {
    let circle = new CircleStat(
      "reused-water",
      formatNumber(Math.floor(this.datasets.curves.reused.values.reduce((a, b) => a + b, 0)), 2),
      this.datasets.curves.consumption.values.reduce((a, b) => a + b, 0),
      this.colors.valid,
      false,
      80,
      7,
      this.html_unit,
    );
    circle.create();
    this.side_charts.push(circle);
  }

  /**
   * Display the water preheat chart
   */
  displayPreheatGraph() {
    let circle = new CircleStat(
      "preheated-water",
      formatNumber(
        this.datasets.curves.preheated.values.reduce((a, b) => a + b, 0),
        2,
      ),
      this.datasets.curves.consumption.values.reduce((a, b) => a + b, 0),
      this.colors.valid,
      false,
      80,
      7,
      this.html_unit,
      true,
    );
    circle.create();
    this.side_charts.push(circle);
  }

  displayLabels(response) {
    // Display indicators blocks if in data
    let helper = response.helper;
    let indicator_labels = $(".indicator-label");
    indicator_labels.each((i, elt) => {
      if (helper.indicators.hasOwnProperty(i)) {
        let abbr = helper.indicators[i].abbreviation;
        if (this.datasets.hist.hasOwnProperty(i)) {
          this.datasets.hist[i].label = abbr;
        }
      }
    });
  }

  /**
   * Return the sum of each number displayed in the tooltip
   * @returns {number}
   */
  get tooltipSum() {
    let tooltips = Object.values(this.tooltips.hist.slice(0, this.indicators.length));
    let ret = null;

    tooltips.forEach((tooltip) => {
      ret += isNaN(tooltip) ? null : tooltip;
    });
    return ret;
  }

  emptyChartsData() {
    this.dateLabels = [];
    this.datasets.hist = [];
    this.datasets.prevHist = [];
    for (let j in this.datasets.curves) {
      this.datasets.curves[j].values = [];
    }
    this.tooltips = {
      hist: [],
      prevhist: [],
      curves: [],
    };
  }

  /**
   * This is a tool to refresh elements dependent of the dbec state,
   * for example tabs that are displayed only if dbec exists
   */
  refreshDBECState(response) {
    // TODO: Better check
    this._is_dbec = response.helper.has_dbec;
    this._water_reused = response.helper.water_reuse;

    // Show/hide general only elements
    let dbec_only_elements = $("." + DBEC_ONLY_SELECTOR);

    // Use a particular class to hide, to avoid it to be displayed if it should be hidden for other reasons
    if (this._is_dbec) {
      dbec_only_elements.removeClass("d-none-" + DBEC_ONLY_SELECTOR);
    } else {
      dbec_only_elements.addClass("d-none-" + DBEC_ONLY_SELECTOR);

      // * If we are currently displaying a tab which needs dbec but it should not be displayed Anymore, go back to the distribution one
      if (this.current_side_graph?.dbec_only) this.side_charts_tabs_list[0].element.click();
    }
    let reuse_only_elements = $("." + REUSE_ONLY_SELECTOR);
    if (this._water_reused) {
      reuse_only_elements.removeClass("d-none-" + REUSE_ONLY_SELECTOR);
    } else {
      reuse_only_elements.addClass("d-none-" + REUSE_ONLY_SELECTOR);

      // * If we are currently displaying a tab which needs dbec but it should not be displayed Anymore, go back to the distribution one
      if (this.current_side_graph?.reuse_only) this.side_charts_tabs_list[0].element.click();
    }
  }

  destroyCharts() {
    super.destroyCharts();

    if (this.waterChart) this.waterChart.destroy();
  }

  initHistogram(res, hist, colors) {
    const data = res.data;
    const helper = res.helper;

    for (let index in helper.indicators) {
      index = parseInt(index);
      let indicator = helper.indicators[index];
      hist[index] = {
        values: [],
        label: indicator.label,
        color: colors[index],
        isEnabled: true,
        id: indicator.id,
        abbreviation: indicator.abbreviation,
      };

      hist[helper.indicators.length + index] = {
        values: [],
        label: "Problème technique",
        abbreviation: "PT",
        color: this.colors.issue,
        isEnabled: true,
        id: indicator.id,
        abbreviation: `Pb ${indicator.abbreviation}`,
      };
    }
  }

  updateChartsData(res) {
    // let ctx = document.getElementById('canvas').getContext('2d');
    const data = res.data;
    const helper = res.helper;

    this.initHistogram(res, this.datasets.hist, [
      this.colors.normal,
      this.colors.dark,
      this.colors.light,
      this.colors.other,
    ]);

    let technical_stop = null;
    let issue = null;
    let period_issue = null;
    let issue_left = 0;

    let indicators = res.helper.indicators;
    this.updateDateLabels();

    for (let datum of Object.values(data)) {
      this.datasets.curves.consumption_per_swimmer.values.push(datum.water_consumption_per_swimmer ?? 0);
      this.datasets.curves.consumption.values.push(datum.water_total);
      this.datasets.curves.preheated.values.push(datum.dbec_warmed_water);
      this.datasets.curves.reused.values.push(datum.water_reused);

      for (let ii in indicators) {
        ii = parseInt(ii);
        let indicator = indicators[ii];
        let issue_reference = `${indicator.reference}_issue`;
        let issue_value = datum?.[issue_reference] ?? 0;
        issue += indicator.water_issue;
        this.datasets.hist[ii].values.push(Math.max(0, datum[indicator.reference]));
        this.datasets.hist[indicators.length + ii].values.push(issue_value);
      }
      technical_stop += datum.technical_stop ?? 0;
    }

    technical_stop > 0 ? $(".waterpack-technicalstop").show() : $(".waterpack-technicalstop").hide();
    $("#js-waterpack-technicalstop-stat").find("b").text(formatNumber(technical_stop, true));

    for (let index in this.datasets.hist) {
      this.tooltips.hist[index] = "NC";
    }
    for (let index in this.datasets.prevhist) {
      this.tooltips.prevhist[index] = "NC";
    }
  }

  updatePreviousChartsData(res) {
    const data = res.data;
    const helper = res.helper;
    this.initHistogram(res, this.datasets.prevHist, [
      this.colors.normal_transparent,
      this.colors.dark_transparent,
      this.colors.light_transparent,
      this.colors.other_transparent,
    ]);

    let indicators = res.helper.indicators;
    for (let datum of Object.values(data)) {
      for (let ii in indicators) {
        ii = parseInt(ii);
        let indicator = indicators[ii];
        this.datasets.prevHist[ii].values.push(datum[indicator.reference]);
      }
    }
  }

  static get pack_name() {
    return "water";
  }

  static get element_sœitch_enabled() {
    return true;
  }

  get target() {
    return "circuit";
  }
}

export default WaterPackPeriodRenderer;

import { Controller } from "@hotwired/stimulus";
import { datePickers } from "@js/helpers/datepickers";
import AttendancePackPeriodRenderer from "@js/renderers/Periods/AttendancePackPeriodRenderer";
import WaterPackPeriodRenderer from "@js/renderers/Periods/WaterPackPeriodRenderer";
import RegulationPackPeriodRenderer from "@js/renderers/Periods/RegulationPackPeriodRenderer";
import AirPackPeriodRenderer from "@js/renderers/Periods/AirPackPeriodRenderer";
import EnergyPackPeriodRenderer from "@js/renderers/Periods/EnergyPackPeriodRenderer";
import ChemicalPackPeriodRenderer from "@js/renderers/Periods/ChemicalPackPeriodRenderer";
import $ from "jquery";
import { DateTime } from "luxon";

export default class extends Controller {
  connect() {
    let dateStart = DateTime.fromJSDate(new Date($("#dateDetail").text())); //Wed Oct 20 2021 02:00:00 GMT+0200
    let dateNext = DateTime.fromJSDate(new Date($("#dateNext").text())); //Fri Oct 21 2021 02:00:00 GMT+0200
    let indexSwitchPeriodicity;

    let duration = dateNext.diff(dateStart);
    let durationInDays = duration.as("days");

    indexSwitchPeriodicity =
      durationInDays === 1 || durationInDays === 7
        ? 0
        : durationInDays >= 28 && durationInDays <= 31
          ? 1
          : durationInDays === 365 || durationInDays === 366
            ? 2
            : 3;

    let dateEnd = durationInDays === 1 ? dateStart : dateStart.plus(duration);

    if ($("#pack").text() == "attendance") {
      let ctx = $("#js-attendancepack-histogramcontainer-popup");
      let periodRenderer = new AttendancePackPeriodRenderer(ctx, dateStart, dateEnd, true, indexSwitchPeriodicity);
      periodRenderer.init();
    }

    if ($("#pack").text() == "water") {
      let ctx = $("#js-waterpack-histogramcontainer-popup");
      let periodRenderer = new WaterPackPeriodRenderer(ctx, dateStart, dateEnd, true, indexSwitchPeriodicity);
      periodRenderer.init();
    }

    if ($("#pack").text() == "regulation") {
      //In the regulation pack, indicators are charged et can be switchable to display their chart
      //get the index of active indicator
      let index_active = $(".consumptionsummary-element.active").attr("id").split("--").pop();
      let ctx = $("#js-regulationpack-histogramcontainer-popup");
      let periodRenderer = new RegulationPackPeriodRenderer(
        ctx,
        dateStart,
        dateEnd,
        true,
        index_active,
        indexSwitchPeriodicity,
      );
      periodRenderer.init();
    }

    if ($("#pack").text() == "energy") {
      let ctx = $("#js-energypack-histogramcontainer-popup");
      let periodRenderer = new EnergyPackPeriodRenderer(ctx, dateStart, dateEnd, true, indexSwitchPeriodicity);
      periodRenderer.init();
    }

    if ($("#pack").text() == "chemical") {
      //In the chemical pack, indicators are charged et can be switchable to display their chart
      //get the index of active indicator
      let index_active = $(".consumptionsummary-element.active").attr("id").split("--").pop();
      let ctx = $("#js-chemicalpack-histogramcontainer-popup");
      let periodRenderer = new ChemicalPackPeriodRenderer(
        ctx,
        dateStart,
        dateEnd,
        true,
        index_active,
        indexSwitchPeriodicity,
      );
      periodRenderer.init();
    }
  }
}

import $ from "jquery";

const BASE_THICKNESS = 20;

//If there is less than this amount of bars, the thickness will increase
const MIN_BARS_COUNT_CONSTANT_THICKNESS = 6;

/**
 * Returns the appropriate thickess for bars of an chart depending on the number of displayed elements
 * Because under 6 bars we
 *
 * @param bars_count the number of bars which will be displayed
 */
export function computeBarThickness(bars_count) {
  let thickness = BASE_THICKNESS;

  //If there is less than 6 bars, increase progressively the thickness
  if (bars_count < MIN_BARS_COUNT_CONSTANT_THICKNESS)
    thickness += (MIN_BARS_COUNT_CONSTANT_THICKNESS - bars_count) * BASE_THICKNESS;

  return thickness;
}

/**
 * Create a custom tooltip for a Chart.js chart
 *
 * Options parameters can have the following properties:
 *
 * @param context
 * @param options
 */
export function customTooltip(context, options) {
  const { chart, tooltip } = context;

  if (!options.hasOwnProperty("tooltip_id")) {
    options.tooltip_id = "chartjs-tooltip";
  }
  if (!options.hasOwnProperty("tooltip_class")) {
    options.tooltip_class = "chartjs-tooltip";
  }
  if (!options.hasOwnProperty("container_id")) {
    options.container_id = "js-chart-container";
  }

  if (!options.hasOwnProperty("offsetY")) {
    options.offsetY = 160;
  }

  if (!options.hasOwnProperty("getTitle")) {
    options.getTitle = (titleLines) => {
      let title_value = "";
      let title = $("<p>");

      titleLines.forEach((sub_title) => {
        title_value += sub_title;
      });

      title.addClass("text-center").addClass("p-v-xs");
      return title;
    };
  }

  if (!options.hasOwnProperty("getBody")) {
    options.getBody = (bodyLines) => {
      let body = $("<div>");

      bodyLines.forEach((body_line) => {
        $("<p>").text(body_line).appendTo(body);
      });

      return body;
    };
  }

  // Tooltip Element
  let tooltipEl = $("#" + options.tooltip_id);
  let chartContainer = $("#" + options.container_id);

  // Create element on first render
  if (tooltipEl.length === 0) {
    tooltipEl = $("<div>", {
      id: options.tooltip_id,
      class: options.tooltip_class,
    }).appendTo(chartContainer);
  }

  tooltipEl.text("");

  // Hide if no tooltip
  if (tooltip.opacity !== 0) {
    tooltipEl.removeClass("above", "below", "no-transform");
    if (tooltip.yAlign) {
      tooltipEl.addClass(tooltip.yAlign);
    } else {
      tooltipEl.addClass("no-transform");
    }
    if (tooltip.body) {
      let titleLines = tooltip.title || [];
      let bodyLines = tooltip.body.map((bodyItem) => bodyItem.lines);

      options.getTitle(titleLines, tooltip).appendTo(tooltipEl);
      options.getBody(bodyLines, tooltip).appendTo(tooltipEl);
    }

    tooltipEl.css({
      opacity: tooltip.opacity,
      position: "absolute",
      left: tooltip.caretX + "px",
      top: -options.offsetY + tooltip.caretY + "px",
      "font-size": tooltip.bodyFontSize + "px",
      padding: tooltip.yPadding + "px " + tooltip.xPadding + "px",
    });
  } else {
    tooltipEl.css("opacity", 0);
  }
}

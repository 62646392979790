import Circles from "../libs/circles.min";

class CircleStat {
  constructor(
    id,
    val,
    valMax,
    color,
    statSize,
    size,
    width,
    unit,
    show_percents = false,
    second = null,
    second_unit = null,
  ) {
    this.id = id;
    this.val = val;
    this.valMax = valMax;
    this.color = color;
    this.statSize = statSize;
    this.size = size;
    this.width = width;
    this.unit = unit;
    this.show_percents = show_percents;
    this.second = second;
    this.second_unit = second_unit;

    this.htmlFromValue.bind(this);
  }

  create() {
    Circles.create({
      id: this.id,
      radius: this.size,
      value: this.val,
      maxValue: this.valMax,
      width: this.width,
      text: this.htmlFromValue(),
      colors: ["#f4f4f4", this.color],
      duration: 400,
      wrpClass: "circles-wrp",
      textClass: "circles-text",
      valueStrokeClass: "circles-valueStroke",
      maxValueStrokeClass: "circles-maxValueStroke",
      styleWrapper: true,
      styleText: true,
    });
  }

  htmlFromValue() {
    let value = this.val.toString();
    value = value.replace(",", ".");

    // if (value.length < 2)
    //     value = '0' + value;

    if (!this.statSize) this.statSize = "";

    let ret = `<div class="statnumber statnumber${this.statSize} has-color-primary-deep">`;

    ret += `<div class="has-color-primary-deep"><b class="">${value}</b><span>${this.unit}</span></div>`; // the main part

    if (this.show_percents) {
      let percent = Math.floor((100 * parseInt(this.val)) / Math.max(this.valMax, 1)); //Avoid null division
      ret += `<div class="has-color-primary-deep"><b class="statnumber-stat">${percent}</b><span>%</span></div>`;
    } else if (this.second) {
      ret += `<div class="has-color-primary-deep"><b class="statnumber-stat">${this.second}</b><span>${this.second_unit}</span></div>`;
    }

    ret += `</div>`;
    return ret;
  }
}

export default CircleStat;

let t = performance.now();
let total = 0;
let lastLabel = "page load";

export function dump_performance(label = null) {
  let new_t = performance.now();
  total += new_t - t;
  console.log(`${lastLabel} -> ${label}: ${new_t - t} millisecondes. (${total} ms)`);
  t = new_t;
  lastLabel = label;
}

import Chart from "chart.js/auto";
Chart.defaults.font.size = 14;

export const SESSION_STORAGE_DATE_START_KEY = "dateStart";
export const SESSION_STORAGE_DATE_END_KEY = "dateEnd";
export const SESSION_STORAGE__PREVIOUS_DATE_START_KEY = "previousDateStart";
export const SESSION_STORAGE__PREVIOUS_DATE_END_KEY = "previousDateEnd";

export const INVALID_FORM_FIELD_CLASS = "form-modal-input-invalid";
export const ISSUE_INPUT_ID_PREFIX = "#issue-new-value-form";

//Selector for elements that should be disabled when a particular pond is selected
export const GENERAL_ONLY_SELECTOR = "general-only";

//Selector for elements that should be displayed only if one pond has dbec
export const DBEC_ONLY_SELECTOR = "dbec-only";

//Selector for elements that should be displayed only if water reuse option is enabled
export const REUSE_ONLY_SELECTOR = "reuse-only";

export function formatNumber(number, round_decimals = false, max = 10) {
  if (typeof number == "string") number = parseFloat(number);

  if (!number || number.isNaN) return "-";

  if (round_decimals === true || Math.abs(number) > max) number = number.toFixed();
  else if (round_decimals !== false) number = number.toFixed(round_decimals);

  if (number == Infinity) return "-";

  return isNaN(number) ? "-" : number;
}

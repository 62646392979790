import { Controller } from "@hotwired/stimulus";

import cytoscape from "cytoscape";
import edgehandles from "cytoscape-edgehandles";
import fcose from "cytoscape-fcose";
cytoscape.use(edgehandles);
cytoscape.use(fcose);

export default class extends Controller {
  static values = {
    mappings: Object,
  };

  connect() {
    let elements = [];
    let categories = [];
    Object.values(this.mappingsValue).forEach((mapping, index) => {
      if (!categories.includes(mapping.entityCategory)) categories.push(mapping.entityCategory);
      elements.push({
        group: "nodes",
        data: {
          id: mapping.tagName,
          parent: mapping.entityCategory,
          ...mapping,
          color: "red",
          index: index,
        },
        classes: ["mapping"],
      });
    });
    Object.values(categories).forEach((category) => {
      elements.push({
        group: "nodes",
        data: {
          id: category,
          color: "black",
          label: category,
        },
      });
    });

    let cy = cytoscape({
      container: document.getElementById("cy"),
      elements: elements,
      style: [
        {
          selector: "node",
          style: {
            label: "data(label)",
            "text-halign": "center",
            "text-valign": "top",
          },
        },
        {
          selector: ".mapping",
          style: {
            color: "data(color)",
            "text-halign": "center",
            "text-valign": "center",
            width: "label",
            heigth: "auto",

            padding: "10px",
            shape: "rectangle",
            "background-opacity": "1",
            "text-wrap": "wrap",
            // "text-max-width": 80
          },
        },
      ],
    });
    window.core = cy;

    let options = {
      name: "grid",
      cols: 2,
      position: function (node) {
        return node.data("index") ?? 0, 1;
      },
    };
    let layout = cy.layout(options);
    layout.run();

    // the default values of each option are outlined below:
    let defaults = {
      canConnect: function (sourceNode, targetNode) {
        // whether an edge can be created between source and target
        return !sourceNode.same(targetNode); // e.g. disallow loops
      },
      edgeParams: function (sourceNode, targetNode) {
        // for edges between the specified source and target
        // return element object to be passed to cy.add() for edge
        return {};
      },
      hoverDelay: 150, // time spent hovering over a target node before it is considered selected
      snap: true, // when enabled, the edge can be drawn by just moving close to a target node (can be confusing on compound graphs)
      snapThreshold: 50, // the target node must be less than or equal to this many pixels away from the cursor/finger
      snapFrequency: 15, // the number of times per second (Hz) that snap checks done (lower is less expensive)
      noEdgeEventsInDraw: true, // set events:no to edges during draws, prevents mouseouts on compounds
      disableBrowserGestures: true, // during an edge drawing gesture, disable browser gestures such as two-finger trackpad swipe and pinch-to-zoom
    };

    let eh = cy.edgehandles(defaults);
    cy.on("ehcomplete", (event, sourceNode, targetNode, addedEdge) => {
      let { position } = event;

      layout.run();
    });

    eh.enableDrawMode();
  }
}

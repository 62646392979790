import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  nav = null;

  static get targets() {
    return ["menu", "toggle", "dropdownMenu"];
  }

  toggleDropdown(event) {
    event.preventDefault();
    const dropdownMenu = event.currentTarget.nextElementSibling;
    dropdownMenu.classList.toggle("show");
  }

  hideDropdown(event) {
    const menu = document.querySelector(".box-menu");
    const dropdownMenus = menu.querySelectorAll(".dropdown-menu");
    const target = event.target;

    if (!menu.contains(target)) {
      dropdownMenus.forEach((dropdownMenu) => {
        dropdownMenu.classList.remove("show");
      });
    }
  }

  updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf("?") !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
      return uri + separator + key + "=" + value;
    }
  }

  async toggleNav(nav_link) {
    let nav_links = $(".navigation-account-menu");

    let href_link = nav_link.attr("href");
    let tab = $(nav_link).data("tab");

    if (this.tab == tab) return;
    this.tab = tab;

    // Appliquer le style de focus sur le lien actif
    nav_links.removeClass("active");
    nav_link.addClass("active");

    const data = await $.ajax({ url: href_link });

    let content_account = $("#content_account");
    content_account.html(data);

    let _location = this.updateQueryStringParameter(location.href, "tab", tab);
    window.history.pushState("", "", _location);
  }

  connect() {
    let nav_links = $(".navigation-account-menu");
    nav_links.each((i, nav_link) => {
      $(nav_link).on("click", (event) => {
        event.preventDefault();
        this.toggleNav($(nav_link));
      });
    });

    this.toggleNav($(".navigation-account-menu.active"));
  }
}

import { Controller } from "@hotwired/stimulus";

import DataTable from "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";

import $ from "jquery";
import select2 from "select2";
select2($);

export default class extends Controller {
  static values = {
    kind: String,
    addUrl: String,
  };

  connect() {
    this.domtable = $(this.element).find(".table");

    if ($.fn.DataTable.isDataTable(this.domtable)) {
      this.domtable.DataTable().destroy();
    }

    this.domtable.addClass("display compact nowrap");

    let table = new DataTable(this.domtable, {
      stateSave: false,
      scrollCollapse: true,
      scrollX: true,
      responsive: false,
      select: true,
      autoWidth: true,
      order: [1, "asc"],
      columnDefs: [
        {
          targets: "_all",
          data: function (row, type, val, meta) {
            if (type === "set") {
              let container = $(val);
              let _val = $(val);

              if ($(val).find("input").length) {
                _val = _val.find("input");
              }

              let isInput = _val.is("input") || _val.is("select");

              let trueValue = isInput ? _val.val() : val;
              let property = container.data("property");
              if (!property) {
                return;
              }
              row[property] = trueValue;
              row[property + "_dom"] = container.removeClass("form-check-input").prop("outerHTML");

              if (!row.properties) row.properties = [];
              row.properties.push(property);
              row.name = property;
              return;
            }

            let property = row.properties?.[meta.col];
            if (!property) return;

            let dom = row[property + "_dom"];

            if (type === "display") {
              return dom;
            }

            if (type === "filter") {
              if ($(dom).is("select")) {
                return $(dom).find("option:selected").text();
              } else if ($(dom).is("input")) {
                return $(dom).val();
              }

              return $(dom).text();
            }

            return row[property];
          },
        },
      ],
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            let column = this;
            let title = column.footer().textContent;

            // Create input element
            let input = document.createElement("input");
            input.placeholder = title;
            column.footer().replaceChildren(input);

            // Event listener for user input
            input.addEventListener("keyup", () => {
              if (column.search() !== this.value) {
                column.search(input.value).draw();
              }
            });
          });

        setTimeout(() => {
          table.columns.adjust().draw();
        }, 100);
      },
    });
    this.table = table;
    table.one("draw.dt", () => {
      this.initializeSelects($("select"));
    });
    $("#table-container").removeClass("d-none");
  }

  async add(event) {
    // let row = $
    let response = await fetch(this.addUrlValue);
    let content = await response.text();

    this.table.rows.add([$(content)[0]]);
    this.table.draw();
  }

  async save(event) {}

  initializeSelects(selects) {
    var batch = 25;
    var index = 0;

    let table = this.table;
    let parent = this;
    function initBatch() {
      for (var i = 0; i < batch && index < selects.length; i++, index++) {
        $(selects[index]).select2({
          dropdownAutoWidth: true,
          width: "element",
        });
      }
      if (index < selects.length) {
        setTimeout(initBatch, 0);
      }

      if (index == selects.length) {
        table.draw();

        table.one("draw.dt", () => {
          parent.initializeSelects($("select"));
        });
      } else if (index % 100 == 99) {
        table.draw();
      }
    }
    initBatch();
  }
}

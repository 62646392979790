import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {}

  switch(event) {
    let tab_content = $(`#report-${$(event.target).data("report")}`);
    $(".nav-report-item").removeClass("btn-light").addClass("btn-dark");
    $(".nav-report-item").not(event.target).removeClass("btn-dark").addClass("btn-light");
    $(tab_content).addClass("active show");
    $(".tab-pane").not(tab_content).removeClass("active show");
  }
}

import NBase from "./NBase";
import Synoptic from "./graph";
import { getGraphIntersection } from "./utils";
import NIO from "./NIO";
import Swal from "sweetalert2";
import _ from "underscore";

export default class NComponent extends NBase {
  static get defaults() {
    return {
      opacity: 1,
      backgroundOpacity: 0,
      valign: "top",
      borderWidth: 0,
      borderStyle: "solid",
    };
  }

  constructor(params) {
    super(params);
    this.refreshAnimationId = null;
  }

  init() {
    this.entity = this.config.entity;

    // Dynamic config
    this.config["id"] = "he_" + this.entity?.global_id;

    // Create in cytoscape

    // LOAD SAVED POSITION
    let save = Synoptic.save?.[this.config.id];
    let saved_pos = {
      x: parseFloat(save?.pos?.x ?? 0.0),
      y: parseFloat(save?.pos?.y ?? 0.0),
    };
    this.config["width"] = save?.width;
    this.config["height"] = save?.height;

    // ADD TO GRAPH
    this.obj = Synoptic.add({
      group: "nodes",
      data: this.config,
      position: saved_pos,
    });

    this.obj.scratch("obj", this);
    Synoptic.addTypeToEntity(this.obj);

    this.obj
      .addClass("he")
      .addClass("interactive")
      .addClass("grid")
      .addClass(Synoptic.mode == "w" ? "removable" : "");
    this.obj.data("layout", true);

    Synoptic.cy.runningLoader += 1;
    this.setIdField();
  }

  bindActions() {
    super.bindActions();
    parent = this;

    this.obj.on(
      "drag",
      _.throttle(
        (event) => {
          this.obj.data("wasDragged", true);

          const el = getGraphIntersection(Synoptic.cy, this.obj);
          parent.draggedOverUnit = el;

          if (el) el.addClass("highlight");

          Synoptic.cy.nodes().not(el).removeClass("highlight");
        },
        100,
        { trailing: true },
      ),
    );

    this.obj.on("free", async (event) => {
      if (this.obj.data("wasDragged")) {
        this.obj.data("wasDragged", false);
        this.dragFree();
      }
    });
  }

  async dragFree() {
    const target = this.obj;
    const unit = parent.draggedOverUnit;

    // ! CREATE/ADD ENTITY INSIDE UNIT
    if (unit && target.data("_parent") != unit.id()) {
      const { isConfirmed: isConfirmed } = await Swal.fire({
        title: `Ajouter ${target.data("tooltip")} à ${unit.data("tooltip")}?`,
        showCancelButton: true,
      });

      if (isConfirmed) {
        let payload = {
          pool: Synoptic.pool_id,
          circuit: Synoptic.getCircuit(),
          unit: unit.data("entity.global_id"),
          id: target.data("entity.global_id"),
        };
        Synoptic.addEntity(payload, target.position());
        let oldParent = target.scratch("parent");
        if (oldParent) {
          oldParent.scratch("children", oldParent.scratch("children").subtract(target));
        }
        target.data("_parent", unit.id()).scratch("parent", unit);
        unit.scratch("children", unit.scratch("children").union(target));
      }
      parent.draggedOverUnit = null;
    }
  }
}

import PeriodRenderer from "../PeriodRenderer";
import { computeBarThickness, customTooltip } from "../../helpers/ChartUtilities";
import Swal from "sweetalert2";
import Chart from "chart.js/auto";
import { formatNumber } from "../../helpers/utils";
import $ from "jquery";
import { DateTime } from "luxon";

class RegulationPackPeriodRenderer extends PeriodRenderer {
  constructor(
    container = $(".ajax-periods"),
    detailStart,
    detailEnd,
    isPopup = false,
    index_active,
    indexSwitchPeriodicity = null,
  ) {
    super(container, detailStart, detailEnd, isPopup, indexSwitchPeriodicity);
    if (isPopup) {
      //pass the param index_active indicator when zoomed in chart
      this.index_active = index_active;
    } else {
      //index_active by default
      this.index_active = "0";
    }
    //sum is attendance here
    this.showSum = true;
    this.totalLabel = "Fréquentation";

    this.unit = "mg/L";
    this.container_id = "js-regulationpack-chloraminecurves";
    this.regulationMainGraph = container.find(".js-regulationpack-histogram");

    this.chloraminesCurveChart = null;

    this.dateLabels = [];

    // TODO: replace with indicators

    this.datasets = {
      hist: {
        attendance: {
          values: [],
          color: this.colors.other_transparent,
          label: "Fréquentation",
        },
      },
      curves: [],
    };

    this.tooltips = {
      hist: [],
      curves: [],
    };

    var context = this;
    $(".consumptionsummary-element").on("click", function () {
      let i = $(this).attr("id").split("--").pop();
      let btn = $(".consumptionsummary-element");
      // btn.css({
      //   "pointer-events": "none",
      //   "background-color": "#AAAAAA",
      // });
      const promise = new Promise((resolve, reject) => {
        context.index_active = `${i}`;
        context.render();
        resolve();
      })
        .then(
          () => {
            // btn.css("pointer-events", "");
            // btn.css({
            //   "pointer-events": "",
            //   "background-color": "whitesmoke",
            // });
          },
          (reason) => console.log(reason),
        )
        .catch((e) => {
          console.log(e);
        });
    });

    if (this.isPopup) this.date_manager.switchPeriodicity(indexSwitchPeriodicity);
  }

  //Overwriting

  // render() {
  //   super.render();
  // }

  updateChartsData(res) {
    const data = res.data;
    const helper = res.helper;

    var context = $(".js-regulationpack-histogram").get(0).getContext("2d");

    let color_water = context.createLinearGradient(0, 0, 0, 300);
    color_water.addColorStop(0, this.colors.gradient_green);
    color_water.addColorStop(1, this.colors.transparent_green);

    // Add indicators to datasets
    for (let index in helper.indicators) {
      let indicator = helper.indicators[index];
      let color_reg = context.createLinearGradient(0, 0, 0, 250);
      color_reg.addColorStop(0, this.indicator_colors[Math.min(3, parseInt(index))]);
      color_reg.addColorStop(1, this.indicator_colors_transparent[Math.min(3, parseInt(index))]);

      this.datasets.curves[index] = {
        values: [],
        label: indicator.label + " (saisie)",
        abbreviation: indicator.abbreviation,
        color: color_reg,
        isEnabled: index === this.index_active,
        unit: indicator.unit,
        type: "line",
        yAxisID: "y1",
        order: 2,
      };
      this.datasets.curves[helper.indicators.length + parseInt(index)] = {
        values: [],
        label: indicator.label + " (mesure)",
        abbreviation: indicator.abbreviation,
        color: color_water,
        isEnabled: index === this.index_active,
        type: "line",
        unit: indicator.unit,
        yAxisID: "y1",
        isComparative: true,
        order: 1,
      };
    }

    let indicators = res.helper.indicators;
    this.updateDateLabels();

    for (let datum of Object.values(data)) {
      this.datasets.hist.attendance.values.push(datum.total);

      for (let ii in indicators) {
        ii = parseInt(ii);
        let indicator = indicators[ii];
        this.datasets.curves[ii].values.push(datum[indicator.reference]);
        let comp_reference = this.getComparative(indicator.reference);
        this.datasets.curves[helper.indicators.length + ii].values.push(datum[comp_reference]);
      }
    }

    for (let index in this.datasets.curves) {
      this.tooltips.curves[index] = "NC";
    }
  }

  buildCharts(response) {
    this.defaultTooltips(response);
    this.displayLabels(response);
    let parent = this;

    $(".chart-title").html("Evolution de " + response.helper.indicators[this.index_active].label);

    // Set indicators background color for inituitive clicking
    let other_elements = $(`.consumptionsummary-element`).not(`.consumptionsummary-element--${this.index_active}`);
    other_elements.removeClass("active");
    $(`.consumptionsummary-element--${this.index_active}`).unbind("mouseenter mouseleave").addClass("active");

    // Set datasets datapoint aspect
    let points = {
      mesure: new Image(),
      input: new Image(),
    };
    points.input.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABHNCSVQICAgIfAhkiAAAAqVJREFUOI2dlMtPU1EQh79zepFqWqVIAsVEeSzkEVNIiKALUDQkunCjCxa6IwYjK/k7WIIJ0ZUbTXShIS4M4ivhFRJoMIgCqWxoNYZCaEKh5Y4L7imlPFqY3fndmS8zc+75KbJE4+B0rVJSh0ixQkSU/qu3ZHK4LTBzWJ3aT7z49Yvbt+HrQkknUHlA7YJonq5YK70/m1viWcFNQ1NN2OoVcD7bNE4soqV9tLVu9EBw41DwnrJ5AbiN5rVcNBR6KD55AoA/65tMLMdYS26ll8ZF82CsNfB6D/jqh+AVWzNkoL58i84KP7f9BVhq92BJEQbCUfrnI0QTyRQcLddN5wqcnW4WzAIXACo9bnoC5RS78w7dQTieoDsYIhRLrfh30aq3auBuxYYG8G34ugzUl2/lBAXwu/PoCZTjy7OMVPbvzNpjAL3dtjw0Xzor/DlB0+EdlSWps4JHAKpxcLpWYX+H7Yt631yzZ6fZImkLt77NEHMu1LakRruQgEloKPQcGQpgaUVDoWena1sFtC2UGqH01IkjQ1O17l2157S4RMxJ7GNzsdMGVTai1RZLRgjHN48Njqzv1IpmSWshaISJ5RjJnQFyjqQtTCzH0sAS1I5L/QJYS24xEI4eGfw2HE39EQrmx6/V/dBO6/0mqX8+QjieyBkajid4vhBJU6QPnAeyYq30AosA0USS7mAoJ7h50ml+ETq7errP6Xw7HLv8hDGhPIuOyhLu+H1YOsOEbOFdOMqzhd0mpGzdMtJ2aXwXGPa3TY9jmyWObUYc24xl2KaC+yM3A2+MsOeZNXycbLREvwTKsu7CGV/Zut10akJnZk3cqB8rWvVWAd1KmDuIpoQ5hTwpWvVWZ0L37TgzLn+eqtYJVaeUlACIqIh22ZPDrfWzh9X9BzRGDv6sluwfAAAAAElFTkSuQmCC";
    points.mesure.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABHNCSVQICAgIfAhkiAAAApdJREFUOI2dlM1L1EEYxz/zW3XV8IVUTLfSSKXag3qyLoUS5lFqfcMCT2LkHyDkLQz/ARU8BRX5sgseRUzrVl1ESBPbMAvXbDVcRd11d2c6/Bz8ma679tye78zzmZlnZr6CeDHZ4kTJChT5KKEw1G+UnKF2dP60MnGi6m5LJSPYiVAdwNUYtd8QaoBAeh+uF8H44PGmmxgMA5fjnsaMZSTN1A1/iA2eaHaBegmkaqkw+Rz1uU4caecBWNn7w9j6HL7wjrUyCOIRtUPu4+CJhltgTGloSUomPWWN3M+vJsmwHVk/IqN41qbpXhzBu791CJdU652bYHdbKpl7C0ARQE2Gg1eVXRTY807tgS/kp3Wml3fbK1r6TlbgGlXjIQOAjGCnhpakZCYEBSi05/G6souSlEwtFRPIegJggoVq1yM9ZY0JQa3wZ6Uuq/QYQDDZ4kTKz2Be1PLtwWM9jRdhFcHxvh1/eNcUJDcMpCrXE+pznWeGAiSLJFw5zkPBRrmBolDnl9JyzwzVUZRmbZ9yGBhS6VSoEyoSjKiQFq5QBhKfzn8E1/8b/HPXUivwGQg1q/Ox9TkiMnpmaFhF8GxYPCnKrHHgUosAvvAOnrXpM4NHV98evgjwUjf8Rb/jQa12L47gC/kThvpCfrq97kNBiX7QHySQ3gcsA3j3t2id6U0Irr/0UmhbS0tkb/aD1YRMu5zmwISu2DN4XtLAg4IakkXSEWBYRXCvTvHUO2qFBkHeoXb001EwnGibecnpuHKcXEzPAczb92zMW3tqQpV6yL0RjxaOG/1kcxVSDQHFcXuhj49s1juNDQb4WGc/cKkOoDQG8CtKDJC92U/VeOjfwZPB1hhvuo5QFWBcMAX5C5tthrtvFk4r+wtZd/XuDTaRHQAAAABJRU5ErkJggg==";

    // MAIN CHART

    // Main dataset
    let chart_datasets = [];
    for (let k in this.datasets.curves) {
      let dataset = this.datasets.curves[k];
      chart_datasets.push(
        {
          label: dataset.label,
          backgroundColor: dataset.color,
          data: dataset.values,
          yAxisID: dataset.yAxisID,
          type: dataset.type,
          order: dataset.order,
          isEnabled: dataset.isEnabled,
          pointBackgroundColor: [],
          pointBorderColor: [],
          fill: true,
          cubicInterpolationMode: "monotone",
          tension: 0.4,
          pointBackgroundColor: function (context) {
            let threshold = response.helper.indicators[parent.index_active].threshold;
            let len = response.helper.indicators.length;
            // if (parent.index_active == context.datasetIndex || parent.index_active + len == context.datasetIndex) {

            let color = null;
            var index = context.dataIndex;
            var value = context.dataset.data[index];
            if (threshold.isTwoWay) {
              color =
                value >= threshold.max || value <= threshold.min
                  ? parent.colors.alert // draw negative values in red
                  : value >= threshold.threshold2 || value <= threshold.threshold1
                    ? parent.colors.warning // else, alternate values in blue and green
                    : dataset.color;
            } else {
              color =
                value >= threshold.max
                  ? parent.colors.alert // draw negative values in red
                  : value >= threshold.threshold2
                    ? parent.colors.warning // else, alternate values in blue and green
                    : dataset.color;
            }

            context.dataset.pointBorderColor[context.dataIndex] = color; // border
            return "white"; //background
          },
        },
        // }
      );
    }
    // Attendance dataset
    let attendanceDataset = {
      data: this.datasets.hist.attendance.values,
      label: this.datasets.hist.attendance.label + "#rounded",
      // This binds the dataset to the right y axis
      yAxisID: "attendance_axis",
      type: this.datasets.hist.attendance.type,
      order: 3,
      backgroundColor: this.datasets.hist.attendance.color,
      hidden: true,
      borderRadius: 10,
      borderWidth: 1,
    };

    // Thresholds dataset
    let limits = {
      borderWidth: 2,
      pointRadius: 0,
      fill: false,
      backgroundColor: "transparent",
      borderDash: [5, 5],
      pointStyle: null,
      pointRadius: 0,
      pointHitRadius: 0,
      order: 4,
    };
    let current_threshold = response.helper.indicators[this.index_active].threshold;

    let warning_limit_max = Object.assign(
      {
        label: "Seuil de danger",
        type: "line",
        yAxisID: "y1",

        borderColor: !current_threshold.isTwoWay ? this.colors.alert : this.colors.alert,
        data: Array(this.dateLabels.length + 1).fill(current_threshold.max),
      },
      limits,
    );

    let good_limit_max = Object.assign(
      {
        label: "Seuil d'alerte",
        type: "line",
        yAxisID: "y1",

        borderColor: !current_threshold.isTwoWay ? this.colors.warning : this.colors.warning,
        data: Array(this.dateLabels.length + 1).fill(current_threshold.threshold2),
      },
      limits,
    );

    let good_limit_min = Object.assign(
      {
        label: "#hide alerte",
        type: "line",
        yAxisID: "y1",
        borderColor: !current_threshold.isTwoWay ? "#00000000" : this.colors.warning,
        data: Array(this.dateLabels.length + 1).fill(current_threshold.threshold1),
      },
      limits,
    );

    let warning_limit_min = Object.assign(
      {
        label: "#hide danger",
        type: "line",
        borderColor: !current_threshold.isTwoWay ? "#00000000" : this.colors.alert,
        data: Array(this.dateLabels.length + 1).fill(current_threshold.min),
        yAxisID: "y1",
      },
      limits,
    );

    // Tooltip rendering
    let customCurvesTooltipsOptions = {
      tooltip_id: "chartjs-tooltip-regulation",
      container_id: "js-regulationpack-histogram",

      getTitle: (titleLines) => {
        let title = $("<p>", {
          class: "has-light-grey-border-bottom p-2",
        });
        let title_content = "";

        titleLines.forEach((title, i) => {
          let date_split = title.split(",");
          let date = this.date_manager.isWeekPeriodicity ? date_split[1] : date_split[0];
          title_content += date;
        });

        title.text(title_content);

        return title;
      },
      getBody: () => {
        let body = $("<div/>", {
          class: "d-flex justify-content-around flex-wrap align-items-stretch",
        });

        let content = `
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">saisies</th>
                                <th scope="col">mesures</th>
                            </tr>
                        </thead>
                    <tbody>`;

        let indicators = response.helper.indicators;
        for (let i in indicators) {
          // .filter(tooltip => tooltip.hide != true)
          let dataset = this.datasets.curves[i];
          let tooltip = this.tooltips.curves[i];
          content += `
                    <tr style="background-color: ${dataset.isEnabled ? "FAF7B7" : ""};">
                    <th scope="row">
                        ${dataset.abbreviation}
                    </th>
                    <td class="chartjs-tooltip-element-regulation">
                        <a style="color: blue;">
                        ${formatNumber(tooltip, 2)}
                        </a>
                    </td>
                    `;
          dataset = this.datasets.curves[indicators.length + parseInt(i)];
          tooltip = this.tooltips.curves[indicators.length + parseInt(i)];
          content += `
                    <td class="chartjs-tooltip-element-regulation">
                        <a style="color: green;">
                        ${formatNumber(tooltip, 2)}
                        </a>
                    </td>
                    </tr>
                    `;
        }
        content += "</tbody></table>";
        body.html(content);

        if (this.showSum) {
          $("<div>", {
            class: "has-light-grey-border-top p-2 w-100 text-center",
          })
            .html(
              "<strong>" +
                this.totalLabel +
                " : </strong>" +
                formatNumber(this.tooltipSum, true) +
                `<p><i style="opacity:0.5;font-size:0.8em">*cliquez pour voir le détail</i></p>`,
            )
            .appendTo(body);
        }

        return body;
      },
    };

    var newLegendClickHandler = function (e, legendItem) {
      var index = legendItem.datasetIndex;
      var ci = this.chart;
      var meta = ci.getDatasetMeta(index);
      let currentDataset = ci.data.datasets[index];

      // See controller.isDatasetVisible comment
      meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

      if (legendItem.text.includes("Fréquentation")) {
        this.chart.scales.attendance_axis.options.display = legendItem.hidden;
        // this.chart.scales.attendance_axis.options.title.display = legendItem.hidden;
        this.chart.scales.x.options.offset = legendItem.hidden;
      }

      ["danger", "alerte"].forEach((tag) => {
        if (legendItem.text.includes(tag)) {
          for (let i = 0; i < ci.data.datasets.length; i++) {
            let dataset = ci.data.datasets[i];
            var meta = ci.getDatasetMeta(i);
            if (dataset.label.includes(tag)) {
              if (currentDataset != dataset) {
                meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
              }
            }
          }
        }
      });

      // We hid a dataset ... rerender the chart
      ci.update();
    };
    let displayDetails = function (e) {
      //check if its hours so return
      const regex = /h00$/;
      if (parent.dateLabels.some((str) => regex.test(str))) {
        return;
      }

      let dateClicked = this.tooltip.title; //['lun. 29/08,S36, (4 jours)']
      let dateNextClicked = "";
      let index = 0;
      let pack = this.renderer.dataStore._pack;
      let dataRendered = this.renderer.tooltips;
      let indicators = this.renderer.indicators;

      //return key of matching arr date_clicked and arr all dates (all periods in ISO)
      this.renderer.dateLabels.forEach((dateLabel, k) => {
        if (dateClicked[0] == dateLabel) {
          return (index = k);
        }
      });

      //return the matching date ISO with the index of periods array
      dateClicked = DateTime.fromISO(parent.helper.periods[index]["start"]);
      dateNextClicked = DateTime.fromISO(parent.helper.periods[index]["end"]);

      $.ajax({
        url: `/pack/regulation/details/pool/${this.renderer._poolId}/pond/${this.renderer._pondId}`,
        method: "GET",
        data: {
          date: dateClicked.toISO(),
          dateNext: dateNextClicked.toISO(),
          titleDate: this.tooltip.title[0],
          pack: pack,
          indicator: this.renderer.indicators?.[this.renderer.index_active],
          dataRendered: dataRendered,
          indicators: indicators,
        },
      }).then((response) => {
        Swal.fire({
          html: response,
          width: "85%",
          confirmButtonColor: "rgb(40, 163, 179)",
          showCloseButton: true,
        });
      });
    };
    // Create main chart
    this.chloraminesCurveChart = new Chart(this.regulationMainGraph, {
      type: "bar",
      data: {
        labels: this.dateLabels,
        datasets: [
          ...chart_datasets.filter((dataset) => dataset.isEnabled),
          attendanceDataset,
          warning_limit_min,
          warning_limit_max,
          good_limit_min,
          good_limit_max,
        ],
      },
      options: {
        onClick: displayDetails,
        responsive: true,
        maintainAspectRatio: false,
        layout: this.defaultLayout,
        elements: this.defaultElements,
        scales: {
          y1: {
            position: "left",
            type: "linear",
            title: {
              text: this.datasets.curves[this.index_active].unit,
            },
            beginAtZero: false,
          },
          attendance_axis: {
            position: "right",
            display: false,
            title: "Baigneurs",
            beginAtZero: true,
          },
          x: {
            display: true,
            stacked: true,
            offset: 0,
            barThickness: computeBarThickness(this.datasets.hist.attendance.values.length),
            grid: {
              display: false,
            },
          },
        },

        labels: { display: true },

        plugins: {
          tooltip: {
            mode: "nearest",
            intersect: false,
            enabled: false,
            callbacks: {
              beforeFooter: (tooltipItems) => {
                for (let index in this.datasets.curves) {
                  this.tooltips.curves[index] = parseFloat(
                    this.datasets.curves[index].values[tooltipItems[0].dataIndex],
                  );
                }
                this.tooltips.hist[0] = parseFloat(this.datasets.hist["attendance"].values[tooltipItems[0].dataIndex]);
              },
            },
            external: (tooltip_model) => {
              customTooltip(tooltip_model, customCurvesTooltipsOptions);
              return tooltip_model;
            },
          },
          legend: {
            onClick: newLegendClickHandler,
            display: true,
            labels: {
              filter: function (legendItem, data) {
                legendItem.text = legendItem.text.replace("#rounded", "");
                return !legendItem.text.includes("#hide");
              },
            },
          },
        },
      },
    });
    //affect attribute renderer to accces this in RegulationackPeriodRenderer.js
    this.chloraminesCurveChart.renderer = this;
    // for (
    //   let i = 0;
    //   i < this.chloraminesCurveChart.scales.attendance_axis.ticks.length;
    //   i++
    // ) {
    //   this.chloraminesCurveChart.scales.attendance_axis.ticks[i] = null;
    //   this.chloraminesCurveChart.scales.attendance_axis.ticksAsNumbers[i] =
    //     null;
    // }
    this.chloraminesCurveChart.update();

    this.animate($(`.consumptionsummary-element--${this.index_active}`));
  }

  emptyChartsData() {
    this.dateLabels = [];
    this.datasets.curves = [];
    for (let j in this.datasets.hist) {
      this.datasets.hist[j].values = [];
    }
  }

  destroyCharts() {
    super.destroyCharts();

    if (this.chloraminesCurveChart) this.chloraminesCurveChart.destroy();
  }

  static get pack_name() {
    return "regulation";
  }

  static get element_switch_enabled() {
    return true;
  }

  get tooltipSum() {
    return this.tooltips.hist[0];
  }

  getComparative(regulation_label) {
    let water_reference = "";
    switch (regulation_label) {
      case "regulation_chloramine":
        water_reference = "water_chloramine";
        break;
      case "regulation_active_chlore":
        water_reference = "water_active_chlore";
        break;

      case "regulation_temperature_pond":
        water_reference = "water_temperature_pond";
        break;
      case "regulation_ph":
        water_reference = "water_ph";
        break;
      case "regulation_free_chlore":
        water_reference = "water_free_chlore";
        break;

      default:
        return null;
    }
    return water_reference;
  }

  get target() {
    return "pond";
  }
}
export default RegulationPackPeriodRenderer;

import AbstractDataStore from "./AbstractDataStore";
import $ from "jquery";

const MEASURES_DATA_TYPE = "measures";
const PERIODS_DATA_TYPE = "periods";

class RenderDataStore extends AbstractDataStore {
  constructor(default_route, pack, pool, pond, circuit, client, periodicity) {
    super(default_route, pack, pool, pond, circuit, client);

    this._periodicity = periodicity;

    this.loadData = this.loadData.bind(this);

    this.requests = [];
  }

  // Single function for all data loads

  /**
   * Query data from server and return a promise with received data
   *
   * @param route the route we want to query, if null will be the default one
   * @param periodicity periodicity of the data, if null will be the default ones,
   *                    if false will not ask for any periodicity
   * @returns {Promise<any> | Promise}
   */
  loadData(route = null, periodicity = null, dateStart = null, dateEnd = null) {
    this.requests
      .filter((element) => element.readyState == 1)
      .forEach((request) => {
        try {
          request.abort();
        } catch (e) {
          console.log(e);
        }
      });
    this.requests = this.requests.filter((element) => element.readyState == 1);

    dateStart ??= this.dateStart;
    dateEnd ??= this.dateEnd;

    return new Promise((resolve, reject) => {
      this.requests.push(
        $.ajax({
          url: this.generateUrl(route, periodicity),
          data: {
            date_start: dateStart.toISO(),
            date_end: dateEnd.toISO(),
            force_form_values: this.forceFormValues || false,
          },
          success: (res) => {
            resolve(res);
            if (res.status === "KO") {
              console.warn(res.message);
              $(".consumptionsummary-error").addClass("d-flex");
            } else {
              $(".consumptionsummary-error").removeClass("d-flex");
            }
          },
          error: reject,
          abort: reject,
          complete: () => {
            // Ajax loader remove in each pack's promise (buildCharts function) to avoid it to disapears before charts update
            window.setTimeout(() => {
              let circlesText = $(".circles-text p b");
              circlesText.each(function () {
                let str = $(this).text().replace(/\./g, ",");
                $(this).text(str);
              });
            }, 1000);
          },
        }),
      );
    });
  }

  loadPreviousData(route = null, periodicity = null, dateStart, dateEnd) {
    return this.loadData(route, periodicity, dateStart, dateEnd);
  }

  /**
   * Load the measures values for the current configuration
   *
   * @returns {*|Promise<any>|Promise|Promise|Promise}
   */
  loadMeasures() {
    return this.loadData(MEASURES_DATA_TYPE);
  }

  /**
   * Load the current periods for the current configuration
   *
   * @returns {*|Promise<any>|Promise|Promise|Promise}
   */
  loadPeriods() {
    return this.loadData(PERIODS_DATA_TYPE, this._periodicity);
  }

  get dateStart() {
    return this._dateStart;
  }
  set dateStart(dateStart) {
    this._dateStart = dateStart;
  }

  get dateEnd() {
    return this._dateEnd;
  }
  set dateEnd(dateEnd) {
    this._dateEnd = dateEnd;
  }

  set periodicity(periodicity) {
    this._periodicity = periodicity;
  }
}
export default RenderDataStore;

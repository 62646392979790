import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import Synoptic from "@js/pages/hippo-local/graph";
import $ from "jquery";

export default class extends Controller {
  static values = {
    poolId: Number,
  };

  container = $("#cy-data");

  initialize() {
    const svgs = import.meta.glob("@images/icons/hydraulic/*.svg", {
      eager: true,
      query: "?raw",
      import: "default",
    });
    let final_svgs = {};
    for (const [origin, content] of Object.entries(svgs)) {
      const oldpath = `/build/app/images/${origin.split("/").pop().slice(0, -4)}`;
      final_svgs[oldpath] = { content: content };
    }
    this.svgs = final_svgs;
  }

  connect() {
    this.setupSynoptic();
  }

  async save() {
    Swal.fire({
      title: "Sauvegarde en cours...",
      position: "top-end",
      showConfirmButton: false,
      icon: "warning",
      toast: true,
      timer: 2000,
    });
    Swal.stopTimer();

    let error = null;
    try {
      const result = await this.synoptic.save();
      console.log(result);
      Swal.update({
        title: "Sauvegarde effectuée",
        icon: "success",
      });
    } catch (e) {
      error = e;
    }
    if (null !== error) {
      Swal.update({
        title: "Sauvegarde echouée",
        icon: "error",
      });
    }
    Swal.resumeTimer();
  }

  async setupSynoptic() {
    this.synoptic = new Synoptic(this.container.data("pool"), this.container.data("mode"), $("#cy"), this.svgs);
    await this.synoptic.init();

    let handleAction = async (event, ctx, action) => {
      console.log(ctx, action);
      $(event.target).prop("disabled", true);
      try {
        await ctx[action](event.target);
      } catch (e) {
        console.log(e);
      } finally {
        $(event.target).prop("disabled", false);
      }
    };

    this.synoptic.update();

    $("#cy-nav-update").on("click", async (event) => await handleAction(event, this.synoptic, "reload"));
    $("#cy-nav-download").on("click", async (event) => await handleAction(event, this.synoptic, "download"));
    $("#cy-nav-load").on("click", async (event) => await handleAction(event, this.synoptic, "load"));
    $("#cy-nav-auto").on("click", async (event) => await handleAction(event, this.synoptic, "layout"));
    $("#cy-nav-fit").on("click", async (event) => await handleAction(event, this.synoptic, "fit"));
    $("#cy-nav-toggle").on("click", async (event) => await handleAction(event, this.synoptic, "toggleUnitsDisplay"));
    // $("#cy-nav-collapse").on("click", async (event) => await handleAction(event, this.synoptic, "collapseExpandAll"));
    $("#cy-nav-lock").on("click", async (event) => await handleAction(event, this.synoptic, "toggleLock"));

    $("#layer-select, #circuit-select")
      .off()
      .on("change", async (event) => {
        let circuit = $("#circuit-select").val();
        let new_loc = location.href.replace(/(.*hippo_local\/[0-9]+\/circuit\/)([0-9]+)(.*)/, `$1${circuit}$3`);
        window.history.replaceState(null, null, new_loc);

        this.synoptic = new Synoptic(this.container.data("pool"), this.container.data("mode"), $("#cy"), this.svgs);
        await this.synoptic.init();
        await this.synoptic.update();
      });
  }

  disconnect() {
    this.synoptic.delete();
    $(".nodeTooltip").remove();
  }

  switchOperation(event) {
    $(event.target).toggleClass("btn-success btn-secondary");
    this.synoptic.toggleSelectionMode($(event.target).hasClass("btn-success"));
  }

  closeForm(event) {
    $("#cy-ui-form").hide();
    $("#cy-ui-form-content").attr("src", "about:blank");
  }

  async send(event) {
    const { value: accept } = await Swal.fire({
      title: "Envoi d'une configuration sur site",
      // input: "text",
      // inputValue: "",
      // inputPlaceholder: "Change destination",
      confirmButtonText: 'Continue <i class="fa fa-arrow-right"></i>',
      showCancelButton: true,
      // cancelButtonText: '<i class="fa fa-cross"></i>',
      // inputValidator: (result) => {
      //   let ipv4_regex = /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm;
      //   condition = !result || ipv4_regex.test(ipv4_regex);

      //   return !condition && "IP non valide";
      // },
    });

    if (accept) {
      const toast = Swal.mixin({
        timerProgressBar: true,
        toast: true,
        position: "top-end",
        timer: 3000,
        showConfirmButton: false,
        showClass: { backdrop: "swal2-noanimation" },
        hideClass: { backdrop: "swal2-noanimation" },
      });

      let cpuConfigs = await $.ajax({ url: `/pool/${this.poolIdValue}/structure/presend` });

      let failed = [];
      let success = [];
      toast.fire({
        timer: null,
        didOpen: async () => {
          Swal.showLoading();
        },
      });
      for (const [cpuId, config] of Object.entries(cpuConfigs)) {
        for (const [filename, content] of Object.entries(config.queue)) {
          toast.update({
            title: `Envoi du fichier  sur ${config.cpu.name}`,
            text: `${filename}`,
          });

          const response = await $.ajax({
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            url: `/pool/${this.poolIdValue}/structure/sendone`,
            data: JSON.stringify({ data: { [filename]: content }, cpu: config.cpu.id }),
          }).then(
            (data) => {
              if (Object.values(data.response).some((status) => status == "NOK")) {
                failed.push({ filename: filename, cpu: config.cpu.name });
                Swal.showValidationMessage(`echec: ${config.cpu.name}:${filename}`);
                return;
              }
            },
            () => failed.push({ filename: filename, cpu: config.cpu.name }),
          );
        }
      }

      console.log(failed);
      if (failed.length == 0) {
        Swal.close();
        Swal.fire({
          title: "Configuration Envoyée",
          icon: "success",
        });
      } else {
        Swal.close();
        Swal.fire({
          title: "Erreur d'envoi",
          icon: "error",
          width: "auto",
          html: `<div data-controller="json-viewer">${JSON.stringify(failed)}</div>`,
        });
      }
    }
  }

  selectOperationPath(event) {
    $("#cy-nav-operations button").css("background-color", "");

    let target = null;
    if ($(event.target).data("operation")) target = $(event.target);
    else target = $(event.target).parents("button");

    this.synoptic.toggleOperationPathSelect(target.data("operation"));
  }

  filter(event) {
    this.synoptic.filter($(event.target).val());
  }

  correct(event) {
    this.synoptic.correct();
  }

  magic(event) {
    this.synoptic.defaultPositioning();
  }
}

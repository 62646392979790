export default class MouseListener {
  constructor(element) {
    this.element = element;
    this.pos = null;
    this.callback = null;
  }

  start() {
    this.element.off("mousemove");

    this.id = setInterval(() => {
      if (this.callback) {
        this.callback(this.pos);
      }
    }, 10);

    this.element.on("mousemove", (event) => {
      this.pos = {
        x: event.pageX - this.element.offset().left,
        y: event.pageY - this.element.offset().top,
      };
    });
  }

  stop() {
    this.element.off("mousemove");
    clearInterval(this.id);

    this.pos = null;
  }

  getPos() {
    return this.pos;
  }
}

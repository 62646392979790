const MEASURES_DATA_TYPE = "measures";
const PERIODS_DATA_TYPE = "periods";

class AbstractDataStore {
  constructor(default_route, pack, pool, pond, circuit, client) {
    this._default_route = default_route;
    this._pack = pack;
    this._pool = pool;
    this._pond = pond;
    this._circuit = circuit;

    this._client = client;
    this.target = "pond";
  }

  generateUrl(route, periodicity = null) {
    route = route ? route : this._default_route;

    let url = `/pack/${this._pack}/pool/${this._pool}`;
    //If pond is NaN this test wil fail

    url += this._circuit ? "/circuit/" + this._circuit : "";
    url += this._pond ? "/pond/" + this._pond : "";
    url += "/" + route;
    url += periodicity ? "/" + periodicity : "";

    return url;
  }

  set pond(pond) {
    this._pond = parseInt(pond);
  }

  set circuit(circuit) {
    this._circuit = parseInt(circuit);
  }
}

export default AbstractDataStore;

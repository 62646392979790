import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import { datePickers } from "@js/helpers/datepickers";
import IssueNewMeasureForm from "../../js/helpers/IssueNewMeasureForm";
import $ from "jquery";
import { DateTime } from "luxon";

export default class extends Controller {
  connect() {
    const pikadayElements = datePickers(DateTime.local(), ["issue"]);

    let issueRenderer = new IssueNewMeasureForm(pikadayElements, $("#issue-new-form-modal"));
    issueRenderer.init();
  }
}

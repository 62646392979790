import RenderDataStore from "../entity/RenderDataStore";
import DateManager, { BASE_DATETIME_FORMAT } from "../helpers/DateManager";
import {
  SESSION_STORAGE__PREVIOUS_DATE_START_KEY,
  SESSION_STORAGE__PREVIOUS_DATE_END_KEY,
  GENERAL_ONLY_SELECTOR,
} from "../helpers/utils";
import $ from "jquery";
import { DateTime } from "luxon";

//TODO
// This class and each subclasses are temporary. It is only a way to stay close enough to ZOL version
// to have a working version after a first refactoring, but this is not factorized enough
// Later these class will  each follow the AbstractPackRenderer structurer (such as the water pack)
// Because the Measure and Period separation like this is too much, periods and measures
// shares a lot of stuff such as dates, events etc

class AbstractRenderer {
  /**
   * constructor
   */

  constructor(container, detailStart, detailEnd, isPopup = false, indexSwitchPeriodicity) {
    this.isPopup = isPopup;
    this.indexSwitchPeriodicity = indexSwitchPeriodicity;
    this.container = container;
    this.show_loaders();
    this.detailStart = detailStart;
    this.detailEnd = detailEnd;
    //Rendering references
    this.colors = {
      light: "rgba(116,221,239,1)",
      light_transparent: "rgba(116,221,239, 0.45)",
      normal: "rgba(3,134,156,1)",
      normal_transparent: "rgba(3,134,156, 0.45)",
      dark: "rgba(0,75,88,1)",
      dark_transparent: "rgba(0,75,88, 0.45)",
      other: "rgb(202,191,205)",
      other_transparent: "rgba(202,191,205, 0.45)",
      gradient_blue: "rgba(67,190,206,0.86)",
      transparent_blue: "rgba(67,190,206,0)",
      gradient_green: "rgba(61, 166, 67, 0.86)",
      transparent_green: "rgba(61, 166, 67, 0)",

      disabled: "#D8D8D5",
      valid: "rgb(96, 169, 11)",
      warning: "rgb(255, 160, 4)",
      alert: "rgb(255, 70, 0)",

      issue: "rgb(255, 131, 51)",
    };

    this.indicator_colors = [this.colors.normal, this.colors.dark, this.colors.light, this.colors.other];

    this.indicator_colors_transparent = [
      this.colors.normal_transparent,
      this.colors.dark_transparent,
      this.colors.light_transparent,
      this.colors.other_transparent,
    ];

    this.evolution_arrows = {
      down_red: "statnumber--downred",
      down_green: "statnumber--downgreen",
      up_red: "statnumber--upred",
      up_green: "statnumber--upgreen",
    };

    this.switchCircuit = this.switchCircuit.bind(this);
    this.switchPond = this.switchPond.bind(this);

    this.locale = "fr-FR";
    this.default_decimals_rounding = 0;

    //Page parameters
    let target = this.target;
    let body = $("body");
    this.poolId = body.data("pool");

    let switcher = this.switchPond;
    if (target == "circuit") switcher = this.switchCircuit;

    this._switch_element_callback = [];
    if (this.constructor.element_switch_enabled) {
      this.elementSelectorItem = $(".js-pond-nav-item");

      if (this.elementSelectorItem.any()) {
        for (let i = 0; i < this.elementSelectorItem.length; i++) {
          this.elementSelectorItem[i].addEventListener(
            "click",
            () => {
              switcher(this.elementSelectorItem[i]);
              this.render();
            },
            false,
          );
        }
        if (!this.isPopup) {
          let firstelementSelectorItem = this.elementSelectorItem.get(0);
          switcher(firstelementSelectorItem);
        }
      }
    }

    this.clientId = body.data("client");
    this.selectedPeriodicity = null;

    //This is an array used to keep a list of each datastores to update when changing pool, dates...
    this._dataStoresReferences = [];

    this.dataStore = new RenderDataStore(
      this.constructor.renderer_type,
      this.constructor.pack_name,
      this.poolId,
      this.elementId,
      this.circuitId,
      this.clientId,
      this.selectedPeriodicity,
    );

    //Dates manager
    this.date_manager = new DateManager(this.isPopup);
    if (this.detailStart) {
      this.date_manager._date_start = this.detailStart;
      this.date_manager._date_end = this.detailEnd;
      this.dataStore.dateStart = this.detailStart;
      this.dataStore.dateEnd = this.detailEnd;
      this.date_manager.addDateCallback(this.refreshDataStoresConfiguration.bind(this));
      this.date_manager.addPeriodicityCallback(this.refreshDataStoresConfiguration.bind(this));
      this.registerDataStore(this.dataStore);
    } else {
      this.date_manager.addDateCallback(this.refreshDataStoresConfiguration.bind(this));
      this.date_manager.addPeriodicityCallback(this.refreshDataStoresConfiguration.bind(this));
      this.registerDataStore(this.dataStore);
    }

    //Methods bind
    this.init = this.init.bind(this);

    this.refreshDataStoresConfiguration = this.refreshDataStoresConfiguration.bind(this);

    this.render = this.render.bind(this);

    this.refreshGeneralState = this.refreshGeneralState.bind(this);

    //Render utilities
    this.getEvolutionArrow = this.getEvolutionArrow.bind(this);

    this.registerDataStore = this.registerDataStore.bind(this);

    // Display reference year
    let context = this;

    let toggleButtons = (state) => {
      $("#date-ref-plus").prop("disabled", !state);
      $("#date-ref-minus").prop("disabled", !state);
    };

    let ref = Math.max(2010, parseInt($("#date-ref").text()));
    $("#date-ref-plus").on("click", function () {
      ref += 1;
      if (ref > new Date().getFullYear()) return;
      toggleButtons(false);

      $.ajax({
        url: `../api/pool/${context.poolId}/reference/${ref}`,
      }).done(function (response) {
        $("#date-ref").text(response.data.reference);
        context.render();
        toggleButtons(true);
      });
    });
    $("#date-ref-minus").on("click", function () {
      ref -= 1;
      if (ref < 2010) return;
      toggleButtons(false);

      $.ajax({
        url: `../api/pool/${context.poolId}/reference/${ref}`,
      }).done(function (response) {
        $("#date-ref").text(response.data.reference);
        context.render();
        toggleButtons(true);
      });
    });
  }

  /**
   * Load first data and do the first display
   *
   * Separated to be sure subclasses constructors has been called
   */
  init() {
    this.refreshDataStoresConfiguration();
  }

  refreshDataStoresConfiguration() {
    this.show_loaders();

    this._dataStoresReferences.forEach((dataStore) => {
      dataStore.dateStart = this.dateStart;
      dataStore.dateEnd = this.dateEnd;
      dataStore.periodicity = this.date_manager.periodicity;
    });

    //If there is no datastore loaded, no need to load data
    if (this._dataStoresReferences.length > 0) this.render();
  }

  updateRef(helper) {
    let prev_date_start = DateTime.fromISO(helper.start).toFormat(BASE_DATETIME_FORMAT);
    let prev_date_end = DateTime.fromISO(helper.end).toFormat(BASE_DATETIME_FORMAT);
    sessionStorage.setItem(SESSION_STORAGE__PREVIOUS_DATE_START_KEY, prev_date_start);
    sessionStorage.setItem(SESSION_STORAGE__PREVIOUS_DATE_END_KEY, prev_date_end);
  }

  render() {}

  /**
   * Change the current poind according to a pond html element
   *
   * @param pondSelector the selector of the html element representing the pond
   */
  switchPond(pondSelector) {
    this.show_loaders();

    this.elementId = $(pondSelector).data("elementid");
    if (this.dataStore) {
      this.dataStore.pond = this.elementId;
    }

    let current = $(".activeElement");

    current.removeClass("activeElement");

    $(pondSelector).addClass("activeElement");
    let pondName = pondSelector.innerHTML;

    this._switch_element_callback.forEach((callback) => callback(this.elementId, pondName));
  }

  /**
   * Change the current poind according to a pond html element
   *
   * @param circuitSelector the selector of the html element representing the pond
   */
  switchCircuit(circuitSelector) {
    this.show_loaders();

    this.circuitId = $(circuitSelector).data("elementid");
    if (this.dataStore) {
      this.dataStore.circuit = this.circuitId;
    }

    let current = $(".activeElement");

    current.removeClass("activeElement");

    $(circuitSelector).addClass("activeElement");
    let circuitName = circuitSelector.innerHTML;
    this._switch_element_callback.forEach((callback) => callback(this.circuitId, circuitName));
  }

  /**
   * Register a callback launched when pond switch
   *
   * @param callback the callback to run
   * @param run true if callback should run when it is registered
   */
  registerswitchPondCallback(callback, run = true) {
    this._switch_element_callback.push(callback);
    if (run) {
      let current = $(".activeElement");
      if (current.length == 0) return;
      let pondName = current.get(0).innerHTML;
      callback(this.elementId, pondName);
    }
  }

  /**
   * Register a callback launched when pond switch
   *
   * @param callback the callback to run
   * @param run true if callback should run when it is registered
   */
  registerswitchCircuitCallback(callback, run = true) {
    this._switch_element_callback.push(callback);
    if (run) {
      let current = $(".activeElement");
      if (current.length == 0) return;
      let circuitName = current.get(0).innerHTML;
      callback(this.circuitId, circuitName);
    }
  }

  //Utilities

  /**
   * Return the appropriate class for the arrow representing the evolution
   *
   * @param evolution_value the value representing the evolution
   * @param down_is_red true if a decrease should be red
   * @returns {*}
   */
  getEvolutionArrow(evolution_value, down_is_red = true) {
    let sign = evolution_value > 0;

    let color = "";
    if ((down_is_red && !sign) || (!down_is_red && sign)) {
      color = "#dc3545";
    } else {
      color = "#198754";
    }

    if (evolution_value < 1 && evolution_value > -1) color = null;

    let angle = Math.min(90, Math.max(-90, -evolution_value));
    return `<i class="fa fa-long-arrow-alt-right"
                          aria-hidden="true"
                          style="transform: rotate(${angle}deg);
                                 color: ${color}"></i>`;
  }

  /**
   * Add the dataStore to the list of datastores to be updated when changing data like pool, dates etc
   *
   * @param dataStore a reference to the datastore we want to track
   */
  registerDataStore(dataStore) {
    this._dataStoresReferences.push(dataStore);
  }

  /**
   * This is a tool to refresh elements dependent of the general state,
   * for example tabs that are displayed only in general state
   */
  refreshGeneralState() {
    //Show/hide general only elements
    let general_only_elements = $("." + GENERAL_ONLY_SELECTOR);

    //Use a particular class to hide, to avoid it to be displayed if it should be hidden for other reasons
    if (this.is_general) general_only_elements.removeClass("d-none-" + GENERAL_ONLY_SELECTOR);
    else general_only_elements.addClass("d-none-" + GENERAL_ONLY_SELECTOR);
  }

  hide_loaders() {
    $(this.container).find(".ajax-loader").remove();
  }

  show_loaders() {
    //See ajax-loader.css to understand this part
    //Loader is animated with css

    let loader = $("<div>", {
      class: "ajax-loader d-flex align-items-center justify-content-center",
    });

    //First circle, normal
    $("<p>").appendTo(loader);

    //Second circle: start at the half of the first animation's life
    $("<p>", { class: "delayed" }).appendTo(loader);

    if ($(this.container).find(".ajax-loader").length === 0) {
      $(this.container).append(loader);
    }
  }

  // Accessors
  /**
   * Shortcut for date start
   * @returns {DateTime}
   */
  get dateStart() {
    return this.date_manager.date_start;
  }

  /**
   * Shortcut for date end
   * @returns {DateTime}
   */
  get dateEnd() {
    return this.date_manager.date_end;
  }

  get poolId() {
    return this._poolId;
  }

  set poolId(pool_id) {
    this._poolId = parseInt(pool_id);
  }

  get elementId() {
    return this._pondId;
  }

  set elementId(pond_id) {
    this._pondId = parseInt(pond_id);

    this.refreshGeneralState();
  }

  //Return true if the current pond is the first one (general)
  get is_general() {
    return this.target == "pond" ? this.elementId == 0 : this.target == "circuit" ? this.circuitId == 0 : true;
  }

  get clientId() {
    return this._clientId;
  }

  set clientId(client_id) {
    this._clientId = client_id;
  }

  /**
   * Return true if the pond switch is enabled
   *
   * @returns {boolean}
   */
  static get element_switch_enabled() {
    return true;
  }

  /**
   * Returns the type of the subclass
   */
  static get renderer_type() {
    throw "You must implement : get render_type()";
  }

  //Pack type
  static get pack_name() {
    throw "You must implement : get pack_name()";
  }

  get target() {
    throw "You must implement : get target()";
  }
}

export default AbstractRenderer;

import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    let textArea = $(this.element).find("textarea");
    // console.log(textArea);
    textArea.css({ height: textArea.prop("scrollHeight") + "px", "overflow-y": "hidden" });
    textArea.on("input focus", () => {
      this.autoSize();
    });
    this.autoSize();
  }

  autoSize() {
    let textArea = $(this.element).find("textarea");
    textArea.height(0);
    textArea.height(textArea.prop("scrollHeight") + "px");
  }
}

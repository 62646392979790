import AbstractDataStore from "./AbstractDataStore";
import $ from "jquery";

class NewValueDataStore extends AbstractDataStore {
  constructor(default_route, pack, pool, pond, circuit, client) {
    super(default_route, pack, pool, pond, circuit, client);
    this.requests = [];
  }

  abortRequests() {
    this.requests
      .filter((element) => element.readyState == 1)
      .forEach((request) => {
        try {
          request.abort();
        } catch (e) {
          console.log(e);
        }
      });
    this.requests = this.requests.filter((element) => element.readyState == 1);
  }

  loadData(date) {
    this.abortRequests();
    return new Promise((resolve, reject) => {
      this.requests.push(
        $.get({
          url: this.generateUrl(),
          data: { date: date },
          success: (res) => {
            if (res.status === "KO") {
              reject(res);
              return;
            }
            resolve(res);
          },
          error: reject,
        }),
      );
    });
  }

  sendData(date, data) {
    return new Promise((resolve, reject) => {
      $.post({
        url: this.generateUrl("new"),
        data: JSON.stringify({ date: date, data: data }),
        contentType: "application/json",

        success: (res) => {
          if (res.status === "KO") {
            reject(res);
            return;
          }
          resolve(res);
        },
        error: reject,
      });
    });
  }
}

export default NewValueDataStore;

import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["select", "optionSelect"];

  connect() {
    this.val = $(this.selectTarget).val();
    // let event = new Event("change", { bubbles: true });
    // this.selectTarget.dispatchEvent(event);

    // sonata admin select2 event convert
    $(this.element).on("focusout", () => {
      let newVal = $(this.selectTarget).val();
      if (newVal !== this.val) {
        this.val = newVal;
        let event = new Event("change", { bubbles: true }); // fire a native event
        this.selectTarget.dispatchEvent(event);
      }
    });
  }

  async change(event) {
    let blueprintId = document.location.pathname.split("/")[4];

    // const response  = await $.ajax({
    //     url: `/form/blueprint/${blueprintId}/${this.val}`
    // })
    // if($(this.optionSelectTarget).hasClass("select2-hidden-accessible")){
    // }

    // console.log($(this.selectTarget).select2('data'));
    // console.log(this.optionSelectTarget);
    // $
  }
}

import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import $ from "jquery";

export default class extends Controller {
  async connect() {
    if (undefined == sessionStorage.readNotification) {
      sessionStorage.readNotification = [];
    }

    let flash_messages = $(this.element).find(".flash-message");

    for (let index = 0; index < flash_messages.length; index++) {
      const element = flash_messages[index];
      let readNotification = sessionStorage.readNotification.split(",");

      if (readNotification.includes(element.id)) continue;

      await Swal.fire({
        title: `${$(element).data("label")}`,
        text: $(element).data("message"),
        icon: $(element).data("level"),
        toast: true,
        position: "top-end",
        background: "rgba(106, 185, 196, 1)",
        color: "white",
        confirmButtonColor: "rgb(40, 163, 179)",
        confirmButtonText: `<i class="fas fa-eye-slash" data-toggle="tooltip" title="Ne plus montrer"></i>`,
        showCancelButton: true,
        cancelButtonText: `<i class="fas fa-times" data-toggle="tooltip" title="Fermer"></i>`,
      }).then(async (result) => {
        console.log(result);
        if (result.isConfirmed) {
          const response = await $.ajax(`/notification/${element.id}/read`);
          let readNotification = sessionStorage.readNotification.split(",");
          readNotification.push(element.id);
          sessionStorage.readNotification = readNotification;
        }
      });
    }

    flash_messages.each((element) => {});
  }
}

import PeriodRenderer from "../PeriodRenderer";
import { computeBarThickness, customTooltip } from "../../helpers/ChartUtilities";
import Swal from "sweetalert2";
import Chart from "chart.js/auto";
import { formatNumber } from "../../helpers/utils";
import $ from "jquery";
import { DateTime } from "luxon";

class AttendancePackPeriodRenderer extends PeriodRenderer {
  constructor(
    container = $(".ajax-periods"),
    detailStart = null,
    detailEnd = null,
    isPopup = false,
    indexSwitchPeriodicity = null,
  ) {
    super(container, detailStart, detailEnd, isPopup, indexSwitchPeriodicity);
    this.showSum = true;

    this.attendanceHistogram = container.find(".js-attendancepack-histogram");
    this.container_id = "js-attendancepack-histogramcontainer";
    this.attendanceChart = null;
    this.unit = "baigneurs";
    this.request_reference = true;

    this.dateLabels = [];
    this.datasets = { hist: [], prevHist: [] };
    this.tooltips = [];
    this.unit = "Personnes";

    this.default_decimals_rounding = 0;
    if (this.isPopup) this.date_manager.switchPeriodicity(indexSwitchPeriodicity);
  }

  //Class specific methods

  //Overwriting
  render() {
    super.render();
  }
  buildCharts(response) {
    this.displayLabels(response);
    let parent = this;

    let customHistogramTooltipsOptions = {
      tooltip_id: "chartjs-tooltip-attendance",
      container_id: "js-attendancepack-histogramcontainer",

      getTitle: (titleLines) => {
        let title = $("<p>", {
          class: "has-light-grey-border-bottom p-2",
        });
        let title_content = "";

        titleLines.forEach((title, i) => {
          let date_split = title.split(",");
          let date = this.date_manager.isWeekPeriodicity ? date_split[1] : date_split[0];
          title_content += date;
        });

        title.text(title_content);

        return title;
      },
      getBody: () => {
        let body = $("<div/>", {
          class: "d-flex justify-content-around flex-wrap align-items-stretch w-auto",
        });

        for (let i in this.datasets.hist) {
          i = parseInt(i);
          let dataset = this.datasets.hist[i];
          if (dataset.isEnabled) {
            let span = $("<span>", {
              class: "chartjs-tooltip-element",
              css: {
                "background-color": dataset.color,
              },
            })
              .html(
                `
                      <span>${dataset.label.replace(" ", "<br>")}</span>
                      <span>${formatNumber(this.tooltips[i], true)}</span>
                    `,
              )
              .appendTo(body);
          }
        }

        $("<div>", {
          class: "has-light-grey-border-top p-2 w-100 text-center",
        })
          .html(
            "<strong>Total : </strong>" +
              formatNumber(this.tooltipSum, true) +
              `<p><i style="opacity:0.5;font-size:0.8em">*cliquez pour voir le détail</i></p>`,
          )
          .appendTo(body);

        return body;
      },
    };

    let attendance_datasets = [];
    for (let k in this.datasets.hist) {
      k = parseInt(k);
      let dataset = this.datasets.hist[k];
      attendance_datasets.push({
        label: dataset.label,
        backgroundColor: dataset.color,
        data: dataset.values,
        type: "bar",
        stack: "stack 3",
        isEnabled: true,
        borderWidth: 1.5,
        borderRadius: 10,
        indic_id: dataset.id,
      });
    }

    let prev_attendance_datasets = [];
    for (let index in this.datasets.prevHist) {
      let histogram = this.datasets.prevHist[index];
      let label = "Référence";
      label += index == 0 ? "" : "blank";
      // label += index == this.datasets.hist.length - 1 ? "rounded" : "";
      prev_attendance_datasets.push({
        label: label, // for rounded chart
        backgroundColor: histogram.color,
        data: histogram.values,
        type: "bar",
        order: 0,
        isEnabled: true,
        stack: "stack 1",
        borderWidth: 1.5,
        borderRadius: 10,
        indic_id: histogram.id,
      });
    }

    var newLegendClickHandler = function (e, legendItem) {
      var index = legendItem.datasetIndex;
      let ci = this.chart;
      let currentDataset = ci.data.datasets[index];

      // Toggle current dataset
      var meta = ci.getDatasetMeta(index);
      meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

      // Toggle others datasets
      for (let i = 0; i < ci.data.datasets.length; i++) {
        let dataset = ci.data.datasets[i];
        var meta = ci.getDatasetMeta(i);
        if (
          (currentDataset.label.includes("Période") && dataset.label.includes("Période")) ||
          (currentDataset.label.includes("Référence") && dataset.label.includes("Référence"))
        ) {
          if (currentDataset != dataset) {
            meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
          }
        }
      }

      // render
      ci.update();
    };
    var displayDetails = function (e) {
      //check if its hours so return
      const regex = /h00$/;
      if (parent.dateLabels.some((str) => regex.test(str))) return;

      let dateNextClicked = "";
      let dateClicked = this.tooltip.title; //['lun. 07/03']
      let index = 0;
      let pack = "attendance";
      //return key of matching arr date_clicked and arr all dates (all periods in ISO)
      parent.dateLabels.forEach((dateLabel, k) => {
        if (dateClicked[0] == dateLabel) {
          return (index = k);
        }
      });

      dateClicked = DateTime.fromISO(parent.helper.periods[index]["start"]);
      dateNextClicked = DateTime.fromISO(parent.helper.periods[index]["end"]);

      $.ajax({
        url: `/pack/attendance/details`,
        method: "GET",
        data: {
          date: dateClicked.toISO(),
          dateNext: dateNextClicked.toISO(),
          titleDate: this.tooltip.title[0],
          pack: pack,
          indicators: parent.indicators,
          indicator_colors: parent.indicator_colors,
        },
      }).then((response) => {
        Swal.fire({
          html: response,
          width: "85%",
          confirmButtonColor: "rgb(40, 163, 179)",
          showCloseButton: true,
        });
      });
    };

    this.attendanceChart = new Chart(this.attendanceHistogram, {
      type: "bar",
      data: {
        labels: this.dateLabels,
        datasets: [...prev_attendance_datasets, ...attendance_datasets],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 30,
            right: 30,
            top: 45,
            bottom: 30,
          },
        },
        elements: {
          point: {
            radius: 25,
            hoverRadius: 35,
            pointStyle: "rectRounded",
          },
        },
        scales: {
          y: {
            stacked: true,
            beginAtZero: true,
            title: {
              display: true,
              text: this.unit,
            },
          },
          x: {
            display: true,
            stacked: true,
            grid: {
              display: false,
            },
          },
        },

        labels: {
          display: true,
        },
        onClick: displayDetails,

        plugins: {
          legend: {
            onClick: newLegendClickHandler,
            labels: {
              filter: function (legendItem, data) {
                return !legendItem.text.includes("blank");
              },
              generateLabels: function (chart) {
                var data = chart.data;
                var legends = Array.isArray(data.datasets)
                  ? data.datasets.map(function (dataset, i) {
                      return {
                        text: dataset.label.replace("rounded", ""),
                        fillStyle: !Array.isArray(dataset.backgroundColor)
                          ? dataset.backgroundColor
                          : dataset.backgroundColor[0],
                        hidden: !chart.isDatasetVisible(i),
                        lineCap: dataset.borderCapStyle,
                        lineDash: dataset.borderDash,
                        lineDashOffset: dataset.borderDashOffset,
                        lineJoin: dataset.borderJoinStyle,
                        lineWidth: dataset.borderWidth,
                        strokeStyle: dataset.borderColor,
                        pointStyle: dataset.pointStyle,

                        // Below is extra data used for toggling the datasets
                        datasetIndex: i,
                      };
                    }, this)
                  : [];

                return legends;
              },
            },
          },
          tooltip: {
            backgroundColor: "rgb(255, 255, 255)",
            bodyFontColor: "rgb(0, 0, 0)",
            mode: "index",
            intersect: false,
            enabled: false,
            callbacks: {
              beforeFooter: (tooltipItems, data) => {
                for (let index in this.datasets.hist) {
                  this.tooltips[index] = parseFloat(this.datasets.hist[index].values[tooltipItems[0].dataIndex]);
                }
              },
            },
            external: (context) => {
              customTooltip(context, customHistogramTooltipsOptions);
            },
          },
        },
      },
    });

    for (let index in response.helper.indicators) {
      let indicator = response.helper.indicators[index];
      $(`#consumptionsummary-element--${index}`).on("click", (event) => {
        for (let index_2 in parent.attendanceChart.data.datasets) {
          let dataset = parent.attendanceChart.data.datasets[index_2];
          if (dataset.indic_id === indicator.id) {
            dataset.hidden = !dataset.hidden;
            if (dataset.hidden) $(event.target).parents(".consumptionsummary-element").removeClass("active");
            else $(event.target).parents(".consumptionsummary-element").addClass("active");
          }
        }
        parent.attendanceChart.update();
      });
    }
  }

  emptyChartsData() {
    this.dateLabels = [];
    this.datasets.hist = [];
    this.datasets.prevHist = [];
  }

  destroyCharts() {
    super.destroyCharts();

    if (this.attendanceChart) this.attendanceChart.destroy();
  }

  /**
   * Return the sum of each number displayed in the tooltip
   * @returns {number}
   */
  get tooltipSum() {
    let tooltips = Object.values(this.tooltips);

    let ret = null;
    for (let tooltip of tooltips) {
      ret += isNaN(tooltip) ? null : tooltip;
    }

    return ret;
  }

  initHistogram(res, hist, colors) {
    const data = res.data;
    const helper = res.helper;

    for (let index in helper.indicators) {
      index = parseInt(index);
      let indicator = helper.indicators[index];
      hist[index] = {
        values: [],
        label: indicator.label,
        color: colors[index],
        isEnabled: true,
        id: indicator.id,
        abbreviation: indicator.abbreviation,
      };
      this.unit = indicator.unit;
    }

    for (let datum of Object.values(data)) {
      for (let ii in helper.indicators) {
        ii = parseInt(ii);
        let indicator = helper.indicators[ii];
        hist[ii].values.push(datum[indicator.reference]);
      }
    }
  }

  updateChartsData(res) {
    const data = res.data;
    const helper = res.helper;

    this.initHistogram(res, this.datasets.hist, [
      this.colors.normal,
      this.colors.dark,
      this.colors.light,
      this.colors.other,
    ]);
    $("#js-attendancepack-totalsummary-stat").find("span").text(this.unit);
    this.updateDateLabels();

    for (let index in this.datasets.hist) {
      this.tooltips[index] = "NC";
    }
  }

  updatePreviousChartsData(res) {
    const data = res.data;
    const helper = res.helper;
    this.initHistogram(res, this.datasets.prevHist, [
      this.colors.normal_transparent,
      this.colors.dark_transparent,
      this.colors.light_transparent,
      this.colors.other_transparent,
    ]);
  }

  static get pack_name() {
    return "attendance";
  }

  static get element_switch_enabled() {
    return false;
  }

  get target() {
    return "pool";
  }
}

export default AttendancePackPeriodRenderer;

import { DateTime } from "luxon";
import { formatNumber } from "../../helpers/utils";
import MeasuresRenderer from "../MeasuresRenderer";
import $ from "jquery";

class EnergyPackMeasuresRenderer extends MeasuresRenderer {
  constructor(container = $(".ajax-measures")) {
    super(container);
    this.default_decimals_rounding = 2;
    this._is_dbec = false;
    this._energy_reused = true;
    this.unit = "MWh";
  }

  drawCircles(response) {
    super.drawCircles(response, "per_surface", "kWh/m²", 2);
  }

  updateSummary(response) {
    let data = response.data;

    // $('#js-energypack-evolution-reused')
    //     .find('span')
    //     .html(this.date_manager.previous_period_comparison_message)
    //     ;

    // $('#js-energypack-evolution-preheated')
    //     .find('span')
    //     .html(this.date_manager.previous_period_comparison_message)
    //     ;

    let gain_key = "dbec_pond_gain_mwatt";
    for (let indicator of response.helper.indicators) {
      if (indicator.reference.toLowerCase().includes("axone")) {
        gain_key = indicator.reference;
        break;
      }
    }

    $("#js-energypack-totalsummary-stat").find("b").text(formatNumber(data?.[gain_key]?.value, 2));

    $("#js-energypack-surfacesummary-stat")
      .find(".statnumber-stat")
      .text(formatNumber(data?.[gain_key]?.per_surface?.value, 2));

    let evolution_summary = $("#js-energypack-evolutionsummary-stat");
    this.displayIndicatorStat(evolution_summary, data?.[gain_key], this.unit, 2);
  }

  updateValues(response) {
    let data = response.data;
    this._is_dbec = response.data.dbec_pond_gain_mwatt !== undefined;

    let helper = response.helper;
    let dateStart = DateTime.fromISO(helper.start);
    let dateEnd = DateTime.fromISO(helper.end);
    let previousDateStart = dateStart
      .set({ year: helper.reference_year })
      .setLocale(navigator.language)
      .toLocaleString(DateTime.DATE_MED);
    let previousDateEnd = dateEnd
      .set({ year: helper.reference_year })
      .setLocale(navigator.language)
      .toLocaleString(DateTime.DATE_MED);
    let toolip = ` Par rapport à la période du ${previousDateStart} au ${previousDateEnd}`;
    $(`.period-tooltip`).find(".tooltip-text").find("b").text(toolip);

    for (let i in data.indicators) {
      let indicator = data.indicators[i];
      let element = $(`#js-energypack-${i}-stat`);
      let reference = indicator.reference;
      let value = data[reference];

      this.displayIndicatorStat(element, value, indicator.unit, 2);
    }

    //For preheat circle
    if (this._is_dbec) {
      let ponds_count = response.data.ponds_count;

      if (ponds_count) {
        $("#js-energypack-total-preheated")
          .find("b")
          .text(ponds_count.with_dbec + " sur " + ponds_count.all);
        $("#js-energypack-total-preheated").parent().removeClass("d-none");
      } else {
        $("#js-energypack-total-preheated").parent().addClass("d-none");
      }

      let evolutionSummaryPreheat = $("#js-energypack-evolution-preheated");
      this.displayIndicatorStat(evolutionSummaryPreheat, data.dbec_pond_gain_mwatt, this.unit, 2);
    }
  }

  static get pack_name() {
    return "energy";
  }

  static get element_switch_enabled() {
    return false;
  }

  get target() {
    return "pool";
  }
}
export default EnergyPackMeasuresRenderer;

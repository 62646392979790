import { Controller } from "@hotwired/stimulus";
import DateManager from "../../js/helpers/DateManager";
import $ from "jquery";

export default class extends Controller {
  static targets = ["pond", "from", "to", "periodicity"];
  connect() {
    // Get pond when change and target in tag
    const pondElement = $(".activeElement");
    const pondNavItem = $(".js-pond-nav-item");

    pondNavItem.on("click", () => {
      this.pondTarget.value = $(".activeElement").attr("data-elementid");
    });

    // Get dates at connect and target in tag
    const dateInputFrom = $("#pack-datepicker-from");
    const dateInputTo = $("#pack-datepicker-to");

    const dateFrom = DateManager.parseDateFromInput(dateInputFrom);
    const dateTo = DateManager.parseDateFromInput(dateInputTo);

    this.fromTarget.value = dateFrom.toISO();
    this.toTarget.value = dateTo.toISO();

    // Get dates when change
    dateInputFrom.on("change", () => {
      this.fromTarget.value = DateManager.parseDateFromInput(dateInputFrom).toISO();
    });

    dateInputTo.on("change", () => {
      this.toTarget.value = DateManager.parseDateFromInput(dateInputTo).toISO();
    });

    // Get peridiocity at connect and target in tag
    const liElementActive = $(".periodicityselector a.active");
    const periodicity = liElementActive.attr("id")?.replace(/.+-(.+)/, "$1"); //regex
    this.periodicityTarget.value = periodicity;

    // Get periodicty on click
    const liElementAny = $(".periodicityselector li a");
    liElementAny.on("click", () => {
      this.periodicityTarget.value = $(".periodicityselector a.active")
        .attr("id")
        .replace(/.+-(.+)/, "$1");
    });
  }
}

import MeasuresRenderer from "../MeasuresRenderer";
import { formatNumber } from "../../helpers/utils";
import { DateTime } from "luxon";
import $ from "jquery";

class WaterPackMeasuresRenderer extends MeasuresRenderer {
  constructor(container = $(".ajax-measures")) {
    super(container);
    this.dataStore.target = "circuit";
    this.default_decimals_rounding = 0;
    this._is_dbec = false;
    this._water_reused = false;
    this.unit = "m\xb3";
  }

  drawCircles(response) {
    super.drawCircles(response, "per_swimmer", "L/B");
  }

  updateSummary(response) {
    let data = response.data;

    this._water_reused = data.water_reused;
    this.total_issue = $("#js-waterpack-evolution-reused")
      .find("span")
      .html(this.date_manager.previous_period_comparison_message);

    $("#js-waterpack-evolution-preheated").find("span").html(this.date_manager.previous_period_comparison_message);

    let total = 0;
    let total_evolution = 0;
    let total_delta = 0;
    if (data.issue.value > 0) {
      // 567
      total = data.water_total.value - data.issue.value; // 4307.30005 - 567 = 3740
      total_delta = total - data.water_total.previousValue; // 3740 -7433.23657 = -3693
      total_evolution = (100 * total_delta) / total; // -98, nb négatif cohérent

      $("#js-waterpack-totalsummary-stat").find("b").text(formatNumber(total, 2));

      let _value = (total / data.attendance_pool.value) * 1000;
      $("#js-waterpack-swimmersummary-stat")
        .find(".statnumber-stat")
        .text(formatNumber(_value, true) + "*");

      $("#js-waterpack-swimmersummary-stat").find(".tooltip-text").removeClass("d-none");

      $(".waterpack-issue").show();
      $("#js-waterpack-issue-stat").find("b").text(formatNumber(data.issue.value, true));
    } else {
      $(".waterpack-issue").hide();
      total = data.water_total.value;
      total_evolution = data.water_total.evolution;
      total_delta = data.water_total.delta;

      $("#js-waterpack-totalsummary-stat").find("b").text(formatNumber(total, 2));

      $("#js-waterpack-swimmersummary-stat")
        .find(".statnumber-stat")
        .text(formatNumber((total / data.attendance_pool.value) * 1000, true));

      $("#js-waterpack-swimmersummary-stat").find(".tooltip-text").addClass("d-none");
    }

    let evolution_summary = $("#js-waterpack-evolutionsummary-stat");
    this.displayIndicatorStat(evolution_summary, { evolution: total_evolution, delta: total_delta }, this.unit, true);
  }

  updateValues(response) {
    let data = response.data;
    let helper = response.helper;

    this._is_dbec = helper.has_dbec;

    let dateStart = helper.start;
    let dateEnd = helper.end;

    let previousDateStart = DateTime.fromMillis(Date.parse(dateStart))
      .set({ year: helper.reference_year })
      .setLocale("fr")
      .toISO();
    let previousDateEnd = DateTime.fromMillis(Date.parse(dateEnd))
      .set({ year: helper.reference_year })
      .setLocale("fr")
      .toISO();

    let toolip = ` Par rapport à la période du ${previousDateStart} au ${previousDateEnd}`;
    $(`.period-tooltip`).find(".tooltip-text").find("b").text(toolip);

    for (let i in data.indicators) {
      let indicator = data.indicators[i];
      let element = $(`#js-waterpack-${i}-stat`);
      let reference = indicator.reference;
      let value = data[reference];

      this.displayIndicatorStat(element, value, indicator.unit, true);
    }

    // For water reuse circle
    if (data.water_reuse !== false) {
      $("#js-waterpack-total-reused")
        .find("b")
        .text(formatNumber(data.water_reused.per_total_consumption.value, true) + "%");

      $("#js-waterpack-evolution-reused")
        .find("b")
        .text(formatNumber(data.water_reused.per_total_consumption.evolution, true) + "%");
    }

    //For preheat circle
    if (this._is_dbec) {
      let ponds_count = response.data.ponds_count;

      if (this.is_general && ponds_count) {
        $("#js-waterpack-total-preheated")
          .find("b")
          .text(ponds_count.with_dbec + " sur " + ponds_count.all);
        $("#js-waterpack-total-preheated").parent().removeClass("d-none");
        $(".general-only").show();
      } else {
        $(".general-only").hide();
        $("#js-waterpack-total-preheated").parent().addClass("d-none");
      }

      let evolutionSummaryPreheat = $("#js-waterpack-evolution-preheated");
      this.displayIndicatorStat(evolutionSummaryPreheat, data.dbec_warmed_water, this.unit, true);
    }
  }

  static get pack_name() {
    return "water";
  }

  static get element_switch_enabled() {
    return true;
  }

  get target() {
    return "circuit";
  }
}

export default WaterPackMeasuresRenderer;

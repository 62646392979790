import AbstractRenderer from "./AbstractRenderer";
import CircleStat from "../helpers/CircleStat";
import { formatNumber } from "../helpers/utils";
import $ from "jquery";

class MeasuresRenderer extends AbstractRenderer {
  constructor(container) {
    super(container);

    this.updateSummary = this.updateSummary.bind(this);
    this.drawCircles = this.drawCircles.bind(this);
    this.updateValues = this.updateValues.bind(this);
  }

  /**
   * Update values and wording on the top summary bar (total attendance, average attendance)
   */
  updateSummary(val) {}

  drawCircles(response, suffix = false, unit = null, decimals = true, color = "#258090") {
    for (let i in response.helper.indicators) {
      let indicator = response.helper.indicators[i];

      let data = suffix ? response.data[indicator.reference][suffix].value : response.data[indicator.reference].value;

      data = formatNumber(data, decimals);
      let threshold = indicator.threshold;

      let circle = new CircleStat(
        `${i}-summary`,
        data,
        threshold.max,
        color,
        "--xs m-t-n",
        40,
        3,
        unit ? unit : indicator.unit,
      );
      circle.create();
    }
  }

  /**
   * Update values and wording next to stat circles (evolution percentage)
   */
  updateValues(res) {}

  render() {
    super.render();

    let dataLoaded = this.dataStore.loadMeasures();

    dataLoaded
      .then((res) => {
        this.helper = res.helper;

        this.displayIndicator(res.data);
        this.updateRef(res.helper);
        this.updateValues(res);
        this.updateSummary(res);
        this.drawCircles(res);
        this.hide_loaders();
      })
      .catch((e) => {
        if (e.statusText != "abort") {
          console.log(e);
        }
      });

    return dataLoaded;
  }

  displayIndicator(data) {
    // reset
    $(`.consumptionsummary-element`).addClass("d-none");

    // Display indicators blocks if in data
    let indicator_labels = $(".indicator-label");
    indicator_labels.each((i, elt) => {
      let block = $(`#consumptionsummary-element--${i}`);
      if (this.helper.indicators.hasOwnProperty(i)) {
        block.removeClass("d-none");
        $(elt).text(this.helper.indicators[i].label);
      }
    });
  }

  displayIndicatorStat(element, datum, unit, round = false, down_is_red = false) {
    element.find("svg").remove();
    element.prepend(this.getEvolutionArrow(datum.evolution, down_is_red));

    element.find(".statnumber-stat").text(formatNumber(datum.evolution, round) + "%");
    element.find(".statnumber-raw").text(formatNumber(datum.delta, round) + " " + unit);
  }

  /**
   * Return the type of the renderer, 'measures' in this case
   *
   * @returns {string}
   */
  static get renderer_type() {
    return "measures";
  }
}

export default MeasuresRenderer;

import NBase from "./NBase";
import Synoptic from "./graph";
import NIO from "./NIO";

export default class NUnit extends NBase {
  static get defaults() {
    return {
      height: 1,
      width: 1,
      color: "white",
      shape: "rectangle",
      valign: "top",
      fontSize: 10,
      opacity: 1,
      backgroundOpacity: 0,
      borderWidth: 0.5,
      borderColor: "black",
      zIndex: 0,
      padding: 5,
    };
  }

  constructor(params) {
    super(params);
  }

  init() {
    this.entity = this.config.entity;

    // Dynamic config
    // this.config["label"] = this.entity.id;
    this.config["id"] = "u_" + this.entity.global_id;

    // Create in cytoscape
    let save = Synoptic.save?.[this.config.id];

    this.config["width"] = save?.width;
    this.config["height"] = save?.height;

    this.obj = Synoptic.add({
      group: "nodes",
      data: this.config,
      // position: saved_pos,
    });
    this.obj
      .addClass("unit")
      .addClass("grid")
      .addClass("interactive")
      .addClass(Synoptic.mode == "w" ? "removable" : "");
    this.obj.data("layout", true);

    this.setIdField();
    this.obj.scratch("obj", this);
  }

  bindActions() {
    super.bindActions();
  }
}

import Swal from "sweetalert2";
import Edge from "./Edge";
import Synoptic from "./graph";
import variables from "./variables.json";
import { contains, isNumber, isString } from "underscore";
import $ from "jquery";

export default class NBase {
  static get defaults() {
    return {
      shape: "rectangle",
      valign: "top",
      color: "white",
      fontSize: 10,
      opacity: 1,
      borderWidth: 1,
      borderColor: "black",
      width: 10,
      height: 10,
    };
  }

  constructor(params) {
    this.obj = null;
    this.ios = [];
    let config = this.constructor.defaults;

    for (let p in params) {
      if (params.hasOwnProperty(p)) {
        if (p.includes("label")) {
          config[p.replace("label", "width")] = params[p].length * 10;
        }
        config[p] = params[p];
      }
    }
    this.config = config;

    this.init();

    let name_key = [],
      id_key = [];
    if (config.entity) {
      name_key = Object.keys(config?.entity).filter(
        (name) => /_name$/.test(name), // ends with name
      );
      id_key = Object.keys(config?.entity).filter(
        (name) => /(?<!global)_id$/.test(name), // ends with id but not global_id
      );
    }

    if (name_key.length > 0) this.obj.data("tooltip", config?.entity?.[name_key[0]] ?? null);
    if ((config?.entity?.[id_key[0]] ?? null) && $("#hmi-body").attr("synopticLabels") == "True")
      this.obj.data("label", `${config?.entity?.[name_key[0]]} `);

    this.obj.data("entity", this.config.entity);

    this.bindActions();
  }

  init() {
    let save = Synoptic.save?.[this.config.id];
    let saved_pos = {
      x: parseInt(save?.pos?.x ?? 0.0),
      y: parseInt(save?.pos?.y ?? 0.0),
    };
    this.config["width"] = save?.width;
    this.config["height"] = save?.height;

    this.obj = Synoptic.add({
      group: "nodes",
      data: this.config,
      position: saved_pos,
    });
    this.obj.scratch("children", Synoptic.cy.collection());
    this.obj.scratch("obj", this);
    this.setIdField();
  }

  remove() {
    this.obj = null;
  }

  setState(state = null) {
    if (!state || !isString(state)) return;
    let logo = $(this.logo?.data("dom"));

    let stateStyle = variables;
    state.split(".").forEach((part) => {
      stateStyle = stateStyle?.[part];
    });

    if (!stateStyle || (!"value") in stateStyle) return;

    if (logo.length > 0) {
      logo?.find("*")?.css({
        stroke: stateStyle?.color,
        fill: stateStyle?.bcolor,
      });

      logo?.removeClass()?.addClass(`nodeImg nodeImgComponent animation-${stateStyle.animation}`);
    } else {
      this.obj.data("borderColor", stateStyle?.color);
    }

    // let tree = this.getDescendants(this.obj, this.obj);

    // tree.edgesTo(Synoptic.cy.elements().not(this.obj)).forEach((edge) => {
    //   let pipeGid = edge.data("entity.global_id");
    //   if (pipeGid) {
    //     let siblings = Synoptic.cy.edges(`[entity.global_id = ${pipeGid}]`);
    //     siblings.data({
    //       gcolor: stateStyle?.color,
    //       ganim: stateStyle?.animation,
    //     }); // gradient is drawn on another layer;
    //   }
    // });
  }

  // Recursive descendant research
  getDescendants(childs, element) {
    if (element.size() > 0) {
      let new_children = Synoptic.cy.elements(`[_parent="${element.id()}"]`);
      childs = childs.union(new_children);
      new_children.forEach((_element) => this.getDescendants(childs, _element));
    }

    return childs;
  }

  bindActions() {
    this.obj.on("state", (event) => this.setState(this.obj.scratch("state")));
  }

  hide() {
    this.obj.hide();
  }

  show() {
    this.obj.show();
  }

  updateList() {
    let listContainer = $("#synoptic-pane");
    let statusGroup = listContainer.find("#statusGroup");
    let elem = this.obj.data("entity") ?? this.obj.data("unit");
    statusGroup.find("li").remove();
    for (let property in elem) {
      statusGroup.find("ul").append($("<li>").addClass("list-group-item").append(`${property}: ${elem[property]}`));
    }
  }

  id() {
    return this.obj.id();
  }

  setIdField() {
    let prototypes = Synoptic.prototypes;
    let obj_field = this.obj.data("entity.class");
    if (!obj_field) return;
    let obj_id = this.obj.data("entity.global_id");
    if (prototypes) {
      let obj_proto = prototypes.filter((p) => p.field == obj_field)?.[0];
      if (obj_proto) {
        let idField = obj_proto.properties.filter((p) => p?.hipponet?.[0] == "id")?.[0]?.hipponet?.[1];
        let objAbbr = obj_proto.constants?.ABBREVIATION;
        this.obj.data("objAbbr", objAbbr);
        this.obj.data("idField", idField);
      }
    }
  }

  scratch(...args) {
    return this.obj.scratch(args);
  }

  data(...args) {
    return this.obj.data(args);
  }
}

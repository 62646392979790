import AbstractRenderer from "./AbstractRenderer";
import { computeBarThickness, customTooltip } from "../helpers/ChartUtilities";
import { formatNumber } from "../helpers/utils";
import { DateTime } from "luxon";
import $ from "jquery";
import _ from "underscore";

class PeriodRenderer extends AbstractRenderer {
  constructor(container, detailStart, detailEnd, isPopup = false, indexSwitchPeriodicity) {
    super(container, detailStart, detailEnd, isPopup, indexSwitchPeriodicity);
    this.showSum = false;
    this.totalLabel = "total";
    this.request_reference = false;
    this.indicators = null;
    // day = default periodicity
    this.selectedPeriodicity = "day";
    this.dataStore.periodicity = this.selectedPeriodicity;

    //Methods bind
    this.buildCharts = this.buildCharts.bind(this);
    this.emptyChartsData = this.emptyChartsData.bind(this);
    this.destroyCharts = this.destroyCharts.bind(this);
  }

  async render() {
    super.render();

    if (this.isPopup) {
      if (this.dataStore.target == "circuit") this.switchCircuit($(".js-pond-nav-item.activeElement"));
      if (this.dataStore.target == "pond") this.switchPond($(".js-pond-nav-item.activeElement"));
    }
    let dataLoaded = this.dataStore.loadData(null, this.date_manager.periodicity);
    let res = null;
    try {
      res = await dataLoaded;
    } catch (error) {
      return;
    }
    //ajout des périodes
    this.data = res.data;
    this.helper = res.helper;

    this.updateRef(res.helper);
    //ajout des indicateurs dans le rendu
    this.indicators = res.helper.indicators;
    if (this.request_reference) {
      let dateStart = this.dataStore.dateStart;
      let dateEnd = this.dataStore.dateEnd;

      let delta = dateStart.diff(dateEnd);
      let prev_dateEnd = dateEnd.set({ year: res.helper.reference_year ?? DateTime.local().minus({ year: 1 }).year });
      let prev_dataLoaded = this.dataStore.loadPreviousData(
        null,
        this.date_manager.periodicity,
        prev_dateEnd.plus(delta),
        prev_dateEnd,
      );

      let prev_res = null;
      try {
        prev_res = await prev_dataLoaded;
      } catch (error) {
        return;
      }
      this.emptyChartsData();
      this.destroyCharts();
      this.updateChartsData(res);
      this.updatePreviousChartsData(prev_res);
      this.buildCharts(res);
      this.hide_loaders();
      return res;
    } else {
      this.emptyChartsData();
      this.destroyCharts();
      this.updateChartsData(res);
      this.buildCharts(res);
      this.hide_loaders();
    }

    return dataLoaded;
  }

  buildCharts() {}

  emptyChartsData() {}

  destroyCharts() {}

  updateChartsData(res) {}

  updatePreviousChartsData(res) {}

  defaultTooltips() {}

  animate(elem) {
    // $(elem).parent().css({ backgroundColor: "#258090" });
    $(elem).fadeTo(100, 0.5).fadeTo(100, 1.0).fadeTo(100, 0.5).fadeTo(100, 1.0);
  }

  get tooltipSum() {
    let ret = null;
    this.tooltips.forEach((tooltip) => {
      ret += isNaN(tooltip) ? null : tooltip;
    });

    return ret;
  }

  // Chart.js common attributes

  get defaultTooltip() {
    let customHistogramTooltipsOptions = {
      tooltip_id: "chartjs-tooltip",
      container_id: this.container_id,

      getTitle: (titleLines) => {
        let title = $("<p>", {
          class: "has-light-grey-border-bottom p-2",
        });
        let title_content = "";

        titleLines.forEach((title, i) => {
          let date_split = title.split(",");
          let date = this.date_manager.isWeekPeriodicity ? date_split[1] : date_split[0];
          title_content += date;
        });

        title.text(title_content);

        return title;
      },
      getBody: () => {
        let body = $("<div/>", {
          class: "d-flex justify-content-around flex-wrap align-items-stretch",
        });

        let content = "";
        this.tooltips
          .filter((tooltip) => tooltip.hide != true)
          .forEach((tooltip) => {
            content += `
                    <span class="chartjs-tooltip-element" style="background-color: ${
                      tooltip.color
                    };" class="chartjs-tooltip-element"> ${tooltip.label} <br> ${formatNumber(
                      tooltip.value,
                      false,
                    )} </span>
                    `;
          });
        body.html(content);

        if (this.showSum) {
          $("<div>", {
            class: "has-light-grey-border-top p-2 w-100 text-center",
          })
            .html("<strong>" + this.totalLabel + " : </strong>" + formatNumber(this.tooltipSum, true))
            .appendTo(body);
        }

        return body;
      },
    };

    return {
      backgroundColor: "rgb(255, 255, 255)",
      bodyFontColor: "rgb(0, 0, 0)",
      mode: "point",
      intersect: false,
      enabled: false,
      callbacks: {
        beforeFooter: (tooltipItems, data) => {
          this.tooltips.forEach((tooltip) => {
            tooltip.value = parseFloat(this.datasets[tooltip.data].data[tooltipItems[0].dataIndex]);
          });
        },
      },
      custom: (tooltip_model) => {
        customTooltip(tooltip_model, customHistogramTooltipsOptions);
      },
    };
  }

  get defaultScales() {
    return {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
          title: {
            display: true,
            text: this.unit,
          },
        },
      ],
      xAxes: [
        {
          display: true,
          stacked: true,
          grid: {
            display: false,
          },
        },
      ],
    };
  }

  get defaultLayout() {
    return {
      padding: {
        left: 0,
        right: 0,
        top: 45,
        bottom: 30,
      },
    };
  }

  get defaultElements() {
    return {
      point: {
        radius: 7,
        hoverRadius: 7,
        hoverBorderWidth: 4,
        pointStyle: "round",
        borderColor: "transparent",
        backgroundColor: "white",
        borderWidth: 2,
      },
    };
  }

  /**
   * Return the type of the renderer, 'periods' in this case
   *
   * @returns {string}
   */
  static get renderer_type() {
    return "periods";
  }

  displayLabels(response) {
    // Display indicators blocks if in data
    let indicators = response.helper.indicators;
    let indicator_labels = $(".indicator-label");
    indicator_labels.each((i, elt) => {
      if (indicators.hasOwnProperty(i)) {
        let abbr = indicators[i].abbreviation;
        // if (this.datasets.curves.hasOwnProperty(i)) {
        // }
      }
    });
  }

  updateDateLabels() {
    const data = this.data;
    let i = 0;
    for (let index in data) {
      if (data.hasOwnProperty(index)) {
        let formatedDates = this.date_manager.formatDate(index, parseInt(i) === 0, i + 1 === data.length);
        this.dateLabels.push(formatedDates);
      }
      i++;
    }
  }
}

export default PeriodRenderer;

import $ from "jquery";

const TO_CONFIRM_ID_SUFFIX = "-to-confirm";
const CONFIRM_ID_SUFFIX = "-confirm";

class ToConfirmButton {
  constructor(id_prefix, reset_after_confirm = false) {
    this._to_confirm = $(id_prefix + TO_CONFIRM_ID_SUFFIX);
    this._confirm = $(id_prefix + CONFIRM_ID_SUFFIX);

    this._waiting_for_confirm = false;

    this._callback = () => {};
    this._first_click_callback = () => {};

    this._to_confirm.on("click", (e) => {
      if (this._first_click_callback(e)) {
        this._to_confirm.hide();
        this._confirm.show();
        this._waiting_for_confirm = true;
      }
    });

    this._confirm.on("click", (e) => {
      this._callback(e);
      if (reset_after_confirm) {
        this.reset();
      }
    });
  }

  text(val) {
    this._to_confirm.text(val);
  }

  click(callback = null, firstClickCallback = () => {}) {
    if (callback === null) {
      if (this._waiting_for_confirm) {
        this._confirm.click();
        return;
      }
      this._to_confirm.click();
      return;
    }

    this._callback = callback;
    this._first_click_callback = firstClickCallback;
  }

  hide() {
    if (this._waiting_for_confirm === true) {
      this._confirm.hide();
    } else {
      this._to_confirm.hide();
    }
  }

  show() {
    if (this._waiting_for_confirm === true) {
      this._confirm.show();
    } else {
      this._to_confirm.show();
    }
  }

  reset() {
    this._to_confirm.show();
    this._confirm.hide();
    this._waiting_for_confirm = false;
  }
}

export default ToConfirmButton;

// import { Application } from "@hotwired/stimulus";
// import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

// window.Stimulus = Application.start();
// const context = require.context("./controllers", true, /^(?!.*(?:hmi)).*\.js$/);
// Stimulus.load(definitionsFromContext(context));

import { startStimulusApp, registerControllers } from "vite-plugin-symfony/stimulus/helpers";

const app = startStimulusApp();
registerControllers(
  app,
  import.meta.glob(["./controllers/**/*_controller.js", "!./controllers/hmi", , "!./controllers/admin"], {
    query: "?stimulus",
    eager: true,
  }),
);

import { Controller } from "@hotwired/stimulus";
import { datePickers } from "@js/helpers/datepickers";
import EnergyPackMeasuresRenderer from "@js/renderers/Measures/EnergyPackMeasuresRenderer";
import EnergyPackPeriodRenderer from "@js/renderers/Periods/EnergyPackPeriodRenderer";
import IssueNewMeasureForm from "@js/helpers/IssueNewMeasureForm";
import EnergyPackNewMeasureForm from "@js/pages/energypack/EnergyPackNewMeasureForm";
import $ from "jquery";

export default class extends Controller {
  connect() {
    const pikadayElements = datePickers();
    let measuresRenderer = new EnergyPackMeasuresRenderer();
    measuresRenderer.init();
    let periodRenderer = new EnergyPackPeriodRenderer();
    periodRenderer.init();

    let newMeasureForm = new EnergyPackNewMeasureForm(pikadayElements.new, $(".energy-side"));
    newMeasureForm.init();
    newMeasureForm.addDataSentCallback(measuresRenderer.render);
    newMeasureForm.addDataSentCallback(periodRenderer.render);
    periodRenderer.registerswitchCircuitCallback(newMeasureForm.setCircuit, false);
  }
}

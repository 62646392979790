import MeasuresRenderer from "../MeasuresRenderer";
import RenderDataStore from "../../entity/RenderDataStore";
import { formatNumber } from "../../helpers/utils";
import $ from "jquery";

class RegulationPackMeasuresRenderer extends MeasuresRenderer {
  constructor(container = $(".ajax-measures")) {
    super(container);

    // Attendance measures
    this.attendanceDataStore = new RenderDataStore(
      this.constructor.renderer_type,
      "attendance",
      this.poolId,
      null,
      null,
      this.clientId,
      this.selectedPeriodicity,
    );
    this.registerDataStore(this.attendanceDataStore);

    //After this call, the attendanceDataStore will be updated within the dateStart and dateEnd setters
    this.attendanceDataStore.dateStart = this.dateStart;
    this.attendanceDataStore.dateEnd = this.dateEnd;

    // Values to fill with datas from DataStore
    this.values = {
      totalSwimmers: null,
      avgDailySwimmers: null,
      totalSwimmersEvolution: null,
    };
    this.unit = "mg/L";
  }

  //Two dataStore, so not calling the parent render method
  async render() {
    let dataLoaded = this.dataStore.loadMeasures();

    let res = null;
    try {
      res = await dataLoaded;
    } catch (error) {
      return;
    }

    this.helper = res.helper;
    this.displayIndicator(res.data);
    this.drawCircles(res);
    this.updateValues(res);
    this.hide_loaders();
    return dataLoaded;
  }

  updateSummary(val) {
    super.updateSummary(val);

    this.update_attendance(val);
  }

  drawCircles(response) {
    super.drawCircles(response, false, null, 2);
  }

  updateValues(val) {
    super.updateValues(val);

    // Prints values
    let data = val.data;
    for (let i in data.indicators) {
      let indicator = data.indicators[i];
      let element = $(`#js-regulationpack-${i}-stat`);
      let reference = indicator.reference;
      let value = data[reference];

      this.displayIndicatorStat(element, value, indicator.unit, 2);
    }
  }

  static get pack_name() {
    return "regulation";
  }

  update_attendance(val) {
    let helper = val.helper;

    let dateStart = DateTime.fromISO(helper.start);
    let dateEnd = DateTime.fromISO(helper.end);
    let previousDateStart = dateStart
      .set({ year: helper.reference_year })
      .setLocale(navigator.language)
      .toLocaleString(DateTime.DATE_MED);
    let previousDateEnd = dateEnd
      .set({ year: helper.reference_year })
      .setLocale(navigator.language)
      .toLocaleString(DateTime.DATE_MED);

    let toolip = ` Par rapport à la période du ${previousDateStart} au ${previousDateEnd}`;
    $(`.period-tooltip`).find(".tooltip-text").find("b").text(toolip);

    let swimmersSum = null;
    let avgDailySwimmers = null;
    let messageYesterday = "Par rapport à hier";
    let messagePeriod = "Par rapport à la période précédente";
    let avgDiff = this.dateEnd.diff(this.dateStart).as("days") + 1;

    // Queries
    let q_evol_sum = $("#js-attendancepack-evolutionsummary-stat");
    let q_evol_sum_b = q_evol_sum.find(".statnumber-stat");
    let q_evol_sum_span = q_evol_sum.find("span");
    let q_total_sum_b = $("#js-attendancepack-totalsummary-stat").find(".statnumber-stat");
    let q_avg_sum_b = $("#js-attendancepack-avgsummary-stat").find(".statnumber-stat");

    q_evol_sum.find("svg").remove();
    q_evol_sum.prepend(this.getEvolutionArrow(val.data.attendance_pool.evolution));
    // q_evol_sum_b.removeClass(this.evolution_arrows.up_green, this.evolution_arrows.down_red);
    // q_evol_sum_b.addClass(this.getEvolutionArrow(val.data.attendance_pool.evolution));

    swimmersSum = val.data.attendance_pool.value;

    // This condition avoids divisions by 0 which causes an "Infinity" result
    if (avgDiff <= 1) {
      avgDailySwimmers = swimmersSum;
    } else {
      avgDailySwimmers = parseFloat(swimmersSum / avgDiff).toFixed(1);
    }

    // Prints values
    q_total_sum_b.html(formatNumber(swimmersSum, 0));
    q_avg_sum_b.html(formatNumber(Math.round(avgDailySwimmers, 0)));

    q_evol_sum.find(".statnumber-stat").text(formatNumber(val.data.attendance_pool.evolution, true) + "%");
    q_evol_sum
      .find(".statnumber-raw")
      .text(formatNumber(val.data.attendance_pool.delta, true) + " " + val.helper.indicators[0].unit);

    // Prints the right wording depending on dates diff
    if (this.date_manager.isOneDay) {
      q_evol_sum_span.html(messageYesterday);
    } else {
      q_evol_sum_span.html(messagePeriod);
    }
  }

  get target() {
    return "pond";
  }
}

export default RegulationPackMeasuresRenderer;

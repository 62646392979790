import $ from "jquery";

import.meta.glob(["@images/**/*"]);

import "./js/main.js";
import "./js/helpers/utils.js";

import * as Sentry from "@sentry/browser";

console.log(process.env.APP_ENV);
console.log(process.env.RELEASE);

if (process.env.APP_ENV == "prod" || document.location.hostname == "hippocampe.onsen-sas.com") {
  Sentry.init({
    dsn: "https://3e8e9eae35bf4c709d4c9971b894ba71@o985241.ingest.sentry.io/5941412",
    environment: process.env.APP_ENV,
    release: process.env.RELEASE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function importAll(r) {
  return r.keys().map(r);
}

// import styles
import "@fortawesome/fontawesome-free/js/all";

import "bootstrap";

// import js

// start the Stimulus application
import "./bootstrap";

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker.register(new URL("./js/pwa.js", import.meta.url));
// }

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

import "@alenaksu/json-viewer";

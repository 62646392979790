import { Controller } from "@hotwired/stimulus";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";
import { formatNumber } from "../../js/helpers/utils";
import $ from "jquery";

export default class extends Controller {
  connect() {
    let block = $(this.element);
    $(function () {
      $("#report-container").on("waterReady", () => {
        let raw_data = $("#report-container").data("water").pool;
        let labels = block.data("labels");
        let translated_labels = block.data("translatedlabels");

        let color_values = {
          light: "rgba(116,221,239,1)",
          light_transparent: "rgba(116,221,239, 0.45)",
          normal: "rgba(3,134,156,1)",
          normal_transparent: "rgba(3,134,156, 0.45)",
          dark: "rgba(0,75,88,1)",
          dark_transparent: "rgba(0,75,88, 0.45)",
          other: "rgb(202,191,205)",
          other_transparent: "rgba(202,191,205, 0.45)",
          gradient_blue: "rgba(67,190,206,0.86)",
          transparent_blue: "rgba(67,190,206,0)",
          gradient_green: "rgba(61, 166, 67, 0.86)",
          transparent_green: "rgba(61, 166, 67, 0)",

          disabled: "#D8D8D5",
          valid: "rgb(96, 169, 11)",
          warning: "rgb(255, 160, 4)",
          alert: "rgb(255, 70, 0)",
        };
        let colors = [color_values.normal, color_values.dark, color_values.light, color_values.other];

        let datasets = [];
        for (let index in labels) {
          datasets.push({
            value: raw_data[labels[index]].value,
            value_ps: raw_data[labels[index]].per_swimmer.value,
            color: colors[index],
            label: translated_labels[index],
          });
        }

        let chart_datasets = [
          {
            backgroundColor: datasets.map((dataset) => dataset.color),
            data: datasets.map((dataset) => dataset.value),
            data_ps: datasets.map((dataset) => dataset.value_ps),
          },
        ];

        let data = {
          datasets: chart_datasets,
          labels: datasets.map((dataset) => dataset.label),
        };

        let options = {
          layout: {
            padding: 60,
          },
          responsive: true,
          maintainAspectRatio: false,

          labels: {
            display: true,
          },

          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
            datalabels: {
              align: "end",
              anchor: "end",
              offset: 5,
              display: "auto",
              clamp: true,
              formatter: (value, context) => {
                let total = context.dataset.data.reduce((a, b) => a + b);
                return value > 0
                  ? context.chart.data.labels[context.dataIndex] +
                      "\n" +
                      formatNumber(value, 2) +
                      " " +
                      "m³" +
                      "\n" +
                      formatNumber((100 * value) / total, true) +
                      "%"
                  : null;
              },
              color: (context) => {
                return context.dataset.backgroundColor;
              },
            },
            center_plugin: {
              data: raw_data.water_total,
            },
          },
          events: [],
        };

        let center_plugin = {
          id: "center_plugin",
          beforeDraw: (chart, args, options) => {
            const { ctx, width, height } = chart;
            const data = chart.options.plugins.center_plugin.data;

            ctx.save();
            var fontSize = 12;
            ctx.font = fontSize + "px sans-serif";
            ctx.textBaseline = "middle";

            var text = `${formatNumber(data.per_swimmer.value, 2)} L/B`,
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2 - 2 * fontSize;
            ctx.fillText(text, textX, textY);

            (text = `${formatNumber(data.value, 2)} m³`),
              (textX = Math.round((width - ctx.measureText(text).width) / 2)),
              (textY = height / 2);
            ctx.fillText(text, textX, textY);

            (text = `${formatNumber(data.evolution, 2)} %`),
              (textX = Math.round((width - ctx.measureText(text).width) / 2)),
              (textY = height / 2 + 2 * fontSize);
            ctx.fillText(text, textX, textY);
            ctx.restore();
          },
        };

        if (data.datasets[0].data.reduce((a, b) => a + b) == 0) {
          $(".block-wc-distrib-water").hide();
        }

        var ctx = block.find("#js-waterpack-histogram-distribution");
        let distrib_chart = new Chart(ctx, {
          type: "doughnut",
          cutoutPercentage: 75,
          devicePixelRatio: 2,
          data: data,
          options: options,
          plugins: [ChartDataLabels, center_plugin],
        });

        distrib_chart.update();
      });
    });
  }
}

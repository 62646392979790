import Synoptic from "./Synoptic";
import MouseListener from "./MouseListener";
import Edge from "@js/pages/hippo-local/Edge";
import cytoscape from "cytoscape";
import gridGuide from "cytoscape-grid-guide";
import _snapOnRelease from "cytoscape-grid-guide/src/snap_on_release";

import Swal from "sweetalert2";
import JSZip from "jszip";
import NUnit from "@js/pages/hippo-local/NUnit";
import NComponent from "@js/pages/hippo-local/NComponent";
import _, { isNull } from "underscore";
import NIO from "@js/pages/hippo-local/NIO";
import stylesheet from "@js/pages/hippo-local/stylesheet.json";
import { correctEdgeSegments } from "@js/pages/hippo-local/utils";
import { compareJSON } from "@js/helpers/JSONTools";
import NBase from "@js/pages/hippo-local/NBase";
import $ from "jquery";
import "select2";
import { dump_performance } from "@js/helpers/utils";

cytoscape.use(gridGuide);

const NO_OPERATION = 0;
const PATH_SELECT_OPERATION = 1;
const MANUAL_SELECT_OPERATION = 2;
var style = stylesheet.stylesheet;

export default class ConfiguratorSynoptic extends Synoptic {
  constructor(...args) {
    console.log("configurator");
    Synoptic.mode = "w";
    super(...args);
    this.mouseListener = new MouseListener($("#cy"));
  }

  bindActions() {
    super.bindActions();

    Synoptic.cy.on("add remove", (event) => {
      Synoptic.cy.trigger("endPan");
    });

    // TRIGGER MOUSE POSITION LISTENNER
    Synoptic.cy.on("tapstart", ".io", (event) => {
      this.mouseListener.currentIO = event.target;
      this.mouseListener.callback = this.updateIOPos;
      this.mouseListener.start();
    });
    Synoptic.cy.on("tapend", (event) => {
      this.mouseListener.stop();
      this.mouseListener.currentIO?.trigger("setrotation", [null, true]);
      this.mouseListener.currentIO = null;
    });

    // SET PORT ROTATION AROUND NODE
    Synoptic.cy.on("setrotation", ".io", (event, angle, round) => {
      event.target.unlock();
      Synoptic.setRotation(event.target, angle, round);
    });

    // CORRECT EDGE DRAWING
    Synoptic.cy.on("correct", "edge", (event) => {
      correctEdgeSegments(event.target);
    });
    Synoptic.cy.on("position", ".io,.anchor,.pin,.he,.unit", (event) => {
      event.target.neighborhood().trigger("correct");
    });

    // SELECT OPERATIONS
    Synoptic.cy.on("tap boxselect", ".unit, .he, edge, .io", (event) => {
      if (event.target.hasClass("io")) event.target = event.target.parent();

      event.target.toggleClass("tapped");

      if (this.currentOperation == PATH_SELECT_OPERATION) {
        let target = event.target;
        let entityOperations = target.data(`entity.operation`);

        if (Array.isArray(entityOperations)) entityOperations = { [this.currentPath]: true };
        else {
          let current = entityOperations?.[this.currentPath];
          entityOperations[this.currentPath] = !current;
        }

        if (event.target.data("pipe")) {
          let pipe = event.target.data("pipe");
          target = Synoptic.cy.elements(`[entity][pipe.global_id = ${pipe.global_id}]`);
        }

        target.data(`entity.operation`, entityOperations);
        target.toggleClass("hideOperationPathSelect");
        target.toggleClass("showOperationPathSelect");
        target.trigger("redraw");
      }
    });

    // REMOVE ON DBLTAP
    Synoptic.cy.on("dbltap", "node.removable, .unit > .io, .pin", (evt) => {
      Swal.fire({
        title: `Supprimer l'élément ?`,
        showCancelButton: true,
      }).then((event) => {
        if (event.value) {
          let node = evt.target;

          if (node.data("entity.class")) {
            Synoptic.deleteEntity({
              id: node.data("entity.global_id"),
              class: node.data("entity.class"),
            }).then(() => {
              $(node.data("dom"))?.remove();
              Synoptic.cy.nodes(`.io[_parent="${node.id()}"]`).remove();
              node.scratch("children")?.move({ parent: null });
              Synoptic.cy.remove(node);
            });
          } else if (node.hasClass("pin")) {
            Synoptic.cy.remove(node);
            node.scratch("baseEdge").show();
          }
        }
      });
    });
    Synoptic.cy.on("dbltap", "edge", (evt) => {
      Swal.fire(`Retirer le lien ?`).then((event) => {
        if (event.value) {
          let edge = evt.target;
          let inPorts = Synoptic.hops(edge, {
            outgoing: false,
            stop: `.io`,
            filter: `.io, .pin, .anchor`,
          });
          let outPorts = Synoptic.hops(edge, {
            outgoing: true,
            stop: `.io`,
            filter: `.io, .pin, .anchor`,
          });
          let entities = [];
          // BACK REMOVAL
          outPorts.each((outPort) => {
            inPorts.each(async (inPort) => {
              let _in = inPort.data("entity.global_id");
              let _out = outPort.data("entity.global_id");

              let response = await Synoptic.disconnectEntity({
                in: _in,
                out: _out,
                pipe: edge.data("pipe")?.global_id,
              });
              if (response.entities) {
                entities.push(...response.entities);
              }
            });
          });

          // FRONT REMOVAL
          let pathIn = Synoptic.hops(edge, {
            outgoing: false,
            stop: `.io`,
            filter: `.io, .pin`,
            selector: `.pin`,
          });
          let pathOut = Synoptic.hops(edge, {
            outgoing: true,
            stop: `.io`,
            filter: `.pin, .io`,
            selector: `.pin`,
          });

          Synoptic.cy.remove(edge);
          Synoptic.cy.remove(pathIn);
          Synoptic.cy.remove(pathOut);

          // Clean orphans anchors
          Synoptic.cy.nodes(".anchor").each((a) => {
            if (Synoptic.cy.elements("*").edgesTo(a).size() * a.edgesTo("*").size() == 0) Synoptic.cy.remove(a);
          });

          entities?.forEach((entity) => {
            Synoptic.cy.elements(`[entity.global_id = ${entity.global_id}]`).data("entity", entity);
          });
        }
      });
    });

    // CREATE NEW EDGE
    Synoptic.cy.on("cxttapstart", ".anchor, .io, .switchUnit", (event) => Synoptic?.eh?.start(event.target));
    Synoptic.cy.on("cxttapend", ".anchor, .io, .switchUnit", () => Synoptic?.eh?.stop());

    // GRIDGUIDE SNAP
    Synoptic.cy.nodes().on("snap", (event) => {
      let gridGuide = window.core.scratch("_gridGuide");
      let snapper = _snapOnRelease(Synoptic.cy, gridGuide.options.gridSpacing, null);
      snapper.snapNode(event.target);
    });

    // CORRECT POSITIONING ON COMPONENT FREE
    Synoptic.cy.on("free, position", (event) => {
      let node = event.target;
      let ios = Synoptic.cy.nodes(`.io[_parent = "${node.id()}"]`);
      ios.trigger("setrotation");
    });

    // FAKE COMPOUND
    // On drag start, calculate and store relative positions
    Synoptic.cy.on("grab", "node.unit", (event) => {
      const parentNode = event.target;
      const childNodes = parentNode.scratch("children");

      // Store relative positions
      childNodes.forEach((child) => {
        const childData = child.data();
        childData._relativeX = child.position("x") - parentNode.position("x");
        childData._relativeY = child.position("y") - parentNode.position("y");
      });
    });

    // On drag, move the child nodes
    Synoptic.cy.on("drag", "node.unit", (event) => {
      const parentNode = event.target;
      const childNodes = parentNode.scratch("children");

      // Update child positions
      Synoptic.cy.batch(() => {
        childNodes.forEach((child) => {
          const childData = child.data();
          child.position({
            x: parentNode.position("x") + childData._relativeX,
            y: parentNode.position("y") + childData._relativeY,
          });
        });
      });
    });

    // Update parent size when a child moves
    Synoptic.cy.on("dragfree", "node", (event) => {
      const node = event.target;
      const parentId = node.data("_parent");
      if (parentId) {
        const parentNode = Synoptic.cy.getElementById(parentId);
        if (!parentNode.grabbed()) {
          this.updateParentSize(parentNode);
        }
      }
      const childNodes = node.scratch("children") ?? Synoptic.cy.collection();
      node.union(childNodes).trigger("snap");
    });

    // Update parent size when a child is added or removed
    Synoptic.cy.on("add remove", "node", (event) => {
      const node = event.target;
      const parentId = node.data("_parent") || node.id();
      const parentNode = Synoptic.cy.getElementById(parentId);
      if (parentNode) {
        if (!parentNode.grabbed()) {
          this.updateParentSize(parentNode);
        }
      }
      const childNodes = node.scratch("children") ?? Synoptic.cy.collection();
      node.union(childNodes).trigger("snap");
    });
  }

  updateChildCache() {
    Synoptic.cy.nodes("node.unit").each((node) => {
      const parentId = node.id();
      const childNodes = Synoptic.cy.nodes(`[_parent = "${parentId}"]`);
      node.scratch("children", childNodes);
    });
  }

  setupGrid() {
    let options = {
      // On/Off Modules
      /* From the following four snap options, at most one should be true at a given time */
      snapToGridOnRelease: true, // Snap to grid on release
      snapToGridDuringDrag: false, // Snap to grid during drag
      snapToAlignmentLocationOnRelease: false, // Snap to alignment location on release
      snapToAlignmentLocationDuringDrag: false, // Snap to alignment location during drag
      distributionGuidelines: true, // Distribution guidelines
      geometricGuideline: true, // Geometric guidelines
      initPosAlignment: false, // Guideline to initial mouse position
      centerToEdgeAlignment: false, // Center to edge alignment
      resize: true, // Adjust node sizes to cell sizes
      parentPadding: false, // Adjust parent sizes to cell sizes by padding
      drawGrid: false, // Draw grid background
      panGrid: false,
      // General
      gridSpacing: 10, // Distance between the lines of the grid.
      snapToGridCenter: false,
      // Parent Padding
      parentSpacing: -1, // -1 to set paddings of parents to gridSpacing
      ignoredElems: ".io",
      guidelinesStackOrder: 10,
    };
    Synoptic.cy.gridGuide(options);
  }

  async build(response) {
    dump_performance("start build");
    await this.load();

    dump_performance("save loaded");

    let data = response;
    // $(".node-logo").remove();
    $(".nodeTooltip").remove();

    this.patterns = (
      await $.ajax({
        url: `/pool/${Synoptic.pool_id}/patterns/${Synoptic.getLayer()}`,
      })
    ).patterns;

    await this.BuildElements(data);

    if (Object.entries(Synoptic.save).length == 0) {
      console.log("load default positioning (metadata)");
      this.defaultPositioning();
    }

    Synoptic.cy.domNode();
  }

  async BuildElements(data) {
    this.requests = this.requests.filter((element) => element.readyState == 1);
    for (request in this.requests) {
      await request;
    }
    dump_performance("wait requests");

    let layer = Synoptic.getLayer();

    switch (layer) {
      case "hydraulic":
        this.buildHydraulics(data);
        break;

      case "software":
        // * Nodes
        filter(data.software, (className) => /_nodes$/.test(className)).forEach((className) => {
          className[1].forEach((n) => nodes.push(new NNode({ entity: n })));
        });
        // * Devices
        filter(data.software, (className) => /_devices$/.test(className)).forEach((className) => {
          className[1].forEach((d) => nodes.push(new NDevice({ entity: d })));
        });
        // * Objects
        filter(data.software, (className) => /_objects$/.test(className)).forEach((className) => {
          className[1].forEach((o) => nodes.push(new NObject({ entity: o })));
        });
        break;
    }
  }

  buildHydraulics(data) {
    if (Synoptic.save.elements) {
      Synoptic.cy.json(Synoptic.save);
      Synoptic.cy.nodes(".he, .unit").unlock();

      // Synoptic.cy.nodes(".he, .unit").each(function (node) {
      //   let nclass = node.data("entity.class");
      //   if (!data.site_description[nclass]?.some((entry) => entry.global_id == node.data("entity.global_id"))) {
      //     node.remove(); // filter out removed elements
      //   }
      // });

      Synoptic.cy.elements().not(".cross").addClass("invalidate");
    }
    Synoptic.cy.style(style);

    // else {
    // JS Object helper
    let filter = (d, f) => Object.entries(d).filter((e) => f(...e));

    let nodes = [];

    let circuit = data.site_description.circuits.find((circuit) => circuit?.circuit_id == Synoptic.getCircuit());

    let units = []; // Keep track of added units
    let components = []; // Keep track of added components (component)

    Synoptic.cy.startBatch();
    // * Units
    filter(
      data.site_description,
      (className) =>
        Synoptic.prototypes?.find((element) => element.field == className)?.root === "App\\Entity\\Pool\\Unit\\Unit" &&
        !/^switch_controls$/.test(className),
    ).forEach((className) => {
      className[1]
        .filter((entity) => entity.circuit_ids?.includes(circuit?.circuit_id))
        .forEach((entity) => {
          units.push(entity);
          entity["class"] = className[0];
          nodes.push(new NUnit({ entity: entity }));
        });
    });

    dump_performance("build units");
    // * Hydraulic Entities
    filter(
      data.site_description,
      (className) =>
        Synoptic.prototypes?.find((element) => element.field == className)?.root === "App\\Entity\\Utils\\Component",
    ).forEach((className) => {
      className[1]
        .filter(
          (entity) =>
            (entity.circuit_ids?.includes(circuit?.circuit_id) || // ? was created in this circuit (in case of orphan component)
              units.some((u) => u.global_id == entity.functional_unit_global_id) || // ? In the current units
              /^sewages$/.test(className[0])) &&
            !/^piping_components$/.test(className[0]),
        )
        .forEach((entity) => {
          entity["class"] = className[0];
          components.push(entity);

          let parentId = entity.functional_unit_global_id ? `u_${entity.functional_unit_global_id}` : null;
          let parent = parentId ? Synoptic.cy.$id(parentId) : null;
          if (isNull(parentId) || parent.length > 0) {
            let component = new NComponent({ entity: entity });
            component.obj.scratch("parent", parent).data("_parent", parentId);
            nodes.push(component);
          }
        });
    });
    dump_performance("build he");

    // * Switch Control*
    filter(data.site_description, (className) => /^switch_controls$/.test(className)).forEach((className) => {
      className[1]
        .filter((entity) => entity.circuit_ids?.includes(circuit?.circuit_id))
        .forEach((entity) => {
          entity["class"] = className[0];
          let switchU = new NComponent({
            entity: entity,
            opacity: 1,
            backGroundOpacity: 1,
            borderWidth: 1,
          });

          switchU.obj.addClass("switchUnit");

          data.site_description?.valves
            ?.filter((valve) =>
              valve.linked_switch_controls.some((link) => link.switch_control_global_id === entity.global_id),
            )
            ?.forEach((valve) => {
              let valve_node = Synoptic.cy.$id(`he_${valve.global_id}`);

              let edge = Synoptic.cy
                .add({
                  group: "edges",
                  data: {
                    source: switchU.obj.id(),
                    target: valve_node.id(),
                    opacity: 1,
                  },
                })
                .addClass("switchControl");
            });
        });
    });

    Synoptic.cy.nodes(".he").each((node) => {
      this.addSVG(node);
    });

    // Preload unit imgs
    // Synoptic.cy.nodes(".unit").each((node) => {
    //   Synoptic.addSVG(node);
    // });

    // * Ports
    let indexes = [];
    this.ports = [];

    // V1
    if (data.site_description.ports) {
      data.site_description.ports
        ?.filter((p) => units.some((u) => u.global_id == p.unit) || components.some((c) => c.global_id == p.entity))
        ?.forEach((port) => {
          if (port.unit) {
            let unit = Synoptic.cy.$id("u_" + port.unit);
            indexes[port.unit] = (indexes[port.entity] ?? -1) + 1;
          }
          if (port.entity) {
            indexes[port.entity] = (indexes[port.entity] ?? -1) + 1;
          }
          let parentId = port.entity ? "he_" + port.entity : "u_" + port.unit;
          if (Synoptic.cy.$id(parentId).length == 0) {
            return;
          }

          let parent = Synoptic.cy.$id(parentId);
          let parentPorts = parent.data("ports") ?? [];
          parentPorts.push("io_" + port.global_id);
          parent.data("ports", Array.from(new Set(parentPorts)));

          let new_port = new NIO(
            {
              label: "",
              id: "io_" + port.global_id,
              entity: port,
              color: !port.name?.includes("out") ? "blue" : "red",
              opacity: 1,
              width: 10,
              height: 10,
              node: parentId,
              _parent: parentId,
              index: indexes[port.entity] ?? indexes[port.unit] ?? -1,
              pipe_out: port.pipe_out ?? null,
              pipe_in: port.pipe_in ?? null,
            },
            parent,
          );

          this.ports.push(new_port);
        });
    } else {
      Synoptic.cy.nodes(".he").forEach((element) => {
        let new_port = new NIO(
          {
            label: "",
            id: "io_" + element.id(),
            entity: null,
            color: "orange",
            opacity: 1,
            width: 10,
            height: 10,
            node: element.id(),
            _parent: element.id(),
            index: 0,
            shape: "ellipse",
          },
          element,
        );
        this.ports.push(new_port);
      });
    }

    // build piping systems if no save

    // * Register Piping components
    filter(data.site_description, (className) => /^piping_components$/.test(className)).forEach((className) => {
      this.pipeclassName = className;
      className[1].forEach((pipe) => {
        this.createPipe(pipe);
      });
    });

    // V2
    if (!data.site_description.ports) {
      Synoptic.cy.nodes("[?entity.outlet_global_ids]").forEach((element) => {
        let outGIds = element.data("entity.outlet_global_ids");
        for (const outGId of outGIds) {
          let outComponent = Synoptic.cy.nodes(`[entity.global_id=${outGId}]`);
          if (!outComponent.length) continue;

          if (element.children(".he").length > 0 && element.hasClass("unit")) continue;
          if (outComponent.children(".he").length > 0 && outComponent.hasClass("unit")) continue;

          let branch = new Edge({
            id: `E_${element.id()}_${outComponent.id()}`,
            source: element.id(),
            target: outComponent.id(),
            entity: element.hasClass("anchor") ? element.data("entity") : outComponent.data("entity"),
          });
          Synoptic.addTypeToEntity(branch.obj);
        }
      });

      Synoptic.cy.nodes(".anchor").forEach((element) => {
        // UPDATE ANCHORS PARENTING
        let sourceParentIds = element
          .connectedEdges()
          .sources()
          .map((e) => e.data("_parent"))
          .filter((e) => e && e != element.id());
        let targetParentIds = element
          .connectedEdges()
          .targets()
          .map((e) => e.data("_parent"))
          .filter((e) => e && e != element.id());
        let neighborhoodParentIds = new Set([...sourceParentIds, ...targetParentIds]);
        let nParents = neighborhoodParentIds.size;
        if (nParents == 1) {
          let parentId = neighborhoodParentIds.values().next().value;
          element.scratch({ parent: Synoptic.cy.$id(parentId) }).data("_parent", parentId);
        }

        // REMOVE UNLINKED ANCHORS
        if (element.edgesTo(Synoptic.cy.nodes("")).length == 0 || Synoptic.cy.nodes("").edgesTo(element).length == 0) {
          element.remove();
        }
      });
    }

    dump_performance("build others");
    Synoptic.cy.endBatch();

    Synoptic.cy.edges().forEach((edge, index, edges) => {
      // correct bi-direction edges
      if (edge.isLoop()) {
        let pipe_id = edge.data("pipe.global_id");
        let pipeEdges = Synoptic.cy.filter(`[pipe.global_id=${pipe_id}]`);
        let sources = pipeEdges.sources(".io");
        let targets = pipeEdges.targets(".io");
        if (sources.same(targets)) {
          pipeEdges.remove();
          let new_edge = new Edge({
            id: `E_corrected_${pipe_id}`,
            source: sources[0].id(),
            target: sources[1].id(),
            pipe: edge.data("pipe"),
            entity: edge.data("entity"),
          });
          new_edge.obj.addClass("bidirectionnal");
        }
      }
    });
    dump_performance("correct loop edges");

    // * add type to entities
    Synoptic.cy.elements("[entity]").each((node, i) => {
      Synoptic.addTypeToEntity(node);
    });
    dump_performance("add types");
    // }

    this.pins = Synoptic.cy.nodes(".pin");

    this.pins.each((pin) => {
      let baseEdge = Synoptic.cy.$id(pin.data("baseEdge"));
      if (baseEdge.length > 0) {
        baseEdge.show();
        this.addPin(baseEdge, pin.position());
      }
    });

    Synoptic.cy.elements(".invalidate").remove();
    Synoptic.cy.elements(".cross").remove();
  }

  async postBuild() {
    super.postBuild();

    // SAVE AND DELETE COMPOUND
    dump_performance("change parenting");
    let children = Synoptic.cy.nodes(":child");
    children.each((child) => {
      child.data("_parent", child.parent().id());
      child.move({ parent: null });
    });
    dump_performance("parenting done");

    this.updateChildCache();

    Synoptic.cy.on("endPan", () => {
      Synoptic.cy.batch(() => Synoptic.cy.nodes(".unit").each(this.updateParentSize));
    });
  }

  updateParentSize(parentNode) {
    const childNodes = parentNode.scratch("children");
    if (!childNodes) return;

    if (childNodes.empty()) {
      // Reset parent size if no children
      parentNode.style({
        width: 50, // Default width
        height: 50, // Default height
      });
      return;
    }

    // Get the bounding box of all child nodes
    const bbox = childNodes.boundingBox();
    const padding = 20; // Optional padding around the children

    // Calculate new size and center for the parent
    const width = bbox.w + padding;
    const height = bbox.h + padding;
    const centerX = bbox.x1 + bbox.w / 2;
    const centerY = bbox.y1 + bbox.h / 2;

    // Update the parent's position and size
    parentNode.position({ x: centerX, y: centerY });
    parentNode.style({
      width: width,
      height: height,
    });

    // Retrigger grid positionning
    parentNode.trigger("snap");
  }

  async setupCtxMenu() {
    let parent = this;

    let nodeCommands = [];
    let unitCommands = [];
    let edgeCommands = [];
    let coreCommands = [];
    // add point
    edgeCommands.push({
      fillColor: "rgba(255, 2, 2, 1)", // optional: custom background color for item
      content: '<i class="fas fa-dot-circle"></i>', // html/text content to be displayed in the menu
      contentStyle: {}, // css key:value pairs to set the command's css in js if you want
      select: function (targetEdge) {
        // a function to execute when the command is selected
        if (targetEdge.isEdge()) {
          let pin = parent.addPin(targetEdge);
          if (pin) Synoptic.cy.center(pin.obj);
        }
      },
      enabled: true, // whether the command is selectable
    });

    // Open entity form
    nodeCommands.push({
      fillColor: "rgba(158, 95, 0, 1)", // optional: custom background color for item
      content: '<i class="fas fa-list"></i>', // html/text content to be displayed in the menu
      contentStyle: {}, // css key:value pairs to set the command's css in js if you want
      select: function (targetNode) {
        // a function to execute when the command is selected
        $.ajax({
          type: "POST",
          url: `/form`,
          data: targetNode.data("entity"),
        }).done((response) => {
          $("#cy-ui-form-content").attr("src", response + "?embed");
          $("#cy-ui-form-content").on("load", function () {});
          let context = $($("#cy-ui-form-content").context);
          $("#cy-ui-form").css("display", "flex");
        });
      },
      enabled: true, // whether the command is selectable
    });

    // Quick edit
    nodeCommands.push({
      fillColor: "rgba(255, 230, 0, 1)", // optional: custom background color for item
      content: '<i class="fas fa-edit"></i>', // html/text content to be displayed in the menu
      contentStyle: {}, // css key:value pairs to set the command's css in js if you want
      select: async (targetNode) => {
        let nameField = this.getNameField(targetNode);
        let idField = Synoptic.getIdField(targetNode);

        let name = targetNode.data(`entity.${nameField}`);
        let localId = targetNode.data(`entity.${idField}`);

        // Setup type select field
        let form = $("<div class='d-flex flex-column align-items-center'>");
        form.append(`Name: <input id="swal-name" class="swal2-input" value="${name}">`);
        form.append(`LocalId: <input type="number" id="swal-localId" class="swal2-input" value=${localId}>`);
        form.append(
          `Controllable: <input type="checkbox" id="swal-controllable" class="swal2-input"
            ${targetNode.data(`entity.controllable`) ? "checked" : ""}>`,
        );
        form.append(
          `Critical: <input type="checkbox" id="swal-critical" class="swal2-input"
            ${targetNode.data(`entity.critical`) ? "checked" : ""}>`,
        );
        let typeIDField = targetNode.data("typeIDField");

        let selectField = $(
          `<select id="swal-type" class="swal2-input" value=${targetNode.data(`entity.${typeIDField}`)} >`,
        );

        let entity = targetNode.data("entity");

        // ADD TYPE TO FORM
        let typePrototype = targetNode.data("typePrototype");
        let types = this.data.site_description?.[typePrototype?.field];
        if (types) {
          var typeNameField = typePrototype?.properties?.find((p) => p?.hipponet?.[0] == "name")?.hipponet?.[1];
          var typeIdField = typePrototype?.properties?.find((p) => p?.hipponet?.[0] == "id")?.hipponet?.[1];

          // add all types avalaible
          types?.forEach((type) => {
            let option = $(
              `<option
                ${targetNode.data(`entity.${typeIDField}`) == type?.[typeIDField] ? "selected" : ""}
                value="${type?.[typeIDField]}">${type?.[typeNameField] + "(" + type?.[typeIDField] + ")"}</option>`,
            );
            selectField.append(option);
          });

          // set current value to entity current type
          selectField.val();

          form.append(selectField);
        }

        // ADD ANALYSER TO FORM
        if (entity.class == "pond_units") {
          // let  =
        }

        // a function to execute when the command is selected
        const { value: formValues } = await Swal.fire({
          title: targetNode.data(`entity.${nameField}`),
          html: form[0].outerHTML,
          focusConfirm: false,
          showCancelButton: true,
          preConfirm: async () => {
            Swal.showLoading();
            let result = {
              [nameField]: $("#swal-name").val(),
              [idField]: $("#swal-localId").val(),
              controllable: $("#swal-controllable").prop("checked"),
              critical: $("#swal-critical").prop("checked"),
              [typeIdField ?? null]: $("#swal-type")?.val(),
            };
            let data = targetNode.data("entity");
            for (let [key, value] of Object.entries(result)) {
              data[key] = value;
            }

            if (typeIdField)
              targetNode.data(
                "type",
                types.find((type) => type?.[typeIdField] == formValues?.[typeIdField]),
              );

            const res = await $.ajax({
              type: "POST",
              url: `/pool/${Synoptic.pool_id}/synoptic/save`,
              data: JSON.stringify([data]),
              contentType: "application/json",
            });

            let diff = compareJSON(data, res[0]);
            if (Object.keys(diff).length > 0) {
              await Swal.fire({
                title: "Enregistrement echoué",
                icon: "error",
                html: `<pre data-controller="json-viewer">${JSON.stringify(diff)}</pre>`,
              });
            }

            targetNode.data(res[0]);
            return result;
          },
        });
      },
      enabled: true, // whether the command is selectable
    });

    // Hide element
    let hideCommand = {
      fillColor: "rgba(109, 99, 99, 1)", // optional: custom background color for item
      content: '<i class="fas fa-eye"></i>', // html/text content to be displayed in the menu
      contentStyle: {}, // css key:value pairs to set the command's css in js if you want
      select: async (targetNode) => {
        let wasHidden = targetNode.hasClass("userHidden");
        let nodes = targetNode.descendants().or(targetNode);
        if (wasHidden) {
          nodes.or(Synoptic.cy.elements().edgesTo(nodes)).removeClass("userHidden").trigger("data");
        } else {
          nodes.or(Synoptic.cy.elements().edgesTo(nodes)).addClass("userHidden").trigger("data");
        }
      },
      enabled: true, // whether the command is selectable
    };

    nodeCommands.push(hideCommand);
    edgeCommands.push(hideCommand);

    // Add element
    let addCommand = {
      fillColor: "rgba(0, 200, 0, 1)", // optional: custom background color for item
      content: '<i class="fas fa-plus"></i>', // html/text content to be displayed in the menu
      contentStyle: {}, // css key:value pairs to set the command's css in js if you want
      select: async (targetNode, event) => {
        let pos = event.position;
        let unit = "cy" in targetNode ? targetNode : null;

        let unitOptions = parent.patterns["Unité"].childs;
        let componentOptions = parent.patterns["Entité"].childs;

        let options = { Unités: {}, Composants: {} };
        let optionsData = {};

        for (const componentKey in componentOptions) {
          options["Composants"][componentOptions[componentKey].class] = componentKey;
          optionsData[componentOptions[componentKey].class] = componentOptions[componentKey];
        }

        if (!unit) {
          for (const unitKey in unitOptions) {
            options["Unités"][unitOptions[unitKey].class] = unitKey;
            optionsData[unitOptions[unitKey].class] = unitOptions[unitKey];
          }
        }
        // SELECT OBJECT TYPE
        let swal = Swal.mixin({
          title: "Selection du nouvel élément",
        });

        const select_event = await swal.fire({
          progressSteps: ["Type"],
          currentProgressStep: 0,
          input: "select",
          inputOptions: options,
          inputPlaceholder: "Selectionner un élément",
          showCancelButton: true,
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value) {
                resolve();
              } else {
                resolve("You need to select an option");
              }
            });
          },
        });
        if (!select_event.isConfirmed) return;

        let nPort = 0;
        if (options["Composants"]?.[select_event.value]) {
          const port_event = await swal.fire({
            progressSteps: ["Type", "Ports"],
            currentProgressStep: 1,
            icon: "question",
            title: `Ajouter des ports ?`,
            showCancelButton: true,
            input: "range",
            inputLabel: "Nombre de port",
            inputAttributes: {
              min: 0,
              max: 10,
              step: 1,
            },
            inputValue: 2,
          });
          if (!port_event.isConfirmed) return;
          nPort = port_event.value;
        }

        let payload = {
          pool: Synoptic.pool_id,
          circuit: parseInt(Synoptic.getCircuit()),
          nPort: nPort,
          label: options["Unités"]?.[select_event.value] ?? options["Composants"]?.[select_event.value],
          class: select_event.value,
          unit: unit?.data("entity.global_id"),
        };
        let _new = await this.createEntity(payload, pos);
        Synoptic.cy.trigger("endPan");
      },
      enabled: true, // whether the command is selectable
    };

    unitCommands.push(addCommand);
    coreCommands.push(addCommand);

    //Menu parameters
    var default_parameters = function (selectors, commands) {
      return {
        menuRadius: function (ele) {
          return 200;
        }, // the outer radius (node center to the end of the menu) in pixels. It is added to the rendered size of the node. Can either be a number or function as in the example.
        selector: selectors, // elements matching this Cytoscape.js selector will trigger cxtmenus
        commands: commands, // function( ele ){ return [ /*...*/ ] }, // a function that returns commands or a promise of commands
        fillColor: "rgba(0, 0, 0, 0.75)", // the background colour of the menu
        activeFillColor: "rgba(1, 105, 217, 0.75)", // the colour used to indicate the selected command
        activePadding: 20, // additional size in pixels for the active command
        indicatorSize: 24, // the size in pixels of the pointer to the active command, will default to the node size if the node size is smaller than the indicator size,
        separatorWidth: 3, // the empty spacing in pixels between successive commands
        spotlightPadding: 4, // extra spacing in pixels between the element and the spotlight
        adaptativeNodeSpotlightRadius: false, // specify whether the spotlight radius should adapt to the node size
        minSpotlightRadius: 24, // the minimum radius in pixels of the spotlight (ignored for the node if adaptativeNodeSpotlightRadius is enabled but still used for the edge & background)
        maxSpotlightRadius: 38, // the maximum radius in pixels of the spotlight (ignored for the node if adaptativeNodeSpotlightRadius is enabled but still used for the edge & background)
        openMenuEvents: "taphold", // space-separated cytoscape events that will open the menu; only `cxttapstart` and/or `taphold` work here
        itemColor: "white", // the colour of text in the command's content
        itemTextShadowColor: "grey", // the text shadow colour of the command's content
        zIndex: 9999, // the z-index of the u div
        atMouse: true, // draw menu at mouse position
        outsideMenuCancel: false, // if set to a number, this will cancel the command if the pointer is released outside of the spotlight, padded by the number given
      };
    };

    //Menu assignation for interactive nodes
    let nodeCommandsParams = default_parameters(".he", nodeCommands);
    let unitCommandsParams = default_parameters(".unit", [...nodeCommands, ...unitCommands]);
    let edgeCommandsParams = default_parameters(".edge", edgeCommands);
    let coreCommandsParams = default_parameters("core", coreCommands);

    //menu instanciation
    Synoptic.cy.cxtmenu(nodeCommandsParams);
    Synoptic.cy.cxtmenu(unitCommandsParams);
    Synoptic.cy.cxtmenu(edgeCommandsParams);
    Synoptic.cy.cxtmenu(coreCommandsParams);
  }

  async download(onComplete = false) {
    const response = await $.ajax({
      url: `/pool/${Synoptic.pool_id}/structure/download?cids`,
    });
    const zip = new JSZip();

    Object.keys(response).forEach((key) => {
      if (!["save", "site_description", "synoptic"].includes(key)) {
        Object.keys(response[key]).forEach((key2) => {
          zip.file(`${key2}.json`, JSON.stringify(response[key][key2], null, 2));
        });
      } else {
        zip.file(`${key}.json`, JSON.stringify(response[key], null, 2));
      }
    });
    const content = await zip.generateAsync({ type: "blob" });
    var a = document.createElement("a");
    var file = new Blob([content], { type: "blob" });
    a.href = URL.createObjectURL(file);
    a.download = `config_${new Date().toISOString()}.zip`;
    a.click();
  }

  onAppReady() {
    super.onAppReady();
    Synoptic.cy.trigger("pan");
    setTimeout(() => {
      Synoptic.cy.nodes().trigger("setrotation", [null, true]);
      Synoptic.cy.batch(() => Synoptic.cy.nodes(".unit").each(this.updateParentSize));
      Synoptic.cy.nodes().not(".io").trigger("snap");
    }, 10);

    this.hideLoader();
  }

  correct() {
    console.log("CORRECT EDGES");
    Synoptic.cy.elements().trigger("position correct");
  }

  // Create node(entity) in DB
  async createEntity(payload, pos) {
    let _new = null;
    let response = await fetch(`/pool/${Synoptic.pool_id}/structure/add/element`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });

    let entity = await response.json();
    let proto = Synoptic.prototypes?.find((element) => element.field == entity?.class);
    switch (proto.root) {
      case "App\\Entity\\Pool\\Unit\\Unit":
        _new = new NUnit({ entity: entity });

        entity?.ports?.forEach((port, index) => {
          let new_port = new NIO(
            {
              label: "",
              id: "io_" + port.global_id,
              entity: port,
              color: !port.name?.includes("out") ? "blue" : "red",
              opacity: 1,
              width: 10,
              height: 10,
              _parent: _new.obj.id(),
              index: index,
              pipe_out: port.pipe_out ?? null,
              pipe_in: port.pipe_in ?? null,
            },
            _new.obj,
          );
        });

        break;
      case "App\\Entity\\Utils\\Component":
        let parentId = "u_" + payload.unit;
        let parent = Synoptic.cy.$id(parentId);
        _new = new NComponent({
          entity: entity,
        });
        _new.scratch("parent", parent).data("_parent", parentId);
        let children = parent.scratch("children") ?? Synoptic.cy.collection();
        parent.scratch("children", children.union(_new.obj));

        entity?.ports?.forEach((port, index) => {
          let new_port = new NIO(
            {
              label: "",
              id: "io_" + port.global_id,
              entity: port,
              color: !port.name?.includes("out") ? "blue" : "red",
              opacity: 1,
              width: 10,
              height: 10,
              _parent: _new.obj.id(),
              index: index,
              pipe_out: port.pipe_out ?? null,
              pipe_in: port.pipe_in ?? null,
            },
            _new.obj,
          );
          _new.scratch("children", _new.scratch("children").union(new_port.obj));
        });

        this.addSVG(_new.obj);
        break;

      default:
        _new = new NComponent({
          entity: entity,
          parent: entity.entity ? "he_" + entity.entity : "u_" + entity.unit,
        });
        break;
    }

    if (_new) {
      _new.obj.position(pos);
      return _new;
    }
  }

  // Create a new pipe with an anchor
  createPipe(pipe, inPorts = null, outPorts = null) {
    let anchor = null;
    pipe["class"] = this.pipeclassName[0];

    // V1
    if (this.data.site_description.ports) {
      inPorts ??= Synoptic.cy
        .nodes(".io")
        .filter((p) => p.data("entity.pipe_out")?.some((pipe_id) => pipe_id == pipe.global_id));
      outPorts ??= Synoptic.cy
        .nodes(".io")
        .filter((p) => p.data("entity.pipe_in")?.some((pipe_id) => pipe_id == pipe.global_id));

      if (inPorts.length > 0 && outPorts.length > 0) {
        //CREATE ANCHOR
        let anchorId = `anchor_${pipe?.piping_component_id}`;
        let anchorSave = Synoptic.save?.[anchorId];
        let anchorPos = {
          x: isNaN(parseFloat(anchorSave?.pos?.x)) ? 0 : parseFloat(anchorSave?.pos?.x),
          y: isNaN(parseFloat(anchorSave?.pos?.y)) ? 0 : parseFloat(anchorSave?.pos?.y),
        };

        let parents = inPorts
          .union(outPorts)
          .connectedEdges()
          .connectedNodes()
          .map((e) => e.parents(".unit").id());

        let nParents = new Set(parents.filter((e) => e)).size;

        if (nParents > 1) {
          inPorts.union(outPorts).filter(".io").addClass("extPort");
        }

        anchor = Synoptic.add({
          group: "nodes",
          data: {
            id: anchorId,
            parent: nParents == 1 ? this.array_mode(parents) : null,
            color: "brown",
            // label: pipe?.piping_component_id,
            pipe: pipe,
          },
        });
        anchor.addClass("anchor").addClass("grid");
        if (anchorSave) anchor.position(anchorPos);

        inPorts.forEach((inPort) => this.addPipeInput(pipe, inPort));
        outPorts.forEach((outPort) => this.addPipeOutput(pipe, outPort));
      }
      return anchor ?? Synoptic.cy.collection();
    }
    // V2
    else {
      // Anchor represent the pipe component itself
      let anchorId = `anchor_${pipe?.global_id}`;
      let anchorSave = Synoptic.save?.[anchorId];
      let anchorPos = {
        x: isNaN(parseFloat(anchorSave?.pos?.x)) ? 0 : parseFloat(anchorSave?.pos?.x),
        y: isNaN(parseFloat(anchorSave?.pos?.y)) ? 0 : parseFloat(anchorSave?.pos?.y),
      };
      anchor = Synoptic.add({
        group: "nodes",
        data: {
          id: anchorId,
          parent: null,
          color: "brown",
          tooltip: pipe?.piping_component_name,
          entity: pipe,
          pipe: pipe,
        },
      });

      anchor.addClass("anchor").addClass("grid");
      if (anchorSave) anchor.position(anchorPos);
    }
  }

  addPin(targetEdge, prevPos = null) {
    // V2
    if (!this.data.site_description.ports) {
      let selector = `.io, .anchor, .pin, .unit, .he`;
      let sourcePort = Synoptic.hops(targetEdge, {
        outgoing: false,
        stop: selector,
        filter: selector,
      });
      let targetPort = Synoptic.hops(targetEdge, {
        outgoing: true,
        stop: selector,
        filter: selector,
      });

      if (sourcePort.size() * targetPort.size() == 0) {
        return null;
      }

      let pos = prevPos ?? targetEdge.midpoint();

      let parents = new Set(
        sourcePort
          .union(targetPort)
          .connectedEdges()
          .connectedNodes()
          .map((e) => e.parents(".unit").id()),
      );
      parents.delete(undefined);

      let newPoint = new NBase({
        id: `pin_${targetEdge.id()}`,
        color: "magenta",
        width: 5,
        height: 5,
        zIndex: 15,
        parent: parents.size == 1 ? parents[0] : null,
      });

      newPoint.obj
        .position(pos)
        .trigger("snap")
        .addClass("pin")
        .addClass("removable")
        .addClass("grid")
        .data("baseEdge", targetEdge.id()) // for saving
        .data("id", `pin_${targetEdge.id()}`)
        .scratch("baseEdge", targetEdge) // for current use, as it might be deleted
        .on("position", (e) => {
          newPoint.obj.connectedEdges().trigger("correct");
        })
        .data({ width: 10, height: 10 });

      let e1 = new Edge({
        id: `E_${newPoint.id()}_in`,
        source: sourcePort.id(),
        target: newPoint.obj.id(),
        pipe: targetEdge.data("pipe"),
        entity: targetEdge.data("pipe"),
        pin: newPoint.obj.id(),
      });

      let e2 = new Edge({
        id: `E_${newPoint.id()}_out`,
        source: newPoint.obj.id(),
        target: targetPort.id(),
        pipe: targetEdge.data("pipe"),
        entity: targetEdge.data("pipe"),
        pin: newPoint.obj.id(),
      });
      e1?.obj?.addClass("pinIn");
      e2?.obj?.addClass("pinOut");

      newPoint.obj.scratch("e1", e1);
      newPoint.obj.scratch("e2", e2);

      targetEdge.hide();
      return newPoint;
    } else {
      let selector = `.io, .anchor, .pin, .unit`;
      let sourcePort = Synoptic.hops(targetEdge, {
        outgoing: false,
        stop: selector,
        filter: selector,
      });
      let targetPort = Synoptic.hops(targetEdge, {
        outgoing: true,
        stop: selector,
        filter: selector,
      });

      if (sourcePort.size() * targetPort.size() == 0) {
        return null;
      }

      let pos = prevPos ?? targetEdge.midpoint();

      let parents = new Set(
        sourcePort
          .union(targetPort)
          .connectedEdges()
          .connectedNodes()
          .map((e) => e.parents(".unit").id()),
      );

      let newPoint = new NBase({
        id: `pin_${targetEdge.id()}`,
        color: "magenta",
        width: 5,
        height: 5,
        zIndex: 15,
      });

      newPoint.obj
        .position(pos)
        .trigger("snap")
        .addClass("pin")
        .addClass("removable")
        .addClass("grid")
        .data("baseEdge", targetEdge.id()) // for saving
        .data("id", `pin_${targetEdge.id()}`)
        .scratch("baseEdge", targetEdge) // for current use, as it might be deleted
        .on("position", (e) => {
          newPoint.obj.connectedEdges().trigger("correct");
        })
        .data({ width: 10, height: 10 });

      newPoint.obj.data("_parent", parents.size == 1 ? parents[0] : null);
      newPoint.obj.scratch("parent", parents.size == 1 ? Synoptic.cy.$id(parents[0]) : null);

      let e1 = new Edge({
        id: `E_${newPoint.id()}_in`,
        source: sourcePort.id(),
        target: newPoint.obj.id(),
        pipe: targetEdge.data("pipe"),
        entity: targetEdge.data("pipe"),
        pin: newPoint.obj.id(),
      });

      let e2 = new Edge({
        id: `E_${newPoint.id()}_out`,
        source: newPoint.obj.id(),
        target: targetPort.id(),
        pipe: targetEdge.data("pipe"),
        entity: targetEdge.data("pipe"),
        pin: newPoint.obj.id(),
      });
      e1?.obj?.addClass("pinIn");
      e2?.obj?.addClass("pinOut");

      newPoint.obj.scratch("e1", e1);
      newPoint.obj.scratch("e2", e2);

      targetEdge.hide();
      return newPoint;
    }
  }

  setupEdgeHandles() {
    let defaults = {
      canConnect: function (sourceNode, targetNode) {
        let portfilter = (e) => e.filter(".io, .anchor").size() > 0;
        // whether an edge can be created between source and target
        let cond1 = !sourceNode.same(targetNode); //forbid loop
        let cond2 = portfilter(sourceNode) && portfilter(targetNode); // port to port
        let cond3 = sourceNode.id() == "tmp" && targetNode.hasClass("io"); // pipe to port or unitsW
        let cond4 =
          sourceNode.data("entity.class") == "switch_controls" &&
          targetNode.data("entity.linked_switch_controls") !== undefined; // switch to actuator

        return cond1 && (cond2 || cond3 || cond4);
      },
      edgeParams: function (sourceNode, targetNode) {
        // for edges between the specified source and target
        // return element object to be passed to Synoptic.cy.add() for edge
        return {};
      },
      hoverDelay: 150, // time spent hovering over a target node before it is considered selected
      snap: true, // when enabled, the edge can be drawn by just moving close to a target node (can be confusing on compound graphs)
      snapThreshold: 50, // the target node must be less than or equal to this many pixels away from the cursor/finger
      snapFrequency: 30, // the number of times per second (Hz) that snap checks done (lower is less expensive)
      noEdgeEventsInDraw: true, // set events:no to edges during draws, prevents mouseouts on compounds
      disableBrowserGestures: true, // during an edge drawing gesture, disable browser gestures such as two-finger trackpad swipe and pinch-to-zoom
    };

    Synoptic.eh = Synoptic.cy.edgehandles(defaults);

    Synoptic.cy.on("ehstart", () => {
      Synoptic.cy.nodes(".logo").addClass("noeventsEe");
    });
    Synoptic.cy.on("ehstop", () => {
      Synoptic.cy.nodes(".logo").removeClass("noeventsEe");
    });

    Synoptic.cy.on("ehcomplete", async (event, sourceNode, targetNode, addedEdge) => {
      let midPoint = addedEdge.midpoint();
      let response = null;
      if (targetNode.hasClass("io") && sourceNode.hasClass("io")) {
        response = await Synoptic.connectEntity({
          in: sourceNode.data("entity.global_id"),
          out: targetNode.data("entity.global_id"),
          piped: true,
        });

        let anchor = this.createPipe(response.pipe, sourceNode, targetNode);
        anchor.position(midPoint);
      } else if (targetNode.hasClass("anchor")) {
        response = await Synoptic.connectEntity({
          in: sourceNode.data("entity.global_id"),
          out: 0,
          piped: true,
          type: "output",
          pipe: targetNode.data("pipe")?.global_id,
        });
        this.addPipeInput(response.pipe, sourceNode);
      } else if (sourceNode.hasClass("anchor")) {
        response = await Synoptic.connectEntity({
          in: 0,
          out: targetNode.data("entity.global_id"),
          piped: true,
          type: "input",
          pipe: sourceNode.data("pipe")?.global_id,
        });
        this.addPipeOutput(response.pipe, targetNode);
      }

      if (sourceNode.hasClass("switchUnit")) {
        response = await Synoptic.connectSwitch({
          switchControl: sourceNode.data("entity"),
          actuator: targetNode.data("entity"),
        });
        addedEdge.addClass("switchControl");
      } else {
        Synoptic.cy.remove(addedEdge);
        sourceNode.connectedEdges().trigger("correct");
      }

      response?.entities?.forEach((entity) => {
        Synoptic.cy.elements(`[entity.global_id = ${entity.global_id}]`).data("entity", entity);
      });
    });
  }
}

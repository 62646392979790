import "bootstrap";
import $ from "jquery";

$.fn["any"] = function () {
  return this.length > 0;
};

$.expr[":"].icontains = function (a, i, m) {
  return (
    $(a)
      .text()
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .indexOf(
        m[3]
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""),
      ) >= 0
  );
};

$("[data-toggle=popover]").popover({
  html: true,
  content: function () {
    return $("#popover-content").html();
  },
});

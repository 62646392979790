export function compareJSON(json1, json2) {
  const result = {};

  // Fonction récursive pour comparer les objets et sous-objets
  function compareObjects(obj1, obj2, path) {
    // Vérifier si les objets sont de type 'object'
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      // Comparer les valeurs primitives
      if (obj1 != obj2) {
        result[path] = obj2;
      }
      return;
    }

    // Parcourir toutes les clés de l'objet 1
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        const currentPath = path ? path + "." + key : key;

        // Vérifier si la clé existe dans l'objet 2
        if (obj2.hasOwnProperty(key)) {
          const value1 = obj1[key];
          const value2 = obj2[key];

          // Récursion pour les objets imbriqués
          compareObjects(value1, value2, currentPath);
        } else {
          // La clé n'existe pas dans l'objet 2, donc elle a été supprimée
          result[path] = null;
        }
      }
    }

    // Vérifier les clés supplémentaires dans l'objet 2
    for (let key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        const currentPath = path ? path + "." + key : key;
        result[currentPath] = obj2[key];
      }
    }
  }
  function isNumericIndex(value) {
    return /^\d+$/.test(value);
  }
  // Comparer les deux objets JSON
  compareObjects(json1, json2, "");

  // Fonction pour regrouper les clés avec leurs valeurs dans un objet imbriqué
  function groupKeys(obj) {
    const groupedObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const parts = key.split(".");
        const topLevelKey = parts[0];
        if (topLevelKey == "") continue;
        const remainingPath = parts.slice(1).join(".");

        if (!groupedObj.hasOwnProperty(topLevelKey)) {
          groupedObj[topLevelKey] = {};
        }

        if (remainingPath !== "") {
          if (!groupedObj[topLevelKey]) {
            groupedObj[topLevelKey] = {};
          }
          groupedObj[topLevelKey][remainingPath] = obj[key];
        } else {
          groupedObj[topLevelKey] = obj[key];
        }
      }
    }
    return groupedObj;
  }

  return groupKeys(result);
}

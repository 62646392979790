import { Controller } from "@hotwired/stimulus";
import DataTable from "datatables.net-dt";
import $ from "jquery";

export default class extends Controller {
  connect() {
    console.log(this.element);
    let domtable = $(this.element).find(".table");
    let table = new DataTable(domtable, {
      paging: true,
      pageLength: 25,
      searching: false,
      // scrollCollapse: true,
      // scrollX: true,
      // autoWidth: true,
      layout: {
        topStart: null,
      },
      initComplete: function () {},
    });

    $(this.element).removeClass("d-none");
    setTimeout(() => {
      table.columns.adjust().draw();
    }, 100);
    table.on("draw.dt", () => {
      $("select").not(".select2-hidden-accessible").select2({ dropdownAutoWidth: true }).trigger("update");
    });

    $("[type=submit]").on("click", function (e) {
      let form = $(".sonata-ba-form > form");
      let added = [];

      let newDoms = "";
      table.$("input, select, textarea, checkbox").each((i, element) => {
        let id = $(element).prop("id");
        if (id) {
          if (!$(`#${id}`).length) {
            added.push(element);
            newDoms += element.outerHTML;
          }
        }
      });
      form.append($(newDoms));
    });
  }
}

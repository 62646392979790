import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import $ from "jquery";

export default class extends Controller {
  static values = {
    istoken: String,
  };
  connect() {
    //on loading page, the opposite svg of button is disabled.
    $(".statusOn").children(".leftBell").addClass("invisible");
    $(".statusOff").children(".rightBell").addClass("invisible");
  }

  openTab(evt) {
    let tabName = $(evt.target).data("link");
    console.log(tabName);

    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    $(".tabcontent:not([class*='d-none'])").addClass("d-none");

    // Get all elements with class="tablinks" and remove the class "active"
    $(".tablinks.active").removeClass("active");

    // Show the current tab, and add an "active" class to the button that opened the tab
    $(`#${tabName}`).removeClass("d-none");
    $(evt.currentTarget).parent().addClass("active");
  }

  async MarkAllAsDisable(evt) {
    const response = await $.ajax("alerts/disableAll");
    console.log(response);
    //on click head slash-bell, all buttons switch to OFF
    $(".rightBell").addClass("invisible");
    $(".statusOn").addClass("statusOff");
    $(".statusOn").removeClass("statusOn");
    $(".leftBell").removeClass("invisible");
  }

  async MarkAllAsEnable(evt) {
    const response = await $.ajax("alerts/enableAll");
    console.log(response);
    //on click head bell, all buttons switch to ON
    $(".leftBell").addClass("invisible");
    $(".statusOff").addClass("statusOn");
    $(".statusOff").removeClass("statusOff");
    $(".rightBell").removeClass("invisible");
  }

  async toggleToDisable(evt) {
    let $id = $(evt.target).attr("data-id");
    const response = await $.ajax("alerts/alarm_sub/" + $id + "/disable");
    console.log(response);
    //on click of enabled button, it turns off.
    $(evt.target).addClass("invisible");
    $(evt.target).parent().toggleClass("statusOff statusOn");
    $(evt.target).parent().children(".leftBell").removeClass("invisible");
  }

  async toggleToEnable(evt) {
    let $id = $(evt.target).data("id");
    const response = await $.ajax("alerts/alarm_sub/" + $id + "/enable");
    console.log(response);
    //on click of disabled button, it turns on.
    $(evt.target).addClass("invisible");
    $(evt.target).parent().toggleClass("statusOn statusOff");
    $(evt.target).parent().children(".rightBell").removeClass("invisible");
  }
}

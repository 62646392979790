import Swal from "sweetalert2";
import { debounce, isNull, isString } from "underscore";
import Synoptic from "./graph";
import variables from "./variables.json";
import $ from "jquery";

export default class Edge {
  static get defaults() {
    return {
      opacity: 1,
      gcolor: "transparent",
      ganim: "static",
      source_label: " ",
      target_label: " ",
      "source-text-offset": 10,
      "target-text-offset": 10,
      source_width: 10,
      target_width: 10,
      line_style: "solid",
      color: "black",
      zIndex: 0,
      edgeType: "type1",
    };
  }

  constructor(params, complex = false) {
    this.complex = complex;
    this.obj = null;
    let config = this.constructor.defaults;

    for (let p in params) {
      if (params.hasOwnProperty(p)) {
        config[p] = params[p];
      }
    }
    this.config = config;
    if (Synoptic.cy.$id(this.config.source).length == 0) {
      return;
    }
    if (Synoptic.cy.$id(this.config.target).length == 0) {
      return;
    }
    this.init();
    if (!this.obj.data("targets")) {
      this.obj.data("targets", []);
    }
    this.bindActions();
  }

  init() {
    this.source = Synoptic.cy.$id(this.config.source);
    this.target = Synoptic.cy.$id(this.config.target);

    this.obj = Synoptic.add({ group: "edges", data: this.config });

    if (this.obj.target().hasClass("anchor")) {
      this.obj.addClass("to-anchor");
    }
    if (this.obj.source().hasClass("anchor")) {
      this.obj.addClass("from-anchor");
    }

    if (this.obj.target().hasClass("pin")) {
      this.obj.addClass("to-anchor");
    }
    if (this.obj.source().hasClass("pin")) {
      this.obj.addClass("from-anchor");
    }

    this.obj.addClass("edge");
    this.obj
      .addClass(Synoptic.mode == "w" ? "removable" : "noevents")
      .addClass(Synoptic.mode == "w" ? "interactive" : "interactive")
      .data("entity", this.config.pipe);
    this.obj.unpanify();
  }

  update() {}

  remove() {
    // if (Synoptic.cy.edgeConnections().isAuxNode(this.obj)) {
    //   let aux = Synoptic.cy.edgeConnections().edge(this.obj);
    //   Synoptic.cy.remove(aux);
    // }
    Synoptic.cy.remove(this.obj);

    this.obj = null;
  }

  bindActions() {
    this.obj.on("data", (event) => this.setState(this.obj.data("state")));
  }

  hide() {
    this.obj.hide();
  }

  show() {
    this.obj.show();
  }

  id() {
    return this.obj.id();
  }

  setState(state = null) {
    if (!state || !isString(state)) return;

    let stateStyle = variables;
    state.split(".").forEach((part) => {
      stateStyle = stateStyle?.[part];
    });

    if ((!"value") in stateStyle) return;
    let samePipeEdges = Synoptic.cy.filter(`[pipe.global_id=${this.obj.data("pipe.global_id")}]`);

    samePipeEdges.removeListener("data");
    samePipeEdges.data({
      gcolor: stateStyle?.color,
      ganim: stateStyle?.animation,
    }); // gradient is drawn on another layer;
    samePipeEdges.on("data", (event) => this.setState(this.obj.data("state")));
  }
}

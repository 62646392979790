import { Controller } from "@hotwired/stimulus";
import WaterPackPeriodRenderer from "../../js/renderers/Periods/WaterPackPeriodRenderer";
import Periodicity from "../../js/entity/Periodicity";
import $ from "jquery";
import { DateTime } from "luxon";

export default class extends Controller {
  connect() {
    //put element into variable
    let date_start = DateTime.fromJSDate(new Date($(this.element).data("date_start")));
    let date_end = DateTime.fromJSDate(new Date($(this.element).data("date_end")));

    let periodRenderer = new WaterPackPeriodRenderer($(this.element), date_start, date_end);
    periodRenderer.date_manager.getPeriodIndex(date_start, date_end);
    periodRenderer.init();
  }
}

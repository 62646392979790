import { DateTime } from "luxon";
import { formatNumber } from "../../helpers/utils";
import MeasuresRenderer from "../MeasuresRenderer";
import $ from "jquery";

class ChemicalPackMeasuresRenderer extends MeasuresRenderer {
  constructor(summary = true, container = $(".ajax-measures")) {
    super(container);
    this.summary = summary;
    this.default_decimals_rounding = 2;
    this.unit = "L";
  }

  drawCircles(response) {
    super.drawCircles(response, "per_day", "L/J", 2);
  }

  updateSummary(response) {
    if (!this.summary) return;

    let data = response.data;

    // $('#js-chemicalpack-evolution-reused')
    //     .find('span')
    //     .html(this.date_manager.previous_period_comparison_message)
    //     ;

    // $('#js-chemicalpack-evolution-preheated')
    //     .find('span')
    //     .html(this.date_manager.previous_period_comparison_message)
    //     ;

    let sum_per_day = 0;
    let sum = 0;
    let sum_delta = 0;
    data.indicators.forEach((indicator) => {
      sum_per_day += data[indicator.reference]["per_day"].value;
      sum += data[indicator.reference].value;
      sum_delta += data[indicator.reference].delta;
    });

    $("#js-chemicalpack-totalsummary-stat").find("b").text(formatNumber(sum, 2));

    $("#js-chemicalpack-perdaysummary-stat").find(".statnumber-stat").text(formatNumber(sum_per_day, 2));

    let evolution_summary = $("#js-chemicalpack-evolutionsummary-stat");
    this.displayIndicatorStat(
      evolution_summary,
      { evolution: (sum_delta / sum) * 100.0, delta: sum_delta },
      this.unit,
      2,
    );
  }

  updateValues(response) {
    let data = response.data;
    this._is_dbec = response.data.dbec_warmed_chemical !== undefined;

    let helper = response.helper;
    let dateStart = DateTime.fromISO(helper.start);
    let dateEnd = DateTime.fromISO(helper.end);
    let previousDateStart = dateStart
      .set({ year: helper.reference_year })
      .setLocale(navigator.language)
      .toLocaleString(DateTime.DATE_MED);
    let previousDateEnd = dateEnd
      .set({ year: helper.reference_year })
      .setLocale(navigator.language)
      .toLocaleString(DateTime.DATE_MED);
    let toolip = ` Par rapport à la période du ${previousDateStart} au ${previousDateEnd}`;
    $(`.period-tooltip`).find(".tooltip-text").find("b").text(toolip);

    for (let i in data.indicators) {
      let indicator = data.indicators[i];
      let element = $(`#js-chemicalpack-${i}-stat`);
      let reference = indicator.reference;
      let value = data[reference];

      this.displayIndicatorStat(element, value, indicator.unit, 2);
    }
  }

  static get pack_name() {
    return "chemical";
  }

  static get element_switch_enabled() {
    return false;
  }

  get target() {
    return "pool";
  }
}

export default ChemicalPackMeasuresRenderer;

import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import $ from "jquery";

import { DateTime } from "luxon";
import select2 from "select2";
select2($);

export default class extends Controller {
  static targets = ["formCard", "nav", "domains", "card", "tab"];

  async connect() {
    this.reload();

    // Break if not in forms page
    const container = document.getElementById("entries-form-container");
    if (!container) return;

    this.controller = new AbortController();
    const response = await fetch("account/account_forms_content", {
      signal: this.controller.signal,
    });
    this.controller = null;
    const content = await response.text();

    container.innerHTML = "";
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const elements = Array.from(doc.body.childNodes);

    elements.forEach((element) => {
      if (element.id === "accordion-forms") {
        let root = element.cloneNode();
        root.innerHTML = "";
        container.appendChild(root);

        this.insertHTMLInChunks(root, Array.from(element.childNodes));
      } else {
        container.appendChild(element);
      }
    });
  }

  insertHTMLInChunks(container, elements) {
    const CHUNK_SIZE = 2; // Number of elements to insert at a time
    let currentIndex = 0;

    const loadNextChunk = () => {
      const fragment = document.createDocumentFragment();
      const chunk = elements.slice(currentIndex, currentIndex + CHUNK_SIZE);

      for (let element of chunk) {
        fragment.appendChild(element);
      }

      container.appendChild(fragment);
      currentIndex += CHUNK_SIZE;

      // Continue loading chunks if there are more elements
      if (currentIndex < elements.length) {
        this.reload();
        setTimeout(() => requestAnimationFrame(loadNextChunk), 50);
      } else {
        this.reload();
      }
    };

    // Start loading chunks
    requestAnimationFrame(loadNextChunk);
  }

  reload() {
    const that = this;
    const poolsFilter = $(".pools-filter");
    if (poolsFilter) {
      poolsFilter.select2({
        placeholder: "Pédiluve, scolaire, piscine 1, extérieur, chlore, ...",
        allowClear: true,
        tags: true,
      });

      poolsFilter.on("select2:select select2:unselect", function (e) {
        console.log($(this).val());
        that.filterFormsByPool($(this).val());
      });

      setTimeout(() => {
        // Check if pool_id is in the URL params
        const urlParams = new URLSearchParams(window.location.search);
        const poolId = urlParams.get("filter_pool_id");
        if (poolId) {
          poolsFilter.val(poolId).trigger("select2:select").trigger("change");
        }

        // Check if form_id is in the URL params

        const formId = urlParams.get("filter_form_id");
        $('.card[data-form_id="' + formId + '"] .btn').trigger("click");
      }, 300);
    }

    this.refreshFormCount();
  }

  disconnect() {
    this.controller?.abort();
  }

  filterFormsByPool(searchArray) {
    $(".form-container-pool").each(function () {
      const poolText = $(this).text().toLowerCase();
      const matches = searchArray.some((searchTerm) => poolText.includes(searchTerm.toLowerCase()));

      if (matches || searchArray.includes($(this).data("pool_id").toString()) || searchArray.length === 0) {
        $(this).removeClass("filtered");
      } else {
        $(this).addClass("filtered");
      }
    });

    this.refreshFormCount();
  }

  refreshFormCount() {
    $(".card").each(function () {
      const formCount = $(this).find(".form-container-pool:not(.filtered)").length;

      $(this)
        .find(".count")
        .html("&nbsp;&nbsp;-&nbsp;&nbsp;" + formCount + " piscine(s)&nbsp;&nbsp;");
    });
  }

  handleDomainsFilter(event) {
    const domainId = event.currentTarget.getAttribute("data-domain-id");
    this.filterCardsByDomain(domainId);
  }

  filterCardsByDomain(poolId) {
    this.formCard.forEach((pool) => {
      if (pool.getAttribute("data-pool-id") === pool) {
        card.style.display = "block";
      } else {
        card.style.display = "none";
      }
    });
  }

  /**
   * Handles the change event for reference inputs.
   *
   * This function enables or disables the submit button based on the value of the references inputs.
   *
   * @param {Event} event - The event object representing the change event.
   */
  handleReferenceChange(event) {
    // TODO: Remove these static variables and replace them with a dynamic way to get the reference types
    const chemicalsTemperature = 47;
    const chemicalsPH = 44;
    const chemicalsFreeChlorine = 43;
    const chemicalsTotalChlorine = 48;
    const chemicalChloramine = 40;
    const chemicalActiveChlorine = 39;

    event.preventDefault();

    const reference = event.currentTarget;
    const formContainerPool = reference.closest(".form-container-pool");

    if (reference.value.trim() !== "") {
      formContainerPool.querySelector(".submit-button").disabled = false;
    } else {
      let valuecounter = 0;
      formContainerPool.querySelectorAll(".reference").forEach((reference) => {
        if (reference.value.trim() !== "") {
          valuecounter++;
        }
      });
      if (valuecounter === 0) {
        formContainerPool.querySelector(".submit-button").disabled = true;
      }
    }

    // Checks for chemicals exceptions
    const closestForm = reference.closest("form");

    const referencesToCheck = [
      chemicalsTemperature,
      chemicalsPH,
      chemicalsFreeChlorine,
      chemicalsTotalChlorine,
      chemicalChloramine,
      chemicalActiveChlorine,
    ];

    const referencesToCompute = [chemicalsTemperature, chemicalsPH, chemicalsFreeChlorine, chemicalsTotalChlorine];

    if (closestForm && this.chemicalsPresent(closestForm, referencesToCheck)) {
      if (this.checkInputs(closestForm, referencesToCompute)) {
        const activeChlorineInput = closestForm.querySelector(
          `.reference[data-reference_id="${chemicalActiveChlorine}"]`,
        );
        const chloramineInput = closestForm.querySelector(`.reference[data-reference_id="${chemicalChloramine}"]`);
        const freeChlorineValue = closestForm.querySelector(
          `.reference[data-reference_id="${chemicalsFreeChlorine}"]`,
        ).value;
        const PHValue = closestForm.querySelector(`.reference[data-reference_id="${chemicalsPH}"]`).value;
        const temperatureValue = closestForm.querySelector(
          `.reference[data-reference_id="${chemicalsTemperature}"]`,
        ).value;
        const totalChlorineValue = closestForm.querySelector(
          `.reference[data-reference_id="${chemicalsTotalChlorine}"]`,
        ).value;

        activeChlorineInput.value = this.calculateActiveChlorine(freeChlorineValue, PHValue, temperatureValue);
        chloramineInput.value = (totalChlorineValue - freeChlorineValue).toFixed(2);

        // Check if Active Chlorine value has changed
        if (
          activeChlorineInput.dataset.oldvalue &&
          activeChlorineInput.value !== activeChlorineInput.dataset.oldvalue
        ) {
          activeChlorineInput.classList.add("changed");
        } else {
          activeChlorineInput.classList.remove("changed");
        }

        // Check if Chloramine value has changed
        if (chloramineInput.dataset.oldvalue && chloramineInput.value !== chloramineInput.dataset.oldvalue) {
          chloramineInput.classList.add("changed");
        } else {
          chloramineInput.classList.remove("changed");
        }
      }
    }

    // Check if value has changed
    if (reference.dataset.oldvalue && reference.value !== reference.dataset.oldvalue) {
      reference.classList.add("changed");
    } else {
      reference.classList.remove("changed");
    }
  }

  /**
   * Function to calculate the active chlorine concentration (HOCl)
   * based on free chlorine, pH, and temperature.
   *
   * @param {number} freeChlorine - Free chlorine concentration (mg/L)
   * @param {number} pH - pH value
   * @param {number} temperature - Temperature in degrees Celsius
   * @returns {number} Active chlorine concentration (mg/L)
   */
  calculateActiveChlorine(freeChlorine, pH, temperature) {
    // Calculate temperature-dependent pKa
    const pKa = 7.54 - 0.022 * (temperature - 25);

    // Calculate the fraction of active chlorine (HOCl)
    const fractionHOCl = 1 / (1 + Math.pow(10, pH - pKa));

    // Calculate active chlorine concentration
    const activeChlorine = (freeChlorine * fractionHOCl).toFixed(2);

    return activeChlorine;
  }

  checkInputs(form, references) {
    return references.every((ref) => {
      const input = form.querySelector(`.reference[data-reference_id="${ref}"]`);
      return input && input.value.trim() !== "" && input.value !== null;
    });
  }

  chemicalsPresent(form, references) {
    return references.every((ref) => form.querySelector(`.reference[data-reference_id="${ref}"]`) !== null);
  }

  /**
   * Handles the form submission event.
   *
   * @param {Event} event - The form submission event.
   *
   * @description
   * This function gathers data from the forms,
   * and sends it to the server via a POST request. It also handles the response and displays
   * appropriate success or error messages via Swal.
   */
  handleSubmit(event) {
    event.preventDefault();

    const button = event.target;
    const formContainerPool = button.closest(".form-container-pool");
    const poolName = formContainerPool.querySelector("h3").textContent;

    let data = {};
    let is_locked = true;

    if (formContainerPool) {
      data = {
        domain_id: formContainerPool.dataset.domain_id,
        form_id: formContainerPool.dataset.form_id,
        type_id: formContainerPool.dataset.form_type_id,
        pool_id: formContainerPool.dataset.pool_id,
        archive_id: formContainerPool.dataset.archive_id,
        date: formContainerPool.querySelector(".pool-date").value,
        timezone: DateTime.local().toFormat("z"),
        targets: [],
      };

      const forms = formContainerPool.querySelectorAll("form");

      forms.forEach((target) => {
        let targetName = "Général";
        if (target.querySelector(".target-name")) targetName = target.querySelector(".target-name").textContent;

        let targetData = {
          target_id: target.querySelector(".target_id").value,
          target_class: target.querySelector(".target_class").value,
          target_fullclass: target.querySelector(".target_fullclass").value,
          target_name: targetName,
          hour: target.querySelector(".hour").value,
          references: [],
        };
        target.querySelectorAll(".reference").forEach((reference) => {
          if (reference.value.trim() !== "") {
            is_locked = false; // We unlock the form if there is at least one reference filled

            const reference_label = reference.parentNode.querySelector(".reference_label").textContent;

            targetData.references.push({
              reference_id: reference.dataset.reference_id,
              category: reference.dataset.category,
              value: reference.value,
              label: reference_label,
            });
          }
        });
        if (targetData.references.length > 0) {
          // We push the target only if there is at least one reference filled
          data.targets.push(targetData);
        }
      });
    }

    if (!is_locked) {
      if (formContainerPool.dataset.archive_id > 0) {
        // Edit case
        Swal.fire({
          icon: "warning",
          title: "Etes-vous sûr de vouloir enregistrer les données du site " + poolName + " ?",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Enregistrer",
          cancelButtonText: "Annuler",
          showLoaderOnConfirm: true,
          input: "textarea",
          inputLabel: "Commentaire :",
          inputPlaceholder: "Saisissez la raison de votre modification (facultatif)",
          preConfirm: async () => {
            data.comment = Swal.getInput().value;
            $(".swal2-cancel").hide();
            // Send the data to the server
            return fetch("/account/form_submit", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            })
              .then((response) => {
                //button.parentNode.classList.remove("loading");
                if (!response.ok) {
                  throw new Error("Erreur lors de l'envoi du formulaire");
                }
              })
              .then((result) => {
                // Reset the forms
                $(".form-control.hour").each(function () {
                  $(this).val(
                    new Date().toLocaleTimeString("FR-fr", {
                      hour: "2-digit",
                      minute: "2-digit",
                    }),
                  );
                });

                Swal.fire({
                  icon: "success",
                  title: "Succès",
                  text: "Votres formulaires ont été enregistrés avec succès !",
                }).then(() => {
                  $("#form_archives_table").DataTable().ajax.reload();
                });
              })
              .catch((error) => {
                console.log(error.message);
                Swal.fire({
                  icon: "error",
                  title: "Erreur",
                  text: "Une erreur est survenue lors de l'enregistrement des données.",
                });
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      } else {
        // Create case
        Swal.fire({
          icon: "warning",
          title: "Etes-vous sûr de vouloir enregistrer les données du site " + poolName + " ?",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Enregistrer",
          cancelButtonText: "Annuler",
          showLoaderOnConfirm: true,
          input: "textarea",
          inputLabel: "Commentaire :",
          inputPlaceholder: "Saisissez un commentaire (facultatif)",
          preConfirm: async () => {
            data.comment = Swal.getInput().value;
            $(".swal2-cancel").hide();
            // Send the data to the server
            return fetch("/account/form_submit", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            })
              .then((response) => {
                //button.parentNode.classList.remove("loading");
                if (!response.ok) {
                  throw new Error("Erreur lors de l'envoi du formulaire");
                }
              })
              .then((result) => {
                // Reset the forms
                $(".form-control.hour").each(function () {
                  $(this).val(
                    new Date().toLocaleTimeString("FR-fr", {
                      hour: "2-digit",
                      minute: "2-digit",
                    }),
                  );
                });

                Swal.fire({
                  icon: "success",
                  title: "Succès",
                  text: "Votres formulaires ont été enregistrés avec succès !",
                }).then(() => {
                  formContainerPool.querySelectorAll(".reference").forEach((input) => {
                    input.value = "";
                  });
                });
              })
              .catch((error) => {
                console.log(error.message);
                Swal.fire({
                  icon: "error",
                  title: "Erreur",
                  text: "Une erreur est survenue lors de l'enregistrement des données.",
                });
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      }
    }
  }

  linkNewForm(event) {
    Swal.fire({
      icon: "info",
      title: "MISE A JOUR !",
      html: "Nouveau formulaire de saisie disponible ici :",
      showConfirmButton: false,
      footer: '<a href="/account?tab=saisie">Page des formulaires</a>',
    });
  }
}

import AbstractPackNewMeasureForm from "../../helpers/AbstractPackNewMeasureForm";
import ToConfirmButton from "../../entity/ToConfirmButton";
import DateManager, { BASE_DATETIME_FORMAT } from "../../helpers/DateManager";
import Swal from "sweetalert2";
import $ from "jquery";
import { DateTime } from "luxon";

const ATTENDANCE_TOTAL_FIELD_SUFFIX_ID = "total";
const ATTENDANCE_INPUT_ID_PREFIX = "#attendance-new-value-form";
const ATTENDANCE_REFERENCE_PREFIXES = "attendance_";
const INVALID_FORM_FIELD_CLASS = "form-modal-input-invalid";

class AttendancePackNewMeasureForm extends AbstractPackNewMeasureForm {
  constructor(datePicker, container = $("body")) {
    super("attendance", [], datePicker, container);

    this.init = this.init.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.readFormData = this.readFormData.bind(this);
    this.updateTotal = this.updateTotal.bind(this);
    this.addDataSentCallback = this.addDataSentCallback.bind(this);

    // Get current page parameters
    let body = $("body");
    this._pool_id = body.data("pool");
    this._client_id = body.data("client");

    this._total_field = $(`${ATTENDANCE_INPUT_ID_PREFIX}-${ATTENDANCE_TOTAL_FIELD_SUFFIX_ID}`);

    this._submit = new ToConfirmButton(ATTENDANCE_INPUT_ID_PREFIX + "-submit");
  }

  fieldChangeCallback(field) {
    super.fieldChangeCallback(field);
    this.updateTotal();
  }

  postSendPopup() {
    this.successMessage("Saisie enregistrée.");
    Swal.fire({
      title: "Saisie enregistrée",
      icon: "success",
    });
    this._data_sent_callbacks.forEach((callback) => {
      callback();
    });
    this.checkExistingData();
  }

  init() {
    super.init();

    this.addDataSentCallback(() => {
      this._submit.reset();
      if (this._attendance_manager) {
        this._submit.text("Modifier");
      } else {
        for (let field of this._fields_keys) {
          this.container.find(".js-hide-on-disable").hide();
          this._fields[field].find("input").prop("disabled", true);
        }
      }
    });
  }

  updateTotal() {
    let parent = this;
    let total = Object.values(this.indics).reduce(
      (acc, indic) => acc + (parseInt($(parent._fields[indic.reference]).find("input").val()) || 0),
      0,
    );
    this._total_field.val(total);
  }

  dataAlreadySentCallback(data) {
    let raw_date = DateManager.parseDateFromInput(this._date_field);
    // raw_date.set({ hour: 0 });
    let now = DateTime.local();

    const alreadySentMessage = "Fréquentation déjà saisie pour cette date";
    if (raw_date > now) {
      if (this._attendance_manager) {
        this.successMessage("Saisie d'une date future");
      } else {
        this.errorMessage("Saisie impossible pour des dates futures");
        this.container.find(".js-hide-on-disable").hide();
      }
    } else if (this._attendance_manager) {
      this.successMessage(alreadySentMessage);
      this._submit.text("Modifier");
    } else {
      this.errorMessage(alreadySentMessage);
      this.container.find(".js-hide-on-disable").hide();
    }

    let parent = this;
    this.indics.forEach(function (indic, index) {
      $(parent._fields[indic.reference])
        .find("input")
        .val(data[indic.reference] || 0);
      $(parent._fields[indic.reference]).find("input").prop("disabled", !parent._attendance_manager);
    });

    this.updateTotal();
  }

  noDataSentCallback(data) {
    this.resetMessage();
    this._submit.text("Enregistrer");
    let parent = this;
    this.indics.forEach(function (indic, index) {
      $(parent._fields[indic.reference])
        .find("input")
        .val(data[indic.reference] || 0);
      $(parent._fields[indic.reference]).find("input").prop("disabled", false);
    });
    this.updateTotal();
  }

  readFormData() {
    let ret = { date: null, data: {} };

    let parent = this;
    this.indics.forEach(function (indic) {
      ret.data[indic.reference] = parseInt(parent._fields[indic.reference].find("input").val());
    });

    let timestamp = DateManager.parseDateFromInput(this._date_field);
    ret.date = timestamp.toFormat(BASE_DATETIME_FORMAT);

    return ret;
  }

  addDataSentCallback(callback) {
    this._data_sent_callbacks.push(callback);
  }

  /**
   * Update form fields with indicators
   */
  updateForm(data) {
    let parent = this;
    this._fields = {};
    $("[id^=attendance-new-value-form-indicator-]").each(function () {
      let id = $(this).attr("id").split("-").pop();
      let indic = data?.indicators?.[id];
      if (indic) {
        parent.indics[id] = indic;
        parent._fields[indic.reference] = $(this);
        $(this)
          .find("label")
          .text(indic.label + " :");
        $(this).find("input").val(data[indic.label]);

        $(this)
          .find("input")
          .change(() => {
            parent.fieldChangeCallback($(this));
          });
      } else {
        $(this).hide();
      }
    });
  }

  checkField(field) {
    return super.checkField(field.find("input"));
  }
}

export default AttendancePackNewMeasureForm;

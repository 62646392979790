import { Controller } from "@hotwired/stimulus";
import { datePickers } from "@js/helpers/datepickers";
import RegulationPackNewMeasureForm from "@js/pages/regulationpack/RegulationPackNewMeasureForm";
import RegulationPackMeasuresRenderer from "@js/renderers/Measures/RegulationPackMeasuresRenderer";
import AttendancePackMeasuresRenderer from "@js/renderers/Measures/AttendancePackMeasuresRenderer";
import RegulationPackPeriodRenderer from "@js/renderers/Periods/RegulationPackPeriodRenderer";
import IssueNewMeasureForm from "@js/helpers/IssueNewMeasureForm";
import Swal from "sweetalert2";
import { title } from "process";
import $ from "jquery";

export default class extends Controller {
  connect() {
    const pikadayElements = datePickers();
    let measuresRenderer = new AttendancePackMeasuresRenderer(false);
    measuresRenderer.init();

    measuresRenderer = new RegulationPackMeasuresRenderer();
    measuresRenderer.init();

    this.periodRenderer = new RegulationPackPeriodRenderer();
    this.periodRenderer.init();

    let newMeasureForm = new RegulationPackNewMeasureForm(pikadayElements.new, $(".regulation-side"));
    newMeasureForm.init();
    newMeasureForm.addDataSentCallback(measuresRenderer.render);
    newMeasureForm.addDataSentCallback(this.periodRenderer.render);
    this.periodRenderer.registerswitchPondCallback(newMeasureForm.setPond, false);
    this.periodRenderer.measureForm = newMeasureForm;
  }

  async setThresholds(event) {
    //On récupère dans variable button le [input.button{{key}}]
    let button = $(event.target);
    let i = button.attr("id"); //it is the key not the id
    let indicator_id = this.periodRenderer.indicators[i].id; //it is the id
    let label = this.periodRenderer.indicators[i].label;
    let unit = this.periodRenderer.indicators[i].unit;
    if (unit == "") {
      unit = "chiffre";
    }
    let max = this.periodRenderer.indicators[i].threshold.max;
    let seuilHaut = this.periodRenderer.indicators[i].threshold.threshold2;
    let seuilBas = this.periodRenderer.indicators[i].threshold.threshold1;
    let min = this.periodRenderer.indicators[i].threshold.min;
    let isTwoWay = this.periodRenderer.indicators[i].threshold.isTwoWay;
    let pond_id = $(".activeElement").attr("data-elementid");
    let html;
    let htmlOfTwoWay =
      "<form>" +
      '<div><label for="swal-input4" style="border-bottom:red dashed 1px">Limite Maxi</label>' +
      '<input type="number" step="0.1" id="swal-input4" class="swal2-input" data-name="LimiteMaxi" value="' +
      max +
      '"  min="0" style="color:red;"></div>' +
      '<div><label for="swal-input2" style="border-bottom:orange dashed 1px">Seuil Haut&nbsp&nbsp</label>' +
      '<input type="number" step="0.1" id="swal-input2" class="swal2-input" data-name="SeuilHaut" value="' +
      seuilHaut +
      '" min="0" style="color:orange;"></div>' +
      '<div><label for="swal-input1" style="border-bottom:orange dashed 1px">Seuil Bas&nbsp&nbsp&nbsp</label>' +
      '<input type="number" step="0.1" id="swal-input1" class="swal2-input" data-name="SeuilBas" value="' +
      seuilBas +
      '" min="0" style="color:orange;"></div>' +
      '<div><label for="swal-input3" style="border-bottom:red dashed 1px">Limite Mini</label>' +
      '<input type="number" step="0.1" id="swal-input3" class="swal2-input" data-name="LimiteMini" value="' +
      min +
      '" min="0" style="color:red;"></div>' +
      "</form>";
    let htmlOfOneWay =
      "<form>" +
      '<div><label for="swal-input4" style="border-bottom:red dashed 1px">Limite Maxi</label>' +
      '<input type="number" step="0.1" id="swal-input4" class="swal2-input" data-name="LimiteMaxi" value="' +
      max +
      '" min="0" style="color:red;"></div>' +
      '<div><label for="swal-input2" style="border-bottom:orange dashed 1px">SeuilHaut&nbsp</label>' +
      '<input type="number"step="0.1" id="swal-input2" class="swal2-input" data-name="SeuilHaut" value="' +
      seuilHaut +
      '"min="0" style="color:orange;"></div>' +
      // '<label for="swal-input1">Seuil Bas&nbsp&nbsp</label>'+
      '<input type="hidden"id="swal-input1" class="swal2-input" data-name="SeuilBas" value="' +
      seuilBas +
      '"min="0" style="color:orange;">' +
      // '<label for="swal-input3">LimiteMini</label>'+
      '<input type="hidden"id="swal-input3" class="swal2-input" data-name="LimiteMini" value="' +
      min +
      '"min="0" style="color:red;">' +
      "</form>";

    if (isTwoWay) {
      html = htmlOfTwoWay;
    } else {
      html = htmlOfOneWay;
    }

    const { value: formValues } = await Swal.fire({
      title:
        label +
        " (" +
        unit +
        ")" +
        '<input type="hidden" id="swal-title" class="swal2-input" data-name="Nom_indicateur" value=' +
        label +
        ">",
      html: html,
      focusConfirm: false,
      width: "400px",
      showDenyButton: true,
      confirmButtonColor: "#28a745",
      confirmButtonText: "Valider",
      denyButtonText: `Annuler`,
      preConfirm: () => {
        return {
          [$("#swal-title").data("name")]: $("#swal-title").val(),
          [$("#swal-input1").data("name")]: $("#swal-input1").val(),
          [$("#swal-input2").data("name")]: $("#swal-input2").val(),
          [$("#swal-input3").data("name")]: $("#swal-input3").val(),
          [$("#swal-input4").data("name")]: $("#swal-input4").val(),
        };
      },
    });
    //si il y a un formValues ET si respect d'ordonnancement
    let condition = formValues.LimiteMaxi > formValues.SeuilHaut;
    if (isTwoWay)
      condition &&= formValues.SeuilHaut > formValues.SeuilBas && formValues.SeuilBas > formValues.LimiteMini;
    if (formValues && condition) {
      formValues.isTwoWay = isTwoWay;
      $.ajax({
        url: `pond/${pond_id}/indicator/${indicator_id}/reference`,
        method: "GET",
        data: formValues,
      }).then((response) => {
        if (response) {
          this.periodRenderer.init();
          Swal.fire({
            icon: "success",
            title: "Enregistré !",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    } else {
      Swal.fire({
        confirmButtonColor: "#cabfcd",
        icon: "error",
        text: "Aucun changement effectué !",
      });
    }
  }
}

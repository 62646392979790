import { DateTime } from "luxon";
import Periodicity from "../entity/Periodicity";
import { SESSION_STORAGE_DATE_START_KEY, SESSION_STORAGE_DATE_END_KEY, formatNumber } from "../helpers/utils";
import $ from "jquery";
import { DP_FORMAT } from "@js/helpers/datepickers";

const PERIODICITY_ROOT_SELECTOR = "periodicityselector";

const MESSAGE_YESTERDAY = "Par rapport à hier";
const MESSAGE_PERIOD = "Par rapport à la période précédente";
const WRAP_MESSAGE_PERIOD = "Par rapport à la<br />période précédente";

export const BASE_DATETIME_FORMAT = "yyyy-LL-dd TT";
export const BASE_DATE_FORMAT = "yyyy-LL-dd";

/**
 * This class is here to cary out the date managing
 *
 * It propose a simple observer interface for date changing, one for start date, one for date end
 * and one for periodicity
 */
class DateManager {
  /**
   * constructor
   */

  /** @type {DateTime} */
  _date_start;
  /** @type {DateTime} */
  _date_end;

  constructor(isPopup = false) {
    this.isPopup = isPopup;

    //Dates
    this.date_start_input = $("#pack-datepicker-from");
    this.date_end_input = $("#pack-datepicker-to");
    this.date_input = $(".pack-datepicker-input");

    //Callbacks
    this.date_start_callback = [];
    this.date_end_callback = [];
    this.periodicity_callback = [];

    // Session storage
    let session_date_start = sessionStorage.getItem(SESSION_STORAGE_DATE_START_KEY);
    let session_date_end = sessionStorage.getItem(SESSION_STORAGE_DATE_END_KEY);

    if (session_date_start) this.date_start = DateTime.fromISO(session_date_start);
    else this.date_start = this.constructor.parseDateFromInput(this.date_start_input);

    if (session_date_end) this.date_end = DateTime.fromISO(session_date_end);
    else this.date_end = this.constructor.parseDateFromInput(this.date_end_input);

    if (!this.date_start.isValid || !this.date_end.isValid) {
      this.date_start = DateTime.local().minus({ days: 7 });
      this.date_end = DateTime.local();
    }

    //Periodicity
    this.periodicities = [
      new Periodicity("day", false, 31, PERIODICITY_ROOT_SELECTOR, () => this.switchPeriodicity(0), !this.isPopup),
      new Periodicity("week", 7, 139, PERIODICITY_ROOT_SELECTOR, () => this.switchPeriodicity(1), !this.isPopup),
      new Periodicity("month", 30, 1095, PERIODICITY_ROOT_SELECTOR, () => this.switchPeriodicity(2), !this.isPopup),
      new Periodicity("year", 60, false, PERIODICITY_ROOT_SELECTOR, () => this.switchPeriodicity(3), !this.isPopup),
    ];
    //By default, first periodicity is chosen
    this._periodicity = this.periodicities[0];
    this.addDateCallback(this.checkPeriodicity.bind(this));
    this.checkPeriodicity();

    this.bindEvents();
  }

  /**
   * Bind inputs events to change local storage
   */
  bindEvents() {
    this.date_start_input.on("change", () => {
      this.date_start = this.constructor.parseDateFromInput(this.date_start_input);
    });

    this.date_end_input.on("change", () => {
      this.date_end = this.constructor.parseDateFromInput(this.date_end_input);
    });
  }

  /**
   * Extract a date from an input to Datetime obj
   *
   * @param input
   * @returns {DateTime}
   */
  static parseDateFromInput(input) {
    let date = DateTime.fromISO(input.val(), { locale: "fr" });
    if (!date.isValid) {
      date = DateTime.fromFormat(input.val(), DP_FORMAT, { locale: "fr" });
    }

    if (!date.isValid) {
      throw new Error("Invalid date format");
    }

    return date;
  }

  /**
   * Switch the periodicity and check if it is still correct
   *
   * @param periodicity_index
   */
  switchPeriodicity(periodicity_index) {
    this.periodicity = this.periodicities[periodicity_index];
    this.checkPeriodicity();
  }

  getPeriodIndex(dateStart, dateEnd) {
    let _dateEnd = DateTime.fromMillis(Date.parse(dateEnd));
    let _dateStart = DateTime.fromMillis(Date.parse(dateEnd));
    let diff = _dateEnd.diff(_dateStart);

    const diffInDays = diff.as("days");
    const diffInWeeks = diff.as("weeks");
    const diffInMonths = diff.as("months");

    let index = null;
    if (diffInDays <= 7) {
      index = 0;
    } else if (diffInWeeks <= 4 || diffInDays <= 31) {
      index = 1;
    } else if (diffInMonths <= 12) {
      index = 2;
    } else {
      index = 3;
    }
    return this.switchPeriodicity(index);
  }
  /**
   * Check if the periodicity is compliant with current dates
   */
  checkPeriodicity() {
    let undefined_periodicity = false;

    this.periodicities.forEach((periodicity) => {
      //First, check if the periodicity is valid
      let valid = periodicity.check(this.duration.as("days"), !this.isPopup);
      //And if it is the current periodicity
      let active = this._periodicity === periodicity;

      //If the current periodicity is active but not valid, we need to find a new periodicity
      undefined_periodicity = undefined_periodicity || (!valid && active);

      //This condition should be true if one of the previous periodicity was active
      //but was not valid anymore, then the current periodicity is undefined
      //If we need to find a new periodicity and this one is valid,
      //it becomes the new active
      if (undefined_periodicity && valid) {
        this.periodicity = periodicity;
        undefined_periodicity = false;
        active = true;
      }

      active &= valid;
      periodicity.active = active;
    });
  }

  //Kind of observer pattern

  /**
   * Register a callback that will be called when
   * any date (start or end) changes
   */
  addDateCallback(callback) {
    this.addDateStartCallback(callback);
    this.addDateEndCallback(callback);
  }

  /**
   * Register a callback that will be called when
   * the start date changes
   */
  addDateStartCallback(callback) {
    this.date_start_callback.push(callback);
  }

  /**
   * Register a callback that will be called when
   * the end date changes
   */
  addDateEndCallback(callback) {
    this.date_end_callback.push(callback);
  }

  /**
   * Register a callback that will be called when
   * the periodicity changed
   */
  addPeriodicityCallback(callback) {
    this.periodicity_callback.push(callback);
  }

  /**
   * Compute the number of days in an incomplete period
   *
   * @param date one date included into the period
   * @param unit the unit of the period
   * @param first true if the date is in the first period
   * @param last true if the date is in the last period
   */
  getDaysCount(date, unit, first, last) {
    let days_count = 0;

    let date_start = date.startOf(unit);
    if (date_start.diff(this.date_start).as("days") === 0) first = false;

    let date_end = date.endOf(unit);
    if (date_end.diff(this.date_end).as("days") === 0) last = false;

    //Case 1 : first day and last day are in the same period
    if (first && last) {
      days_count = this.date_end.diff(this.date_start).as("days") + 1;
    }
    //Case 2 : we are in the first period but the last day is in another one
    else if (first) {
      days_count = this.date_end.diff(this.date_start).as("days") + 1;
    }
    //Case 3 : we are in the last period but the first day is in another one
    else if (last) {
      days_count = this.date_end.diff(this.date_start).as("days") + 1;
    }

    return days_count;
  }

  /**
   * Return the date in a format adapted to the periodicity
   *
   * @param date the date to format
   * @param first true if the date is the first day of a period
   * @param last true if the date is the last day of a period
   */
  formatDate(date, first = false, last = false) {
    let LuxonFormats = { year: "yyyy", month: "yyyy-MM", week: "kkkk-WW", day: "yyyy-MM-dd", hour: "yyyy-MM-dd HH" };

    let formatted;

    if (this.isYearPeriodicity) {
      let _date = DateTime.fromFormat(date, LuxonFormats.year, { locale: navigator.language });
      formatted = _date.toFormat("yyyy");

      let days_count = this.getDaysCount(_date, "year", first, last);
      if (days_count) {
        if (days_count > 31) {
          let months_count = Math.floor(days_count / 30.5);
          formatted = [formatted, ` (${formatNumber(days_count, true)} mois)`];
        } else {
          formatted = [formatted, ` (${formatNumber(days_count, true)} jour${days_count >= 2 ? "s" : ""})`];
        }
      }
    } else if (this.isMonthPeriodicity) {
      let _date = DateTime.fromFormat(date, LuxonFormats.month, { locale: navigator.language });
      formatted = _date.toFormat("MMM	yyyy");
      let days_count = this.getDaysCount(_date, "month", first, last);
      if (days_count) formatted = [formatted, ` (${formatNumber(days_count, true)} jour${days_count >= 2 ? "s" : ""})`];
    } else if (this.isWeekPeriodicity) {
      let _date = DateTime.fromFormat(date, LuxonFormats.week, { locale: navigator.language });
      formatted = _date.toFormat("kkkk-'S'WW");
      let week_number = "S" + _date.weekNumber;
      let days_count = this.getDaysCount(_date, "week", first, last);
      formatted = [_date.setLocale("fr").toFormat("ccc dd/LL"), week_number];
      if (days_count) formatted.push(` (${formatNumber(days_count, true)} jour${days_count >= 2 ? "s" : ""})`);
    } else if (this.isOneDay) {
      let _date = DateTime.fromFormat(date, LuxonFormats.hour, { locale: navigator.language });
      formatted = _date.toFormat("HH'h'mm");
    } else {
      let _date = DateTime.fromFormat(date, LuxonFormats.day, { locale: navigator.language });
      formatted = _date.toFormat("ccc dd/MM");
    }

    return formatted;
  }

  //Getters and setters

  /**
   * Return a message for comparison from the last period
   * considering the current periodicity
   */
  get previous_period_comparison_message() {
    if (this.isOneDay) return MESSAGE_YESTERDAY;

    return MESSAGE_PERIOD;
  }

  /**
   * Return a message (where long lines are wrapped) for comparison from the
   * last period considering the current periodicity
   */
  get wrap_previous_period_comparison_message() {
    if (this.isOneDay) return MESSAGE_YESTERDAY;

    return WRAP_MESSAGE_PERIOD;
  }

  get date_start() {
    return this._date_start;
  }

  set date_start(value) {
    if (value === this.date_start) return;

    try {
      const date = DateTime.fromMillis(Date.parse(value));
      if (!date.isValid) return;
    } catch (error) {
      return;
    }

    this._date_start = value;

    this.date_start_callback.forEach((callback) => {
      callback(this.date_start);
    });

    sessionStorage.setItem(SESSION_STORAGE_DATE_START_KEY, this.date_start.toISO());

    $(".date-start").each((i, element) => {
      $(element).text(this.date_start.setLocale("fr").toFormat($(element).data("format") ?? "DD"));
    });
    $('form.pack-export input[name="date_start"]').each((i, element) => {
      $(element).val(this.date_start.toISO());
    });
  }

  get date_end() {
    return this._date_end;
  }

  set date_end(value) {
    if (value === this.date_end) return;

    try {
      const date = DateTime.fromMillis(Date.parse(value));

      if (!date.isValid) return;
    } catch (error) {
      return;
    }

    this._date_end = value;

    this.date_end_callback.forEach((callback) => callback(this.date_end));

    sessionStorage.setItem(SESSION_STORAGE_DATE_END_KEY, this.date_end);

    $(".date-end").text(this.date_end.setLocale("fr").toFormat("DD"));
    $('form.pack-export input[name="date_end"]').val(this.date_end);
  }

  get duration() {
    return this.date_end.diff(this.date_start, "days");
  }

  get periodicity() {
    return this._periodicity.name;
  }

  set periodicity(value) {
    if (value === this.periodicity) return;

    this._periodicity = value;

    this.periodicity_callback.forEach((callback) => callback(this.periodicity));
  }

  //Shortcuts for periodicity
  /**
   * Return true if the period displayed is only one day
   */
  get isOneDay() {
    return this.duration.as("days") === 0;
  }

  get isWeekPeriodicity() {
    return this.periodicity === "week";
  }

  get isMonthPeriodicity() {
    return this.periodicity === "month";
  }

  get isYearPeriodicity() {
    return this.periodicity === "year";
  }

  get isHourPeriodicity() {
    return this.periodicity === "hour";
  }
}

export default DateManager;

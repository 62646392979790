import { Controller } from "@hotwired/stimulus";
import { datePickers } from "@js/helpers/datepickers";
import WaterPackMeasuresRenderer from "@js/renderers/Measures/WaterPackMeasuresRenderer";
import WaterPackPeriodRenderer from "@js/renderers/Periods/WaterPackPeriodRenderer";
import WaterPackNewMeasureForm from "@js/pages/waterpack/WaterPackNewMeasureForm";
import $ from "jquery";

export default class extends Controller {
  connect() {
    datePickers();

    let measuresRenderer = new WaterPackMeasuresRenderer();
    measuresRenderer.init();

    let periodRenderer = new WaterPackPeriodRenderer();
    periodRenderer.init();

    let newMeasureForm = new WaterPackNewMeasureForm(null, $(".water-side"));
    newMeasureForm.init();
    newMeasureForm.addDataSentCallback(measuresRenderer.render);
    newMeasureForm.addDataSentCallback(periodRenderer.render);
    periodRenderer.registerswitchCircuitCallback(newMeasureForm.setCircuit, false);
  }
}

import { isNaN, isNumber } from "underscore";

const SQRT2_OVER2 = Math.sqrt(2) / 2;

// Given three collinear points p, q, r, the function checks if
// point q lies on line segment 'pr'
export function onSegment(p, q, r) {
  if (q.x <= Math.max(p.x, r.x) && q.x >= Math.min(p.x, r.x) && q.y <= Math.max(p.y, r.y) && q.y >= Math.min(p.y, r.y))
    return true;

  return false;
}

export function mod(n, m) {
  return ((n % m) + m) % m;
}

// To find orientation of ordered triplet (p, q, r).
// The function returns following values
// 0 --> p, q and r are collinear
// 1 --> Clockwise
// 2 --> Counterclockwise
export function orientation(p, q, r) {
  // See https://www.geeksforgeeks.org/orientation-3-ordered-points/
  // for details of below formula.
  let val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);

  if (val == 0) return 0; // collinear

  return val > 0 ? 1 : 2; // clock or counterclock wise
}

// The main function that returns true if line segment 'p1q1'
// and 'p2q2' intersect.
export function doIntersect(p1, q1, p2, q2) {
  // Find the four orientations needed for general and
  // special cases
  let o1 = orientation(p1, q1, p2);
  let o2 = orientation(p1, q1, q2);
  let o3 = orientation(p2, q2, p1);
  let o4 = orientation(p2, q2, q1);

  // General case
  if (o1 != o2 && o3 != o4) return true;

  // Special Cases
  // p1, q1 and p2 are collinear and p2 lies on segment p1q1
  if (o1 == 0 && onSegment(p1, p2, q1)) return true;

  // p1, q1 and q2 are collinear and q2 lies on segment p1q1
  if (o2 == 0 && onSegment(p1, q2, q1)) return true;

  // p2, q2 and p1 are collinear and p1 lies on segment p2q2
  if (o3 == 0 && onSegment(p2, p1, q2)) return true;

  // p2, q2 and q1 are collinear and q1 lies on segment p2q2
  if (o4 == 0 && onSegment(p2, q1, q2)) return true;

  return false; // Doesn't fall in any of the above cases
}

export function distanceTo(pos1, pos2) {
  return Math.sqrt((pos1.x - pos2.x) ** 2 + (pos1.y - pos2.y) ** 2);
}

export function collide(el1, el2) {
  let w1 = el1.width(),
    h1 = el1.height(),
    w2 = el2.width(),
    h2 = el2.height();
  let x1 = el1.position("x"),
    y1 = el1.position("y"),
    x2 = el2.position("x"),
    y2 = el2.position("y");

  return Math.abs(x1 - x2) <= 0.5 * (w1 + w2) && Math.abs(y1 - y2) <= 0.5 * (h1 + h2);
}

export function getGraphIntersection(cy, target) {
  let nodes = [];
  if (cy) {
    const pos = target.position();
    nodes = cy.nodes(".unit");
    nodes = nodes.filter(
      (el, i) =>
        el !== target &&
        !el.descendants().contains(target) &&
        !el.edgesWith(target).length &&
        collide(target, el) &&
        Math.min(el.width(), el.height()),
    );
  }
  return nodes[0];
}

function getDistWeight(sX, sY, tX, tY, PointX, PointY) {
  var W, D;

  D = (PointY - sY + ((sX - PointX) * (sY - tY)) / (sX - tX)) / Math.sqrt(1 + Math.pow((sY - tY) / (sX - tX), 2));
  W = Math.sqrt(Math.pow(PointY - sY, 2) + Math.pow(PointX - sX, 2) - Math.pow(D, 2));

  var distAB = Math.sqrt(Math.pow(tX - sX, 2) + Math.pow(tY - sY, 2));
  W = W / distAB;

  //check whether the point (PointX, PointY) is on right or left of the line src to tgt. for instance : a point C(X, Y) and line (AB).  d=(xB-xA)(yC-yA)-(yB-yA)(xC-xA). if d>0, then C is on left of the line. if d<0, it is on right. if d=0, it is on the line.
  var delta1 = (tX - sX) * (PointY - sY) - (tY - sY) * (PointX - sX);
  switch (true) {
    case delta1 >= 0:
      delta1 = 1;
      break;
    case delta1 < 0:
      delta1 = -1;
      break;
  }
  //check whether the point (PointX, PointY) is "behind" the line src to tgt
  var delta2 = (tX - sX) * (PointX - sX) + (tY - sY) * (PointY - sY);
  switch (true) {
    case delta2 >= 0:
      delta2 = 1;
      break;
    case delta2 < 0:
      delta2 = -1;
      break;
  }

  D = Math.abs(D) * delta1; //ensure that sign of D is same as sign of delta1. Hence we need to take absolute value of D and multiply by delta1
  W = W * delta2;

  return {
    ResultDistance: D,
    ResultWeight: W,
  };
}

export function correctEdgeSegments(elem, force = null) {
  if (!elem) return;
  if (
    elem.source().filter(".he, .unit, .io, .anchor, .pin, .cy-expand-collapse-collapsed-node").length *
      elem.target().filter(".he, .unit, .io, .anchor, .pin, .cy-expand-collapse-collapsed-node").length ==
    0
  )
    return;
  if (elem.style("curve-style") !== "segments" && elem.style("curve-style") !== "straight") return;

  let sPos = elem.source().position();
  let tPos = elem.target().position();

  let { x: sX, y: sY } = sPos;
  let { x: tX, y: tY } = tPos;

  let addControlPoint = (axis, value, points) => {
    let newPoint = {};
    let otherAxis = axis == "x" ? "y" : "x";
    newPoint[axis] = value;
    let oldPoint = points.slice(-1)[0] ?? sPos;
    newPoint[otherAxis] = oldPoint[otherAxis];
    newPoint.dir = Math.sign(oldPoint[axis] - value);

    if (tX > sX) {
      if (tY > sY) {
        newPoint.dir = axis == "x" ? -1 : 1;
      } else {
        newPoint.dir = -Math.sign(oldPoint[axis] - value);
      }
    } else {
      if (tY > sY) {
        newPoint.dir = Math.sign(oldPoint[axis] - value);
      } else {
        newPoint.dir = axis == "x" ? -1 : 1;
      }
    }

    points.push(newPoint);
  };

  let addControlPoints = (e, points) => {
    let dists = [];
    let ratios = [];
    points.forEach((p, i) => {
      if (isNaN(sX) || isNaN(sY) || isNaN(tX) || isNaN(tY)) return;
      let oldPoint = points.slice(-1)[0] ?? sPos;

      const { ResultDistance: dist, ResultWeight: ratio } = getDistWeight(sX, sY, tX, tY, p.x, p.y);

      if (Math.abs(dist) > 0 && Math.abs(ratio) > 0) {
        dists.push(dist);
        ratios.push(ratio);
      }
    });

    if (dists.length > 0 && ratios.length > 0) {
      elem?.data({
        segmentDistances: `${dists.join(" ")}`,
        segmentWeights: `${ratios.join(" ")}`,
        curveStyle: "segments",
      });
    } else {
      elem?.data({
        curveStyle: "straight",
      });
    }
  };

  let p = [];

  let getAngle = (elem) =>
    elem.filter(".io").length ? (elem.scratch("parent").data("angle") ?? elem.data("angle")) : undefined;

  let isFlat = (elem) => Math.abs(Math.cos(getAngle(elem))) > SQRT2_OVER2;
  let isUp = (elem) => Math.abs(Math.sin(getAngle(elem))) > SQRT2_OVER2;

  let sUp = isUp(elem.source());
  let sFlat = isFlat(elem.source());
  let sNull = getAngle(elem) === undefined;
  let tUp = isUp(elem.target());
  let tFlat = isFlat(elem.target());
  let r = (sUp && tUp) || (sFlat && tFlat) ? 0.5 : 1; // position ratio

  // Main control Point
  if (sUp || (sNull && tFlat)) {
    addControlPoint("y", (1 - r) * sY + r * tY, p);
  } else if (sFlat || (sNull && tUp)) {
    addControlPoint("x", (1 - r) * sX + r * tX, p);
  } else {
    if (force) {
      addControlPoint("x", (1 - r) * sX + r * tX, p);
    }
    addControlPoint("y", (1 - r) * sY + r * tY, p);
  }

  // Secondary control point
  if (sUp && tUp) {
    addControlPoint("x", tX, p);
  } else if (sFlat && tFlat) {
    addControlPoint("y", tY, p);
  }

  // addControlPoint("y", 0.5 * (s.position().y + t.position().y), p);
  // addControlPoint("x", elem.targetEndpoint().x || t.position().x, p);

  addControlPoints(elem, p);

  // Retry another way if draw failed
  setTimeout(() => {
    if (isNaN(elem.targetEndpoint().x + elem.targetEndpoint().y + elem.sourceEndpoint().x + elem.sourceEndpoint().y)) {
      if (!force)
        correctEdgeSegments(elem, true); //first try
      else elem.data("curveStyle", "straight"); // fallback
    }
  }, 10);
}

import { Controller } from "@hotwired/stimulus";
import { datePickers } from "@js/helpers/datepickers";
import AttendancePackMeasuresRenderer from "@js/renderers/Measures/AttendancePackMeasuresRenderer";
import ChemicalPackMeasuresRenderer from "@js/renderers/Measures/ChemicalPackMeasuresRenderer";
import ChemicalPackPeriodRenderer from "@js/renderers/Periods/ChemicalPackPeriodRenderer";
import ChemicalPackNewMeasureForm from "@js/pages/chemicalpack/ChemicalPackNewMeasureForm";
import IssueNewMeasureForm from "@js/helpers/IssueNewMeasureForm";
import $ from "jquery";

export default class extends Controller {
  connect() {
    const pikadayElements = datePickers();
    let measuresRenderer = new AttendancePackMeasuresRenderer(false);
    measuresRenderer.init();
    measuresRenderer = new ChemicalPackMeasuresRenderer(false);
    measuresRenderer.init();

    let periodRenderer = new ChemicalPackPeriodRenderer();
    periodRenderer.init();

    let newMeasureForm = new ChemicalPackNewMeasureForm(pikadayElements.new, $(".chemical-side"));
    newMeasureForm.init();
    newMeasureForm.addDataSentCallback(measuresRenderer.render);
    newMeasureForm.addDataSentCallback(periodRenderer.render);
  }
}

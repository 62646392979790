import AbstractPackNewMeasureForm from "./AbstractPackNewMeasureForm";
import ToConfirmButton from "../entity/ToConfirmButton";
import DateManager, { BASE_DATETIME_FORMAT } from "./DateManager";
import Swal from "sweetalert2";
import { ISSUE_INPUT_ID_PREFIX } from "./utils";
import $ from "jquery";
import { DateTime } from "luxon";

class IssueNewMeasureForm extends AbstractPackNewMeasureForm {
  constructor(datePicker) {
    super("issue", [], datePicker);

    this.init = this.init.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.readFormData = this.readFormData.bind(this);
    this.addDataSentCallback = this.addDataSentCallback.bind(this);

    // Get current page parameters
    let body = $("body");
    this._pool_id = body.data("pool");
    this._client_id = body.data("client");

    this._submit = new ToConfirmButton(ISSUE_INPUT_ID_PREFIX + "-submit");
  }

  fieldChangeCallback(field) {
    super.fieldChangeCallback(field);
  }

  init() {
    super.init();

    this.addDataSentCallback(() => {
      this._submit.reset();
    });

    $("#issue-pack").change(function () {
      let unit = "";

      switch ($(this).val()) {
        case "None":
          $(".if-measure").hide();
          break;

        case "Water":
          unit = "m³";
          $(".if-measure").show();
          break;
        case "Energy":
          unit = "MWh";
          $(".if-measure").show();
          break;
        case "Chemical":
          unit = "L";
          $(".if-measure").show();
          break;
        case "Attendance":
          unit = "Personne";
          $(".if-measure").show();
          break;
      }
      $(".issue-value-unit").text(`Valeur (${unit})`);

      $(`.choices-circuit-${$(this).val()}`).show();

      switch ($("#issue-element").val()) {
        case "0":
          $("[class*=choices-circuit-").hide();
          $(".choices-pool").show();
          break;

        default:
          $("[class*=choices-circuit-").hide();
          $(".choices-pool").hide();
          $(`.choices-circuit-${$("#issue-element").val()}`).show();
          break;
      }

      $("[class*=choices-pack-]")
        .not(`.choices-pack-${$(this).val()}`)
        .hide();

      $("#issue-measure").val(
        $("#issue-measure option")
          .filter(function (index) {
            return $(this).css("display") != "none";
          })
          .val(),
      );
    });

    $("[class^=choices-circuit-").hide();
    $("#issue-element").change(function () {
      switch ($(this).val()) {
        case "0":
          $("[class*=choices-circuit-").hide();
          $(".choices-pool").show();
          break;

        default:
          $("[class*=choices-circuit-").hide();
          $(".choices-pool").hide();
          $(`.choices-circuit-${$(this).val()}`).show();
          break;
      }
      $("[class*=choices-pack-]")
        .not(`.choices-pack-${$("#issue-pack").val()}`)
        .hide();
    });
  }

  checkExistingData() {
    this._submit.show();
  }

  dataAlreadySentCallback(data) {
    this._submit.show();
  }

  noDataSentCallback(data) {
    this._submit.show();
  }

  readFormData() {
    let ret = { date: null, data: {} };

    let timestamp = DateManager.parseDateFromInput(this._date_field);
    let today = DateTime.local();
    timestamp.set({ hours: today.hour, minutes: today.minute });
    ret.date = timestamp.toFormat(BASE_DATETIME_FORMAT);

    ret.data["type"] = $("#issue-select").val();
    ret.data["periodStart"] = DateManager.parseDateFromInput($(`#pack-datepicker-issue-start`));
    ret.data["periodEnd"] = DateManager.parseDateFromInput($(`#pack-datepicker-issue-end`));
    ret.data["pack"] = $("#issue-pack").val();
    ret.data["element"] = $("#issue-element").val();
    ret.data["measure"] = $("#issue-measure").val();
    ret.data["value"] = $("#issue-value").val();
    ret.data["description"] = $("#issue-description").val();

    return ret;
  }

  addDataSentCallback(callback) {
    this._data_sent_callbacks.push(callback);
  }

  /**
   * Update form fields with indicators
   */
  updateForm(data) {}

  checkField(field) {
    return super.checkField(field.find("input"));
  }

  postSendPopup() {
    this.successMessage("Saisie enregistrée.");
    Swal.fire({
      title: "Saisie enregistrée",
      icon: "success",
      width: "auto",
    });
    this._data_sent_callbacks.forEach((callback) => {
      callback();
    });
    this.checkExistingData();
  }
}

export default IssueNewMeasureForm;

import AbstractPackNewMeasureForm from "../../helpers/AbstractPackNewMeasureForm";
import ToConfirmButton from "../../entity/ToConfirmButton";
import DateManager, { BASE_DATETIME_FORMAT } from "../../helpers/DateManager";
import tippy, { createSingleton } from "tippy.js";
import "tippy.js/themes/material.css";
import "tippy.js/dist/tippy.css"; // optional for styling
import $ from "jquery";
import { DateTime } from "luxon";

const CHEMICAL_TOTAL_FIELD_SUFFIX_ID = "total";
const CHEMICAL_INPUT_ID_PREFIX = "#chemical-new-value-form";
const CHEMICAL_REFERENCE_PREFIXES = "chemical_";
const INVALID_FORM_FIELD_CLASS = "form-modal-input-invalid";

const CIRCUIT_FIELD_ID = CHEMICAL_INPUT_ID_PREFIX + "-element";

class ChemicalPackNewMeasureForm extends AbstractPackNewMeasureForm {
  constructor(datePicker, container = $("body")) {
    super("chemical", [], datePicker, container);

    this.init = this.init.bind(this);
    this.setCircuit = this.setCircuit.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.readFormData = this.readFormData.bind(this);
    this.updateTotal = this.updateTotal.bind(this);
    this.addDataSentCallback = this.addDataSentCallback.bind(this);

    // Get current page parameters
    let body = $("body");
    this._pool_id = body.data("pool");
    this._client_id = body.data("client");

    this._total_field = $(`${CHEMICAL_INPUT_ID_PREFIX}-${CHEMICAL_TOTAL_FIELD_SUFFIX_ID}`);

    this._submit = new ToConfirmButton(CHEMICAL_INPUT_ID_PREFIX + "-submit");
  }

  fieldChangeCallback(field) {
    super.fieldChangeCallback(field);
    this.updateTotal();
  }

  init() {
    super.init();

    this.addDataSentCallback(() => {
      this._submit.reset();
      if (this._onsen_user) {
        this._submit.text("Modifier");
      } else {
        for (let field of this._fields_keys) {
          this.container.find(".js-hide-on-disable").hide();
          // this._fields[field].find('input').prop('disabled', true)
        }
      }
    });
  }

  updateTotal() {
    let parent = this;
    let total = Object.values(this.indics).reduce(
      (acc, indic) => acc + (parseInt($(parent._fields[indic.reference]).find("input").val()) || 0),
      0,
    );
    this._total_field.val(total);
  }

  noDataSentCallback(data) {
    this.resetMessage();
    this._submit.text("Enregistrer");
    let parent = this;
    this.indics.forEach(function (indic, index) {
      $(parent._fields[indic.reference])
        .find("input")
        .val(data[indic.reference] || 0);
      // $(parent._fields[indic.reference]).find('input').prop('disabled', false);
    });
    this.updateTotal();
  }

  readFormData() {
    let ret = { date: null, data: {} };

    let parent = this;
    this.indics.forEach(function (indic) {
      if (parent._fields[indic.reference]) {
        ret.data[indic.reference] = parseInt(parent._fields[indic.reference].find("input").val());
      }
    });

    let timestamp = DateManager.parseDateFromInput(this._date_field);
    let today = DateTime.local().startOf("day");
    timestamp.set({ hours: today.hour, minutes: today.minute });
    ret.date = timestamp.toFormat(BASE_DATETIME_FORMAT);

    return ret;
  }

  addDataSentCallback(callback) {
    this._data_sent_callbacks.push(callback);
  }

  /**
   * Update form fields with indicators
   */
  updateForm(data) {
    let parent = this;
    this._fields = {};

    data.indicators.forEach((indic, index) => {
      let field = $(`#chemical-new-value-form-indicator-${index}`);
      if (!indic.formula) {
        field.removeClass("d-none");
        parent.indics[index] = indic;
        parent._fields[indic.reference] = field;
        field.find("label").text(indic.label + " :");
        field.find("input").val(data[indic.label]);

        field.find("input").change(() => {
          parent.fieldChangeCallback(field);
        });
      } else {
        field.addClass("d-none");
      }
    });

    this.tooltips.forEach((e) => e.destroy());
    this.tooltips = [];

    data.indicators.forEach((indic, index) => {
      let field = $(`#chemical-new-value-form-indicator-${index}`);
      if (!indic.formula) {
        field.removeClass("d-none");
        parent.indics[index] = indic;
        parent._fields[indic.reference] = field;
        field.find("label").text(indic.label + " :");
        field.find("input").val(data?.[indic.reference]?.value);

        let estimatedIndex = null;
        if (data?.[indic.reference]?.remote && data?.[indic.reference]?.value) {
          estimatedIndex = data?.[indic.reference]?.value + data?.[indic.reference]?.remote;
        }

        this.tooltips.push(
          ...tippy(`#chemical-new-value-form-indicator-${index}`, {
            content: estimatedIndex ? `Estimation: ${estimatedIndex.toFixed(2)}` : "Pas d'estimation disponible",
            trigger: "mouseenter focus focusin click",
            animation: "fade",
            placement: "right",
            theme: "material",
            arrow: true,
          }),
        );

        field.find("input").on("change", () => {
          parent.fieldChangeCallback(field);
        });
      } else {
        field.addClass("d-none");
      }
    });
  }

  checkField(field) {
    return super.checkField(field.find("input"));
  }

  setCircuit(id, name) {
    this._circuit_id = parseInt(id);
    this._circuit_name = name;
    $(CIRCUIT_FIELD_ID).text(this._circuit_name);
    this._data_store.circuit = this._circuit_id;
    this.checkExistingData();
  }
}

export default ChemicalPackNewMeasureForm;

import $ from "jquery";
import { Controller } from "@hotwired/stimulus";
import { DateTime } from "luxon";

export default class extends Controller {
  static targets = ["select", "dashboardList", "packName"];
  static values = { dashboardsList: String };
  static data = { data: Array };

  packNameMapping = {
    0: "Fréquentation",
    1: "Réglementation",
    2: "Eau",
    3: "Energie",
    4: "Air",
    5: "Chemical",
    6: "Datum",
    7: "Dataset",
    8: "Index Mesure",
    9: "Financier",
    null: "Géneral",
  };

  connect() {
    // let poolId = e.target.value;
    $("#dashboard_table").DataTable({
      ajax: {
        url: `api/v1/dashboards?page=1`,
        dataSrc: "",
      },

      language: {
        processing: "Traitement en cours...",
        search: "Rechercher&nbsp;:",
        lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
        info: "",
        infoEmpty: "",
        infoFiltered: "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
        emptyTable: "Aucune donnée disponible dans le tableau",
        paginate: {
          first: "<<",
          previous: "<",
          next: ">",
          last: ">>",
        },
        aria: {
          sortAscending: ": activer pour trier la colonne par ordre croissant",
          sortDescending: ": activer pour trier la colonne par ordre décroissant",
        },
      },
      columns: [
        { data: "pool.entitled" },
        {
          data: "category",
          render: function (data, type, row) {
            if (typeof data === "number" && this.packNameMapping[data]) {
              return this.packNameMapping[data];
            }
            return "Général";
          }.bind(this),
        },
        { data: "title" },
        { data: "description", defaultContent: "" },
        {
          data: "circuit.name",
          render: function (data, type) {
            if (type === "display") {
              if (!data || data.trim() === "") {
                return `<i class="fas fa-swimming-pool"></i>`;
              } else {
                return data;
              }
            }
          },
          defaultContent: "NO DATA",
        },
        {
          data: "createdAt",
          type: "date",
          render: function (data, type) {
            if (type === "display") {
              return DateTime.fromJSDate(new Date(data)).setLocale("fr").toLocaleString(DateTime.DATE_MED);
            }
            return data;
          },
        },
        {
          data: "url",
          // TODO AJOUTER LE CONTROLLER EXTERNAL DASHBOARD
          render: function (data, type, row, meta) {
            if (data) {
              return `
                                 <div class="td-center" data-controller="external-dashboard">
                                  <a data-action="click->external-dashboard#openModal" href="${data}">
                                  <i class="fas fa-chart-line"></i>
                                  </a>
                                  </div>

                                    `;
            } else {
              return "";
            }
          },
        },
      ],
      rowCallback: function (row, data) {
        let lastIndex = this.api().rows().count() - 1;
        if (this.api().row(row).index() === lastIndex) {
          $(row).find(".report_badge").remove(".report_badge");
          $(row).find("td:first").append('   <span class="report_badge">New</span>');
        }
      },

      paging: true,
    });
  }
}

// async loadCircuit(){
//     $('#selectPack').on('change', (e) => {
//         const selectedOption = $(e.target).find('option:selected');
//         const dataPackValue = selectedOption.data('pool');
//         console.log(`Option sélectionnée avec data-pack: ${dataPackValue}`);
//     });
//
//     if (this.dataValue) {
//         this.createSelectCircuitName(this.dataValue);
//     } else {
//         console.log('Aucune donnée n\'est disponible.');
//     }
// }
//
// /**
//  * Méthode pour créer les option avec les nom de circuit
//  * @param data
//  */
// createSelectCircuitName(data){
// const select = $('<select>', {
//     id: 'select',
//     name: 'ca'
// });
// $.each(data, function (index, item){
//
//     let option = $('<option>', {
//         value: item.circuit.name,
//         text: item.circuit.name
//     }).appendTo(select);
//
// });
//     const circuitSelect = $('#selectCircuit');
//     circuitSelect.empty();
//     circuitSelect.append(select);
// }
//
// createSelectPackName(packCount){
//     let button = $('<button>');
//     $.each(packCount, function (index, item) {
//         button = $('<button>', {
//             value: index,
//             text:`   ${index} : ${item} disponible(s)`
//         });
//
//     });
//     const circuitSelect = $('#selectPack');
//     circuitSelect.empty();
//     circuitSelect.append(button);
// }
//
//
// countPackByName(data) {
//     const packCount = {};
//     data.forEach(item => {
//         const category = this.getCategoryName(item.category) ;
//
//         // Incrémentez le compteur pour cette catégorie ou initialisez-le à 1 si la catégorie n'existe pas encore
//         if (packCount[category] === undefined) {
//             packCount[category] = 1;
//         } else {
//             packCount[category]++;
//         }
//     });
//     return packCount;
// }
//
// getCategoryName(categoryNumber) {
//     const categoryNames = {
//         0: "Fréquentation",
//         1: "Réglementation",
//         2: "Eau",
//         3: "Energie",
//         4: "Air",
//         5: "Chemical",
//         6: "Datum",
//         7: "Dataset",
//         8: "Index Mesure",
//         9: "Financier"
//     };
//
//     // Vérifiez si le type de catégorie existe dans la liste de correspondance
//     if (categoryNumber in categoryNames) {
//         return categoryNames[categoryNumber];
//     } else {
//         // Si le type de catégorie n'a pas de correspondance, renvoyez une valeur par défaut
//         return `Catégorie inconnue (${categoryNumber})`;
//     }
// }
//
// initializeCategoryButtons() {
//     const categoryButtons = $("[data-action='click->dashboard#loadDashboard']");
//     categoryButtons.on("click", this.loadDashboardsByPack.bind(this));
// }
//
// loadDashboardsByPack(e) {
//     const categoryId = $(e.currentTarget).data('categoryid');
//     const poolId = $(e.currentTarget).data('poolid');
//     console.log(categoryId);
//
//     $.ajax({
//         url: `/api/v1/dashboards?page=1&category=${categoryId}&pool=${poolId}`,
//         method: 'GET',
//     }).done(function (data) {
//         // Traitement réussi
//         console.log('Succès', data);
//     }).fail(function (xhr, status, error) {
//         // Gestion des erreurs
//         console.error('Erreur', status, error);
//     });
// }
